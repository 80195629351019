import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_VenueownerAppointment from "../../Components/Admin_components/add_appointment.js";
import Delete_VenueownerAppointment from "../../Components/Admin_components/delete_appointment.js";
import Update_VenueownerAppointment from "../../Components/Admin_components/update_appointment.js";
import "../../Api/apiUrls.js";
import { API_URL, getAppointmentByVenueOwnerId } from "../../Api/apiUrls";

function VenueownerAppointment() {
  const [
    modalAdd_VenueownerAppointmentIsOpen,
    setAdd_VenueownerAppointmentModalIsOpen,
  ] = useState("");
  const [
    modalDelete_VenueownerAppointmentIsOpen,
    setDelete_VenueownerAppointmentIsOpen,
  ] = useState(false);
  const [
    modalUpdate_VenueownerAppointmentIsOpen,
    setUpdate_VenueownerAppointmentModalIsOpen,
  ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const type = [
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Reviewing", label: "Reviewing" },
  ];
  const openAdd_VenueownerAppointment = () => {
    closeAdd_VenueownerAppointment();
    setAdd_VenueownerAppointmentModalIsOpen(true);
  };

  const closeAdd_VenueownerAppointment = () => {
    setAdd_VenueownerAppointmentModalIsOpen(false);
  };
  const openUpdate_VenueownerAppointment = () => {
    closeUpdate_VenueownerAppointment();
    setUpdate_VenueownerAppointmentModalIsOpen(true);
  };

  const closeUpdate_VenueownerAppointment = () => {
    setUpdate_VenueownerAppointmentModalIsOpen(false);
  };
  const openDelete_VenueownerAppointment = (userId) => {
    setSelectedUserId(userId);
    closeDelete_VenueownerAppointment();
    setDelete_VenueownerAppointmentIsOpen(true);
  };

  const closeDelete_VenueownerAppointment = () => {
    setDelete_VenueownerAppointmentIsOpen(null);
  };
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };
  const [appointment, setAppointment] = useState([]);
  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);
  const venueOwnerId = localStorage.getItem("user_id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${getAppointmentByVenueOwnerId}${venueOwnerId}`
        );
        const data = await response.json();
        setAppointment(data.data.Appointments);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    console.log(appointment.length);
  }, [appointment]);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const formatTimeCreatedAt = (createdAt) => {
    const createdAtTime = new Date(createdAt);
    return createdAtTime.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    const filteredAppointments = appointment.filter((appointment) => {
      const isStatusMatch =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        appointment.status === selectedStatus.value;
      const values = Object.values(appointment).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );

      return isStatusMatch && isSearchMatch;
    });

    // Rest of your code...
    setFilteredAppointments(filteredAppointments);
  }, [selectedStatus, appointment, searchQuery]);
  function convertTimeTo12HourFormat(time) {
    if (!time || time === "--") return "--";

    const [hour, minute, second] = time.split(":");
    let formattedHour = parseInt(hour, 10);
    const isPM = formattedHour >= 12;
    formattedHour = formattedHour % 12 || 12; // Convert 0 to 12 for 12 AM/PM

    const formattedTime = `${formattedHour}:${minute} ${isPM ? "PM" : "AM"}`;
    return formattedTime;
  }
  return (
    <div>
      <Header></Header>
      {modalAdd_VenueownerAppointmentIsOpen && (
        <Add_VenueownerAppointment onClose={closeAdd_VenueownerAppointment} />
      )}

      {modalDelete_VenueownerAppointmentIsOpen && (
        <Delete_VenueownerAppointment
          userId={selectedUserId}
          onClose={closeDelete_VenueownerAppointment}
        />
      )}
      {modalUpdate_VenueownerAppointmentIsOpen && (
        <Update_VenueownerAppointment
          onClose={closeUpdate_VenueownerAppointment}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Appointment</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Appointment</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    placeholder="Search..."
                  />
                </div>
                {/* <div class="child ml-2">
                  <Select options={type} placeholder="Select Type"></Select>
                </div> */}
                <div class="child ml-2">
                  <Select
                    options={status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                    value={selectedStatus}
                  ></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {/* <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_appointment}
                    >
                      <i class="bi bi-person-add"></i> Add Appointment
                    </button> */}
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : appointment.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Title</th>
                      <th scope="col">Slot With Appointment Date</th>
                      {/* <th scope="col">Gift</th> */}
                      {/* <th scope="col">Appointment Date </th> */}
                      <th scope="col">Customer Details</th>

                      <th scope="col">Payment Amount</th>
                      {/* <th scope="col">Payment Mode</th>
                      <th scope="col">Transaction Status</th> */}
                      <th scope="col">Booking Date Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Review/Cancellation Reason</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredAppointments.map((appointmentItem, index) => (
                      <tr key={appointmentItem.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {appointmentItem.bookingSlot?.venue?.name || "--"}

                          <br />
                        </td>
                        <td>
                          {formatCreatedAt(
                            appointmentItem.bookingSlot?.startDate || "--"
                          )}
                          <br />
                          {convertTimeTo12HourFormat(
                            appointmentItem.bookingSlot?.startTime || "--"
                          )}{" "}
                          -
                          {convertTimeTo12HourFormat(
                            appointmentItem.bookingSlot?.endTime || "--"
                          )}
                          <br />
                        </td>
                        {/* <td>
                          {appointmentItem.gift?.name || "--"}
                          <br />
                          {appointmentItem.gift?.mobileNumber || "--"}
                          <br />
                          {appointmentItem.gift?.email || "--"}
                          <br />
                        </td> */}
                        {/* <td>
                  <b>{formatCreatedAt(appointmentItem.dateTime)}</b>
                  <br />
                </td> */}
                        <td>
                          {appointmentItem.user?.name || "No Name"}
                          <br />

                          {appointmentItem.user?.mobileNumber || "No Name"}

                          <br />
                          {appointmentItem.user?.email || "No Name"}
                          <br />
                        </td>
                        <td>
                          {appointmentItem.transaction?.amount || "--"}
                          <br />
                        </td>
                        {/* <td>
                          <b>{appointmentItem.paymentMode || "--"}</b>
                          <br />
                        </td>
                        <td>
                          <b>
                            {appointmentItem.transaction?.transaction_status ||
                              "--"}
                          </b>
                          <br />
                        </td> */}
                        <td>
                          {formatCreatedAt(appointmentItem.created_at || "--")}

                          <br />

                          {formatTimeCreatedAt(
                            appointmentItem.created_at || "--"
                          )}

                          <br />
                        </td>
                        <td>
                          {appointmentItem.status || "--"}
                          <br />
                        </td>
                        <td>
                          {appointmentItem.reviewOrReasonOfCancellation || "--"}

                          <br />
                        </td>
                        {/* <td>
                          <button
                            id="btnNewUser"
                            type="button"
                            data-toggle="modal"
                            data-target="#modalAdd"
                            className="btn btn-round"
                          >
                            <i></i> Cancel
                          </button>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredAppointments.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default VenueownerAppointment;
