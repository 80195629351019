import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";

function Ask_question() {
  return (
    <div>
      <Header></Header>
      {/* {modalAdd_ServicesIsOpen && <Add_Services onClose={closeAdd_Services} />}
      {modalUpdate_ServicesIsOpen && (
        <Update_Services onClose={closeUpdate_Services} />
      )}
      {modalDelete_ServicesIsOpen && (
        <Delete_Services onClose={closeDelete_Services} />
      )} */}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Ask Question</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Ask_question</li>
            </ol>
          </nav>
        </div>
        <section class="section"></section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Ask_question;
