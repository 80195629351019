import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_VenueownerTransaction from "../../Components/Venue_owner_comp/add_transactions.js";
import Delete_VenueownerTransaction from "../../Components/Venue_owner_comp/delete_transaction.js";

function VenueownerTransaction() {
  const [
    modalAdd_VenueownerTransactionIsOpen,
    setAdd_VenueownerTransactionModalIsOpen,
  ] = useState("");
  const [
    modalDelete_VenueownerTransactionIsOpen,
    setDelete_VenueownerTransactionIsOpen,
  ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const status = [
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
  ];
  const type = [
    { value: "Venue", label: "Venue" },
    { value: "News", label: "News" },
    { value: "Blog", label: "Blog" },
  ];
  const openAdd_VenueownerTransaction = () => {
    closeAdd_VenueownerTransaction();
    setAdd_VenueownerTransactionModalIsOpen(true);
  };

  const closeAdd_VenueownerTransaction = () => {
    setAdd_VenueownerTransactionModalIsOpen(false);
  };
  const openDelete_VenueownerTransaction = (userId) => {
    setSelectedUserId(userId);
    closeDelete_VenueownerTransaction();
    setDelete_VenueownerTransactionIsOpen(true);
  };

  const closeDelete_VenueownerTransaction = () => {
    setDelete_VenueownerTransactionIsOpen(null);
  };
  return (
    <div>
      <Header></Header>
      {modalAdd_VenueownerTransactionIsOpen && (
        <Add_VenueownerTransaction onClose={closeAdd_VenueownerTransaction} />
      )}
      {modalDelete_VenueownerTransactionIsOpen && (
        <Delete_VenueownerTransaction
          userId={selectedUserId}
          onClose={closeDelete_VenueownerTransaction}
        />
      )}

      {/* {modalAdd_AboutUsIsOpen && <Add_AboutUs onClose={closeAdd_AboutUs} />} */}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Transaction</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Transaction</li>
            </ol>
          </nav>
        </div>
        <section class="section"></section>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div class="child ml-2">
                  <Select options={type} placeholder="Select Type"></Select>
                </div>
                <div class="child ml-2">
                  <Select options={status} placeholder="Select Status"></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    {/* <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_transaction}
                    >
                      <i class="bi bi-person-add"></i> Add Transaction
                    </button> */}
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <table class="table table-striped  ">
              <thead>
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Tittle</th>
                  <th scope="col">Type</th>
                  <th scope="col">Slot</th>
                  <th scope="col">Seats</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Status</th>
                  {/* <th scope="col">Action</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  {/* <td>
                    <div className="social-links mt-2">
                      { <button className="btn btn-warning btn-sm">
                        <i
                          className="fas fa-pencil-alt"
                          style={{ color: "#fff" }}
                        ></i>
                      </button> }
                      &nbsp;&nbsp;
                      <a onClick={openDelete_transaction}>
                        <i className="fas fa-trash-alt"></i>
                      </a>
                      &nbsp;
                    </div>
                  </td> */}
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default VenueownerTransaction;
