
import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Api/apiUrls";
import Select from "react-select";
import $ from 'jquery';
import Select2 from 'react-select2-wrapper';
import 'select2/dist/css/select2.css';
import { API_URL , addworkshopExcel } from "../../Api/apiUrls";

function AddWorkshopbyExcel({ onClose }) {
    const [modalAdd_ecxelIsOpen, setAdd_excelModalIsOpen] = useState("");
    const [instructors, setInstructors] = useState([]);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [selectedInstructor, setSelectedInstructor] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedFile, setSelectedFile] = useState(null);

    const [isAdding, setIsAdding] = useState(false);

    const closeAdd_excel = () => {
        setAdd_excelModalIsOpen(false);
    };

    function reloadPage() {
        window.location.reload();
    }

    useEffect(() => {
        fetch(`${API_URL}/user/get-instructor/`)
            .then(response => response.json())
            .then(data => {
                const options = data.data.users.map(user => ({
                    id: user.id,
                    text: user.name,
                    image: `${API_URL}/${user.profile_image}`,
                }));
                setInstructors(options);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching instructors:', error);
                setIsLoading(false);
            });
    }, []);


    const handleFileChange = (event) => {
        if (event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
    
        if (!selectedFile || !selectedInstructor) {
            alert("Please select a file and an instructor.");
            return;
        }
    
        setIsAdding(true);  // Indicate that the submission is in progress
    
        const formData = new FormData();
        formData.append('excel_file', selectedFile); // Ensure this key matches your API's expected key
        formData.append('workshop_instructor_id', selectedInstructor);
    
        fetch(`${addworkshopExcel}${selectedInstructor}`, {
            method: 'POST',
            body: formData,
        })
        .then(response => response.json())
        .then(data => {
            if (!data.success && data.status === 400) {
                // Download the error file if success is false and status is 400
                const errorFileUrl = data.file_url;
                window.open(errorFileUrl, '_blank');
                window.location.reload();
            } else {
                console.log('Success:', data);
                // Reload the page or handle success case
                window.location.reload();
            }
            setIsAdding(false);  // Reset submission state
        })
        .catch((error) => {
            console.error('Error:', error);
            window.open(`https://artiquity.in/api/media/error_excel/workshop_errors.xlsx`, '_blank');
            window.location.reload();
            setIsAdding(false);  // Reset submission state
            // Handle error
        });
    };
    


    // Helper functions to format the display of options
    

    
    
    const formatOption = (state) => {
        if (!state.id) return state.text; // Default text

        const instructor = instructors.find(inst => inst.id == state.id);
        if (!instructor) return state.text;

        return $(
            `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
        );
    };

    const formatOptionSelection = (state) => {
        if (!state.id) return state.text; // Default text

        const instructor = instructors.find(inst => inst.id == state.id);
        if (!instructor) return state.text;

        return $(
            `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
        );
    };

    return (
        <div>
            {modalAdd_ecxelIsOpen && <AddWorkshopbyExcel onClose={closeAdd_excel} />}
            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "75%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",

                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Add Workshop by Excel</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="selectedFile">
                                        Upload Excel
                                    </label>
                                    <br />
                                    <input
                                        type="file"
                                        id="selectedFile"
                                        name="document"
                                        accept=".xls,.xlsx,.xlsm,.xlsb,.xltx,.xltm"
                                        onChange={handleFileChange}
                                    />
                                </div>
                                {/* <div className="form-group">
                                    <label className="small mb-1" htmlFor="inputFullName">
                                        Select Instructor<font color="red" style={{ fontSize: "10px" }}></font>
                                    </label>
                                    <Select
                                        options={filteredOptions}  // Use filteredOptions to allow search functionality
                                        onInputChange={handleInputChange}
                                        value={selectedInstructor}
                                        onChange={handleInstructorChange}
                                        getOptionLabel={(option) => (
                                            <div>
                                                <img
                                                    src={option.image}
                                                    style={{ width: "30px", marginRight: "10px" }}
                                                    alt={option.label}
                                                />
                                                {option.label}
                                            </div>
                                        )}
                                        getOptionValue={(option) => option.value}
                                        isSearchable
                                        isLoading={isLoading}  // Show a loading spinner while fetching data
                                    />
                                </div> */}

                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="instructorSelect">
                                        Select Instructor <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                    </label>
                                    {!isLoading ? (
                                        <Select2
                                            id="instructorSelect"
                                            data={instructors}
                                            value={selectedInstructor}
                                            onChange={(e) => setSelectedInstructor(e.target.value)}
                                            options={{
                                                placeholder: 'Select an instructor',
                                                allowClear: true,
                                                templateResult: formatOption,
                                                templateSelection: formatOptionSelection,
                                            }}
                                            style={{ width: '100%'}}
                                        />
                                    ) : (
                                        <p>Loading instructors...</p>
                                    )}
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAdding === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleFormSubmit}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}




export default AddWorkshopbyExcel
