import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Toggle from "react-toggle";
import Add_faq from "../../Components/Admin_components/add_Faq.js";
import { useAccordionButton } from "react-bootstrap";
import Update_faq from "../../Components/Admin_components/update_faq.js";
import Delete_faq from "../../Components/Admin_components/delete_faq.js";
import "../../Api/apiUrls.js";
import {API_URL, getFaqForFaqPage, updateToggleFaq} from "../../Api/apiUrls";


function Faq(UserId) {
  const [modalAdd_faqIsOpen, setAdd_faqModalIsOpen] = useState("");
  const [modalUpdate_faqIsOpen, setUpdate_faqModalIsOpen] = useState("");
  const [faq, setFaq] = useState("");
  const [toggleStates, setToggleStates] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFaqUserId, setSelectedFaqUserId] = useState("");
  const [selectedFaqQuestion, setSelectedFaqQuestion] = useState("");
  const [selectedFaqAnswer, setSelectedFaqAnswer] = useState("");
  const [selectedFaqPreference, setSelectedFaqPreference] = useState("");
  const [modalDelete_faqIsOpen, setDelete_faqIsOpen] = useState(false);
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  const openAdd_faq = () => {
    closeAdd_faq();
    setAdd_faqModalIsOpen(true);
  };

  const closeAdd_faq = () => {
    setAdd_faqModalIsOpen(false);
  };
  const openUpdated_faq = (UserId, faqQuestion, faqAnswer, faqPreference) => {
    setSelectedFaqUserId(UserId);
    setSelectedFaqQuestion(faqQuestion);
    setSelectedFaqAnswer(faqAnswer);
    setSelectedFaqPreference(faqPreference);
    closeUpdate_faq();
    setUpdate_faqModalIsOpen(true);
  };

  const closeUpdate_faq = () => {
    setUpdate_faqModalIsOpen(false);
  };

  const openDelete_faq = (UserId) => {
    setSelectedFaqUserId(UserId);
    closeDelete_faq();
    setDelete_faqIsOpen(true);
  };

  const closeDelete_faq = () => {
    setDelete_faqIsOpen(null);
  };
  useEffect(() => {
    fetch(`${getFaqForFaqPage}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const faq = data.data.FAQ;
        setFaq(faq);
        console.log(faq);
        const initialToggleStates = faq.reduce((acc, faq) => {
          acc[faq.id] = faq.status === "1" ? 1 : 0;
          return acc;
        }, {});

        setToggleStates(initialToggleStates);
        // console.log(faq[0].id);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const toggleSwitch = async (UserId) => {
    const newToggleState = toggleStates[UserId] === 1 ? 0 : 1;
    console.log(newToggleState);

    // Update the local state
    setToggleStates((prevState) => ({
      ...prevState,
      [UserId]: newToggleState,
    }));
    try {
      const response = await fetch(
        `${updateToggleFaq}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: newToggleState === 1 ? "1" : "0",
            id: UserId,
          }),
        }
      );

      const data = await response.json();
      console.log(data); // Log the server response
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };
  return (
    <div>
      <Header></Header>
      {modalAdd_faqIsOpen && <Add_faq onClose={closeAdd_faq} />}
      {modalUpdate_faqIsOpen && <Update_faq onClose={closeUpdate_faq} />}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>FAQ</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                {/* <div class="child ml-2">
                  <Select options={options}></Select>
                </div> */}
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_faq}
                    >
                      <i class="bi bi-person-add"></i> Add FAQ
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>

            {isLoading === true ? (
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  marginLeft: "50%",
                }}
              >
                <TailSpin color="black" width={50} />
              </div>
            ) : faq.length > 0 ? (
              <table class="table table-striped ">
                <thead>
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Question</th>
                    <th scope="col">Answer</th>
                    <th scope="col">Show On Home Page</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {faq.map((faq, index) => (
                    <tr key={faq.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{faq.question || "-"}</td>
                      <td>{faq.answer || "-"}</td>
                      <td>
                        <Toggle
                          checked={toggleStates[faq.id] === 1}
                          onChange={() => toggleSwitch(faq.id)}
                        />
                      </td>

                      <td>
                        <div className="social-links mt-2">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openUpdated_faq(
                                faq.id,
                                faq.question,
                                faq.answer,
                                faq.sequence
                              )
                            }
                          >
                            <i class="bi bi-pencil"></i>
                          </a>
                          &nbsp;&nbsp;
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openDelete_faq(faq.id)}
                          >
                            {" "}
                            <i class="bi bi-trash3"></i>
                          </a>
                          &nbsp;
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
            {modalUpdate_faqIsOpen && (
              <Update_faq
                UserId={selectedFaqUserId}
                faqQuestion={selectedFaqQuestion}
                faqAnswer={selectedFaqAnswer}
                faqPreference={selectedFaqPreference}
                onClose={closeUpdate_faq}
              />
            )}
            {modalDelete_faqIsOpen && (
              <Delete_faq
                UserId={selectedFaqUserId}
                onClose={closeDelete_faq}
              />
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {faq.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Faq;
