import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import Add_categories from "../../Components/Admin_components/add_categories.js";
import Update_categories from "../../Components/Admin_components/update_categories.js";
import Delete_categories from "../../Components/Admin_components/delete_categories.js";
import "../../Api/apiUrls";
import {
  UpdateAdminToggleCategory,
  getAdminCategories,
} from "../../Api/apiUrls";
function Categories() {
  const [categories, setCategories] = useState([]);
  const [modalAdd_categoriesIsOpen, setAdd_categoriesModalIsOpen] =
    useState(false);
  const [toggleStates, setToggleStates] = useState({});
  const [modalUpdate_categoriesIsOpen, setUpdate_categoriesModalIsOpen] =
    useState(false);
  const [modalDelete_categoriesIsOpen, setDelete_categoriesIsOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [selectedCategoryName, setSelectedCategoryName] = useState("");
  const [selectedCategoryDescription, setSelectedCategoryDescription] =
    useState("");
  const [selectedCategoryType, setSelectedCategoryType] = useState("");
  const [selectedCategoryStatus, setSelectedCategoryStatus] = useState("");
  const [selectedCategoryHomepage, setSelectedCategoryHomepage] = useState("");
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredCategories, setFilteredCategories] = useState([]);
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const Status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption.value);
  };
  useEffect(() => {
    const filteredCategories = categories.filter((category) => {
      const isStatus = !selectedStatus ||
        selectedStatus === "All" ||
        category.status === selectedStatus;
      const isType = !selectedType ||
        selectedType === "All" ||
        category.type === selectedType;
      const values = Object.values(category).map(value =>
        typeof value === 'string' ? value.toLowerCase() : ''
      );
      const isSearchMatch = values.some(value => value.includes(searchQuery.toLowerCase()));
      return isStatus && isType && isSearchMatch;
    });
    setFilteredCategories(filteredCategories);
  }, [
    selectedType,
    selectedStatus,
    categories,
    searchQuery
  ]);
  const openAdd_categories = () => {
    closeAdd_categories();
    setAdd_categoriesModalIsOpen(true);
  };

  const closeAdd_categories = () => {
    setAdd_categoriesModalIsOpen(false);
  };
  const openUpdate_categories = (
    categoryId,
    categoryName,
    categoryDescription,
    categoryType,
    categoryStatus,

    categoryHomepage
  ) => {
    setSelectedCategoryId(categoryId);
    setSelectedCategoryName(categoryName);
    setSelectedCategoryDescription(categoryDescription);
    setSelectedCategoryType(categoryType);
    setSelectedCategoryStatus(categoryStatus);
    setSelectedCategoryHomepage(categoryHomepage);
    closeUpdate_categories();
    setUpdate_categoriesModalIsOpen(true);
  };

  const closeUpdate_categories = () => {
    setUpdate_categoriesModalIsOpen(false);
  };
  const openDelete_categories = (categoryId) => {
    setSelectedCategoryId(categoryId);
    closeDelete_categories();
    setDelete_categoriesIsOpen(true);
  };

  const closeDelete_categories = () => {
    setDelete_categoriesIsOpen(null);
  };
  const toggleSwitch = async (categories_id) => {
    const newToggleState = toggleStates[categories_id] === 1 ? 0 : 1;
    console.log(newToggleState);

    // Update the local state
    setToggleStates((prevState) => ({
      ...prevState,
      [categories_id]: newToggleState,
    }));
    try {
      const response = await fetch(UpdateAdminToggleCategory, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          homepage: newToggleState === 1 ? "1" : "0",
          categories_id: categories_id,
        }),
      });

      const data = await response.json();
      console.log(data); // Log the server response
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  useEffect(() => {
    fetch(getAdminCategories)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const categories = data.data.Categories;
        console.log(categories);
        setCategories(categories);
        const initialToggleStates = categories.reduce((acc, categories) => {
          acc[categories.id] = categories.homepage === "1" ? 1 : 0;
          return acc;
        }, {});

        setToggleStates(initialToggleStates);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <Header></Header>
      {modalAdd_categoriesIsOpen && (
        <Add_categories onClose={closeAdd_categories} />
      )}
      {modalUpdate_categoriesIsOpen && (
        <Update_categories
          categoryId={selectedCategoryId}
          categoryName={selectedCategoryName}
          categoryDescription={selectedCategoryDescription}
          categoryType={selectedCategoryType}
          categoryStatus={selectedCategoryStatus}
          categoryHomepage={selectedCategoryHomepage}
          onClose={closeUpdate_categories}
        />
      )}
      {modalDelete_categoriesIsOpen && (
        <Delete_categories
          categoryId={selectedCategoryId}
          onClose={closeDelete_categories}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1> Category</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Category</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div style={{ display: "flex" }}>
                <div class="child col-md-2" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "37px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    placeholder="Search..."
                    onChange={handleSearchInputChange}
                    value={searchQuery}
                  />
                </div>

                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={Status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>

                <div
                  class=" col-md-2"
                  style={{ width: "100%", padding: "5px" }}
                >
                  <Select
                    options={type}
                    placeholder="Select Type"
                    onChange={handleTypeChange}
                  ></Select>
                </div>

                <div
                  class=" col-md-2"
                  style={{ padding: "5px", marginLeft: "37%" }}
                >
                  <a>
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_categories}
                    >
                      <i class="bi bi-person-add"></i> Add Category
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            {isLoading === true ? (
              <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                <TailSpin color="black" width={50} />
              </div>
            ) : categories.length > 0 ? (
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Description </th>
                    <th scope="col">Type </th>
                    <th scope="col">Status</th>
                    <th scope="col">Show on Home Page</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody id="tableBody">
                  {filteredCategories.map((category, index) => (
                    <tr key={category.id}>
                      <th scope="row">{index + 1}</th>

                      <td>
                        {category.name || "No Name"}
                        <br />
                      </td>
                      <td>
                        {category.description || "No Name"}
                        <br />
                      </td>
                      <td>
                        {category.type || "No Name"}
                        <br />
                      </td>
                      <td>{category.status || "No Name"}</td>
                      <td>
                        <Toggle
                          checked={toggleStates[category.id] === 1}
                          onChange={() => toggleSwitch(category.id)}
                        />
                      </td>

                      <td>
                        <div className="social-links mt-2">
                          <a
                            onClick={() =>
                              openUpdate_categories(
                                category.id,
                                category.name,
                                category.description,
                                { value: category.id, label: category.type },
                                { value: category.id, label: category.status },

                                category.homepage
                              )
                            }
                          >
                            <i className="bi bi-pencil"></i>
                          </a>
                          &nbsp;
                          <a onClick={() => openDelete_categories(category.id)}>
                            <i className="bi bi-trash3"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredCategories.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Categories;
