import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_blogs_news from "../../Components/Admin_components/add_blogs_news.js";

function Blogs_news() {
  const [modalAdd_blogs_newsIsOpen, setAdd_blogs_newsModalIsOpen] =
    useState("");
  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];
  const openAdd_blogs_news = () => {
    closeAdd_blogs_news();
    setAdd_blogs_newsModalIsOpen(true);
  };

  const closeAdd_blogs_news = () => {
    setAdd_blogs_newsModalIsOpen(false);
  };
  return (
    <div>
      <Header></Header>
      {modalAdd_blogs_newsIsOpen && (
        <Add_blogs_news onClose={closeAdd_blogs_news} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Blogs & News</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Blogs & News</li>
            </ol>
          </nav>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div class="child ml-2">
                  <Select options={options}></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_blogs_news}
                    >
                      <i class="bi bi-person-add"></i> Add Blogs
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <table class="table  ">
              <thead class="table-active">
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Covered Image</th>
                  <th scope="col">Description</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <div className="social-links mt-2">
                    <button className="btn btn-warning btn-sm">
                      <i
                        className="fas fa-pencil-alt"
                        style={{ color: "#fff" }}
                      ></i>
                    </button>
                    &nbsp;&nbsp;
                    <button className="btn btn-danger btn-sm">
                      <i className="fas fa-trash-alt"></i>
                    </button>
                    &nbsp;
                  </div>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td colspan="2">Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Blogs_news;
