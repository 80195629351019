import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls";
import { getAdminEmployee } from "../../Api/apiUrls";
import AddEmployee from "../../Components/Admin_components/addEmployee.js";
import UpdateEmployee from "../../Components/Admin_components/updateEmployee.js";
import DeleteEmployee from "../../Components/Admin_components/deleteEmployee.js";

function Employee() {
    const [employee, setEmployee] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedEmployeeId, setSelectedEmployeeId] = useState(null);
    const [selectedEmployeeName, setSelectedEmployeeName] = useState(null);
    const [selectedEmployeeEmail, setSelectedEmployeeEmail] = useState(null);
    const [selectedEmployeePassword, setSelectedEmployeePassword] = useState(null);
    const [selectedEmployeeAccess, setSelectedEmployeeAccess] = useState(null);
    const [selectedeventStatus, setSelectedEventStatus] = useState("");
    const [modalAddEmployeeIsOpen, setAddEmployeeModalIsOpen] = useState("");
    const openAddEmployee = () => {
        closeAddEmployee();
        setAddEmployeeModalIsOpen(true);
    };
    const closeAddEmployee = () => {
        setAddEmployeeModalIsOpen(false);
    };
    const [modalUpdateEmployeeIsOpen, setUpdateEmployeeModalIsOpen] = useState("");
    const openUpdateEmployee = (employeeId, employeeName, employeeEmail, employeePassword, employeeAccess, employeeStatus) => {
        setSelectedEmployeeId(employeeId);
        setSelectedEmployeeName(employeeName);
        setSelectedEmployeeEmail(employeeEmail);
        setSelectedEmployeePassword(employeePassword);
        setSelectedEmployeeAccess(employeeAccess);
        setSelectedEventStatus(employeeStatus)
        closeUpadteEmployee();
        setUpdateEmployeeModalIsOpen(true);
    };
    const closeUpadteEmployee = () => {
        setUpdateEmployeeModalIsOpen(false);
    };

    const [modalDeleteEmployeeIsOpen, setDeleteEmployeeIsOpen] = useState(false);

    const openDeleteEmployee = (employeeId) => {
        closeDeleteEmployee();
        setSelectedEmployeeId(employeeId);
        setDeleteEmployeeIsOpen(true);
    };

    const closeDeleteEmployee = () => {
        setDeleteEmployeeIsOpen(null);
    };

    const formatCreatedAt = (createdAt) => {
        const createdAtDate = new Date(createdAt);

        // Format the date part
        const datePart = createdAtDate.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        });

        // Format the time part
        const timePart = createdAtDate.toLocaleTimeString("en-GB", {
            hour: "numeric",
            minute: "numeric",

            hour12: true,
        });

        return `${datePart}`;
    };

    useEffect(() => {
        fetch(getAdminEmployee)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                const employee = data.data.users;
                setEmployee(employee);

            })
            .catch((error) => console.error("Error fetching data:", error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);


    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredEvents = Array.isArray(employee)
        ? employee.filter((employee) => {
            const values = Object.values(employee).map((value) =>
                typeof value === "string" ? value.toLowerCase() : ""
            );
            const isSearchMatch = values.some((value) =>
                value.includes(searchQuery.toLowerCase())
            );
            return isSearchMatch;
        })
        : [];
    return (
        <div>
            <Header></Header>
            {modalAddEmployeeIsOpen && (
                <AddEmployee onClose={closeAddEmployee} />
            )}
            {modalUpdateEmployeeIsOpen && (
                <UpdateEmployee
                    employeeId={selectedEmployeeId}
                    employeeName={selectedEmployeeName}
                    employeeEmail={selectedEmployeeEmail}
                    employeePassword={selectedEmployeePassword}
                    employeeAccess={selectedEmployeeAccess}
                    employeeStatus={selectedeventStatus}

                    onClose={closeUpadteEmployee} />
            )}
            {modalDeleteEmployeeIsOpen && (
                <DeleteEmployee
                    employeeId={selectedEmployeeId}
                    onClose={closeDeleteEmployee}
                />
            )}
            <main id="main" class="main">
                <div class="pagetitle">
                    <h1>Employee</h1>
                </div>
                <section class="section">
                    <div class="card p-2">
                        <div class="parent col-md-12 row mt-2">
                            <div class="col-md-6">
                                <div class="child">
                                    <input
                                        onChange={handleSearchChange}
                                        value={searchQuery}
                                        id="myInput"
                                        type="text"
                                        style={{
                                            borderRadius: "5px",
                                            height: "35px",
                                            padding: "10px",
                                            border: "1px solid lightgrey ",
                                        }}
                                        placeholder="Search..."
                                    />
                                </div>
                            </div>

                            <div class="col-md-6" style={{ textAlign: "right" }}>
                                <div class="child">
                                    <a>
                                        {" "}
                                        <button
                                            id="btnNewUser"
                                            type="button"
                                            data-toggle="modal"
                                            data-target="#modalAdd"
                                            class=" btn-round"
                                            onClick={openAddEmployee}
                                        >
                                            <i class="bi bi-person-add"></i> Add Employee
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}>
                        </div>
                        <div class="table-responsive">
                            {isLoading === true ? (
                                <div
                                    style={{
                                        marginTop: "50px",
                                        display: "flex",
                                        marginLeft: "50%",
                                    }}
                                >
                                    <TailSpin color="black" width={50} />
                                </div>
                            ) : employee.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.No</th>
                                            <th scope="col">Details</th>
                                            <th scope="col">Excess</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Created On</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableBody">
                                        {filteredEvents.map((employee, index) => (
                                            <tr key={employee.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>
                                                    {employee?.name || "---"}
                                                    <br />
                                                    {employee?.email || "---"}
                                                    <br />
                                                </td>
                                                <td>{employee.access.replace(/[\[\]"]+/g, '') || "--"}</td>
                                                <td>{employee.status || "--"}</td>
                                                <td>{formatCreatedAt(employee.created_at)}</td>
                                                <td>
                                                    <div className="social-links mt-2">
                                                        <a
                                                            style={{ cursor: "pointer" }}

                                                            onClick={() => openUpdateEmployee(
                                                                employee.id,
                                                                employee.name,
                                                                employee.email,
                                                                employee.password,
                                                                employee.access,
                                                                { value: employee.id, label: employee.status }


                                                            )}

                                                        >
                                                            <i class="bi bi-pencil"></i>
                                                        </a>
                                                        &nbsp;&nbsp;
                                                        <a
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => openDeleteEmployee(employee.id)}
                                                        >
                                                            <i class="bi bi-trash3"></i>
                                                        </a>
                                                        <br />

                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>

                            ) : (
                                <h3 style={{ textAlign: "center" }}> No data available </h3>
                            )}


                        </div>
                    </div>
                    <div>
                        {" "}
                        <p
                            style={{
                                backgroundColor: "#000",
                                color: "white",
                                padding: "10px",
                            }}
                        > {filteredEvents.length} Total
                        </p>
                    </div>
                </section>
            </main>
            <Footer></Footer>

        </div>
    )
}

export default Employee
