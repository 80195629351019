import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import CustomerChat from "../../Components/Customer_comp/customerChat.js";
import "../../Api/apiUrls.js";
import { getCustomerQuestion } from "../../Api/apiUrls";
function Question() {
  const [questionId, setQuestionId] = useState("");
  const [modalCustomerChatIsOpen, setCustomerChatModalIsOpen] = useState("");

  const openCustomerChat = (questionId) => {
    setQuestionId(questionId);
    closeCustomerChat();
    setCustomerChatModalIsOpen(true);
  };
  const closeCustomerChat = () => {
    setCustomerChatModalIsOpen(false);
  };
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
    { value: "News", label: "News" },
  ];
  const [isLoading, setIsLoading] = useState(true);
  const [question, setQuestion] = useState([]);
  const customerId = localStorage.getItem("user_id");

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    fetch(getCustomerQuestion + customerId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const question = data.data.Questions;
        console.log(question);
        setQuestion(question);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Question value:", Question);
      });
  }, []);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const [filteredQuestion, setFilteredQuestion] = useState([]);
  useEffect(() => {
    const filteredQuestion = question.filter((question) => {
      const isTypeMatch =
        !selectedType ||
        selectedType.value === "All" ||
        question.question_for === selectedType.value;
      const values = Object.values(question).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );

      return isTypeMatch && isSearchMatch;
    });

    // Rest of your code...
    setFilteredQuestion(filteredQuestion);
  }, [selectedType, question, searchQuery]);
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <div>
      {modalCustomerChatIsOpen && (
        <CustomerChat onClose={closeCustomerChat} questionId={questionId} />
      )}
      <Header></Header>

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Question</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Question</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-3">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
              </div>
              <div class="col-md-3">
                <Select
                  options={type}
                  value={selectedType}
                  onChange={(selectedOption) => setSelectedType(selectedOption)}
                  placeholder="Select Type"
                />
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : question.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Question</th>
                      <th scope="col">Type</th>
                      <th scope="col">Listing Details</th>
                      <th scope="col">Instructor Details</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredQuestion.map((question, index) => (
                      <tr key={question.id}>
                        <th scope="row">{index + 1}</th>

                        <td>{question.question || "--"}</td>
                        <td>{question.question_for || "--"}</td>
                        {/* <td>
                          {question.question_for === "Venue"
                            ? question.venue
                              ? question.venue.name
                              : "--"
                            : question.workshop
                            ? question.workshop.name
                            : "--"}
                        </td> */}


<td>
                        {question.question_for === "Venue" ? (
                          <>
                            {question.venue
                              ? `${question.venue.name || "-"}`
                              : "-"}
                          </>
                        ) : question.question_for === "Workshop" ? (
                          <>
                            {question.workshop
                              ? `${question.workshop.name || "-"}`
                              : "-"}
                          </>
                        ) : question.question_for === "News" ? (
                          <>
                            {question.news
                              ? `${question.news.title || "-"}`
                              : "-"}
                          </>
                        ) : (
                          "-"
                        )}
                      </td>

                        <td>
                          {question.receiver.name || "--"}
                          <br />
                          {question.receiver.email || "--"}
                          <br />
                          {question.receiver.mobileNumber || "--"}
                        </td>

                        <td>
                          {/* <b>{question.created_at || "-"}</b> */}
                          {formatCreatedAt(question.created_at)}
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openCustomerChat(question.id)}
                            >
                              <i class="bi bi-reply"></i>
                            </a>
                            &nbsp;&nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredQuestion.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Question;
