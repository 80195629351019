import React, { useEffect, useState } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_review_rating from "../../Components/Admin_components/add_review_rating.js";
import Delete_review_rating from "../../Components/Admin_components/delete_review_rating.js";
import "../../Api/apiUrls";
import { getAdminReviewRating } from "../../Api/apiUrls";
function Review_and_Rating() {
  const [modalAdd_review_ratingIsOpen, setAdd_review_ratingModalIsOpen] =
    useState("");
  const [modalDelete_review_ratingIsOpen, setDelete_review_ratingIsOpen] =
    useState(false);
  const [selectedReview_ID, setSelectedReview_ID] = useState(null);
  const openAdd_review_rating = () => {
    closeAdd_review_rating();
    setAdd_review_ratingModalIsOpen(true);
  };
  const [isLoading, setIsLoading] = useState(true);
  const closeAdd_review_rating = () => {
    setAdd_review_ratingModalIsOpen(false);
  };
  const openDelete_review_rating = (Review_id) => {
    setSelectedReview_ID(Review_id);
    closeDelete_review_rating();
    setDelete_review_ratingIsOpen(true);
  };

  const closeDelete_review_rating = () => {
    setDelete_review_ratingIsOpen(null);
  };

  const renderStars = (rating) => {
    const maxRating = 5; // Maximum rating (number of stars)
    const starIcons = [];

    for (let i = 1; i <= maxRating; i++) {
      // Determine whether to render a filled or empty star based on the rating
      const isFilled = i <= rating;

      // Add the appropriate star icon (you can customize the star icons as needed)
      starIcons.push(
        <i
          key={i}
          className={`bi bi-star${isFilled ? "-fill" : ""}`}
          style={{ color: isFilled ? "gold" : "gray" }}
        ></i>
      );
    }

    return starIcons;
  };
  const [rating, setRating] = useState([]);
  useEffect(() => {
    fetch(getAdminReviewRating)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const rating = data.data.Review;
        console.log(rating);
        setRating(rating);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Review Rating value:", rating);
      });
  }, []);
  return (
    <div>
      <Header></Header>
      {modalAdd_review_ratingIsOpen && (
        <Add_review_rating onClose={closeAdd_review_rating} />
      )}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Review and Rating</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">review_and_rating</li>
            </ol>
          </nav> */}
        </div>
        <section class="section"></section>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_review_rating}
                    >
                      <i class="bi bi-person-add"></i> Add Review
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>

            {isLoading === true ? (
              <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                <TailSpin color="black" width={50} />
              </div>
            ) : rating.length > 0 ? (
              <table class="table table-striped  ">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Review</th>

                    <th scope="col">Rating</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody id="tableBody">
                  {rating.map((rating, index) => (
                    <tr key={rating.id}>
                      <th scope="row">{index + 1}</th>

                      <td>
                        {rating.name || "No Name"}
                        <br />
                      </td>

                      <td>
                        {rating.review || "No Name"}
                        <br />
                      </td>
                      <td>
                        {renderStars(rating.rating)}
                        <br />
                      </td>
                      <td>
                        <div className="social-links mt-2">
                          <a
                            onClick={() => openDelete_review_rating(rating.id)}
                          >
                            <i class="bi bi-trash3"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
            {modalDelete_review_ratingIsOpen && (
              <Delete_review_rating
                Review_Id={selectedReview_ID}
                onClose={closeDelete_review_rating}
              />
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {rating.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Review_and_Rating;
