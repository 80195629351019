import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_Instructor_Packages from "../../Components/instructor_comp/add_packages.js";
import Update_Instructor_Packages from "../../Components/instructor_comp/update_packages.js";
import Delete_Instructor_Packages from "../../Components/instructor_comp/delete_packages.js";
import "../../Api/apiUrls.js";
import { getInstructorPackages } from "../../Api/apiUrls";
function Instructor_Packages() {
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [
    modalAdd_Instructor_PackagesIsOpen,
    setAdd_Instructor_PackagesModalIsOpen,
  ] = useState("");
  const [
    modalUpdate_Instructor_PackagesIsOpen,
    setUpdate_Instructor_PackagesModalIsOpen,
  ] = useState(false);
  const [
    modalDelete_Instructor_PackagesIsOpen,
    setDelete_Instructor_PackagesIsOpen,
  ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const openAdd_Instructor_Packages = () => {
    closeAdd_Instructor_Packages();
    setAdd_Instructor_PackagesModalIsOpen(true);
  };

  const closeAdd_Instructor_Packages = () => {
    setAdd_Instructor_PackagesModalIsOpen(false);
  };
  const openUpdate_Instructor_Packages = () => {
    closeUpdate_Instructor_Packages();
    setUpdate_Instructor_PackagesModalIsOpen(true);
  };

  const closeUpdate_Instructor_Packages = () => {
    setUpdate_Instructor_PackagesModalIsOpen(false);
  };
  const openDelete_Instructor_Packages = (userId) => {
    setSelectedUserId(userId);
    closeDelete_Instructor_Packages();
    setDelete_Instructor_PackagesIsOpen(true);
  };

  const closeDelete_Instructor_Packages = () => {
    setDelete_Instructor_PackagesIsOpen(null);
  };
  const statusOptions = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const [selectedStatus, setSelectedStatus] = useState(statusOptions[0]);
  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  useEffect(() => {
    fetch(getInstructorPackages)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const packages = data.data.Package;
        console.log(packages);
        setPackages(packages);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Package value:", packages);
      });
  }, []);
  const filteredPackages = packages.filter((packages) => {
    if (selectedStatus.value === "All") {
      return true;
    }
    return packages.status === selectedStatus.value;
  });

  return (
    <div>
      <Header></Header>
      {modalAdd_Instructor_PackagesIsOpen && (
        <Add_Instructor_Packages onClose={closeAdd_Instructor_Packages} />
      )}
      {modalUpdate_Instructor_PackagesIsOpen && (
        <Update_Instructor_Packages onClose={closeUpdate_Instructor_Packages} />
      )}
      {modalDelete_Instructor_PackagesIsOpen && (
        <Delete_Instructor_Packages
          userId={selectedUserId}
          onClose={closeDelete_Instructor_Packages}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Packages</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Packages</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div class="child ml-2">
                  <Select
                    options={status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                    value={selectedStatus}
                  ></Select>
                </div>
              </div>
              {/* <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_Instructor_Packages}
                    >
                      <i class="bi bi-person-add"></i> Add Packages
                    </button>
                  </a>
                </div>
              </div> */}
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "52%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              <table class="table table-striped ">
                {isLoading === true ? (
                  <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                    <TailSpin color="black" width={50} />
                  </div>
                ) : packages.length > 0 ? (
                  <table class="table table-striped  ">
                    <thead>
                      <tr>
                        <th scope="col">Sr.no</th>
                        <th scope="col">Name </th>
                        <th scope="col">Status</th>
                        <th scope="col">Price</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Type</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody id="tableBody">
                      {/* {packages.map((packages, index) => ( */}
                      {filteredPackages.map((packages, index) => (
                        <tr key={packages.id}>
                          <th scope="row">{index + 1}</th>

                          <td>
                            {packages.name || "No Name"}
                            <br />
                          </td>

                          <td>
                            {packages.status || "No Name"}
                            <br />
                          </td>
                          <td>
                            {packages.price || "No Name"}
                            <br />
                          </td>
                          <td>
                            {packages.duration || "No Name"}
                            <br />
                          </td>
                          <td>
                            {packages.type || "No Name"}
                            <br />
                          </td>
                          <td>
                            <button
                              id="btnNewUser"
                              type="button"
                              data-toggle="modal"
                              data-target="#modalAdd"
                              class=" btn-round"
                              style={{ marginRight: "10px" }}
                            >
                              <i></i> Buy
                            </button>

                            <button
                              id="btnNewUser"
                              type="button"
                              data-toggle="modal"
                              data-target="#modalAdd"
                              class=" btn-round"
                            >
                              <i></i> Cancel
                            </button>
                          </td>

                          {/* <td>
                            <div className="social-links mt-2">
                              <a
                                onClick={() =>
                                  openUpdate_banners(
                                    banner.id,
                                    banner.title,
                                    banner.photo,
                                    banner.status
                                  )
                                }
                              >
                                <i className="fas fa-pencil-alt"></i>
                              </a>
                              &nbsp;
                              <a onClick={() => openDelete_banners(banner.id)}>
                                <i className="fas fa-trash-alt"></i>
                              </a>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <h3 style={{ textAlign: "center" }}> No data available </h3>
                )}
              </table>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Instructor_Packages;
