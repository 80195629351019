import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";

import Add_instructor from "../../Components/Admin_components/add_instructor.js";
import Delete_instructor from "../../Components/Admin_components/delete_instructor.js";
import Update_instructor from "../../Components/Admin_components/update_instructor.js";
import Workshoplist from "../../Components/Admin_components/workshopDetails.js";
import "../../Api/apiUrls";
import { getAdminInstructor } from "../../Api/apiUrls";
function Instructor() {
  const [modalAdd_instructorIsOpen, setAdd_instructorModalIsOpen] =
    useState("");
  const [modalDelete_instructorIsOpen, setDelete_instructorIsOpen] =
    useState(false);
    const [modalUpdate_instructorIsOpen, setUpdate_instructorModalIsOpen] =
    useState("");
  const [selectedInstructorId, setSelectedInstructorId] = useState(null);
  const [instructor, setInstructor] = useState([]);
  const [selectedInstructor, setSelectedInstructor] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const Status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredInstructors = instructor.filter((inst) => {
    const statusFilter = !selectedStatus || selectedStatus === "All" || inst.status === selectedStatus;
    const searchFilter = Object.values(inst).some((value) =>
      typeof value === 'string' && value.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return statusFilter && searchFilter;
  });
  const openAdd_instructor = () => {
    closeAdd_instructor();
    setAdd_instructorModalIsOpen(true);
  };

  const closeAdd_instructor = () => {
    setAdd_instructorModalIsOpen(false);
  };
  const openDelete_instructor = (instructorId) => {
    setSelectedInstructorId(instructorId);
    closeDelete_instructor();
    setDelete_instructorIsOpen(true);
  };

  const closeDelete_instructor = () => {
    setDelete_instructorIsOpen(null);
  };

  const handleEditClick = (instructor) => {
    setSelectedInstructor(instructor);
    setUpdate_instructorModalIsOpen(true);
  };
  // const openUpdate_instructor = (instructorId) => {
  //   setSelectedInstructorId(instructorId);
  //   closeDelete_instructor();
  //   setDelete_instructorIsOpen(true);
  // };

  const closeUpdate_instructor = () => {
    setUpdate_instructorModalIsOpen(false);
  };
  const [id, setId] = useState("");
  const [type, seType] = useState("instructor");
  const [modalWorkshoplistIsOpen, setWorkshoplistModalIsOpen] =
  useState("");

const openWorkshoplist = (id , type) => {
  setId(id);
  seType(type);
  setWorkshoplistModalIsOpen(true);
};

const closeWorkshoplist = () => {
  setWorkshoplistModalIsOpen(false);
};



  useEffect(() => {
    fetch(getAdminInstructor)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const instructor = data.data.users;
        console.log(instructor);
        setInstructor(instructor);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Instructor value:", instructor);
      });
  }, []);

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  return (
    <div>
      <Header></Header>
      {modalAdd_instructorIsOpen && (
        <Add_instructor onClose={closeAdd_instructor} />
      )}
      {modalUpdate_instructorIsOpen && (
          <Update_instructor
            open={modalUpdate_instructorIsOpen}
            onClose={() => setUpdate_instructorModalIsOpen(false)}
            instructor={selectedInstructor}
          />
        )}

{modalWorkshoplistIsOpen && (
        <Workshoplist onClose={closeWorkshoplist} id={id} type={type} />
      )}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Instructor</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Instructor</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div style={{ marginLeft: "-16px" }} class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div className="child ml-2">
                  <Select
                    options={Status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    {/* <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_instructor}
                    >
                      <i class="bi bi-person-add"></i> Add Instructor
                    </button> */}
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>

            {isLoading === true ? (
              <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                <TailSpin color="black" width={50} />
              </div>
            ) : instructor.length > 0 ? (
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr. No.</th>
                    <th scope="col">Details</th>
                    {/* <th scope="col">Followers</th> */}

                    <th scope="col">Bank Detail</th>
                    <th scope="col">Listing</th>
                    <th scope="col">Location</th>

                    <th scope="col">Status</th>
                    <th scope="col">Created On</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody id="tableBody">
                  {filteredInstructors.map((instructor, index) => (
                    <tr key={instructor.id}>
                      <th scope="row">{index + 1}</th>

                      <td>
                        {instructor.name || "--"}
                        <br />
                        {instructor.mobileNumber || "--"}
                        <br />
                        {instructor.email || "--"}
                        <br />
                      </td>

                      {/* <td>
                        {instructor.followers_count || "--"}
                        <br />
                      </td> */}

                      <td>
                        {instructor.bank_account_number || "--"}
                        <br />
                        {instructor.bank_name || "--"}
                        <br />
                        {instructor.bank_ifsc_code || "--"}
                        <br />
                        {instructor.account_holder_name || "--"}
                        <br />
                      </td>
                      <td>
                      <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openWorkshoplist(instructor.id , "instructor")}
                          >
                        {instructor.listings_count || "--"}
                        </a>
                        <br />
                      </td>
                      <td>
                        {instructor.city || "--"}
                        <br />
                      </td>
                      <td>
                        {instructor.status || "--"}
                        <br />
                      </td>
                      <td>{formatCreatedAt(instructor.created_at)}</td>
                      <td>
                        <div className="social-links mt-2">
                        <a
                           onClick={() => handleEditClick(instructor)}
                          >
                            <i className="bi bi-pencil"></i>
                          </a>
                          &nbsp;
                          <a
                            onClick={() => openDelete_instructor(instructor.id)}
                          >
                            <i className="bi bi-trash"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
            {modalDelete_instructorIsOpen && (
              <Delete_instructor
                instructorId={selectedInstructorId}
                onClose={closeDelete_instructor}
              />
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredInstructors.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Instructor;
