import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import MessageChat from "../../Components/Customer_comp/messageChat.js";
import "../../Api/apiUrls.js";
import { getCustomerSentMessages } from "../../Api/apiUrls";
function Message() {
  const [isLoading, setIsLoading] = useState(true);
  const [questionId, setQuestionId] = useState("");
  const [modalCustomerChatIsOpen, setCustomerChatModalIsOpen] = useState("");
  const openCustomerChat = (questionId) => {
    setQuestionId(questionId);
    closeCustomerChat();
    setCustomerChatModalIsOpen(true);
  };
  const closeCustomerChat = () => {
    setCustomerChatModalIsOpen(false);
  };
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const [message, setMessage] = useState([]);
  const customerId = localStorage.getItem("user_id");
  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);
  useEffect(() => {
    fetch(getCustomerSentMessages + customerId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const message = data.data.Messages;
        console.log(message);
        setMessage(message);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Messages value:", Message);
      });
  }, []);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredQuestions = Array.isArray(message)
    ? message.filter((message) => {
        const values = Object.values(message).map((value) =>
          typeof value === "string" ? value.toLowerCase() : ""
        );
        const isSearchMatch = values.some((value) =>
          value.includes(searchQuery.toLowerCase())
        );
        return isSearchMatch;
      })
    : [];
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  return (
    <div>
      {modalCustomerChatIsOpen && (
        <MessageChat onClose={closeCustomerChat} questionId={questionId} />
      )}
      <Header></Header>

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Message</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Message</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                    onChange={handleSearchChange}
                    value={searchQuery}
                  />
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : message.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Question</th>
                      <th scope="col">Type of Enquiry</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredQuestions.map((message, index) => (
                      <tr key={message.id}>
                        <th scope="row">{index + 1}</th>

                        <td>{message.message || "No Name"}</td>

                        <td>{message.source || "No Name"}</td>

                        <td>
                          {/* <b>{question.created_at || "-"}</b> */}
                          {formatCreatedAt(message.created_at)}
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openCustomerChat(message.id)}
                            >
                              <i class="bi bi-reply"></i>
                            </a>
                            &nbsp;&nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {message.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Message;
