import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import Select from "react-select";
import $ from 'jquery';
import Select2 from 'react-select2-wrapper';
import 'select2/dist/css/select2.css';
import "../../Api/apiUrls";
import { API_URL, addAdminEvents } from "../../Api/apiUrls";

function AddEvents({ onClose }) {
    const [modalAddEventIsOpen, setAddEventModalIsOpen] =
        useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [eventStatus, setEventStatus] = useState("");
    const [eventType, setEventType] = useState("");
    const [eventTitle, setEventTitle] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [eventKeywords, setEventKeywords] = useState("");
    const [eventSummary, setEventSummary] = useState("");
    const [eventOrganizer, setEventOrganizer] = useState("");
    const [eventOrganizerBio, setEventOrganizerBio] = useState("");
    const [eventCity, setEventCity] = useState("");
    const [workshopDate, setWorkshopDate] = useState("");
    const [isAddingnews, setIsAddingnews] = useState(false);
    function reloadPage() {
        window.location.reload();
    }
    const closeAddEvent = () => {
        setAddEventModalIsOpen(false);
    };
    const status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ];

    const type = [
        { value: "School Collaboration", label: "School Collaboration" },
    ];

    const handleStatusChange = (selectedOption) => {
        setEventStatus(selectedOption.value);
    };

    const handleTypeChange = (selectedOption) => {
        setEventType(selectedOption.value);
    };
    // ---------------------------instructor----------------------------------------------
    const [instructors, setInstructors] = useState([]);
    const [selectedInstructor, setSelectedInstructor] = useState(null);
    useEffect(() => {
        fetch(`${API_URL}/user/get-instructor/`)
            .then(response => response.json())
            .then(data => {
                const options = data.data.users.map(user => ({
                    id: user.id,
                    text: user.name,
                    image: `${API_URL}/${user.profile_image}`,
                }));
                setInstructors(options);
                setIsLoading(false);
            })
            .catch(error => {
                console.error('Error fetching instructors:', error);
                setIsLoading(false);
            });
    }, []);


    // Helper functions to format the display of options
    const formatOption = (state) => {
        if (!state.id) return state.text; // Default text

        const instructor = instructors.find(inst => inst.id == state.id);
        if (!instructor) return state.text;

        return $(
            `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
        );
    };

    const formatOptionSelection = (state) => {
        if (!state.id) return state.text; // Default text

        const instructor = instructors.find(inst => inst.id == state.id);
        if (!instructor) return state.text;

        return $(
            `<span><img src="${instructor.image}" class="img-flag" style="width:20px; margin-right: 10px;" />${state.text}</span>`
        );
    };
    const [file, setIconFile] = useState();
    const handleFileChange = (event) => {
        const file = event.target.files[0];
    
        setIconFile(file);
        console.log(file);
    
        const formData = new FormData();
        formData.append("organizer_image", file);
        const reader = new FileReader();
        reader.onload = function (e) {
          const imgElement = document.getElementById("selectedImg");
          imgElement.src = e.target.result;
        };
        reader.readAsDataURL(file);
      };

    async function addEvent(
        eventTitle,
        eventDescription,
        eventKeywords,
        eventSummary,
        eventOrganizer,
        eventOrganizerBio,
        file,
        eventCity,
        workshopDate,
        eventStatus,
        eventType,
        selectedInstructor
    ) {
        try {
            const formData = new FormData();

            formData.append("title", eventTitle);
            formData.append("description", eventDescription);
            formData.append("keywords", eventKeywords);
            formData.append("short_summary", eventSummary);
            formData.append("status", eventStatus);
            formData.append("event_type", eventType);
            formData.append("organizer", eventOrganizer);
            formData.append("bio", eventOrganizerBio);
            formData.append("organizer_image", file);
            formData.append("city", eventCity);
            formData.append("event_date", workshopDate);
            formData.append("instructor", selectedInstructor);

            const response = await fetch(addAdminEvents, {
                method: "POST",
                body: formData,
            });
            const responseData = await response.json();
            if (response.ok) {
                if (responseData.status === 200) {
                    return true;
                } else {
                    return false;
                }
            } else {
                throw new Error("Failed to add Event");
            }
        } catch (error) {
            console.error("Error adding Event:", error);
            return false;
        }
    }

    const handleAddEvents = async () => {

        setIsAddingnews(true);

        const result = await addEvent(
            eventTitle,
            eventDescription,
            eventKeywords,
            eventSummary,
            eventOrganizer,
            eventOrganizerBio,
            file,
            eventCity,
            workshopDate,
            eventStatus,
            eventType,
            selectedInstructor
        );
        setIsAddingnews(false);
        if (result) {
            alert("Event Added Successfully");
            window.location.reload();
            onClose();
        } else {
            alert("Error Adding Event");
        }
    };
    return (
        <div>
            {modalAddEventIsOpen && (
                <AddEvents onClose={closeAddEvent} />
            )}


            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Add Events</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Title<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={eventTitle}
                                        onChange={(e) => setEventTitle(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Description<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        style={{ height: "100px" }}
                                        value={eventDescription}
                                        onChange={(e) => setEventDescription(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        keywords<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={eventKeywords}
                                        onChange={(e) => setEventKeywords(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        One Line Summary<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=" "
                                        value={eventSummary}
                                        onChange={(e) => setEventSummary(e.target.value)}
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Organizer<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=" "
                                        value={eventOrganizer}
                                        onChange={(e) => setEventOrganizer(e.target.value)}
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Organizer Bio<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=" "
                                        value={eventOrganizerBio}
                                        onChange={(e) => setEventOrganizerBio(e.target.value)}
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputDocument">
                                       Organizer Logo
                                    </label>
                                    <br />
                                    <input
                                        type="file"
                                        id="selectedImg"
                                        name="document"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        City<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=" "
                                        value={eventCity}
                                        onChange={(e) => setEventCity(e.target.value)}
                                    />
                                </div>

                                <div class="form-group" style={{ paddingLeft: "0px" }}>
                                    <label class="small mb-1" for="inputFullName">
                                        Workshop Date<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="date"
                                        value={workshopDate}
                                        onChange={(e) => setWorkshopDate(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="instructorSelect">
                                        Select Instructor
                                    </label>
                                    {!isLoading ? (
                                        <Select2
                                            id="instructorSelect"
                                            data={instructors}
                                            value={selectedInstructor}
                                            onChange={(e) => setSelectedInstructor(e.target.value)}
                                            options={{
                                                placeholder: 'Select an instructor',
                                                allowClear: true,
                                                templateResult: formatOption,
                                                templateSelection: formatOptionSelection,
                                            }}
                                            style={{ width: '100%' }}
                                        />
                                    ) : (
                                        <p>Loading instructors...</p>
                                    )}
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Event Type<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2 col-md-4">
                                        <Select
                                            onChange={handleTypeChange}
                                            options={type}
                                        ></Select>
                                    </div>
                                </div>



                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Status<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                            onChange={handleStatusChange}
                                            options={status}
                                        ></Select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingnews === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleAddEvents}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddEvents
