import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_appointment.css";
import "../../Api/apiUrls.js";
import { addCancellationPolicy } from "../../Api/apiUrls";

function Add_CancelationPolicy({ onClose }) {
  const [
    modalAdd_CancellationPolicyIsOpen,
    setAdd_CancellationPolicyModalIsOpen,
  ] = useState(false);

  const [isAddingPolicy, setIsAddingPolicy] = useState(false);
  const [Policy, setPolicy] = useState("");
  const closeAddCancellationPolicy = () => {
    setAdd_CancellationPolicyModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  async function addCancelationPolicy(Policy) {
    try {
      const formData = new FormData();
      formData.append("policy_text", Policy);

      const response = await fetch(`${addCancellationPolicy}`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true;
      } else {
        throw new Error("Failed to add Cancellation Policy");
      }
    } catch (error) {
      console.error("Error adding Cancellation Policy:", error);
      return false;
    }
  }

  const handleAddPolicy = async () => {
    if (!Policy) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingPolicy(true);

    const result = await addCancelationPolicy(Policy);
    setIsAddingPolicy(false);
    if (result) {
      alert("Cancellation Policy Added Successfully");
      // console.log(newsStatus);
      window.location.reload();
      onClose();
    } else {
      alert("Error Adding Cancellation Policy");
    }
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div>
      {modalAdd_CancellationPolicyIsOpen && (
        <Add_CancelationPolicy onClose={closeAddCancellationPolicy} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Cancellation Policy</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="description"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={Policy}
                    onChange={(e) => setPolicy(e.target.value)}
                  />
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingPolicy === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddPolicy}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_CancelationPolicy;
