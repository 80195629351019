import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_packages.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { addAdminNew, addWeAreHiring } from "../../Api/apiUrls";

function Add_Hiring({ onClose }) {
  const [modalAdd_HiringIsOpen, setAdd_HiringModalIsOpen] = useState("");
  const [hiringTittle, setHiringName] = useState("");
  const [hiringDescription, setHiringDescription] = useState("");
  const [hiringExperience, setHiringExperience] = useState("");
  const [hiringPosition, setHiringPosition] = useState("");
  const [hiringSkills, setHiringSkills] = useState("");
  const [hiringLocation, setHiringLocation] = useState("");
  const [hiringQualification, setHiringQualification] = useState("");
  const [hiringRolesRes, setHiringRolesRes] = useState("");
  const [hiringWhyWorkWithUs, setHiringWhyWorkWithUs] = useState("");
  useState("");
  const [hiringStatus, setHiringStatus] = useState("");

  const [isAddingHiring, setIsAddingHiring] = useState(false);

  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const closeAdd_Hiring = () => {
    setAdd_HiringModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  const handleStatusChange = (selectedOption) => {
    setHiringStatus(selectedOption.value);
  };

  async function AddHiring(
    hiringTittle,
    hiringExperience,
    hiringDescription,
    hiringPosition,
    hiringSkills,
    hiringLocation,
    hiringQualification,
    hiringRolesRes,
    hiringWhyWorkWithUs,
    hiringStatus
  ) {
    try {
      const formData = new FormData();
      formData.append("title", hiringTittle);
      formData.append("experience", hiringExperience);
      formData.append("description", hiringDescription);
      formData.append("open_positions", hiringPosition);
      formData.append("skills_required", hiringSkills);
      formData.append("location", hiringLocation);
      formData.append("qualification", hiringQualification);
      formData.append("roles_and_responsibilities", hiringRolesRes);
      formData.append("why_work_with_us", hiringWhyWorkWithUs);
      formData.append("status", hiringStatus);

      const response = await fetch(`${addWeAreHiring}`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true;
      } else {
        throw new Error("Failed to add We Are Hiring");
      }
    } catch (error) {
      console.error("Error adding We Are Hiring:", error);
      return false;
    }
  }

  const handleAddHiring = async () => {
    if (!hiringTittle) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingHiring(true);

    const result = await AddHiring(
      hiringTittle,
      hiringExperience,
      hiringDescription,
      hiringPosition,
      hiringSkills,
      hiringLocation,
      hiringQualification,
      hiringRolesRes,
      hiringWhyWorkWithUs,
      hiringStatus
    );
    setIsAddingHiring(false);
    if (result) {
      alert(" Hiring Added Successfully");
      console.log(hiringStatus);
      window.location.reload();
      onClose();
    } else {
      alert("Error Adding Hiring");
    }
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div>
      {modalAdd_HiringIsOpen && <add_Hiring onClose={closeAdd_Hiring} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add We Are Hiring</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Tittle<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={hiringTittle}
                    onChange={(e) => setHiringName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Experience<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={hiringExperience}
                    onChange={(e) => setHiringExperience(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={hiringDescription}
                    onChange={(e) => setHiringDescription(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Position<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={hiringPosition}
                    onChange={(e) => setHiringPosition(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Skills<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={hiringSkills}
                    onChange={(e) => setHiringSkills(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Location<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={hiringLocation}
                    onChange={(e) => setHiringLocation(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Qualification<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={hiringQualification}
                    onChange={(e) => setHiringQualification(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Roles & Responsibilities
                    <font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={hiringRolesRes}
                    onChange={(e) => setHiringRolesRes(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Why Work With Us
                    <font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={hiringWhyWorkWithUs}
                    onChange={(e) => setHiringWhyWorkWithUs(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status<font color="red"></font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      options={status}
                      onChange={handleStatusChange}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingHiring === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddHiring}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_Hiring;
