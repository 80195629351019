import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_appointment.css";
import "../../Api/apiUrls.js";
import {updateFaq} from "../../Api/apiUrls";


function Update_faq({
  onClose,
  UserId,
  faqAnswer,
  faqQuestion,
  faqPreference,
}) {
  const [modalUpdate_faqIsOpen, setUpdate_faqModalIsOpen] = useState("");
  const [newFaqQuestion, setNewFaqQuestion] = useState(faqQuestion);
  const [newFaqAnswer, setNewFaqAnswer] = useState(faqAnswer);
  const [newFaqPreference, setNewFaqPreference] = useState(faqPreference);
  const [isUpdatingFaq, setIsUpdatingFaq] = useState("");
  const closeUpdate_faq = () => {
    setUpdate_faqModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }
  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  const handleUpdate = () => {
    setIsUpdatingFaq(true);

    const formData = new FormData();
    formData.append("faq_id", UserId);
    formData.append("question", newFaqQuestion);
    formData.append("answer", newFaqAnswer);
    formData.append("sequence", newFaqPreference);

    fetch(`${updateFaq}`, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingFaq(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating FAQ:", data.message);
          setIsUpdatingFaq(false);
        }
      })
      .catch((error) => {
        console.error("Error updating FAQ:", error);
        setIsUpdatingFaq(false);
      });
  };
  return (
    <div>
      {modalUpdate_faqIsOpen && <Update_faq onClose={closeUpdate_faq} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              // overflowY: "scroll",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update FAQ</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Question
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newFaqQuestion}
                    onChange={(e) => setNewFaqQuestion(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Answer
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newFaqAnswer}
                    onChange={(e) => setNewFaqAnswer(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Preference
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    min={"0"}
                    value={newFaqPreference}
                    onChange={(e) => setNewFaqPreference(e.target.value)}
                  />
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              {/* <div>
                <ThreeDots color="#000" height={50} width={50} />
              </div> */}

              <button
                id="btnAdd"
                type="button"
                class="btn btn-primary "
                style={{ background: "#000 ", border: "none" }}
                onClick={handleUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_faq;
