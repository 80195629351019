import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_blog.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { addBlog } from "../../Api/apiUrls";
function Add_blog({ onClose }) {
  const [modalAdd_blogIsOpen, setAdd_blogModalIsOpen] = useState("");
  const [blogsName, setBlogsName] = useState("");
  const [blogsDescription, setBlogsDescription] = useState("");
  const [blogsSummary, setBlogsSummary] = useState("");
  const [blogsAuthor, setBlogsAuthor] = useState("");
  const [blogsStatus, setBlogsStatus] = useState("");
  const [file, setIconFile] = useState();
  const [blogsKeyword, setBlogKeyword] = useState("");
  const [isAddingblog, setIsAddingblog] = useState(false);
  const [blogsBio, setBlogsBio] = useState("");
  const closeAdd_blog = () => {
    setAdd_blogModalIsOpen(false);
  };
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  function reloadPage() {
    window.location.reload();
  }

  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const handleStatusChange = (selectedOption) => {
    setBlogsStatus(selectedOption.value);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setIconFile(file);
    console.log(file);

    const formData = new FormData();
    formData.append("image", file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const imgElement = document.getElementById("selectedImg");
      imgElement.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  async function addBlogs(
    blogsName,
    file,
    blogsDescription,
    blogsKeyword,
    blogsSummary,
    blogsStatus,
    blogsAuthor,
    blogsBio
  ) {
    try {
      const formData = new FormData();
      formData.append("title", blogsName);
      formData.append("type", "Blog");
      formData.append("photo", file);
      formData.append("discription", blogsDescription);
      formData.append("keywords", blogsKeyword);
      formData.append("shortSummery", blogsSummary);
      formData.append("status", blogsStatus);
      formData.append("user", blogsAuthor);
      formData.append("bio", blogsBio);

      const response = await fetch(addBlog, {
        method: "POST",
        body: formData,
      });
      const responseData = await response.json();
      if (response.ok) {
        if (responseData.status === 200){
          return true;
        }else{
          return false;
        }
      } else {
        alert("unsucessfull");
        // throw new Error('Failed to add News');
      }
    } catch (error) {
      // console.error('Error adding News:', error);
      return false;
    }
  }

  const handleAddBlogs = async () => {
    if (!blogsName) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingblog(true);

    const result = await addBlogs(
      blogsName,
      file,
      blogsDescription,
      blogsKeyword,
      blogsSummary,
      blogsStatus,
      blogsAuthor,
      blogsBio
    );
    setIsAddingblog(false);
    if (result) {
      alert("Blog Added Successfully");
      // console.log(newsStatus);
      //  window.location.reload();
      onClose();
    } else {
      alert("Error Adding Blog");
    }
  };

  return (
    <div>
      {modalAdd_blogIsOpen && <add_blog onClose={closeAdd_blog} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Blog</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={blogsName}
                    onChange={(e) => setBlogsName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Featured Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="document"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    style={{ height: "100px" }}
                    value={blogsDescription}
                    onChange={(e) => setBlogsDescription(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Keyword
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={blogsKeyword}
                    onChange={(e) => setBlogKeyword(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    One Line Summary
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={blogsSummary}
                    onChange={(e) => setBlogsSummary(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Author
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={blogsAuthor}
                    onChange={(e) => setBlogsAuthor(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Bio
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={blogsBio}
                    onChange={(e) => setBlogsBio(e.target.value)}
                  />
                </div>
                {/* <div class="form-group">
                    <label class="small mb-1" for="inputFullName">
                      Add Keywords <font color="red">*</font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="text"
                      placeholder=""
                    />
                  </div> */}
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status <font color="red"></font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      options={Status}
                      onChange={handleStatusChange}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingblog === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddBlogs}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_blog;
