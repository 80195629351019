import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";

import { TailSpin } from "react-loader-spinner";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_TermsCondition from "../../Components/Admin_components/add_termsConditions.js";
import Update_TermsCondition from "../../Components/Admin_components/update_TermsCondition.js";
import Delete_TermsCondition from "../../Components/Admin_components/delete_TermsConditions.js";
import "../../Api/apiUrls.js";
import { API_URL, getTermsAndConditions } from "../../Api/apiUrls";

function TermsCondition() {
  const [modalAdd_TermsConditionIsOpen, setAdd_TermsConditionModalIsOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [Policy, setPolicy] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [
    modalUpdate_TermsConditionIsOpen,
    setUpdate_TermsConditionModalIsOpen,
  ] = useState(false);
  const [modalDelete_TermsConditionIsOpen, setDelete_TermsConditionIsOpen] =
    useState(false);
  // const [selectedUserId, setSelectedUserId] = useState(null);
  const Status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const openAdd_TermsCondition = () => {
    closeAddTermsCondition();
    setAdd_TermsConditionModalIsOpen(true);
  };

  const closeAddTermsCondition = () => {
    setAdd_TermsConditionModalIsOpen(false);
  };

  const openUpdate_TermsCondition = (policyId, Policy) => {
    setSelectedUserId(policyId);
    setSelectedPolicy(Policy);
    closeUpdate_TermsCondition();
    setUpdate_TermsConditionModalIsOpen(true);
  };

  const closeUpdate_TermsCondition = () => {
    setUpdate_TermsConditionModalIsOpen(false);
  };
  const openDelete_TermsCondition = (policyId) => {
    setSelectedUserId(policyId);
    closeDelete_TermsCondition();
    setDelete_TermsConditionIsOpen(true);
  };

  const closeDelete_TermsCondition = () => {
    setDelete_TermsConditionIsOpen(null);
  };

  useEffect(() => {
    fetch(`${getTermsAndConditions}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const policy = data.data.TermsAndConditions;
        setPolicy(policy);
        console.log(policy);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <Header></Header>
      {modalAdd_TermsConditionIsOpen && (
        <Add_TermsCondition onClose={closeAddTermsCondition} />
      )}
      {modalUpdate_TermsConditionIsOpen && (
        <Update_TermsCondition
          policyId={selectedUserId}
          onClose={closeUpdate_TermsCondition}
        />
      )}
      {modalDelete_TermsConditionIsOpen && (
        <Delete_TermsCondition
          policyId={selectedUserId}
          onClose={closeDelete_TermsCondition}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Terms & Conditions</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div class="child ml-2">
                  <Select options={Status} placeholder="Select Status"></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class="btn-round"
                      onClick={openAdd_TermsCondition}
                    >
                      <i class="bi bi-person-add"></i> Add Terms Conditions
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
            {isLoading === true ? (
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  marginLeft: "50%",
                }}
              >
                <TailSpin color="black" width={50} />
              </div>
            ) : Policy.length > 0 ? (
              <table class="table table-striped ">
                <thead>
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Terms & Conditions</th>

                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Policy.map((Policy, index) => (
                    <tr key={Policy.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{Policy.terms_and_conditions || "-"}</td>

                      <td>
                        <div className="social-links mt-2">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openUpdate_TermsCondition(
                                Policy.id,
                                Policy.terms_and_conditions
                              )
                            }
                          >
                            <i class="bi bi-pencil"></i>
                          </a>
                          &nbsp;&nbsp;
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openDelete_TermsCondition(Policy.id)}
                          >
                            <i class="bi bi-trash3"></i>
                          </a>
                          &nbsp;
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
            {modalUpdate_TermsConditionIsOpen && (
              <Update_TermsCondition
                policyId={selectedUserId}
                Policy={selectedPolicy}
                onClose={closeUpdate_TermsCondition}
              />
            )}
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default TermsCondition;
