import React from "react";
import "../../Pages/Customer/Style.css";

function Footer() {
  return (
    <div>
      <footer id="footer" class="footer">
        <div class="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Artiquity</span>
          </strong>
          . All Rights Reserved
        </div>
        <div class="credits">
          Designed by{" "}
          <a target="_blank" href="https://ordinet.in/">
            Ordinet Solutions Pvt. Ltd.
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
