import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_blogs_news.css";
import "../../Api/apiUrls.js";
import { addAboutUs } from "../../Api/apiUrls";
function Add_about({ onClose }) {
  const [modalAdd_aboutIsOpen, setAdd_aboutModalIsOpen] = useState("");
  const [image, setImage] = useState();
  const [isAddingAbout, setIsAddingAbout] = useState(false);
  const [selectedHappy, setSelectedAbout] = useState([]);
  const [description, setDescription] = useState();
  const closeAdd_about = () => {
    setAdd_aboutModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setImage(file);
    console.log(file);

    const formData = new FormData();
    formData.append("photo", image);
    formData.append("about_us_text", description);
    const reader = new FileReader();
    reader.onload = function (e) {
      const imgElement = document.getElementById("selectedImg");
      imgElement.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  async function addAbout(image) {
    try {
      const formData = new FormData();
      formData.append("photo", image);
      formData.append("about_us_text", description);
      // console.log(categoryHomepage);
      const response = await fetch(addAboutUs, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true; // Category added successfully
      } else {
        throw new Error("Failed to add About Us");
      }
    } catch (error) {
      console.error("Error adding About Us:", error);
      return false; // Category addition failed
    }
  }

  const handleAddAbout = async () => {
    // if (!image) {
    //   alert("Please fill in all fields");
    //   return;
    // }

    setIsAddingAbout(true);

    const result = await addAbout(image);

    setIsAddingAbout(false);

    if (result) {
      alert("About Us Added Successfully");
      setSelectedAbout("");
      console.log(image);
      setImage("");
      window.location.reload();

      onClose(); // Close the modal
    } else {
      alert("Error Adding About Us");
    }
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div>
      {modalAdd_aboutIsOpen && <Add_about onClose={closeAdd_about} />}
      <div className="popup-background" onClick={handleClick}>
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              overflowY: "scroll",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add About Us</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="document"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description
                  </label>
                  <textarea
                  style={{height:"100px"}}
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingAbout === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddAbout}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_about;
