import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import ReactSwitch from "react-switch";
import Add_venue from "../../Components/Admin_components/add_venue.js";
import Update_venue from "../../Components/Admin_components/update_venue.js";
import Delete_venues from "../../Components/Admin_components/delete_venues.js";
import Venue_booking_slots from "../../Components/Admin_components/Venue_booking_slots.js";
import Delete_slot from "../../Components/Admin_components/delete_slot.js";
import VenueWishlist from "../../Components/Admin_components/venueWishlist.js";
import VenueTotalAppointment from "../../Components/Admin_components/venueTotalAppointment.js";
import VenueReview from "../../Components/Admin_components/venueReview.js";
import VenueActiveAppointment from "../../Components/Admin_components/venueActiveAppointment.js";
import Add_media from "../../Components/Admin_components/add_media.js";
import "../../Api/apiUrls";
import {
  getAdminVenue,
  venueExcel,
  UpdateAdminVenueToggle,
} from "../../Api/apiUrls";
function Venue() {
  const [checked, setChecked] = useState(0);
  const [checked2, setChecked2] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [toggleStates, setToggleStates] = useState({});
  const [toggleStates2, setToggleStates2] = useState({});
  const [venueData, setVenueData] = useState([]);
  const [modalVenue_booking_slotsIsOpen, setVenue_booking_slotsIsOpen] =
    useState("");
  const [modalAdd_venueIsOpen, setAdd_venueModalIsOpen] = useState("");
  const [modalUpdate_venueIsOpen, setUpdate_venueModalIsOpen] = useState(false);
  const [modalDelete_venuesIsOpen, setDelete_venuesIsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [dateTime, setDateTime] = useState("");
  const [id, setId] = useState("");
  const [modalAdd_mediaIsOpen, setAdd_MediaModalIsOpen] = useState("");

  const [venueSelectedName, setSelectedVenueName] = useState(null);
  const [venueSelectedShortDescription, setSelectedVenueShortDescription] =
    useState(null);
  const [venueSelectedDescription, setSelectedVenueDescription] =
    useState(null);
  const [venueSelectedInclusion, setSelectedVenueInclusion] = useState(null);
  const [venueSelectedCancellation, setSelectedVenueCancellation] =
    useState(null);
  const [venueSelectedSeats, setSelectedVenueSeats] = useState(null);
  const [venueSelectedDuration, setSelectedVenueDuration] = useState(null);
  const [venueSelectedPrice, setSelectedVenuePrice] = useState(null);
  const [venueSelectedAddress, setSelectedVenueAddress] = useState(null);
  const [venueSelectedAddress2, setSelectedVenueAddress2] = useState(null);
  const [venueSelectedPostalCode, setSelectedVenuePostalCode] = useState(null);
  const [venueSelectedGoogleCode, setSelectedVenueGoogleCode] = useState(null);
  const [venueSelectedEmail, setSelectedVenueEmail] = useState(null);
  const [venueSelectedPhone, setSelectedVenuePhone] = useState(null);
  const [venueSelectedWebsite, setSelectedVenueWebsite] = useState(null);
  const [venueSelectedCategory, setVenueSelectedCategory] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [venueSelectedCity, setVenueSelectedCity] = useState(null);
  const [venueSelectedLocation, setVenueSelectedLocation] = useState(null);
  const [selectedVenueStatus, setSelectedVenueStatus] = useState(null);
  const [venueSelectedVenueOwner, setSelectedVenueVenueOwner] = useState(null);

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const date = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    const formattedDateTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    return formattedDateTime;
  };
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const [filteredVenueData, setFilteredVenueData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // useEffect(() => {
  //   const filteredData = venueData.filter((venue) => {
  //     const isFilter =
  //       !selectedFilter ||
  //       selectedFilter.value === "All" ||
  //       venue.filter === selectedFilter.value;
  //     const isStatus =
  //       !selectedStatus ||
  //       selectedStatus.value === "All" ||
  //       venue.status === selectedStatus.value;
  //     const isMonth =
  //       !selectedMonth ||
  //       selectedMonth.value === "All" ||
  //       venue.created_at_month === selectedMonth.value;
  //     const isYear =
  //       !selectedYear ||
  //       selectedYear.value === "All" ||
  //       venue.created_at_year === selectedYear.value;
  //     const values = Object.values(venue).map((value) =>
  //       typeof value === "string" ? value.toLowerCase() : ""
  //     );
  //     const isSearchMatch = values.some((value) =>
  //       value.includes(searchQuery.toLowerCase())
  //     );
  //     return isFilter && isStatus && isMonth && isYear && isSearchMatch;
  //   });

  //   setFilteredVenueData(filteredData);
  // }, [
  //   selectedFilter,
  //   selectedStatus,
  //   selectedMonth,
  //   selectedYear,
  //   venueData,
  //   searchQuery,
  // ]);

  useEffect(() => {
    const filteredData = venueData.filter((venue) => {
      const isFilter =
        !selectedFilter ||
        selectedFilter.value === "All" ||
        venue.filter === selectedFilter.value;
      const isStatus =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        venue.status === selectedStatus.value;
      const isMonth =
        !selectedMonth ||
        selectedMonth.value === "All" ||
        venue.created_at_month === selectedMonth.value;
      const isYear =
        !selectedYear ||
        selectedYear.value === "All" ||
        venue.created_at_year === selectedYear.value;
  
      // Safely access the venue_owner's name with optional chaining
      const venueOwnerName = venue.venue_owner?.name?.toLowerCase() || "";
      
      const values = [
        ...Object.values(venue).map((value) =>
          typeof value === "string" ? value.toLowerCase() : ""
        ),
        venueOwnerName,
      ];
  
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
  
      return isFilter && isStatus && isMonth && isYear && isSearchMatch;
    });
  
    setFilteredVenueData(filteredData);
  }, [
    selectedFilter,
    selectedStatus,
    selectedMonth,
    selectedYear,
    venueData,
    searchQuery,
  ]);
  

  

  const openAdd_venue = () => {
    closeAdd_venue();
    const currentDateTime = getCurrentDateTime();
    setDateTime(currentDateTime);
    setAdd_venueModalIsOpen(true);
  };

  const closeAdd_venue = () => {
    setAdd_venueModalIsOpen(false);
  };
  const openUpdate_venue = (
    venueId,
    venueName,
    venueShort,
    venueDescription,
    venueInclusion,
    venueCancellation,
    venueCategory,
    venueseats,
    venueDuration,
    venuePrice,
    venueAddress,
    venueAddress2,
    venueCity,
    venueLocation,
    venuePostal,
    venueGoogle,
    venueEmail,
    venuePhone,
    venueWebsite,
    venueStatus,
    venueVenueOwner
  ) => {
    setSelectedUserId(venueId);
    setSelectedVenueName(venueName);
    setSelectedVenueShortDescription(venueShort);
    setSelectedVenueDescription(venueDescription);
    setSelectedVenueInclusion(venueInclusion);
    setSelectedVenueCancellation(venueCancellation);
    setVenueSelectedCategory(venueCategory);
    setSelectedVenueSeats(venueseats);
    setSelectedVenueDuration(venueDuration);
    setSelectedVenuePrice(venuePrice);
    setSelectedVenueAddress(venueAddress);
    setSelectedVenueAddress2(venueAddress2);
    setVenueSelectedCity(venueCity);
    setVenueSelectedLocation(venueLocation);
    setSelectedVenuePostalCode(venuePostal);
    setSelectedVenueGoogleCode(venueGoogle);
    setSelectedVenueEmail(venueEmail);
    setSelectedVenuePhone(venuePhone);
    setSelectedVenueWebsite(venueWebsite);
    setSelectedVenueStatus(venueStatus);
    setSelectedVenueVenueOwner(venueVenueOwner);
    closeUpdate_venue();
    setUpdate_venueModalIsOpen(true);
  };

  const filter = [
    { value: "All", label: "All" },
    { value: "Featured", label: "Featured" },
    { value: "Sponsored", label: "Sponsored" },
    // { value: "Gift", label: "Gift" },
  ];
  const month = [
    { value: "All", label: "All" },
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const year = [
    { value: "All", label: "All" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const closeUpdate_venue = () => {
    setUpdate_venueModalIsOpen(false);
  };
  const openDelete_venues = (venueId) => {
    setSelectedUserId(venueId);
    closeDelete_venues();
    setDelete_venuesIsOpen(true);
  };

  const closeDelete_venues = () => {
    setDelete_venuesIsOpen(null);
  };
  const openVenue_booking_slots = (id) => {
    setId(id);
    setVenue_booking_slotsIsOpen(true);
  };
  const closeVenue_booking_slots = () => {
    setVenue_booking_slotsIsOpen(false);
  };

  const [modalVenueWishlistIsOpen, setVenueWishlistModalIsOpen] = useState("");
  const openVenueWishlist = (id) => {
    setId(id);
    setVenueWishlistModalIsOpen(true);
  };
  const closeVenueWishlist = () => {
    setVenueWishlistModalIsOpen(false);
  };

  const [
    modalVenueTotalAppointmentIsOpen,
    setVenueTotalAppointmentModalIsOpen,
  ] = useState("");

  const openVenueTotalAppointment = (id) => {
    setId(id);
    setVenueTotalAppointmentModalIsOpen(true);
  };
  const closeVenueTotalAppointment = () => {
    setVenueTotalAppointmentModalIsOpen(false);
  };

  const [modalVenueReviewIsOpen, setVenueReviewModalIsOpen] = useState("");

  const openVenueReview = (id) => {
    setId(id);
    setVenueReviewModalIsOpen(true);
  };
  const closeVenueReview = () => {
    setVenueReviewModalIsOpen(false);
  };

  const [
    modalVenueActiveAppointmentIsOpen,
    setVenueActiveAppointmentModalIsOpen,
  ] = useState("");

  const openVenueActiveAppointment = (id) => {
    setId(id);
    setVenueActiveAppointmentModalIsOpen(true);
  };
  const closeVenueActiveAppointment = () => {
    setVenueActiveAppointmentModalIsOpen(false);
  };

  const openAdd_media = (venueId) => {
    setSelectedUserId(venueId);
    closeAdd_media();
    // const currentDateTime = getCurrentDateTime();
    // setDateTime(currentDateTime);
    setAdd_MediaModalIsOpen(true);
  };

  const closeAdd_media = () => {
    setAdd_MediaModalIsOpen(false);
  };

  const toggleSwitch = async (venueId) => {
    const newToggleState = toggleStates[venueId] === 1 ? 0 : 1;
    console.log(newToggleState);

    // Update the local state
    setToggleStates((prevState) => ({
      ...prevState,
      [venueId]: newToggleState,
    }));
    try {
      const response = await fetch(UpdateAdminVenueToggle, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          homepage: newToggleState === 1 ? "1" : "0",
          Venue_id: venueId,
        }),
      });

      const data = await response.json();
      console.log(data); // Log the server response
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  const toggleSwitch2 = async (venueId) => {
    const newToggleState2 = toggleStates2[venueId] === 1 ? 0 : 1;
    console.log(newToggleState2);

    // Update the local state
    setToggleStates2((prevState) => ({
      ...prevState,
      [venueId]: newToggleState2,
    }));
    try {
      const response = await fetch(UpdateAdminVenueToggle, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          featured: newToggleState2 === 1 ? "1" : "0",
          Venue_id: venueId,
        }),
      });

      const data = await response.json();
      console.log(data); // Log the server response
    } catch (error) {
      console.error("Error updating toggle state:", error);
    }
  };

  useEffect(() => {
    fetch(getAdminVenue)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const venue = data.data.Venue;
        setVenueData(venue);
        console.log(venue);
        const initialToggleStates = venue.reduce((acc, venue) => {
          acc[venue.id] = venue.homepage === "1" ? 1 : 0;
          return acc;
        }, {});

        setToggleStates(initialToggleStates);

        const initialToggleStates2 = venue.reduce((acc, venue) => {
          acc[venue.id] = venue.featured === "1" ? 1 : 0;
          return acc;
        }, {});

        setToggleStates2(initialToggleStates2);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  function handleDownloadClick() {
    window.open(venueExcel, "_blank");
  }
  return (
    <div>
      <Header></Header>
      {modalAdd_venueIsOpen && (
        <Add_venue onClose={closeAdd_venue} dateTime={dateTime} />
      )}
      {modalUpdate_venueIsOpen && (
        <Update_venue
          venueId={selectedUserId}
          venueName={venueSelectedName}
          venueShort={venueSelectedShortDescription}
          venueDescription={venueSelectedDescription}
          venueInclusion={venueSelectedInclusion}
          venueCancellation={venueSelectedCancellation}
          venueCategory={venueSelectedCategory}
          venueseats={venueSelectedSeats}
          venueDuration={venueSelectedDuration}
          venuePrice={venueSelectedPrice}
          venueAddress={venueSelectedAddress}
          venueAddress2={venueSelectedAddress2}
          venueCity={venueSelectedCity}
          venueLocation={venueSelectedLocation}
          venuePostal={venueSelectedPostalCode}
          venueGoogle={venueSelectedGoogleCode}
          venueEmail={venueSelectedEmail}
          venuePhone={venueSelectedPhone}
          venueWebsite={venueSelectedWebsite}
          venueStatus={selectedVenueStatus}
          venueVenueOwner={venueSelectedVenueOwner}
          onClose={closeUpdate_venue}
        />
      )}
      {modalDelete_venuesIsOpen && (
        <Delete_venues onClose={closeDelete_venues} venueId={selectedUserId} />
      )}
      {modalVenue_booking_slotsIsOpen && (
        <Venue_booking_slots onClose={closeVenue_booking_slots} id={id} />
      )}
      {modalVenueWishlistIsOpen && (
        <VenueWishlist onClose={closeVenueWishlist} id={id} />
      )}
      {modalVenueTotalAppointmentIsOpen && (
        <VenueTotalAppointment onClose={closeVenueTotalAppointment} id={id} />
      )}

      {modalVenueReviewIsOpen && (
        <VenueReview onClose={closeVenueReview} id={id} />
      )}

      {modalVenueActiveAppointmentIsOpen && (
        <VenueActiveAppointment onClose={closeVenueActiveAppointment} id={id} />
      )}

      {modalAdd_mediaIsOpen && (
        <Add_media onClose={closeAdd_media} venueId={selectedUserId} />
      )}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Venue</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li class="breadcrumb-item active">Venue</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-12 col-md-12 col-lg-12 row">
                <div class="child col-md-2" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "37px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    placeholder="Search..."
                  />
                </div>
                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={filter}
                    placeholder="Filter By"
                    onChange={(selectedOption) =>
                      setSelectedFilter(selectedOption)
                    }
                  />
                </div>
                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={status}
                    placeholder="Select status"
                    onChange={(selectedOption) =>
                      setSelectedStatus(selectedOption)
                    }
                  />
                </div>

                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={month}
                    placeholder="Select Month"
                    onChange={(selectedOption) =>
                      setSelectedMonth(selectedOption)
                    }
                  />
                </div>
                <div class="  col-md-2 " style={{ padding: "5px" }}>
                  <Select
                    options={year}
                    placeholder="Select Year"
                    onChange={(selectedOption) =>
                      setSelectedYear(selectedOption)
                    }
                  />
                </div>
                <div
                  class=" col-md-2"
                  style={{ padding: "5px", display: "flex" }}
                >
                  <a>
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_venue}
                    >
                      <i class="bi bi-person-add"></i> Add Venue
                    </button>
                  </a>
                  <i
                    onClick={handleDownloadClick}
                    style={{
                      cursor: "pointer",
                      fontSize: "20px",
                      marginLeft: "10px",
                    }}
                    class="bi bi-download"
                  ></i>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "20px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : filteredVenueData.length > 0 ? (
                <table class="table table-striped " style={{ padding: "0rem" }}>
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Home Page</th>
                      <th scope="col">Featured</th>
                      <th scope="col">Media Count</th>
                      <th scope="col">Action</th>
                      <th scope="col">Slots</th>
                      <th scope="col">Status</th>
                      <th scope="col">Venue Name</th>
                      <th scope="col">Venue Provider</th>
                      <th scope="col">Rating</th>
                      <th scope="col">Reviews</th>
                      <th scope="col">Share</th>
                      <th scope="col">Visit</th>
                      <th scope="col">Wishlisted</th>
                      <th scope="col">Total Appointments</th>
                      <th scope="col">Appointment Active</th>
                      <th scope="col">Package Type</th>
                      <th scope="col">Status Of Package</th>

                      <th scope="col">Created On</th>
                     
                     
                    </tr>
                  </thead>
                  <tbody>
                    {filteredVenueData.map((venue, index) => (
                      <tr key={venue.id}>
                        <th scope="row">{index + 1}</th>
                        {/* <td>{workshop.id}</td> */}
                        <td>
                          <Toggle
                            checked={toggleStates[venue.id] === 1}
                            onChange={() => toggleSwitch(venue.id)}
                          />
                        </td>
                        <td>
                          <Toggle
                            checked={toggleStates2[venue.id] === 1}
                            onChange={() => toggleSwitch2(venue.id)}
                          />
                        </td>
                        <td>{venue.media_count}</td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdate_venue(
                                  venue.id,
                                  venue.name,
                                  venue.short_description,
                                  venue.description,
                                  venue.inclusion_amenities,
                                  venue.cancellation_policy,
                                  {
                                    value: venue.category.id,
                                    label: venue.category.name,
                                  },
                                  venue.duration,
                                  venue.capacity,
                                  venue.price,
                                  venue.address_line1,
                                  venue.address_line2,
                                  {
                                    value: venue.city.id,
                                    label: venue.city.name,
                                  },
                                  {
                                    value: venue.area.id,
                                    label: venue.area.name,
                                  },
                                  venue.pincode,
                                  venue.google_location_link,
                                  venue.email,
                                  venue.mobileNumber,
                                  venue.website,
                                  { value: venue.id, label: venue.status },
                                  {
                                    value: venue.venue_owner.id,
                                    label: venue.venue_owner.name,
                                  }
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openDelete_venues(venue.id)}
                            >
                              <i class="bi bi-trash3"></i>
                            </a>
                            &nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              // onClick={() => openDelete_venues(venue.id)}
                              // onClick={openAdd_media}
                              onClick={() => openAdd_media(venue.id)}
                            >
                              <i class="bi bi-camera"></i>
                            </a>
                          </div>
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              onClick={() => openVenue_booking_slots(venue.id)}
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                          </div>
                        </td>
                        <td>{venue.status}</td>
                        <td>{venue.name}</td>
                        <td>
                          {venue.venue_owner ? venue.venue_owner.name : "-"}

                          <br />

                          {venue.venue_owner ? venue.venue_owner.email : "-"}

                          <br />

                          {venue.venue_owner
                            ? venue.venue_owner.mobileNumber
                            : "-"}
                        </td>
                        <td>{venue.ratings_count}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openVenueReview(venue.id)}
                          >
                            {venue.reviews_count}
                          </a>
                        </td>
                        <td>{venue.share_count}</td>
                        <td>{venue.visit}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openVenueWishlist(venue.id)}
                          >
                            {venue.wishlist_count}
                          </a>
                        </td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openVenueTotalAppointment(venue.id)}
                          >
                            {venue.total_appointments}
                          </a>
                        </td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openVenueActiveAppointment(venue.id)}
                          >
                            {" "}
                            {venue.active_appointments}
                          </a>
                        </td>
                        <td>{venue.package ? venue.package.duration : "-"}</td>
                        <td>{venue.package ? venue.package.status : "-"}</td>

                        <td>{formatCreatedAt(venue.created_at)}</td>
                       

                        
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredVenueData.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Venue;
