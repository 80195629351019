import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";

function Instructor_Dashboard() {
  return (
    <div>
      <Header></Header>
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/dashboard">Home</a>
              </li>
              <li class="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        {/* <section class="section">
          <div class="card p-2">
            <div class="parent mt-2">
              <div class="child">
                <input
                  id="myInput"
                  type="text"
                  style={{
                    borderRadius: "5px",
                    height: "35px",
                    padding: "10px",
                    border: "1px solid ",
                  }}
                  placeholder="Search..."
                />
              </div>
              <div class="child" style={{ marginLeft: "1%" }}>
                <div class="dropdown">
                  <button
                    class="btn btn-secondary dropdown-toggle  btn-round"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Dropdown Menu
                  </button>
                  <div
                    class="dropdown-menu 
                     btn-round"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <a class="dropdown-item" href="#">
                      Item 1
                    </a>
                    <a class="dropdown-item" href="#">
                      Item 2
                    </a>
                    <a class="dropdown-item" href="#">
                      Item 3
                    </a>
                  </div>
                </div>
              </div>

              <div class="child" style={{ marginLeft: "49%" }}>
                <a href="/addBlogs">
                  {" "}
                  <button
                    id="btnNewUser"
                    type="button"
                    data-toggle="modal"
                    data-target="#modalAdd"
                    class=" btn-round"
                  >
                    <i class="bi bi-person-add"></i> Add
                  </button>
                </a>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              { <TailSpin color="black" width={50} /> }
            </div>
            <table class="table  ">
              <thead class="table-active">
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">First</th>
                  <th scope="col">Last</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td colspan="2">Larry the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody>
            </table>
          </div>
        </section> */}
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Instructor_Dashboard;
