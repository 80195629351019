import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_review from "../../Components/Admin_components/add_review.js";
import Update_review from "../../Components/Admin_components/update_review.js";
import Delete_review from "../../Components/Admin_components/delete_review.js";
import Review_eye from "../../Components/Admin_components/eye_review.js";
import "../../Api/apiUrls";
import { getAdminReview } from "../../Api/apiUrls";
function Review() {
  const [review, setReview] = useState([]);
  const [modalReview_eyeIsOpen, setReview_eyeModalIsOpen] = useState("");
  const [modalAdd_reviewIsOpen, setAdd_reviewModalIsOpen] = useState("");
  const [modalUpdate_reviewIsOpen, setUpdate_reviewModalIsOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEye_reviewIdId, setSelectedEye_reviewIdId] = useState(null);
  const [modalDelete_reviewIsOpen, setDelete_reviewIsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const renderStars = (review) => {
    const maxReview = 5;
    const starIcons = [];

    for (let i = 1; i <= maxReview; i++) {
      const isFilled = i <= review;

      starIcons.push(
        <i
          key={i}
          className={`bi bi-star${isFilled ? "-fill" : ""}`}
          style={{ color: isFilled ? "gold" : "gray" }}
        ></i>
      );
    }

    return starIcons;
  };

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedType, setSelectedType] = useState(null);

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption.value);
  };

  const filteredReviews = review.filter((item) => {
    return (
      (!selectedStatus ||
        selectedStatus === "All" ||
        item.status.toString() === selectedStatus) &&
      (!selectedType || selectedType === "All" || item.type === selectedType)
    );
  });
  const status = [
    { value: "All", label: "All" },
    { value: "0", label: "Pending" },
    { value: "1", label: "Approve" },
    { value: "-1", label: "Reject" },
  ];
  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const drop = [
    { value: " Website", label: " Website" },
    { value: " Admin", label: " Admin" },
  ];
  const openReview_eye = (eye_reviewId) => {
    setSelectedEye_reviewIdId(eye_reviewId);
    closeReview_eye();
    setReview_eyeModalIsOpen(true);
  };

  const closeReview_eye = () => {
    setReview_eyeModalIsOpen(false);
  };
  const openAdd_review = () => {
    closeAdd_review();
    setAdd_reviewModalIsOpen(true);
  };

  const closeAdd_review = () => {
    setAdd_reviewModalIsOpen(false);
  };
  const openUpdate_review = () => {
    closeUpdate_review();
    setUpdate_reviewModalIsOpen(true);
  };

  const closeUpdate_review = () => {
    setUpdate_reviewModalIsOpen(false);
  };
  const openDelete_review = (userId) => {
    setSelectedUserId(userId);
    closeDelete_review();
    setDelete_reviewIsOpen(true);
  };

  const closeDelete_review = () => {
    setDelete_reviewIsOpen(null);
  };

  useEffect(() => {
    fetch(getAdminReview)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const review = data.data.Review;
        console.log(review);
        setReview(review);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div>
      <Header></Header>
      {modalAdd_reviewIsOpen && <Add_review onClose={closeAdd_review} />}
      {modalReview_eyeIsOpen && (
        <Review_eye
          eye_reviewId={selectedEye_reviewIdId}
          onClose={closeReview_eye}
        />
      )}
      {modalUpdate_reviewIsOpen && (
        <Update_review onClose={closeUpdate_review} />
      )}
      {modalDelete_reviewIsOpen && (
        <Delete_review userId={selectedUserId} onClose={closeDelete_review} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Review</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Review</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                {/* <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div> */}
                <div className="child ml-2">
                  <Select
                    options={status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
                <div className="child ml-2">
                  <Select
                    options={type}
                    placeholder="Select Type"
                    onChange={handleTypeChange}
                  ></Select>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div className="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : review.length > 0 ? (
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Tittle</th>
                      <th scope="col">Review</th>
                      <th scope="col">Rating</th>
                      <th scope="col">User</th>
                      <th scope="col">Instructor Details</th>
                      <th scope="col">Type</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredReviews.map((review, index) => (
                      <tr key={review.id}>
                        <th scope="row">{index + 1}</th>

                        <td>
                          {review.type === "Venue"
                            ? review.venue?.name
                            : review.workshop?.name || "-"}
                          <br />
                        </td>
                        <td>
                          {review.review || "-"}
                          <br />
                        </td>
                        <td>
                          {renderStars(review.rating)}
                          <br />
                        </td>
                        <td>
                          {review.user.name || "-"}
                          <br />
                          {review.user.email || "-"}
                          <br />
                          {review.user.mobileNumber || "-"}
                          <br />
                        </td>
                        <td>
                          {/* If the review type is 'workshop', display workshop instructor details */}
                          {review.type === "Workshop" && (
                            <div>
                              <p style={{ marginBottom: "0px" }}>
                                {" "}
                                {review.workshop.workshopInstructor.name}
                              </p>
                              <p style={{ marginBottom: "0px" }}>
                                {" "}
                                {review.workshop.workshopInstructor.email}
                              </p>
                              <p style={{ marginBottom: "0px" }}>
                                {" "}
                                {
                                  review.workshop.workshopInstructor
                                    .mobileNumber
                                }
                              </p>
                            </div>
                          )}
                          {/* If the review type is 'venue', display venue owner details */}
                          {review.type === "venue" && (
                            <div>
                              <p style={{ marginBottom: "0px" }}>
                                {" "}
                                {review.venue.venue_owner.name}
                              </p>
                              <p style={{ marginBottom: "0px" }}>
                                {" "}
                                {review.venue.venue_owner.email}
                              </p>
                              <p style={{ marginBottom: "0px" }}>
                                {" "}
                                {review.venue.venue_owner.mobileNumber}
                              </p>
                            </div>
                          )}
                        </td>
                        <td>
                          {review.type || "-"}
                          <br />
                        </td>
                        <td>
                          {formatCreatedAt(review.created_at)}
                          <br />
                        </td>
                        <td>
                          {review.status === 1
                            ? "Approve"
                            : review.status === 0
                            ? "Pending"
                            : review.status === -1
                            ? "Reject"
                            : "-"}

                          <br />
                        </td>

                        <td>
                          <div className="social-links mt-2">
                            <a>
                              <i
                                class="bi bi-eye"
                                style={{ cursor: "pointer" }}
                                // onClick={openReview_eye}
                                onClick={() =>
                                  openReview_eye(
                                    review.id
                                    // review.review,
                                    // review.rating,
                                    // review.name,
                                    // review.type,
                                    // review.created_at,
                                    // review.status
                                  )
                                }
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredReviews.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Review;
