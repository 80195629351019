import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_blog.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { API_URL, UpdateAdminBlog } from "../../Api/apiUrls";
function Update_blog({
  blogsId,
  blogsName,
  blogsImage,
  blogsDescription,
  blogsSummary,
  blogsAuthor,
  blogsKeyword,
  blogsBio,
  blogsStatus,
  onClose,
}) {
  const [modalUpdate_blogIsOpen, setUpdate_blogModalIsOpen] = useState("");
  const [newblogsName, setNewBlogsName] = useState(blogsName);
  const [newblogsDescription, setNewBlogsDescription] =
    useState(blogsDescription);
  const [newblogsSummary, setNewBlogsSummary] = useState(blogsSummary);
  const [newblogsAuthor, setNewBlogsAuthor] = useState(blogsAuthor);
  const [newBlogsStatus, setNewBlogsStatus] = useState(blogsStatus);
  const [newfile, setNewFile] = useState(null);
  const [newblogsKeyword, setNewBlogsKeyword] = useState(blogsKeyword);
  const [newblogsBio, setNewBlogsBio] = useState(blogsBio);
  const [isUpdatingblogs, setIsUpdatingblogs] = useState(false);

  const closeUpdate_blog = () => {
    setUpdate_blogModalIsOpen(false);
  };
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  function reloadPage() {
    window.location.reload();
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setNewFile(selectedFile);
    console.log(selectedFile);
  };
  const handleStatusChange = (selectedOption) => {
    setNewBlogsStatus(selectedOption);
  };
  const handleUpdate = () => {
    setIsUpdatingblogs(true);
    const formData = new FormData();
    formData.append("blog_and_news_id", blogsId);
    formData.append("title", newblogsName);
    formData.append("discription", newblogsDescription);
    formData.append("keywords", newblogsKeyword);
    formData.append("shortSummery", newblogsSummary);
    formData.append("user", newblogsAuthor);
    formData.append("bio", newblogsBio);
    formData.append("status", newBlogsStatus.label);
    if (newfile) {
      formData.append("photo", newfile);
    }

    fetch(UpdateAdminBlog, {
      method: "PUT",
      // headers: {
      //   'accept': 'application/json',
      //   'Content-Type': 'application/json',
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated!!");
          setIsUpdatingblogs(false);
          window.location.reload();
          onClose();
        } else {
          console.error("Error updating Blog:", data.message);
          setIsUpdatingblogs(false);
        }
      })
      .catch((error) => {
        console.error("Error updating blog:", error);
        setIsUpdatingblogs(false);
      });
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  return (
    <div>
      {modalUpdate_blogIsOpen && <Update_blog onClose={closeUpdate_blog} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Blog</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newblogsName}
                    onChange={(e) => setNewBlogsName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Featured Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="document"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                  <img
                    id="selectedImg"
                    src={`${API_URL}/${blogsImage}`}
                    // alt={category.name || 'No Name'}
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newblogsDescription}
                    onChange={(e) => setNewBlogsDescription(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Keyword
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newblogsKeyword}
                    onChange={(e) => setNewBlogsKeyword(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    One Line Summery<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newblogsSummary}
                    onChange={(e) => setNewBlogsSummary(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Author<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newblogsAuthor}
                    onChange={(e) => setNewBlogsAuthor(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Bio
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newblogsBio}
                    onChange={(e) => setNewBlogsBio(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status <font color="red"></font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      options={Status}
                      value={newBlogsStatus}
                      onChange={handleStatusChange}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isUpdatingblogs === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_blog;
