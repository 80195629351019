import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_categories.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { API_URL, UpdateAdminBanner, updateAboutUs } from "../../Api/apiUrls";
import About from "../../Pages/Admin/aboutUs";

function Update_aboutUs({ onClose, aboutUsId, description, photo }) {
  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const [newfile, setNewFile] = useState(photo);
  const [modalUpdate_aboutUsIsOpen, setUpdate_aboutUsModalIsOpen] =
    useState("");
  const [newAboutUsDescription, setNewAboutUsDescription] =
    useState(description);

  const [isUpdatingAboutUs, setIsUpdatingAboutUs] = useState(false);
  const closeUpdate_aboutUs = () => {
    setUpdate_aboutUsModalIsOpen(false);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setNewFile(selectedFile);
    console.log(selectedFile);
  };

  function reloadPage() {
    window.location.reload();
  }

  const handleUpdate = () => {
    setIsUpdatingAboutUs(true);

    const formData = new FormData();
    formData.append("about_us_id", aboutUsId);
    formData.append("about_us_text", newAboutUsDescription);
    if (newfile) {
      formData.append("photo", newfile);
    }

    fetch(`${updateAboutUs}`, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingAboutUs(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating about us:", data.message);
          setIsUpdatingAboutUs(false);
        }
      })
      .catch((error) => {
        console.error("Error updating about us:", error);
        setIsUpdatingAboutUs(false);
      });
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  return (
    <div>
      {modalUpdate_aboutUsIsOpen && (
        <Update_aboutUs onClose={closeUpdate_aboutUs} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update About Us</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Cover Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="aboutUsSelectedImg"
                    name="document"
                    onChange={handleFileChange}
                  />
                  <br />
                  <br />
                  <img
                    src={`${API_URL}/${photo}`}
                    // alt={category.name || 'No Name'}
                    style={{ width: "60px", height: "60px" }}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description<font color="red">*</font>
                  </label>
                  <textarea
                    style={{ height: "100px" }}
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newAboutUsDescription}
                    onChange={(e) => setNewAboutUsDescription(e.target.value)}
                  />
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              {isUpdatingAboutUs === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary"
                  onClick={handleUpdate}
                  style={{ background: "#000 ", border: "none" }}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_aboutUs;
