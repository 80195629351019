import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_packages.css";
import Select from "react-select";
import "../../Api/apiUrls";
import {
    API_URL,
    UpdateAdminEvents
} from "../../Api/apiUrls";

function UpdateEvents({ eventId, eventTitle, eventDescription, eventKeywords, eventSummary,
    eventOrganizer,
    eventOrganizerBio,
    eventOrganizerLogo,
    eventCity,
    eventDate,
    eventInstructor,
    eventType,
    eventStatus,
    onClose }) {

    const [neweventStatus, setNewEventStatus] = useState(eventStatus);
    const [neweventType, setNewEventType] = useState(eventType);
    const [neweventTitle, setNewEventTitle] = useState(eventTitle);
    const [neweventDescription, setNewEventDescription] = useState(eventDescription);
    const [neweventKeywords, setNewEventKeywords] = useState(eventKeywords);
    const [neweventSummary, setNewEventSummary] = useState(eventSummary);
    const [neweventOrganizer, setNewEventOrganizer] = useState(eventOrganizer);
    const [neweventOrganizerBio, setNewEventOrganizerBio] = useState(eventOrganizerBio);
    const [newfile, setNewFile] = useState(null);
    const [neweventCity, setNewEventCity] = useState(eventCity);
    const [neweventDate, setNewEventDate] = useState(eventDate);
    const [isAddingnews, setIsAddingnews] = useState(false);
    const [modalUpdateEventsIsOpen, setUpdateEventsModalIsOpen] = useState("");
    const closeUpdateEvents = () => {
        setUpdateEventsModalIsOpen(false);
    };
    const [instructors, setInstructors] = useState([]);

    function reloadPage() {
        window.location.reload();
    }
    const [isLoading, setIsLoading] = useState(true);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    useEffect(() => {
        // Fetch data from the API
        fetch(`${API_URL}/user/get-instructor/`)
            .then((response) => response.json())
            .then((data) => {
                // Extract relevant data from the response
                const options = data.data.users.map((user) => ({
                    value: user.id,
                    label: user.name,
                    image: `${API_URL}/${user.profile_image}`,
                }));
                setInstructors(options);
                setFilteredOptions(options); // Initially, set filtered options to all options
                setIsLoading(false);
            })
            .catch((error) => console.error("Error fetching instructors:", error));
    }, []);

    const handleInputChange = (newValue) => {
        setSearchValue(newValue);
        // filterOptions(newValue);
    };

    const [selectedInstructor, setSelectedInstructor] =
        useState(eventInstructor);

    const handleInstructorChange = (selectedOption) => {
        setSelectedInstructor(selectedOption);
    };

    const status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ];

    const type = [
        { value: "School Collaboration", label: "School Collaboration" },
    ];

    const handleStatusChange = (selectedOption) => {
        setNewEventStatus(selectedOption);
    };

    const handleTypeChange = (selectedOption) => {
        setNewEventType(selectedOption);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setNewFile(selectedFile);
        console.log(selectedFile);
    };

    const [isUpdatingnews, setIsUpdatingnews] = useState(false);
    const handleUpdate = () => {
        setIsUpdatingnews(true);
        const formData = new FormData();
        formData.append("event_id", eventId);
        formData.append("title", neweventTitle);
        formData.append("description", neweventDescription);
        formData.append("keywords", neweventKeywords);
        formData.append("short_summary", neweventSummary);
        formData.append("status", neweventStatus.label);
        formData.append("event_type", neweventType.label);
        formData.append("organizer", neweventOrganizer);
        formData.append("bio", neweventOrganizerBio);
        formData.append("city", neweventCity);
        formData.append("event_date", neweventDate);
        formData.append("instructor", selectedInstructor.value);
        formData.append("organizer_image", "");
        if (newfile) {
            formData.append("organizer_image", newfile);
        }

        fetch(UpdateAdminEvents, {
            method: "PUT",
            // headers: {
            //   'accept': 'application/json',
            //   'Content-Type': 'application/json',
            // },
            body: formData,
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    alert("Updated!!");
                    setIsUpdatingnews(false);
                    window.location.reload();
                    onClose();
                } else {
                    console.error("Error updating news:", data.message);
                    setIsUpdatingnews(false);
                }
            })
            .catch((error) => {
                console.error("Error updating news:", error);
                setIsUpdatingnews(false);
            });
    };
    return (
        <div>
            {modalUpdateEventsIsOpen && <UpdateEvents onClose={closeUpdateEvents} />}
            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Update Events</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Title<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={neweventTitle}
                                        onChange={(e) => setNewEventTitle(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Description<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        style={{ height: "100px" }}
                                        value={neweventDescription}
                                        onChange={(e) => setNewEventDescription(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        keywords<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={neweventKeywords}
                                        onChange={(e) => setNewEventKeywords(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        One Line Summary<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=" "
                                        value={neweventSummary}
                                        onChange={(e) => setNewEventSummary(e.target.value)}
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Organizer<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=" "
                                        value={neweventOrganizer}
                                        onChange={(e) => setNewEventOrganizer(e.target.value)}
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Organizer Bio<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=" "
                                        value={neweventOrganizerBio}
                                        onChange={(e) => setNewEventOrganizerBio(e.target.value)}
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputDocument">
                                        Cover Image
                                    </label>
                                    <br />
                                    <input
                                        type="file"
                                        id="bannerSelectedImg"
                                        name="document"
                                        accept="image/*"
                                        onChange={handleFileChange}
                                    />
                                    <img
                                        src={`https://artiquity.in${eventOrganizerLogo}`}
                                        // alt={category.name || 'No Name'}
                                        style={{ width: "60px", height: "60px" }}
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        City<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=" "
                                        value={neweventCity}
                                        onChange={(e) => setNewEventCity(e.target.value)}
                                    />
                                </div>
                                <div class="form-group" style={{ paddingLeft: "0px" }}>
                                    <label class="small mb-1" for="inputFullName">
                                        Workshop Date<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="date"
                                        value={neweventDate}
                                        onChange={(e) => setNewEventDate(e.target.value)}
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="inputFullName">
                                        Select Instructor<font color="red">*</font>
                                    </label>
                                    <Select
                                        options={instructors}
                                        // options={filteredOptions}
                                        onInputChange={handleInputChange}
                                        value={selectedInstructor} // value prop for controlled component
                                        onChange={handleInstructorChange} // handle change function
                                        getOptionLabel={(option) => (
                                            <div>
                                                <img
                                                    src={option.image}
                                                    style={{ width: "30px", marginRight: "10px" }}
                                                />
                                                {option.label}
                                            </div>
                                        )}
                                        getOptionValue={(option) => option.value}
                                        isSearchable
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Event Type<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2 col-md-4">
                                        <Select
                                            value={neweventType}
                                            onChange={handleTypeChange}
                                            options={type}
                                        ></Select>
                                    </div>
                                </div>



                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Status<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                            value={neweventStatus}
                                            onChange={handleStatusChange}
                                            options={status}
                                        ></Select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingnews === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleUpdate}
                                >
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UpdateEvents
