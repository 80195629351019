import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_messages from "../../Components/Admin_components/add_messages.js";
import Delete_messages from "../../Components/Admin_components/delete_messages.js";
import Message_reply from "../../Components/Admin_components/message_reply.js";
import "../../Api/apiUrls";
import { getAdminMessages } from "../../Api/apiUrls";
function Messages() {
  const [modalMessage_replyIsOpen, setMessage_replyModalIsOpen] = useState("");
  const [modalAdd_messagesIsOpen, setAdd_messagesModalIsOpen] = useState("");
  const [modalDelete_messagesIsOpen, setDelete_messagesIsOpen] =
    useState(false);
  const [questionId, setQuestionId] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const openAdd_messages = () => {
    closeAdd_messages();
    setAdd_messagesModalIsOpen(true);
  };

  const closeAdd_messages = () => {
    setAdd_messagesModalIsOpen(false);
  };
  const openDelete_messages = (userId) => {
    closeDelete_messages();
    setDelete_messagesIsOpen(true);
  };

  const closeDelete_messages = () => {
    setDelete_messagesIsOpen(null);
  };
  const openMessage_reply = (questionId) => {
    setQuestionId(questionId);
    closeMessage_reply();
    setMessage_replyModalIsOpen(true);
  };

  const closeMessage_reply = () => {
    setMessage_replyModalIsOpen(null);
  };
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    fetch(getAdminMessages)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const messages = data.data.Messages;
        console.log(messages);
        setMessages(messages);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Messages value:", Messages);
      });
  }, []);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredMessages = Array.isArray(messages)
    ? messages.filter((messages) => {
        const values = Object.values(messages).map((value) =>
          typeof value === "string" ? value.toLowerCase() : ""
        );
        const isSearchMatch = values.some((value) =>
          value.includes(searchQuery.toLowerCase())
        );
        return isSearchMatch;
      })
    : [];
  return (
    <div>
      <Header></Header>
      {modalAdd_messagesIsOpen && <Add_messages onClose={closeAdd_messages} />}
      {modalMessage_replyIsOpen && (
        <Message_reply onClose={closeMessage_reply} questionId={questionId} />
      )}
      {modalDelete_messagesIsOpen && (
        <Delete_messages onClose={closeDelete_messages} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Messages</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Messages</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a></a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {" "}
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : messages.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr. No.</th>
                      <th scope="col">Message</th>
                      <th scope="col">User Details</th>
                      <th scope="col">Type</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredMessages.map((messages, index) => (
                      <tr key={messages.id}>
                        <th scope="row">{index + 1}</th>

                        <td>
                          {messages.message || "No Name"}
                          <br />
                        </td>
                        {/* <td>
                          <b
                            dangerouslySetInnerHTML={{
                              __html: messages.sender
                                ? `${messages.sender.name || "No Name"}<br />${
                                    messages.sender.email || "No Email"
                                  }`
                                : "No Sender Details",
                            }}
                          />
                        </td> */}
                        <td
                          dangerouslySetInnerHTML={{
                            __html: messages.sender
                              ? `${messages.sender.name || "No Name"}<br />${
                                  messages.sender.email || "No Email"
                                }`
                              : "No Sender Details",
                          }}
                        />
                        <td>
                          {messages.source || "No Name"}
                          <br />
                        </td>
                        <td>{formatCreatedAt(messages.created_at)}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openMessage_reply(messages.id)}
                          >
                            <i class="bi bi-reply"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredMessages.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Messages;
