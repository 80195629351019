import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import "../../Api/apiUrls";
import { getAdminBlogs } from "../../Api/apiUrls";
import Add_blog from "../../Components/Admin_components/add_blog.js";
import Delete_blog from "../../Components/Admin_components/delete_blog.js";
import Update_blog from "../../Components/Admin_components/update_blog.js";
import BlogSave from "../../Components/Admin_components/blogsSave.js";
import BlogsComment from "../../Components/Admin_components/newsComment.js";
import "../../Api/apiUrls.js";
import { API_URL } from "../../Api/apiUrls";

function Blog() {
  const [modalBlogSaveIsOpen, setBlogSaveIsOpen] = useState("");
  const [modalAdd_blogIsOpen, setAdd_blogModalIsOpen] = useState("");
  const [modalDelete_blogIsOpen, setDelete_blogIsOpen] = useState("");
  const [modalUpdate_blogIsOpen, setUpdate_blogModalIsOpen] = useState(false);
  const [selectedblogsName, setSelectedBlogsName] = useState(null);
  const [selectedblogsImage, setSelectedBlogsImage] = useState(null);
  const [selectedblogsDescription, setSelectedBlogsDescription] =
    useState(null);
  const [modalBlogsCommentsIsOpen, setBlogsCommentsIsOpen] = useState("");
  const [selectedblogsSummary, setSelectedBlogsSummary] = useState(null);
  const [selectedblogsAuthor, setSelectedBlogsAuthor] = useState(null);
  const [selectedblogsStatus, setSelectedBlogsStatus] = useState(null);
  const [selectedblogsKeyword, setSelectedBlogsKeyword] = useState(null);
  const [selectedblogsBio, setSelectedBlogsBio] = useState(null);
  const [selectedBlogsId, setSelectedBlogsId] = useState(null);
  const [blogs, setBlogs] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [id, setId] = useState("");

  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredBlogs = Array.isArray(blogs)
    ? blogs.filter((blog) => {
        const isStatus =
          !selectedStatus ||
          selectedStatus === "All" ||
          blog.status === selectedStatus;

        const values = Object.values(blog).map((value) =>
          typeof value === "string" ? value.toLowerCase() : ""
        );
        const isSearchMatch = values.some((value) =>
          value.includes(searchQuery.toLowerCase())
        );
        return isStatus && isSearchMatch;
      })
    : [];
  const openAdd_blog = () => {
    closeAdd_blog();

    setAdd_blogModalIsOpen(true);
  };
  const openUpdate_blog = (
    blogsId,
    blogsName,
    blogsImage,
    blogsDescription,
    blogsKeyword,
    blogsSummary,
    blogsAuthor,
    blogsBio,
    blogsStatus
  ) => {
    setSelectedBlogsId(blogsId);
    setSelectedBlogsName(blogsName);
    setSelectedBlogsImage(blogsImage);
    setSelectedBlogsDescription(blogsDescription);
    setSelectedBlogsKeyword(blogsKeyword);
    setSelectedBlogsSummary(blogsSummary);
    setSelectedBlogsAuthor(blogsAuthor);
    setSelectedBlogsBio(blogsBio);
    setSelectedBlogsStatus(blogsStatus);
    closeUpdate_blog();
    setUpdate_blogModalIsOpen(true);
  };

  const closeUpdate_blog = () => {
    setUpdate_blogModalIsOpen(false);
  };
  const openBlogsComment = (id) => {
    closeBlogsComments();
    setId(id);
    setBlogsCommentsIsOpen(true);
  };

  const closeBlogsComments = () => {
    setBlogsCommentsIsOpen(null);
  };
  const closeAdd_blog = () => {
    setAdd_blogModalIsOpen(false);
  };
  const openDelete_blog = (blogsId) => {
    closeDelete_blog();
    setSelectedBlogsId(blogsId);
    setDelete_blogIsOpen(true);
  };
  const closeDelete_blog = () => {
    setDelete_blogIsOpen(null);
  };
  const openBlogSave = (id) => {
    closeBlogSave();
    setId(id);
    setBlogSaveIsOpen(true);
  };

  const closeBlogSave = () => {
    setBlogSaveIsOpen(null);
  };
  useEffect(() => {
    fetch(getAdminBlogs)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const blogs = data.data.BlogsAndNews;
        setBlogs(blogs);
        console.log(blogs);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  return (
    <div>
      <Header></Header>
      {modalAdd_blogIsOpen && <Add_blog onClose={closeAdd_blog} />}

      {modalUpdate_blogIsOpen && <Update_blog onClose={closeUpdate_blog} />}
      {modalBlogSaveIsOpen && <BlogSave onClose={closeBlogSave} id={id} />}
      {modalBlogsCommentsIsOpen && (
        <BlogsComment onClose={closeBlogsComments} id={id} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Blog</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Blog</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div className="child ml-2">
                  <Select
                    options={status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    {
                      <button
                        id="btnNewUser"
                        type="button"
                        data-toggle="modal"
                        data-target="#modalAdd"
                        class=" btn-round"
                        onClick={openAdd_blog}
                      >
                        <i class="bi bi-person-add"></i> Add Blog
                      </button>
                    }
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : blogs.length > 0 ? (
                <table class="table  table-striped ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Image</th>
                      <th scope="col">Name</th>
                      <th scope="col">Author</th>
                      <th scope="col">Keyword</th>
                      <th scope="col">Comments</th>
                      <th scope="col">Bio</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Status</th>
                      <th scope="col">Share</th>
                      <th scope="col">Save</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredBlogs.map((blogs, index) => (
                      <tr key={blogs.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {blogs.photo && (
                            <img
                              src={`${API_URL}/${blogs.photo}` || "-"}
                              style={{ height: "50px", width: "50px" }}
                            />
                          )}
                        </td>
                        <td>{blogs.title || "-"}</td>
                        <td>{blogs.user || "-"}</td>
                        <td>{blogs.keywords || "-"}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openBlogsComment(blogs.id)}
                          >
                            {blogs.comments}
                          </a>
                        </td>
                        <td>{blogs.bio || "-"}</td>
                        <td>{formatCreatedAt(blogs.created_at)}</td>
                        <td>{blogs.status || "-"}</td>
                        <td>{blogs.share || "-"}</td>

                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openBlogSave(blogs.id)}
                          >
                            {blogs.save_count}
                          </a>
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdate_blog(
                                  blogs.id,
                                  blogs.title,
                                  blogs.photo,
                                  blogs.discription,
                                  blogs.keywords,
                                  blogs.shortSummery,
                                  blogs.user,
                                  blogs.bio,

                                  { value: blogs.id, label: blogs.status }
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openDelete_blog(blogs.id)}
                            >
                              <i class="bi bi-trash3"></i>
                            </a>
                            &nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}

              {modalUpdate_blogIsOpen && (
                <Update_blog
                  blogsId={selectedBlogsId}
                  blogsName={selectedblogsName}
                  blogsImage={selectedblogsImage}
                  blogsDescription={selectedblogsDescription}
                  blogsKeyword={selectedblogsKeyword}
                  blogsSummary={selectedblogsSummary}
                  blogsAuthor={selectedblogsAuthor}
                  blogsBio={selectedblogsBio}
                  blogsStatus={selectedblogsStatus}
                  onClose={closeUpdate_blog}
                />
              )}

              {modalDelete_blogIsOpen && (
                <Delete_blog
                  blogsId={selectedBlogsId}
                  onClose={closeDelete_blog}
                />
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredBlogs.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Blog;
