import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
// import login from "../Static/img/istockphoto.jpg";
import logo from "../../Static/img/ARTIQUITY_LOGO.jpeg";
// import pic from "../Static/img/adminLogo.jpeg";
import pic from "../../Static/img/adminLogo.jpeg";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "../../Api/apiUrls.js";
import { API_URL } from "../../Api/apiUrls";

function LoginEmployee() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const formData = new FormData();
            formData.append("email", username);
            formData.append("password", password);
            formData.append("type_of_user", "Employee");

            const response = await fetch(`${API_URL}/login/login/`, {
                method: "POST",
                body: formData,
            });
            const jsonResponse = await response.json();
            const { success, status, message, data } = jsonResponse;

            console.log(jsonResponse);
            console.log(success);
            console.log(message);
            console.log(data.type_of_user);
            console.log(status === 400);
            console.log(data.id);
            console.log(data.access);

            if (status === 400) {
                return alert("Incorrect Login ID or Password");
            }

            if (success) {
                localStorage.setItem("user_id", data.id);
                localStorage.setItem("token", data.token);
                localStorage.setItem("is_login", true);
                localStorage.setItem("type_of_user", data.type_of_user);
                localStorage.setItem("access", data.access);
                const token = localStorage.getItem("token")
                // Extract the first word from access and navigate to the corresponding page
                const firstAccessPage = data.access.replace(/[\[\]]/g, "").split(",")[0].trim(); 
                console.log(firstAccessPage)
                window.location.href = "http://admin.artiquity.in/admin/" + firstAccessPage + "?token=" + token;
            } else {
                setError("Invalid username or password");
            }
        } catch (error) {
            setError("An error occurred while logging in");
        }
    };



    return (
        <>


            <div class=" row col-md-12">
                <div class="body  ">
                    <div class="wrapper ">

                        <div class="form-wrapper sign-in">
                            <form action="" style={{ padding: '30px' }} onSubmit={handleSubmit}>
                                <img style={{ width: '90%' }} src={logo}></img><br /><br />
                                <h2>Employee Login</h2>
                                <div class="input-group">
                                    <input type="text" required value={username}
                                        onChange={(e) => setUsername(e.target.value)} />
                                    <label for="">Username</label>
                                </div>
                                <div class="input-group">
                                    <input type="password" required value={password}
                                        onChange={(e) => setPassword(e.target.value)} />
                                    <label for="">Password</label>
                                </div>
                                <button type="submit" class="btnlogin">Login</button>
                            </form>
                            <div style={{ display: 'block' }}>

                                <img style={{ width: '320px', height: '400px', boxShadow: '0 0 10px rgba(0, 0, 0, .5)' }} src={pic}></img>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default LoginEmployee;
