import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_packages.css";
import Select from "react-select";
function Add_Instructor_Packages({ onClose }) {
  const [
    modalAdd_Instructor_PackagesIsOpen,
    setAdd_Instructor_PackagesModalIsOpen,
  ] = useState("");
  // const [partnerName, setPartnerName] = useState("");
  // const [email, setEmail] = useState("");
  // const [link, setLink] = useState("");
  // const [file, setIconFile] = useState();
  const [isAddingInstructor_Packages, setIsAddingInstructor_Packages] =
    useState(false);

  const closeAdd_Instructor_Packages = () => {
    setAdd_Instructor_PackagesModalIsOpen(false);
  };
  const duration = [
    { value: "Day", label: "Day" },
    { value: "Week", label: "Week" },
    { value: "Month", label: "Month" },
    { value: "Quater", label: "Quater" },
    { value: "Annual", label: "Annual" },
  ];
  const type = [
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
    { value: "News", label: "News" },
  ];
  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  function reloadPage() {
    window.location.reload();
  }

  return (
    <div>
      {modalAdd_Instructor_PackagesIsOpen && (
        <add_Instructor_Packages onClose={closeAdd_Instructor_Packages} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "75%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Packages</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Duration<font color="red">*</font>
                  </label>
                  <div class="child ml-2">
                    <Select options={duration}></Select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Price<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=" Add Text Editor"
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Type:<font color="red">*</font>
                  </label>
                  <div class="child ml-2">
                    <Select options={type}></Select>
                  </div>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status:<font color="red">*</font>
                  </label>
                  <div class="child ml-2">
                    <Select options={status}></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingInstructor_Packages === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_Instructor_Packages;
