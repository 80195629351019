import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_blogs_news.css";
import "../../Api/apiUrls";
import { addAdminHappy } from "../../Api/apiUrls";

function Add_happy({ onClose }) {
  const [modalAdd_happyIsOpen, setAdd_happyModalIsOpen] = useState("");

  const [isAddingHappy, setIsAddingHappy] = useState(false);
  const [image, setImage] = useState();
  const [selectedHappy, setSelectedHappy] = useState([]);
  const [file, setIconFile] = useState();
  const closeAdd_happy = () => {
    setAdd_happyModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    setImage(file);
    console.log(file);

    const formData = new FormData();
    formData.append("photo", image);
    const reader = new FileReader();
    reader.onload = function (e) {
      const imgElement = document.getElementById("selectedImg");
      imgElement.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  async function addHappy(image) {
    try {
      const formData = new FormData();
      formData.append("photo", image);

      // console.log(categoryHomepage);
      const response = await fetch(addAdminHappy, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true; // Category added successfully
      } else {
        throw new Error("Failed to add happy customer");
      }
    } catch (error) {
      console.error("Error adding happy customer:", error);
      return false; // Category addition failed
    }
  }

  const handleAddHappy = async () => {
    if (!image) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingHappy(true);

    const result = await addHappy(image);

    setIsAddingHappy(false);

    if (result) {
      alert("Happy Customer Added Successfully");
      setSelectedHappy("");
      console.log(image);
      setImage("");
      // window.location.reload();
      // setIconFile(null);
      onClose(); // Close the modal
    } else {
      alert("Error Adding Happy Customer");
    }
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div>
      {modalAdd_happyIsOpen && <Add_happy onClose={closeAdd_happy} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              overflowY: "scroll",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Happy Customer</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="document"
                    accept="image/*"
                    onChange={handleFileChange}
                  />
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingHappy === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddHappy}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_happy;
