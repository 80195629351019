import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_assignedpackages from "../../Components/Admin_components/add_assignedpackages.js";
import Update_assignedPackages from "../../Components/Admin_components/update_assignedPackages.js";
import Delete_assignedpackages from "../../Components/Admin_components/delete_assignedpackages.js";
import "../../Api/apiUrls";
import {
  getAdminAssignedPackages,
  assignedPackagesExcel,
} from "../../Api/apiUrls";
function Assigned_packages() {
  const [modalAdd_assignedpackagesIsOpen, setAdd_assignedpackagesModalIsOpen] =
    useState("");
  const [
    modalUpdate_assignedPackagesIsOpen,
    setUpdate_assignedPackagesModalIsOpen,
  ] = useState(false);
  const [modalDelete_assignedpackagesIsOpen, setDelete_assignedpackagesIsOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [packageManagement, setPackageManagement] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const filter = [
    { value: "All", label: "All" },
    { value: "Featured", label: "Featured" },
    { value: "Sponsored", label: "Sponsored" },
    // { value: "Gift", label: "Gift" },
  ];

  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
    { value: "News", label: "News" },
  ];
  const transaction = [
    { value: "All", label: "All" },
    { value: "Success", label: "Success" },
    { value: "Failed", label: "Failed" },
    { value: "On Hold", label: "On Hold" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Expired", label: "Expired" },
    { value: "Published", label: "Published" },
  ];
  const Package = [
    { value: "All", label: "All" },
    { value: "Year", label: "Annual" },
    { value: "Month", label: "Monthly" },
    { value: "Quarter", label: "Quarterly" },
    { value: "Week", label: "Weekly" },
    { value: "Day", label: "Daily" },
  ];

  const openAdd_assignedpackages = () => {
    closeAdd_assignedpackages();
    setAdd_assignedpackagesModalIsOpen(true);
  };

  const closeAdd_assignedpackages = () => {
    setAdd_assignedpackagesModalIsOpen(false);
  };
  const openUpdate_assignedPackages = () => {
    closeUpdate_assignedPackages();
    setUpdate_assignedPackagesModalIsOpen(true);
  };

  const closeUpdate_assignedPackages = () => {
    setUpdate_assignedPackagesModalIsOpen(false);
  };
  const openDelete_assignedpackages = (userId) => {
    setSelectedUserId(userId);
    closeDelete_assignedpackages();
    setDelete_assignedpackagesIsOpen(true);
  };

  const closeDelete_assignedpackages = () => {
    setDelete_assignedpackagesIsOpen(null);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption);
  };

  const handlePackageChange = (selectedOption) => {
    setSelectedPackage(selectedOption);
  };

  const handleTransactionChange = (selectedOption) => {
    setSelectedTransaction(selectedOption);
  };

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    fetch(getAdminAssignedPackages)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const packageManagement = data.data.PackageManagement;
        console.log(packageManagement);
        setPackageManagement(packageManagement);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Package Management value:", packageManagement);
      });
  }, []);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPackageManagement, setFilteredPackageManagement] = useState(
    []
  );

  useEffect(() => {
    // Filter packageManagement based on selected filters and search query
    const newFilteredPackageManagement = packageManagement.filter((item) => {
      const statusCondition =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        item.status === selectedStatus.value;
      const filterCondition =
        !selectedFilter ||
        selectedFilter.value === "All" ||
        item.filter === selectedFilter.value;
      const typeCondition =
        !selectedType ||
        selectedType.value === "All" ||
        item.package.type === selectedType.value;
      const packageCondition =
        !selectedPackage ||
        selectedPackage.value === "All" ||
        item.package.duration === selectedPackage.value;
      const transactionCondition =
        !selectedTransaction ||
        selectedTransaction.value === "All" ||
        item.transaction?.transaction_status === selectedTransaction.value;

      const searchCondition = Object.values(item).some((value) => {
        if (typeof value === "object" && value !== null) {
          return Object.values(value).some((subValue) => {
            if (typeof subValue === "string") {
              return subValue.toLowerCase().includes(searchQuery.toLowerCase());
            }
            return false;
          });
        } else if (typeof value === "string") {
          return value.toLowerCase().includes(searchQuery.toLowerCase());
        }
        return false;
      });

      return (
        statusCondition &&
        filterCondition &&
        typeCondition &&
        packageCondition &&
        transactionCondition &&
        searchCondition
      );
    });

    // Update the state with the filtered packageManagement
    setFilteredPackageManagement(newFilteredPackageManagement);
  }, [
    selectedStatus,
    selectedFilter,
    selectedType,
    selectedPackage,
    selectedTransaction,
    searchQuery,
    packageManagement,
  ]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);

    // Format the date part
    const datePart = createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    // Format the time part
    const timePart = createdAtDate.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",

      hour12: true,
    });

    return `${datePart} - ${timePart}`;
  };

  function handleDownloadClick() {
    window.open(assignedPackagesExcel, "_blank");
  }
  return (
    <div>
      <Header></Header>
      {modalAdd_assignedpackagesIsOpen && (
        <Add_assignedpackages onClose={closeAdd_assignedpackages} />
      )}
      {modalUpdate_assignedPackagesIsOpen && (
        <Update_assignedPackages onClose={closeUpdate_assignedPackages} />
      )}
      {modalDelete_assignedpackagesIsOpen && (
        <Delete_assignedpackages
          userId={selectedUserId}
          onClose={closeDelete_assignedpackages}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Package Management</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Package Management</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="row">
                <div class="child col-md-2" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "37px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div class=" col-md-2 " style={{ padding: "5px" }}>
                  <Select
                    options={status}
                    placeholder="Select Status"
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  ></Select>
                </div>
                {/* <div class=" col-md-2 " style={{ padding: "5px" }}>
                  <Select
                    options={filter}
                    placeholder="Filter By"
                    value={selectedFilter}
                    onChange={handleFilterChange}
                  ></Select>
                </div> */}
                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={type}
                    placeholder="Select type"
                    value={selectedType}
                    onChange={handleTypeChange}
                  ></Select>
                </div>

                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={Package}
                    placeholder="Select package"
                    value={selectedPackage}
                    onChange={handlePackageChange}
                  ></Select>
                </div>
                <div class="  col-md-2 " style={{ padding: "5px" }}>
                  <Select
                    options={transaction}
                    placeholder="Select transaction"
                    value={selectedTransaction}
                    onChange={handleTransactionChange}
                  ></Select>
                </div>
              </div>
            </div>
            <div
              className="col-md-12"
              style={{ cursor: "pointer", textAlign: "end" }}
              onClick={handleDownloadClick}
            >
              Download
              <i style={{ fontSize: "20px" }} class="bi bi-download"></i>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : packageManagement.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.no</th>
                      <th scope="col">Details</th>
                      <th scope="col">Owner</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Package Name</th>
                      <th scope="col">Order ID</th>
                      <th scope="col">Date of Activation</th>
                      <th scope="col">Date of Expiry</th>
                      <th scope="col">Package Type</th>
                      <th scope="col">Package Amount</th>
                      <th scope="col">Payment Mode</th>
                      <th scope="col">Transaction Status</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredPackageManagement.map((item, index) => (
                      <tr key={packageManagement.id}>
                        <th scope="row">{index + 1}</th>

                        {/* <td>
                          {item?.transaction?.order_id || "---"}
                          <br />
                        </td> */}
                        <td>
                          {item.venue
                            ? item.venue.name
                            : item.workshop
                            ? item.workshop.name
                            : item.news
                            ? item.news.title
                            : "--"}
                        </td>
                        <td>
                          {item.user.name || "---"}
                          <br />

                          {item.user.mobileNumber || "---"}

                          <br />
                          {item.user.email || "---"}
                          <br />
                        </td>

                        <td>
                          {item.transaction?.type || "---"}
                          <br />
                        </td>
                        <td>
                          {item.status || "---"}
                          <br />
                        </td>
                        <td>
                          {item.package.name || "---"}
                          <br />
                        </td>
                        <td>
                          {item?.transaction?.order_id || "---"}
                          <br />
                        </td>
                        <td>
                          {formatCreatedAt(item.dateOfActivation || "---")}
                          <br />
                        </td>
                        <td>
                          {formatCreatedAt(item.dateOfExpiry || "---")}
                          <br />
                        </td>
                        <td>
                          {item.package.duration || "---"}
                          <br />
                        </td>
                        <td>
                          {item.package.price || "---"}
                          <br />
                        </td>
                        <td>
                          {item.paymentMode || "---"}
                          <br />
                        </td>
                        <td>
                          {item.transaction?.transaction_status || "---"}
                          <br />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredPackageManagement.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Assigned_packages;
