import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_appointment.css";
import "../../Api/apiUrls.js";
import { getReviewById, updateReviewStatuss } from "../../Api/apiUrls";

function Review_eye({ onClose, eye_reviewId }) {
  const [modalReview_eyeIsOpen, setReview_eyeModalIsOpen] = useState("");
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const closeReview_eye = () => {
    setReview_eyeModalIsOpen(false);
  };
  const [isLoading, setIsLoading] = useState(true);
  const [review, setReview] = useState("");
  const renderStars = (review) => {
    const maxReview = 5;
    const starIcons = [];

    for (let i = 1; i <= maxReview; i++) {
      const isFilled = i <= review;

      starIcons.push(
        <i
          key={i}
          className={`bi bi-star${isFilled ? "-fill" : ""}`}
          style={{ color: isFilled ? "gold" : "gray" }}
        ></i>
      );
    }

    return starIcons;
  };

  function reloadPage() {
    window.location.reload();
  }
  useEffect(() => {
    fetch(`${getReviewById}${eye_reviewId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const reviewData = data.data.Review;
        console.log(reviewData);
        if (reviewData) {
          setReview(reviewData);
          console.log(review);
        } else {
          console.error("Data structure is not as expected:", data);
        }
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, [eye_reviewId]);

  const updateReviewStatus = async (status) => {
    try {
      const response = await fetch(`${updateReviewStatuss}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: status,
          Review_id: eye_reviewId,
        }),
      });

      if (response.ok) {
        // Successful API request
        console.log(`Review ${status === 1 ? "Approved" : "Rejected"}`);
        onClose();
        reloadPage();
      } else {
        // Handle API error
        console.error("Failed to update review status");
      }
    } catch (error) {
      console.error("Error occurred while updating review status", error);
    }
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  return (
    <div>
      {modalReview_eyeIsOpen && <Review_eye onClose={closeReview_eye} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              // overflowY: "scroll",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Review</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <table class="table">
                <tbody id="tableBody">
                  {/* {review.map((review, eye_reviewId) => ( */}
                  <tr>
                    <p>
                      <strong>Review:</strong> {review.review}
                    </p>
                    <p>
                      <strong>Rating:</strong> {renderStars(review.rating)}
                    </p>
                    <p>
                      <strong>User:</strong> {review.user?.name}
                    </p>
                    <p>
                      <strong>Type:</strong> {review.type}
                    </p>
                    <p>
                      <strong>Created On:</strong>{" "}
                      {formatCreatedAt(review.created_at)}
                    </p>
                    <p>
                      <strong>Status:</strong>{" "}
                      {review.status === 1
                        ? "Approve"
                        : review.status === 0
                        ? "Pending"
                        : review.status === -1
                        ? "Reject"
                        : "-"}
                    </p>
                  </tr>

                  {/* ))} */}
                </tbody>
              </table>
            </div>
            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => updateReviewStatus(-1)}
              >
                Reject
              </button>

              <button
                id="btnAdd"
                type="button"
                class="btn btn-primary "
                style={{ background: "#000 ", border: "none" }}
                onClick={() => updateReviewStatus(1)}
              >
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review_eye;
