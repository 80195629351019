import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_banner.css";
import { TailSpin } from "react-loader-spinner";
import Select from "react-select";
import "../../Api/apiUrls.js";
import Delete_Workshop_Image from "./delete_workshop_image";
import {
  addWorkshopImages,
  getWorkshopImagesByWorkshopId,
  getWorkshopImagesById,
  updateWorkshopImages,
  deleteWorkshopImagesById,
  API_URL,
} from "../../Api/apiUrls";

function Add_mediaa({ onClose, workshopId }) {
  // const [bannerStatus, setStatus] = useState("");
  // const [file, setIconFile] = useState();
  const [media, setMedia] = useState();
  const [sequence, setSequence] = useState();
  const [imageId, setImageId] = useState();
  const [modalAdd_mediaIsOpen, setAdd_MediaModalIsOpen] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAddingMedia, setIsAddingMedia] = useState(false);
  const [isUpdatingmedia, setIsUpdatingmedia] = useState(false);
  const [newSequence, setNewSequence] = useState(false);
  const [newStatus, setNewStatus] = useState(false);
  const [showFields4, setShowFields4] = useState(false);
  const [mediaStatus, setMediaStatus] = useState("");
  const fileInputRef = React.createRef();
  const [modalDelete_workshopImageIsOpen, setDelete_workshopImageIsOpen] =
    useState(false);
  const [selectedWorkshopImageID, setSelectedWorkshopImageID] = useState("");
  const openDelete_workshopImage = (workshop_images_id) => {
    setSelectedWorkshopImageID(workshop_images_id);
    closeDelete_workshopImage();
    setDelete_workshopImageIsOpen(true);
  };

  const closeDelete_workshopImage = () => {
    setDelete_workshopImageIsOpen(null);
  };
  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const closeAdd_media = () => {
    setAdd_MediaModalIsOpen(false);
  };
  const handleCancelClick4 = () => {
    setShowFields4(false);
  };
  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  const Status = [
    { value: "0", label: "Pending" },
    { value: "1", label: "Approve" },
    { value: "-1", label: "Reject" },
  ];

  const [mediaList, setMediaList] = useState([]);

  const refreshMediaList = async () => {
    const response = await fetch(`${getWorkshopImagesByWorkshopId}${workshopId}`);
    const updatedMediaList = await response.json();
    setMediaList(updatedMediaList);
  };

  useEffect(() => {
    window.addEventListener('mediaDeleted', refreshMediaList);

    return () => {
      window.removeEventListener('mediaDeleted', refreshMediaList);
    };
  }, []);
  const handleStatusChange = (selectedOption) => {
    setMediaStatus(selectedOption.value);
  };

  // const handleAdd_media = async (event) => {
  //   try {
  //     event.preventDefault();

  //     if (!file) {
  //       alert("File not selected");
  //       return;
  //     }

  //     setIsAddingMedia(true);

  //     const formData = new FormData();
  //     formData.append("workshop", workshopId);
  //     formData.append("photo", file);

  //     const addResponse = await fetch(`${addWorkshopImages}`, {
  //       method: "POST",
  //       body: formData,
  //     });

  //     if (!addResponse.ok) {
  //       alert("Failed to add Media");
  //       return;
  //     }
  //     const getResponse = await fetch(
  //       `${getWorkshopImagesByWorkshopId}${workshopId}`
  //     );

  //     if (!getResponse.ok) {
  //       alert("Failed to fetch Media");
  //       return;
  //     }

  //     const data = await getResponse.json();
  //     const media = data.data.WorkshopImages;
  //     console.log(media);
  //     setMedia(media);
  //     setImageId(media.id);
  //     setIconFile("");
  //     if (fileInputRef.current) {
  //       fileInputRef.current.value = "";
  //     }
  //   } catch (error) {
  //     console.error("Error handling Media:", error);
  //   } finally {
  //     setIsAddingMedia(false);
  //   }
  // };

  // ----------------------------------------
  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   setIconFile(file);
  //   console.log(file);

  //   const formData = new FormData();
  //   formData.append("image", file);
  //   const reader = new FileReader();
  //   reader.onload = function (e) {
  //     const imgElement = document.getElementById("selectedImg");
  //     imgElement.src = e.target.result;
  //   };
  //   reader.readAsDataURL(file);
  // };

  const [iconFile, setIconFile] = useState([]); 
  
  const handleAdd_media = async (event) => {
    try {
      event.preventDefault();
  
      const files = iconFile;
      if (!files || files.length === 0) {
        alert("No files selected");
        return;
      }
  
      setIsAddingMedia(true);
  
      const formData = new FormData();
      formData.append("workshop", workshopId); // Add additional data if needed
  
      files.forEach((file) => {
        formData.append("photo", file);
      });
  
      const addResponse = await fetch(`${addWorkshopImages}`, {
        method: "POST",
        body: formData,
      });
  
      if (!addResponse.ok) {
        alert("Failed to add Media");
        return;
      }
  
      const getResponse = await fetch(
        `${getWorkshopImagesByWorkshopId}${workshopId}`
      );
  
      if (!getResponse.ok) {
        alert("Failed to fetch Media");
        return;
      }
  
      const data = await getResponse.json();
      const media = data.data.WorkshopImages;
      console.log(media);
      setMedia(media);
      setImageId(media.id);
      setIconFile([]); // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (error) {
      console.error("Error handling Media:", error);
    } finally {
      setIsAddingMedia(false);
    }
  };


  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); 
    setIconFile(files); 
    console.log(files);
  };




  useEffect(() => {
    const fetchMedia = async () => {
      try {
        const response = await fetch(`${getWorkshopImagesByWorkshopId}${workshopId}`);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        const mediaList = data.data.WorkshopImages;
        console.log(mediaList);
        setMedia(mediaList);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMedia();

    const handleMediaUpdated = () => {
      fetchMedia();
    };

    window.addEventListener('mediaUpdated', handleMediaUpdated);

    return () => {
      window.removeEventListener('mediaUpdated', handleMediaUpdated);
    };
  }, [getWorkshopImagesByWorkshopId, workshopId]);
  // --------------------------------------------------get workshop_images_id---------------------------------------//
  const handleClick4 = (imageId) => {
    fetch(`${getWorkshopImagesById}${imageId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const media = data.data.WorkshopImages;
        setNewSequence(media.sequence);
        setNewStatus(media.status);

        setImageId(media.id);
        console.log(media.id);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });

    setShowFields4(true);
  };
  // --------------------------------------------------UPdate Media--------------------------------------------//
  const handleUpdateMedia = async (imageId) => {
    try {
      setIsUpdatingmedia(true);

      const formData = new FormData();
      formData.append("workshop_images_id", imageId);
      formData.append("sequence", newSequence);
      formData.append("status", mediaStatus);

      const updateResponse = await fetch(`${updateWorkshopImages}`, {
        method: "PUT",
        body: formData,
      });

      if (!updateResponse.ok) {
        alert("Failed to update media");
        return;
      }

      // Trigger get API after update
      const getResponse = await fetch(
        `${getWorkshopImagesByWorkshopId}${workshopId}`
      );

      if (!getResponse.ok) {
        alert("Failed to fetch media");
        return;
      }

      const data = await getResponse.json();
      const media = data.data.WorkshopImages;
      console.log(media);
      setMedia(media);
      setImageId(media.id);
    } catch (error) {
      console.error("Error handling media:", error);
    } finally {
      setIsUpdatingmedia(false);
      setShowFields4(false);
    }
  };
  // ------------------------------------------------Delete Media---------------------------------------------------//

  // const handleDeleteVenue = (imageId) => {
  //   fetch(`${deleteWorkshopImagesById}${imageId}`, {
  //     method: "DELETE",
  //     headers: {
  //       accept: "application/json",
  //     },
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.success) {
  //         // Trigger the get API after successful deletion
  //         return fetch(`${getWorkshopImagesByWorkshopId}${workshopId}`);
  //       } else {
  //         // Handle the error if the delete operation was not successful
  //         console.error("Error deleting slot:", data.message);
  //       }
  //     })
  //     .then((getResponse) => {
  //       // Check if the get API response is successful
  //       if (getResponse && getResponse.ok) {
  //         return getResponse.json();
  //       } else {
  //         throw new Error("Failed to fetch slot");
  //       }
  //     })
  //     .then((getData) => {
  //       const media = getData.data.WorkshopImages;
  //       console.log(media);
  //       setMedia(media);
  //     })
  //     .catch((error) => {
  //       console.error("Error handling slot:", error);
  //     });
  // };
  return (
    <div>
      {modalAdd_mediaIsOpen && <Add_mediaa onClose={closeAdd_media} />}
      {modalDelete_workshopImageIsOpen && (
        <Delete_Workshop_Image
          workshop_images_id={selectedWorkshopImageID}
          onClose={closeDelete_workshopImage}
        />
      )}
      <div className="popup-background" onClick={handleClick}>
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Media</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Media Image
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="photo"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleFileChange}
                    multiple
                  />


                </div>

                {isAddingMedia === true ? (
                  <div>
                    <ThreeDots color="#000" height={50} width={50} />
                  </div>
                ) : (
                  <button
                    id="btnAdd"
                    type="button"
                    class="btn btn-primary "
                    style={{ background: "#000 ", border: "none" }}
                    onClick={handleAdd_media}
                  >
                    Add
                  </button>
                )}
              </form>
              <br />
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : media.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Image</th>
                      <th scope="col">Sequence</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {media.map((media, index) => (
                      <tr key={media.id}>
                        <th scope="row">{index + 1}</th>

                        <td>
                          {media.photo ? (
                            <img
                              src={`${API_URL}/${media.photo}`}
                              style={{ width: "100px", height: "100px" }}
                            />
                          ) : (
                            <i
                              className="bi bi-folder"
                              style={{ fontSize: "25px" }}
                            ></i>
                          )}
                        </td>
                        <td>
                          <b>{media.sequence || "No Name"}</b>
                          <br />
                        </td>
                        <td>
                          <b>
                            {media.status === 1
                              ? "Approve"
                              : media.status === 0
                                ? "Pending"
                                : media.status === -1
                                  ? "Reject"
                                  : "No Name"}
                          </b>
                          <br />
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            <a onClick={() => handleClick4(media.id)}>
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;
                            <a
                              onClick={() => openDelete_workshopImage(media.id)}
                            >
                              <i className="bi bi-trash3"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
            {showFields4 && (
              <div>
                <>
                  <div>
                    <div
                      class="col-md-12"
                      style={{ display: "flex", paddingLeft: "0px" }}
                    >
                      <div
                        class="form-group col-md-6"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label class="small mb-1" for="inputFullName">
                          Enter Sequence<font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="text"
                          value={newSequence}
                          onChange={(e) => setNewSequence(e.target.value)}
                        />
                      </div>
                      <div class="form-group">
                        <label class="small mb-1" for="inputFullName">
                          Status<font color="red"></font>
                        </label>
                        <br />
                        <div class="child ml-2">
                          <Select
                            onChange={handleStatusChange}
                            options={Status}
                          ></Select>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", marginBottom: "20px" }}>
                      <button
                        type="button"
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "#000",
                          color: "#fff",
                          border: "none",
                          padding: "5px 20px",
                        }}
                        onClick={() =>
                          //handleUpdateFaq(faq.getCurrentDateTime)
                          handleUpdateMedia(imageId, newSequence, mediaStatus)
                        }
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={handleCancelClick4}
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "lightgrey",
                          color: "#000",
                          border: "none",
                          padding: "5px 20px",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              </div>
            )}
            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_mediaa;
