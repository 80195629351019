import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_Instructor_Appointment from "../../Components/instructor_comp/add_appointment.js";
import Delete_Instructor_Appointment from "../../Components/instructor_comp/delete_appointment.js";
import Update_Instructor_Appointment from "../../Components/instructor_comp/update_appointment.js";
import Instr_appo_slot from "../../Components/instructor_comp/instr_appo_slot.js";
import "../../Api/apiUrls.js";
import { getInstructorAppointmentId } from "../../Api/apiUrls";
function Instructor_Appointment() {
  const [modalInstr_appo_slotsIsOpen, setInstr_appo_slotIsOpen] = useState("");
  const [
    modalAdd_Instructor_AppointmentIsOpen,
    setAdd_Instructor_AppointmentModalIsOpen,
  ] = useState("");
  const [
    modalDelete_Instructor_AppointmentIsOpen,
    setDelete_Instructor_AppointmentIsOpen,
  ] = useState(false);
  const [
    modalUpdate_Instructor_AppointmentIsOpen,
    setUpdate_Instructor_AppointmentModalIsOpen,
  ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const type = [
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Reviewing", label: "Reviewing" },
  ];
  const openAdd_Instructor_Appointment = () => {
    closeAdd_Instructor_Appointment();
    setAdd_Instructor_AppointmentModalIsOpen(true);
  };

  const closeAdd_Instructor_Appointment = () => {
    setAdd_Instructor_AppointmentModalIsOpen(false);
  };
  const openUpdate_Instructor_Appointment = () => {
    closeUpdate_Instructor_Appointment();
    setUpdate_Instructor_AppointmentModalIsOpen(true);
  };

  const closeUpdate_Instructor_Appointment = () => {
    setUpdate_Instructor_AppointmentModalIsOpen(false);
  };
  const openDelete_Instructor_Appointment = (userId) => {
    setSelectedUserId(userId);
    closeDelete_Instructor_Appointment();
    setDelete_Instructor_AppointmentIsOpen(true);
  };

  const closeDelete_Instructor_Appointment = () => {
    setDelete_Instructor_AppointmentIsOpen(null);
  };
  const openInstr_appo_slot = () => {
    setInstr_appo_slotIsOpen(true);
  };
  const closeInstr_appo_slot = () => {
    setInstr_appo_slotIsOpen(false);
  };
  const instructorId = localStorage.getItem("user_id");
  const [appointment, setAppointment] = useState([]);

  // useEffect(() => {
  //   const hasReloaded = localStorage.getItem('hasReloaded');
  //   if (!hasReloaded) {
  //     // alert("hiiiiiiiiiii")
  //     localStorage.setItem('hasReloaded', 'true');
  //     window.location.reload();
  //   }
  // }, []);
  
  useEffect(() => {
    
    fetch(getInstructorAppointmentId + instructorId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const appointments = data.data.Appointment;
        console.log(appointments);
        setAppointment(appointments);
        setIsLoading(false);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        console.log("Appointment value:", appointment);
      });
  }, []);

  useEffect(() => {
    console.log(appointment.length);
  }, [appointment]);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const formatTimeCreatedAt = (createdAt) => {
    const createdAtTime = new Date(createdAt);
    return createdAtTime.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
    });
  };
  const convertTimeTo12HourFormat = (time24hr) => {
    const [start, end] = time24hr.split(" - ");

    const convertTo12HourFormat = (time) => {
      const [hour, minutes] = time.split(":");
      const isPM = parseInt(hour, 10) >= 12;

      const convertedHour = isPM ? parseInt(hour, 10) % 12 : parseInt(hour, 10);
      const period = isPM ? "PM" : "AM";

      return `${convertedHour}:${minutes} ${period}`;
    };

    const convertedStart = convertTo12HourFormat(start);
    const convertedEnd = convertTo12HourFormat(end);

    return `${convertedStart} - ${convertedEnd}`;
  };
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  useEffect(() => {
    const filteredAppointments = appointment.filter((appointment) => {
      const isStatusMatch =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        appointment.status === selectedStatus.value;

      const values = Object.values(appointment).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );

      return isStatusMatch && isSearchMatch;
    });

    // Rest of your code...
    setFilteredAppointments(filteredAppointments);
    console.log(filteredAppointments);
  }, [selectedStatus, appointment, searchQuery]);
  return (
    <div>
      <Header></Header>
      {modalAdd_Instructor_AppointmentIsOpen && (
        <Add_Instructor_Appointment onClose={closeAdd_Instructor_Appointment} />
      )}

      {modalDelete_Instructor_AppointmentIsOpen && (
        <Delete_Instructor_Appointment
          userId={selectedUserId}
          onClose={closeDelete_Instructor_Appointment}
        />
      )}
      {modalUpdate_Instructor_AppointmentIsOpen && (
        <Update_Instructor_Appointment
          onClose={closeUpdate_Instructor_Appointment}
        />
      )}
      {modalInstr_appo_slotsIsOpen && (
        <Instr_appo_slot onClose={closeInstr_appo_slot} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Appointment</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Appointment</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="col-12 col-md-12 col-lg-12 row">
              {/* <div class="col-md-6"> */}
                <div class="child col-md-3" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width:"100%"
                    }}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    placeholder="Search..."
                  />
                </div>
                {/* <div class="child ml-2">
                  <Select options={type} placeholder="Select Type"></Select>
                </div> */}
                <div className="child col-md-3" style={{ padding: "5px" }}>
                  <Select
                    options={status}
                    value={selectedStatus}
                    onChange={(selectedOption) =>
                      setSelectedStatus(selectedOption)
                    }
                    placeholder="Select Status"
                  />
                </div>
              {/* </div> */}
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {/* <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_appointment}
                    >
                      <i class="bi bi-person-add"></i> Add Appointment
                    </button> */}
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : appointment.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Title</th>
                      <th scope="col">Slot With Appointment Date</th>
                      <th scope="col">Gift</th>
                      {/* <th scope="col">Appointment Date </th> */}
                      <th scope="col">Customer Details</th>

                      <th scope="col">Payment Amount</th>

                      <th scope="col">Booking Date Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Review/Cancellation Reason</th>
                      {/* <th scope="col">Action</th> */}
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredAppointments.map((appointmentItem, index) => (
                      <tr key={appointmentItem.id}>
                        <th scope="row">{index + 1}</th>

                        <td>
                          {appointmentItem?.workshopSlot?.workshop?.name ||
                            "--"}

                          <br />
                        </td>
                        <td>
                          {convertTimeTo12HourFormat(
                            appointmentItem.slot || "--"
                          )}
                          <br />
                          {/* <b>{appointment.dateTime || "No Name"}</b> */}
                          {formatCreatedAt(appointmentItem?.workshopSlot?.date)}
                          <br />
                        </td>

                        <td>
                          {appointmentItem.gift?.name || "--"}
                          <br />
                          {appointmentItem.gift?.mobileNumber || "--"}
                          <br />
                          {appointmentItem.gift?.email || "--"}
                          <br />
                        </td>
                        {/* <td>
                          <b>{formatCreatedAt(appointment.dateTime)}</b>
                          <br />
                        </td> */}
                        <td>
                          {appointmentItem.user?.name || "No Name"}
                          <br />
                          {appointmentItem.user?.mobileNumber || "No Name"}
                          <br />
                          {appointmentItem.user?.email || "No Name"}
                          <br />
                        </td>
                        <td>
                          {appointmentItem.price || "--"}
                          <br />
                        </td>
                        {/* <td>
                          <b>{appointment.paymentMode || "--"}</b>
                          <br />
                        </td> */}
                        {/* <td>
                          <b>
                            {appointment.transaction.transaction_status || "--"}
                          </b>
                          <br />
                        </td> */}
                        <td>
                          {formatCreatedAt(appointmentItem.created_at || "--")}

                          <br />

                          {formatTimeCreatedAt(
                            appointmentItem.created_at || "--"
                          )}

                          <br />
                        </td>
                        <td>
                          {appointmentItem.status || "--"}
                          <br />
                        </td>
                        <td>
                          {appointmentItem.reasonOfCancellation || "--"}

                          <br />
                        </td>
                        {/* <td>
                          <button
                            id="btnNewUser"
                            type="button"
                            data-toggle="modal"
                            data-target="#modalAdd"
                            class=" btn-round"
                          >
                            <i></i> Cancel
                          </button>
                        </td> */}

                        {/* <td>
                          <div className="social-links mt-2">
                            <a style={{ cursor: "pointer" }}>
                              <i class="bi bi-reply"></i>
                            </a>
                            &nbsp;&nbsp;
                          </div>
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredAppointments.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Instructor_Appointment;
