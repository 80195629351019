import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";

function Users() {
  return (
    <div>
      <Header></Header>
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Users</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Users</li>
            </ol>
          </nav>
        </div>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Users;
