import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import Header from "./Header.js";
import Footer from "./Footer.js";
import axios from "axios";
import Select from "react-select";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
import { CategoryScale } from "chart.js";

import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import "./dasboard.css";
import "../../Api/apiUrls.js";
import {
  getDashboardNewListingsCounts,
  getDashboardNewsPackageCounts,
  getDashboardActivePackageCounts,
  getDashboardActiveAppointmentCounts,
  getDashboardNewReviewCounts,
  getDashboardNewMessageCounts,
  getDashboardNewPendingPaymentsCounts,
  getDashboardCompletedPaymentsCounts,
  getDashboardTotalNewListingsCounts,
  getDashboardTotalPackageCounts,
  getDashboardTotalAppointmentCounts,
  getDashboardTotalPackageEarning,
  getDashboardTotalEarning,
  getDashboardTotalReviewCounts,
  getDashboardTotalVisitCounts,
  getDashboardTotalShareCounts,
  getDashboardTotalWishlistCounts,
  getDashboardTotalSavedCounts,
  getDashboardTotalUserCounts,
  refreshWebsite
} from "../../Api/apiUrls";
import { Link } from "react-router-dom";

Chart.register(CategoryScale);

function Dashboard() {
  const [counts, setCounts] = useState({
    workshop: 0,
    venue: 0,
    news: 0,
    blogs: 0,
  });

  const [countsPackage, setCountsPackage] = useState({
    Workshop: 0,
    Venue: 0,
    News: 0,
  });

  const [countsAppointment, setCountsAppointment] = useState({
    workshop: 0,
    venue: 0,
  });

  const [countsReview, setCountsReview] = useState({
    workshop: 0,
    venue: 0,
    blog: 0,
  });
  const [countsNews, setCountsNews] = useState({
    news: 0,
  });
  const [countsMessages, setCountsMessages] = useState({
    workshop: 0,
    venue: 0,
    news: 0,
  });

  const [countsNewPayments, setCountsNewPayments] = useState({
    instructor_payments: 0,
    venue_owner_payments: 0,
  });

  const [countsCompletedPayments, setCountsCompletedPayments] = useState({
    instructor_payments: 0,
    venue_owner_payments: 0,
  });
  const [listingData, setListingData] = useState([]);
  const [packageData, setPackageData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [packageRevenueData, setPackageRevenueData] = useState([]);
  const [totalRevenueData, setTotalRevenueData] = useState([]);
  const [reviewData, setReviewData] = useState([]);
  const [viewsData, setViewsData] = useState([]);
  const [shareData, setShareData] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [savedData, setSavedData] = useState([]);
  const [usersData, setUsersData] = useState([]);

  const [filter, setFilter] = useState("monthly");
  const [filterView, setFilterView] = useState("monthly");
  const [filterListing, setFilterListing] = useState("monthly");
  const [filterPackage, setFilterPackage] = useState("monthly");
  const [filterAppointment, setFilterAppointment] = useState("monthly");
  const [filterPackageRevenue, setFilterPackageRevenue] = useState("monthly");
  const [filterTotalRevenue, setFilterTotalRevenue] = useState("monthly");
  const [filterShare, setFilterShare] = useState("monthly");
  const [filterWishlist, setFilterWishlist] = useState("monthly");
  const [filterSaved, setFilterSaved] = useState("monthly");
  const [filterUsers, setFilterUsers] = useState("monthly");
  const [dataReview, setDataReview] = useState({});
  const [dataView, setDataView] = useState({});
  const [dataListing, setDataListing] = useState({});
  const [dataPackage, setDataPackage] = useState({});
  const [dataAppointment, setDataAppointment] = useState({});
  const [dataPackageRevenue, setDataPackageRevenue] = useState({});
  const [dataTotalRevenue, setDataTotalRevenue] = useState({});
  const [dataShare, setDataShare] = useState({});
  const [dataWishlist, setDataWishlist] = useState({});
  const [dataSaved, setDataSaved] = useState({});
  const [dataUsers, setDataUsers] = useState({});

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    fetchData();
    fetchDataPackage();
    fetchDataAppointment();
    fetchDataReview();
    fetchDataMessages();
    fetchDataNewPayments();
    fetchDataCompletedPayments();
    fetchNewsData();
  }, []);

  useEffect(() => {
    fetchDataReviewGraph();
    // // fetchDataViewsGraph();
    // fetchDataListingGraph();
    // fetchDataPackageGraph();
    // fetchDataAppointmentGraph();
    // fetchDataPackageRevenueGraph();
    // fetchDataTotalRevenueGraph();
    // fetchDataSharesGraph();
    // fetchDataWishlistGraph();
    // fetchDataSavedGraph();
    // fetchDataUsersGraph();
  }, [filter]);

  useEffect(() => {
    fetchDataViewsGraph();
  }, [filterView]);

  useEffect(() => {
    fetchDataListingGraph();
  }, [filterListing]);


  useEffect(() => {
    fetchDataPackageGraph();
  }, [filterPackage]);

  useEffect(() => {
    fetchDataAppointmentGraph();
  }, [filterAppointment]);


  useEffect(() => {
    fetchDataPackageRevenueGraph();
  }, [filterPackageRevenue]);


  useEffect(() => {
    fetchDataTotalRevenueGraph();
  }, [filterTotalRevenue]);


  useEffect(() => {
    fetchDataSharesGraph();
  }, [filterShare]);

  useEffect(() => {
    fetchDataWishlistGraph();
  }, [filterWishlist]);

  

  useEffect(() => {
    fetchDataSavedGraph();
  }, [filterSaved]);



  useEffect(() => {
    fetchDataUsersGraph();
  }, [filterUsers]);





  const fetchData = async () => {
    try {
      const response = await axios.get(`${getDashboardNewListingsCounts}`);
      if (response.data && response.data.success) {
        setCounts(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchNewsData = async () => {
    try {
      const response = await axios.get(`${getDashboardNewsPackageCounts}`);
      if (response.data && response.data.success) {
        setCountsNews(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const fetchDataPackage = async () => {
    try {
      const response = await axios.get(`${getDashboardActivePackageCounts}`);
      if (response.data && response.data.success) {
        setCountsPackage(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataAppointment = async () => {
    try {
      const response = await axios.get(
        `${getDashboardActiveAppointmentCounts}`
      );
      if (response.data && response.data.success) {
        setCountsAppointment(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataReview = async () => {
    try {
      const response = await axios.get(`${getDashboardNewReviewCounts}`);
      if (response.data && response.data.success) {
        setCountsReview(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataMessages = async () => {
    try {
      const response = await axios.get(`${getDashboardNewMessageCounts}`);
      if (response.data && response.data.success) {
        setCountsMessages(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataNewPayments = async () => {
    try {
      const response = await axios.get(
        `${getDashboardNewPendingPaymentsCounts}`
      );
      if (response.data && response.data.success) {
        setCountsNewPayments(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataCompletedPayments = async () => {
    try {
      const response = await axios.get(
        `${getDashboardCompletedPaymentsCounts}`
      );
      if (response.data && response.data.success) {
        setCountsCompletedPayments(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchDataListingGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalNewListingsCounts}${filterListing.toLowerCase()}`);
      if (response.data && response.data.success) {
        setListingData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartData = () => {
    const years = Object.keys(listingData);
    const workshops = [];
    const venues = [];
    const news = [];
    for (const year of years) {
      const counts = listingData[year];
      workshops.push(counts.workshop);
      venues.push(counts.venue);
      news.push(counts.news);
    }
    return {
      labels: years,
      datasets: [
        {
          label: "Workshop",
          data: workshops,
          borderColor: "#ef8157",
          // backgroundColor: "#ef8157",
          tension: 1,
          cubicInterpolationMode: "monotone",
          pointRadius: 0,
          pointHoverRadius: 5, // Set the size of the data point on hover
          pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
        },
        {
          label: "Venue",
          data: venues,
          borderColor: "#51cbce",
          tension: 1,
          pointRadius: 0,
        },
        {
          label: "News",
          data: news,
          borderColor: "#6bd098",
          tension: 1,
          pointRadius: 0,
        },
      ],
    };
  };
  const data2 = generateChartData();
  useEffect(() => {
    setDataListing(data2);
  }, [listingData, filterListing]);

  const handleFilterChangeListing = (event) => {
    setFilterListing(event.target.value);
  };



  const fetchDataPackageGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalPackageCounts}${filterPackage.toLowerCase()}`);
      if (response.data && response.data.success) {
        setPackageData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataPackage = () => {
    const years = Object.keys(packageData);
    const workshops = [];
    const venues = [];
    const news = [];
    for (const year of years) {
      const counts = packageData[year];
      workshops.push(counts.workshop);
      venues.push(counts.venue);
      news.push(counts.news);
    }
    return {
      labels: years,
      datasets: [
        {
          label: "Workshop",
          data: workshops,
          borderColor: "#ef8157",
          // backgroundColor: "#ef8157",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          // pointRadius: 0,
          pointHoverRadius: 5, // Set the size of the data point on hover
          pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
        },
        {
          label: "Venue",
          data: venues,
          borderColor: "#51cbce",
          tension: 1,
          pointRadius: 0,
        },
        {
          label: "News",
          data: news,
          borderColor: "#6bd098",
          tension: 1,
          pointRadius: 0,
        },
      ],
    };
  };

  const dataPackage2 = generateChartDataPackage();

  useEffect(() => {
    setDataPackage(dataPackage2);
  }, [packageData, filterPackage]);

  const handleFilterChangePackage = (event) => {
    setFilterPackage(event.target.value);
  };

  const fetchDataAppointmentGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalAppointmentCounts}${filterAppointment.toLowerCase()}`);
      if (response.data && response.data.success) {
        setAppointmentData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataAppointment = () => {
    const years = Object.keys(appointmentData);
    const workshops = [];
    const venues = [];
    for (const year of years) {
      const counts = appointmentData[year];
      workshops.push(counts.workshop);
      venues.push(counts.venue);
    }
    return {
      labels: years,
      datasets: [
        {
          label: "Workshop",
          data: workshops,
          borderColor: "#ef8157",
          // backgroundColor: "#ef8157",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          // pointRadius: 0,
          pointHoverRadius: 5, // Set the size of the data point on hover
          pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
        },
        {
          label: "Venue",
          data: venues,
          borderColor: "#51cbce",
          tension: 1,
          pointRadius: 0,
        },
      ],
    };
  };

  const dataAppointment2 = generateChartDataAppointment();

  useEffect(() => {
    setDataAppointment(dataAppointment2);
  }, [appointmentData, filterAppointment]);

  const handleFilterChangeAppointment = (event) => {
    setFilterAppointment(event.target.value);
  };

  const fetchDataPackageRevenueGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalPackageEarning}${filterPackageRevenue.toLowerCase()}`);
      if (response.data && response.data.success) {
        setPackageRevenueData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataPackageRevenue = () => {
    const years = Object.keys(packageRevenueData);
    const workshops = [];
    const venues = [];
    const news = [];
    for (const year of years) {
      const counts = packageRevenueData[year];
      workshops.push(counts.workshop);
      venues.push(counts.venue);
      news.push(counts.news);
    }
    return {
      labels: years,
      datasets: [
        {
          label: "Workshop",
          data: workshops,
          borderColor: "#ef8157",
          // backgroundColor: "#ef8157",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          // pointRadius: 0,
          pointHoverRadius: 5, // Set the size of the data point on hover
          pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
        },
        {
          label: "Venue",
          data: venues,
          borderColor: "#51cbce",
          tension: 1,
          pointRadius: 0,
        },
        {
          label: "News",
          data: news,
          borderColor: "#6bd098",
          tension: 1,
          pointRadius: 0,
        },
      ],
    };
  };

  const dataPackagerevenue2 = generateChartDataPackageRevenue();

  useEffect(() => {
    setDataPackageRevenue(dataPackagerevenue2);
  }, [packageRevenueData, filterPackageRevenue]);

  const handleFilterChangePackageRevenue = (event) => {
    setFilterPackageRevenue(event.target.value);
  };

  const fetchDataTotalRevenueGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalEarning}${filterTotalRevenue.toLowerCase()}`);
      if (response.data && response.data.success) {
        setTotalRevenueData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataTotalRevenue = () => {
    const years = Object.keys(totalRevenueData);
    const workshops = [];
    const venues = [];
    const news = [];
    for (const year of years) {
      const counts = totalRevenueData[year];
      workshops.push(counts.workshop.total);
      venues.push(counts.venue.total);
      news.push(counts.news.package);
    }
    return {
      labels: years,
      datasets: [
        {
          label: "Workshop",
          data: workshops,
          borderColor: "#ef8157",
          // backgroundColor: "#ef8157",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          // pointRadius: 0,
          pointHoverRadius: 5, // Set the size of the data point on hover
          pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
        },
        {
          label: "Venue",
          data: venues,
          borderColor: "#51cbce",
          tension: 1,
          pointRadius: 0,
        },
        {
          label: "News",
          data: news,
          borderColor: "#6bd098",
          tension: 1,
          pointRadius: 0,
        },
      ],
    };
  };

  const dataTotalrevenue2 = generateChartDataTotalRevenue();

  useEffect(() => {
    setDataTotalRevenue(dataTotalrevenue2);
  }, [totalRevenueData, filterTotalRevenue]);

  const handleFilterChangeTotalRevenue = (event) => {
    setFilterTotalRevenue(event.target.value);
  };

  const fetchDataReviewGraph = async () => {
    try {
      const response = await axios.get(
        `${getDashboardTotalReviewCounts}${filter.toLowerCase()}`
      );
      if (response.data && response.data.success) {
        setReviewData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataReview = () => {
    const years = Object.keys(reviewData);
    const reviews = years.map((year) => reviewData[year].reviews);
    return {
      labels: years,
      datasets: [
        {
          label: "Reviews",
          data: reviews,
          borderColor: "#ef8157",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(255, 99, 132, 1)",
        },
      ],
    };
  };
  const dataReview2 = generateChartDataReview();
  useEffect(() => {
    // const dataReview2 = generateChartDataReview();
    setDataReview(dataReview2);
  }, [reviewData, filter]);


  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };



  const fetchDataViewsGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalVisitCounts}${filterView.toLowerCase()}`);
      if (response.data && response.data.success) {
        setViewsData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataViews = () => {
    const years = Object.keys(viewsData);
    const views = years.map((year) => viewsData[year].views);
    return {
      labels: years,
      datasets: [
        {
          label: "Views",
          data: views,
          borderColor: "#51cbce",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#51cbce",
        },
      ],
    };
  };
  const dataViews2 = generateChartDataViews();
  useEffect(() => {
    // const dataReview2 = generateChartDataReview();
    setDataView(dataViews2);
  }, [viewsData, filter]);

  const handleFilterChangeViews = (event) => {
    setFilterView(event.target.value);
  };


  const fetchDataSharesGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalShareCounts}${filterShare.toLowerCase()}`);
      if (response.data && response.data.success) {
        setShareData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataShare = () => {
    const years = Object.keys(shareData);
    const shares = years.map((year) => shareData[year].total);
    return {
      labels: years,
      datasets: [
        {
          label: "Shares",
          data: shares,
          borderColor: "#fbc658",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#fbc658",
        },
      ],
    };
  };
  const dataShares2 = generateChartDataShare();

  useEffect(() => {
    setDataShare(dataShares2);
  }, [shareData, filterShare]);

  const handleFilterChangeShare = (event) => {
    setFilterShare(event.target.value);
  };

  const fetchDataWishlistGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalWishlistCounts}${filterWishlist.toLowerCase()}`);
      if (response.data && response.data.success) {
        setWishlistData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataWishlist = () => {
    const years = Object.keys(wishlistData);
    // const wishlist = years.map((year) => wishlistData[year].total);
    const workshop = [];
    const venues = [];
    for (const year of years) {
      const counts = wishlistData[year];
      workshop.push(counts.workshop);
      venues.push(counts.venue);
    }
    return {
      labels: years,
      datasets: [
        {
          label: "workshop",
          data: workshop,
          borderColor: "rgb(239, 129, 87)",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(239, 129, 87)",
        },
        {
          label: "venue",
          data: venues,
          borderColor: "#51cbce",
          tension: 1,
          pointRadius: 0,
        },
      ],
    };
  };
  const datawishlistt2 = generateChartDataWishlist();


  useEffect(() => {
    setDataWishlist(datawishlistt2);
  }, [wishlistData, filterWishlist]);

  const handleFilterChangeWishlist = (event) => {
    setFilterWishlist(event.target.value);
  };

  const fetchDataSavedGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalSavedCounts}${filterSaved.toLowerCase()}`);
      if (response.data && response.data.success) {
        setSavedData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataSaved = () => {
    const years = Object.keys(savedData);
    // const saved = years.map((year) => savedData[year].total);
    const blogs = [];
    const news = [];
    for (const year of years) {
      const counts = savedData[year];
      blogs.push(counts.blog);
      news.push(counts.news);
    }
    return {
      labels: years,
      datasets: [
        {
          label: "Blogs",
          data: blogs,
          borderColor: "rgb(251, 198, 88)",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(251, 198, 88)",
        },
        {
          label: "News",
          data: news,
          borderColor: "#6bd098",
          tension: 1,
          cubicInterpolationMode: "monotone",
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#6bd098",
        },
      ],
    };
  };
  const dataSaved2 = generateChartDataSaved();

  useEffect(() => {
    setDataSaved(dataSaved2);
  }, [savedData, filterSaved]);

  const handleFilterChangeSaved = (event) => {
    setFilterSaved(event.target.value);
  };

  const fetchDataUsersGraph = async () => {
    try {
      const response = await axios.get(`${getDashboardTotalUserCounts}${filterUsers.toLowerCase()}`);
      if (response.data && response.data.success) {
        setUsersData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const generateChartDataUsers = () => {
    const years = Object.keys(usersData);
    // const users = years.map((year) => usersData[year].users);
    const customer = [];
    const instructor = [];
    const venueOwner = [];
    for (const year of years) {
      const counts = usersData[year];
      customer.push(counts.customer);
      instructor.push(counts.instructor);
      venueOwner.push(counts.venue_owner);
    }
    return {
      labels: years,
      datasets: [
        {
          label: "Customer",
          data: customer,
          borderColor: "#6bd098",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "#6bd098",
        },
        {
          label: "Instructor",
          data: instructor,
          borderColor: "rgb(239, 129, 87)",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(239, 129, 87)",
        },
        {
          label: "VenueOwner",
          data: venueOwner,
          borderColor: "rgb(81, 203, 206)",
          tension: 0.3,
          cubicInterpolationMode: "monotone",
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgb(81, 203, 206)",
        },
      ],
    };
  };
  const dataUsers2 = generateChartDataUsers();

  useEffect(() => {
    setDataUsers(dataUsers2);
  }, [usersData, filterUsers]);

  const handleFilterChangeUsers = (event) => {
    setFilterUsers(event.target.value);
  };

  const token = localStorage.getItem("token");
  console.log(token);



    const [isAdding, setIsAdding] = useState(false);

    const handleRefresh = async () => {
      setIsAdding(true);
      try {
        const response = await fetch(refreshWebsite);
        const data = await response.json();
    
        if ((data.success && data.status === 200) || (!data.success && data.status === 400)) {
          alert(data.message);
        } else {
          alert('Failed to refresh the website.');
        }
      } catch (error) {
        alert('An error occurred while refreshing the website.');
      } finally {
        setIsAdding(false);
      }
    }
    

    return (
      <div>
        <Header></Header>
        <main id="main" class="main">
          <div class="pagetitle col-md-12 row">
            <h1 class="col-md-3">Dashboard</h1>
            <div class="col-md-6"></div>
            <div className="col-md-3">
              {isAdding ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnNewUser"
                  type="button"
                  data-toggle="modal"
                  data-target="#modalAdd"
                  className="btn-round"
                  style={{ width: '100%' }}
                  onClick={handleRefresh}
                >
                  <i className="bi bi-arrow-clockwise"></i> Refresh Website
                </button>
              )}
            </div>

          </div><br></br>
          <div class="row">
            <div class="col-md-6 col-lg-6 col-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-2" style={{ textAlign: "center" }}>
                      <i
                        class="bi bi-list-ul"
                        style={{ fontSize: "50px", color: "#fbc658" }}
                      ></i>
                    </div>
                    <div class="row col-md-10">
                      <div
                        class="col-md-4 col-lg-4"
                        style={{ textAlign: "center" }}
                      >
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Workshop
                        </span>
                        <br />
                        <Link
                          to={{
                            pathname: "/admin/workshop",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {counts.workshop}
                          </span>
                        </Link>
                      </div>
                      <div
                        class="col-md-4 col-lg-4"
                        style={{ textAlign: "center" }}
                      >
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Venue
                        </span>
                        <br />
                        {/* <a href="/admin/venue"> */}
                        <Link
                          to={{
                            pathname: "/admin/venue",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>{counts.venue}</span>
                        </Link>

                        {/* </a> */}
                      </div>
                      <div
                        class="col-md-4 col-lg-4"
                        style={{ textAlign: "center" }}
                      >
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          News
                        </span>
                        <br />
                        <Link
                          to={{
                            pathname: "/admin/news",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>{counts.news}</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr class="hr"></hr>
                  <i
                    class="bi bi-list-ul"
                    style={{ marginRight: "9px", fontSize: "14px" }}
                  ></i>
                  <span style={{ fontSize: "14px", color: "#9a9a9a" }}>
                    New Listing
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-6 col-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-2" style={{ textAlign: "center" }}>
                      <i
                        class="bi bi-cash-stack"
                        style={{ fontSize: "50px", color: "#6bd098" }}
                      ></i>
                    </div>
                    <div class="row col-md-10">
                      <div class="col-md-4" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Workshop
                        </span>

                        <br />

                        <Link
                          to={{
                            pathname: "/admin/packageManagement",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsPackage.Workshop}
                          </span>
                        </Link>
                      </div>
                      <div class="col-md-4" style={{ textAlign: "center" }}>
                        {/* <a href="/admin/packageManagement"> */}
                        <Link
                          to={{
                            pathname: "/admin/packageManagement",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                            Venue
                          </span>
                          <br />
                          <span style={{ fontSize: "28px" }}>
                            {countsPackage.Venue}
                          </span>
                        </Link>
                      </div>
                      <div class="col-md-4" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          News
                        </span>
                        <br />
                        {/* <a href="/admin/news"> */}
                        <Link
                          to={{
                            pathname: "/admin/news",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsPackage.News}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr class="hr"></hr>
                  <i
                    class="bi bi-cash-stack"
                    style={{ marginRight: "9px", fontSize: "14px" }}
                  ></i>
                  <span style={{ fontSize: "14px", color: "#9a9a9a" }}>
                    Active Package
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-2" style={{ textAlign: "center" }}>
                      <i
                        class="bi bi-stars"
                        style={{ fontSize: "50px", color: "#ef8157" }}
                      ></i>
                    </div>
                    <div class="row col-md-10">
                      <div class="col-md-4" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Workshop
                        </span>
                        <br />
                        {/* <a href="/admin/review"> */}
                        <Link
                          to={{
                            pathname: "/admin/review",
                            search: `?token=${token}`,
                          }}
                        >
                          {" "}
                          <span style={{ fontSize: "28px" }}>
                            {countsReview.workshop}
                          </span>{" "}
                        </Link>
                      </div>
                      <div class="col-md-4" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Venue
                        </span>
                        <br />
                        {/* <a href="/admin/review"> */}
                        <Link
                          to={{
                            pathname: "/admin/review",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsReview.venue}
                          </span>
                        </Link>
                      </div>
                      <div class="col-md-4" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Blogs
                        </span>
                        <br />
                        {/* <a href="/admin/review"> */}
                        <Link
                          to={{
                            pathname: "/admin/review",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsReview.blog}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr class="hr"></hr>
                  <i
                    class="bi bi-stars"
                    style={{ marginRight: "9px", fontSize: "14px" }}
                  ></i>
                  <span style={{ fontSize: "14px", color: "#9a9a9a" }}>
                    New Reviews
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-2" style={{ textAlign: "center" }}>
                      <i
                        class="bi bi-chat-dots"
                        style={{ fontSize: "50px", color: "#51cbce" }}
                      ></i>
                    </div>
                    <div class="row col-md-10">
                      <div class="col-md-4" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Workshop
                        </span>
                        <br />

                        <Link
                          to={{
                            pathname: "/admin/news",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsMessages.workshop}
                          </span>
                        </Link>
                      </div>
                      <div class="col-md-4" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Venue
                        </span>
                        <br />
                        {/* <a href="/admin/news"> */}
                        <Link
                          to={{
                            pathname: "/admin/news",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsMessages.venue}
                          </span>
                        </Link>
                      </div>
                      <div class="col-md-4" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          News
                        </span>
                        <br />
                        {/* <a href="/admin/news"> */}
                        <Link
                          to={{
                            pathname: "/admin/news",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsMessages.news}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr class="hr"></hr>
                  <i
                    class="bi bi-chat-dots"
                    style={{ marginRight: "9px", fontSize: "14px" }}
                  ></i>
                  <span style={{ fontSize: "14px", color: "#9a9a9a" }}>
                    New Messages
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* New News */}
          <div class="row">
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-2" style={{ textAlign: "center" }}>
                      <i
                        class="bi bi-newspaper"
                        style={{ fontSize: "50px", color: "#fbc658" }}
                      ></i>
                    </div>
                    <div class="row col-md-10">
                      <div class="" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          News Count
                        </span>
                        <br />
                        {/* <a href="/admin/news"> */}
                        <Link
                          to={{
                            pathname: "/admin/news",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsNews.News}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr class="hr"></hr>
                  <i
                    class="bi bi-calendar2-event"
                    style={{ marginRight: "9px", fontSize: "14px" }}
                  ></i>
                  <span style={{ fontSize: "14px", color: "#9a9a9a" }}>
                    New News
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-2" style={{ textAlign: "center" }}>
                      <i
                        class="bi bi-calendar2-event"
                        style={{ fontSize: "50px", color: "#fbc658" }}
                      ></i>
                    </div>
                    <div class="row col-md-10">
                      <div class="col-md-6" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Workshop
                        </span>
                        <br />
                        {/* <a href="/admin/appointment"> */}
                        <Link
                          to={{
                            pathname: "/admin/appointment",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsAppointment.workshop}
                          </span>
                        </Link>
                      </div>
                      <div class="col-md-6" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Venue
                        </span>
                        <br />
                        {/* <a href="/admin/appointment"> */}
                        <Link
                          to={{
                            pathname: "/admin/appointment",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsAppointment.venue}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr class="hr"></hr>
                  <i
                    class="bi bi-calendar2-event"
                    style={{ marginRight: "9px", fontSize: "14px" }}
                  ></i>
                  <span style={{ fontSize: "14px", color: "#9a9a9a" }}>
                    Active Appointment
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-2" style={{ textAlign: "center" }}>
                      <i
                        class="bi bi-credit-card"
                        style={{ fontSize: "50px", color: "#ef8157" }}
                      ></i>
                    </div>
                    <div class="row col-md-10">
                      <div class="col-md-6" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Workshop
                        </span>
                        <br />
                        {/* <a href="/admin/appointment"> */}
                        <Link
                          to={{
                            pathname: "/admin/appointment",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsNewPayments.instructor_payments}
                          </span>
                        </Link>
                      </div>
                      <div class="col-md-6" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Venue
                        </span>
                        <br />
                        {/* <a href="/admin/appointment"> */}
                        <Link
                          to={{
                            pathname: "/admin/appointment",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsNewPayments.venue_owner_payments}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr class="hr"></hr>
                  <i
                    class="bi bi-credit-card"
                    style={{ marginRight: "9px", fontSize: "14px" }}
                  ></i>
                  <span style={{ fontSize: "14px", color: "#9a9a9a" }}>
                    New Payments
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-2" style={{ textAlign: "center" }}>
                      <i
                        class="bi bi-calendar2-check"
                        style={{ fontSize: "50px", color: "#51cbce" }}
                      ></i>
                    </div>
                    <div class="row col-md-10">
                      <div class="col-md-6" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Workshop
                        </span>
                        <br />
                        {/* <a href="/admin/instructorPayOut"> */}
                        <Link
                          to={{
                            pathname: "/admin/instructorPayOut",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsCompletedPayments.instructor_payments}
                          </span>
                        </Link>
                      </div>
                      <div class="col-md-6" style={{ textAlign: "center" }}>
                        <span style={{ fontSize: "16px", color: "#9a9a9a" }}>
                          Venue
                        </span>
                        <br />
                        {/* <a href="/admin/instructorPayOut"> */}
                        <Link
                          to={{
                            pathname: "/admin/instructorPayOut",
                            search: `?token=${token}`,
                          }}
                        >
                          <span style={{ fontSize: "28px" }}>
                            {countsCompletedPayments.venue_owner_payments}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <hr class="hr"></hr>
                  <i
                    class="bi bi-calendar2-check"
                    style={{ marginRight: "9px", fontSize: "14px" }}
                  ></i>
                  <span style={{ fontSize: "14px", color: "#9a9a9a" }}>
                    Completed Payments
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2"></div>
            <div class="col-md-8">
              <div class="card dasboardCard">
                <div
                  class="card-body row"
                  style={{ padding: "5px", textAlign: "center" }}
                >
                  <div class="col-md-3" style={{ textAlign: "center" }}>
                    <i class="bi bi-circle-fill" style={{ color: "#ef8157" }}></i>{" "}
                    Workshop
                  </div>
                  <div class="col-md-3" style={{ textAlign: "center" }}>
                    <i class="bi bi-circle-fill" style={{ color: "#51cbce" }}></i>{" "}
                    Venue
                  </div>
                  <div class="col-md-3" style={{ textAlign: "center" }}>
                    <i class="bi bi-circle-fill" style={{ color: "#6bd098" }}></i>{" "}
                    News
                  </div>
                  <div class="col-md-3" style={{ textAlign: "center" }}>
                    <i class="bi bi-circle-fill" style={{ color: "#fbc658" }}></i>{" "}
                    Blogs
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2"></div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-9 col-6 col-lg-9">
                      <h6>TOTAL LISTING NO.</h6>
                    </div>
                    <div
                      class="col-md-3 col-6 col-lg-3"
                      style={{ textAlign: "center" }}
                      
                    >
                      <select class="selectCustom"
                      value={filterListing}
                      onChange={handleFilterChangeListing}
                      >
                      <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={data2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Listings Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-9 col-6 col-lg-9">
                      <h6>TOTAL PACKAGE NO.</h6>
                    </div>
                    <div
                      class="col-md-3 col-6 col-lg-3"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        class="selectCustom"
                        style={{ backgroundColor: "#ef8157" }}
                        value={filterPackage}
                        onChange={handleFilterChangePackage}
                      >
                       <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={dataPackage2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Listings Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-9 col-6 col-lg-9">
                      <h6>TOTAL APPOINTMENT NO.</h6>
                    </div>
                    <div
                      class="col-md-3 col-6 col-lg-3"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        class="selectCustom"
                        style={{ backgroundColor: "#51cbce" }}
                        value={filterAppointment}
                        onChange={handleFilterChangeAppointment}

                      >
                        <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={dataAppointment2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Listings Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-9 col-6 col-lg-9">
                      <h6>TOTAL PACKAGE REVENUE</h6>
                    </div>
                    <div
                      class="col-md-3 col-6 col-lg-3"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        class="selectCustom"
                        style={{ backgroundColor: "#fbc658" }}
                        value={filterPackageRevenue}
                        onChange={handleFilterChangePackageRevenue}
                      >
                        <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={dataPackagerevenue2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Listings Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-9 col-6 col-lg-9">
                      <h6>TOTAL REVENUE: APPOINTMENT + PACKAGE </h6>
                    </div>
                    <div
                      class="col-md-3 col-6 col-lg-3"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        class="selectCustom"
                        style={{ backgroundColor: "#6bd098" }}
                        value={filterTotalRevenue}
                        onChange={handleFilterChangeTotalRevenue}

                      >
                         <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={dataTotalrevenue2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Listings Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-8 col-6 col-lg-8">
                      <h6>TOTAL REVIEWS NO.</h6>
                    </div>
                    <div
                      className="col-md-4 col-6 col-lg-4"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        className="selectCustom"
                        style={{ backgroundColor: "#ef8157" }}
                        value={filter}
                        onChange={handleFilterChange}
                      >
                       
                        <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={dataReview2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Listings Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-8 col-6 col-lg-8">
                      <h6>TOTAL VIEWS NO.</h6>
                    </div>
                    <div
                      class="col-md-4 col-6 col-lg-4"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        class="selectCustom"
                        style={{ backgroundColor: "#51cbce" }}
                        value={filterView}
                        onChange={handleFilterChangeViews}
                      >
                       <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={dataViews2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Listings Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-8 col-6 col-lg-8">
                      <h6>TOTAL SHARES NO.</h6>
                    </div>
                    <div
                      class="col-md-4 col-6 col-lg-4"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        class="selectCustom"
                        style={{ backgroundColor: "#fbc658" }}
                        value={filterShare}
                        onChange={handleFilterChangeShare}
                        
                      >
                       <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={dataShares2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Shares Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-8 col-6 col-lg-8">
                      <h6>TOTAL WISHLISTED NO.</h6>
                    </div>
                    <div
                      class="col-md-4 col-6 col-lg-4"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        class="selectCustom"
                        style={{ backgroundColor: "#6bd098" }}
                        value={filterWishlist}
                        onChange={handleFilterChangeWishlist}
                        
                      >
                       <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={datawishlistt2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Wishlist Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-8 col-6 col-lg-8">
                      <h6>TOTAL SAVED NO.</h6>
                    </div>
                    <div
                      class="col-md-4 col-6 col-lg-4"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        class="selectCustom"
                        style={{ backgroundColor: "#ef8157" }}
                        value={filterSaved}
                        onChange={handleFilterChangeSaved}
                        
                      >
                         <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={dataSaved2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Saved Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="card dasboardCard">
                <div class="card-body" style={{ padding: "20px" }}>
                  <div class="row">
                    <div class="col-md-8 col-6 col-lg-8">
                      <h6>TOTAL USERS NO.</h6>
                    </div>
                    <div
                      class="col-md-4 col-6 col-lg-4"
                      style={{ textAlign: "center" }}
                    >
                      <select
                        class="selectCustom"
                        style={{ backgroundColor: "#51cbce" }}
                        value={filterUsers}
                        onChange={handleFilterChangeUsers}
                        
                      >
                       <option className="selectCustomOption" value="yearly">
                          Yearly
                        </option>
                        <option className="selectCustomOption" value="monthly">
                          Monthly
                        </option>
                        <option className="selectCustomOption" value="weekly">
                          Weekly
                        </option>
                        <option className="selectCustomOption" value="daily">
                          Daily
                        </option>
                      </select>
                    </div>
                  </div>
                  <br />
                  <div style={{ height: "auto", width: "auto" }}>
                    <Line
                      data={dataUsers2}
                      options={{
                        plugins: {
                          title: {
                            display: false,
                            text: "Total Listings Over the Years",
                          },
                          legend: {
                            display: false,
                            position: "bottom",
                          },
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Footer></Footer>
      </div>
    );
  }

  export default Dashboard;
