import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";

import { TailSpin } from "react-loader-spinner";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_Hiring from "../../Components/Admin_components/add_hiring.js";
import Update_Hiring from "../../Components/Admin_components/update_hiring.js";
import Delete_Hiring from "../../Components/Admin_components/delete_hiring.js";
import "../../Api/apiUrls.js";
import { API_URL, getWeAreHiring } from "../../Api/apiUrls";

function Hiring() {
  const [modalAdd_HiringIsOpen, setAdd_HiringModalIsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [hiring, setHiring] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedHiring, setSelectedHiring] = useState(null);
  const [selectedTittle, setSelectedTittle] = useState(null);
  const [selectedDescription, setSelectedDescription] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedExperience, setSelectedExperience] = useState(null);
  const [modalUpdate_HiringIsOpen, setUpdate_HiringModalIsOpen] =
    useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedQualification, setSelectedQualification] = useState(null);
  const [selectedRolesRes, setSelectedRolesRes] = useState(null);
  const [selectedWhyWorkWithUs, setSelectedWorkWithUs] = useState(null);
  const [modalDelete_HiringIsOpen, setDelete_HiringIsOpen] = useState(false);
  // const [selectedUserId, setSelectedUserId] = useState(null);
  const Status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const openAdd_Hiring = () => {
    closeAddHiring();
    setAdd_HiringModalIsOpen(true);
  };

  const closeAddHiring = () => {
    setAdd_HiringModalIsOpen(false);
  };
  const filteredHiring = Array.isArray(hiring)
    ? hiring.filter((hiring) => {
        const isStatus =
          !selectedStatus ||
          selectedStatus === "All" ||
          hiring.status === selectedStatus;

        return isStatus;
      })
    : [];
  const openUpdate_Hiring = (
    hiringId,
    hiringTittle,
    hiringExperience,
    hiringDescription,
    hiringPosition,
    hiringSkills,
    hiringLocation,
    hiringQualification,
    hiringRolesRes,
    hiringWhyWorkWithUs,
    hiringStatus
  ) => {
    setSelectedUserId(hiringId);
    setSelectedHiring();
    setSelectedTittle(hiringTittle);
    setSelectedExperience(hiringExperience);
    setSelectedDescription(hiringDescription);
    setSelectedPosition(hiringPosition);
    setSelectedSkills(hiringSkills);
    setSelectedLocation(hiringLocation);
    setSelectedQualification(hiringQualification);
    setSelectedRolesRes(hiringRolesRes);
    setSelectedWorkWithUs(hiringWhyWorkWithUs);
    setSelectedStatus(hiringStatus);
    closeUpdate_Hiring();
    setUpdate_HiringModalIsOpen(true);
  };

  const closeUpdate_Hiring = () => {
    setUpdate_HiringModalIsOpen(false);
  };
  const openDelete_Hiring = (policyId) => {
    setSelectedUserId(policyId);
    closeDelete_Hiring();
    setDelete_HiringIsOpen(true);
  };

  const closeDelete_Hiring = () => {
    setDelete_HiringIsOpen(null);
  };

  useEffect(() => {
    fetch(`${getWeAreHiring}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const hiring = data.data.WeAreHiring;
        setHiring(hiring);
        console.log(hiring);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div>
      <Header></Header>
      {modalAdd_HiringIsOpen && <Add_Hiring onClose={closeAddHiring} />}
      {modalUpdate_HiringIsOpen && (
        <Update_Hiring hiringId={selectedUserId} onClose={closeUpdate_Hiring} />
      )}
      {modalDelete_HiringIsOpen && (
        <Delete_Hiring hiringId={selectedUserId} onClose={closeDelete_Hiring} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1> We are Hiring</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div class="child ml-2">
                  <Select options={Status} placeholder="Select Status"></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class="btn-round"
                      onClick={openAdd_Hiring}
                    >
                      <i class="bi bi-person-add"></i> Add We Hiring
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
            {isLoading === true ? (
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  marginLeft: "50%",
                }}
              >
                <TailSpin color="black" width={50} />
              </div>
            ) : hiring.length > 0 ? (
              <table class="table table-striped ">
                <thead>
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Tittle</th>
                    <th scope="col">Experience</th>
                    <th scope="col">Description</th>
                    <th scope="col">Position</th>
                    <th scope="col">Skills</th>
                    <th scope="col">Location</th>
                    <th scope="col">Qualification</th>
                    <th scope="col">Why Work With Us</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredHiring.map((hiring, index) => (
                    <tr key={hiring.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{hiring.title || "-"}</td>
                      <td>{hiring.experience || "-"}</td>
                      <td>{hiring.description || "-"}</td>
                      <td>{hiring.open_positions || "-"}</td>
                      <td>{hiring.skills_required || "-"}</td>
                      <td>{hiring.location || "-"}</td>
                      <td>{hiring.qualification || "-"}</td>
                      <td>{hiring.why_work_with_us || "-"}</td>
                      <td>{hiring.status || "-"}</td>

                      <td>
                        <div className="social-links mt-2">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openUpdate_Hiring(
                                hiring.id,
                                hiring.title,
                                hiring.experience,
                                hiring.description,
                                hiring.open_positions,
                                hiring.skills_required,
                                hiring.location,
                                hiring.qualification,
                                hiring.why_work_with_us,

                                { value: hiring.id, label: hiring.status }
                              )
                            }
                          >
                            <i class="bi bi-pencil"></i>
                          </a>
                          &nbsp;&nbsp;
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openDelete_Hiring(hiring.id)}
                          >
                            <i class="bi bi-trash3"></i>
                          </a>
                          &nbsp;
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
            {modalUpdate_HiringIsOpen && (
              <Update_Hiring
                hiringId={selectedUserId}
                hiring={selectedHiring}
                hiringTittle={selectedTittle}
                hiringExperience={selectedExperience}
                hiringDescription={selectedDescription}
                hiringPosition={selectedPosition}
                hiringLocation={selectedLocation}
                hiringSkills={selectedSkills}
                hiringQualification={selectedQualification}
                hiringRolesRes={selectedRolesRes}
                hiringWhyWorkWithUs={selectedWhyWorkWithUs}
                hiringStatus={selectedStatus}
                onClose={closeUpdate_Hiring}
              />
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredHiring.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Hiring;
