import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "../../Components/Customer_comp/cust_appo_slot.css";
import React, { useState } from "react";
function Cust_appo_slot({ onClose }) {
  const [modalCust_appo_slotIsOpen, setCust_appo_slotModalIsOpen] =
    useState("");
  // const [partnerName, setPartnerName] = useState("");
  // const [email, setEmail] = useState("");
  // const [link, setLink] = useState("");
  // const [file, setIconFile] = useState();
  const [isAddingCust_appo_slot, setIsAddingCust_appo_slot] = useState(false);

  const closeCust_appo_slot = () => {
    setCust_appo_slotModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  return (
    <div>
      {modalCust_appo_slotIsOpen && (
        <Cust_appo_slot onClose={closeCust_appo_slot} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "75%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Slot</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div
                  class="col-md-12"
                  style={{ display: "flex", paddingLeft: "0px" }}
                >
                  <div
                    class="form-group col-md-6"
                    style={{ paddingLeft: "0px" }}
                  >
                    <label class="small mb-1" for="inputFullName">
                      Select Date<font color="red"></font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="date"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputFullName">
                      Select Start time <font color="red"></font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="time"
                    />
                  </div>
                </div>
                <div
                  class="col-md-12"
                  style={{ display: "flex", paddingLeft: "0px" }}
                >
                  <div
                    class="form-group col-md-6"
                    style={{ paddingLeft: "0px" }}
                  >
                    <label class="small mb-1" for="inputFullName">
                      Select End Time<font color="red"></font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="time"
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputFullName">
                      Enter Price <font color="red"></font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="text"
                    />
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label class="small mb-1" for="inputFullName">
                    Enter Availabel Seats<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                  />
                </div>
                <div style={{ display: "flex", marginBottom: "20px" }}></div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingCust_appo_slot === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000  ", border: "none" }}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cust_appo_slot;
