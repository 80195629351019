import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_Customer_Appointment from "../../Components/Customer_comp/add_appointment.js";
import Delete_Customer_Appointment from "../../Components/Customer_comp/cust_appoi_delete.js";
import Update_Customer_Appointment from "../../Components/Customer_comp/update_appointment.js";
import Cust_appo_slot from "../../Components/Customer_comp/cust_appo_slot.js";
import Add_review from "../../Components/Customer_comp/add_rating.js";
import CancelForm from "../../Components/Customer_comp/cancelForm.js";
import "../../Api/apiUrls.js";
import { getCustomerAppointmentId } from "../../Api/apiUrls";
function Customer_Appointment() {
  const [modalCust_appo_slotsIsOpen, setCust_appo_slotIsOpen] = useState("");
  const [id, setId] = useState("");
  const customerId = localStorage.getItem("user_id");
  const [isCancelEnabled, setIsCancelEnabled] = useState(true);

  const forceUpdate = useRef(0);
  const [
    modalAdd_Customer_AppointmentIsOpen,
    setAdd_Customer_AppointmentModalIsOpen,
  ] = useState("");
  const [modalAdd_reviewIsOpen, setAdd_reviewModalIsOpen] = useState("");
  const [
    modalDelete_Customer_AppointmentIsOpen,
    setDelete_Customer_AppointmentIsOpen,
  ] = useState(false);
  const [
    modalUpdate_Customer_AppointmentIsOpen,
    setUpdate_Customer_AppointmentModalIsOpen,
  ] = useState(false);
  const [appointment, setAppointment] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Reviewing", label: "Reviewing" },
  ];

  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const date = String(now.getDate()).padStart(2, "0");

    return `${year}-${month}-${date}`;
  };

  const calculateDifferenceInDays = (workshopDate) => {
    const currentDate = new Date();
    const workshopDateTime = new Date(workshopDate);
    const differenceInTime = workshopDateTime.getTime() - currentDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays;
  };

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    fetch(getCustomerAppointmentId + customerId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const appointmentData = data.data.Appointment;
        console.log(appointmentData);
        setAppointment(appointmentData);

        // Iterate through each appointment to calculate cancellation eligibility
        appointmentData.forEach((appointment) => {
          const { slot, workshopSlot } = appointment;

          if (workshopSlot && workshopSlot.date) {
            const appointmentDate = workshopSlot.date;
            const appointmentTime = slot;

            const isAppointmentCancelEnabled = calculateDifferenceInDays(
              appointmentDate,
              appointmentTime
            );

            console.log("Appointment Date:", appointmentDate);
            console.log("Appointment Time:", appointmentTime);
            console.log("Slot:", slot);
            console.log(
              "Is Appointment Cancel Enabled:",
              isAppointmentCancelEnabled
            );

            // Do something with each appointment here
            // For example, you might want to store them in an array or perform other operations
          }
        });

        // Force a re-render
        forceUpdate.current = (forceUpdate.current + 1) % 1000;
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Appointment value:", appointment);
      });
  }, [customerId, forceUpdate]);

  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  useEffect(() => {
    const filteredAppointments = appointment.filter((appointment) => {
      const isTypeMatch =
        !selectedType ||
        selectedType.value === "All" ||
        appointment.type === selectedType.value;

      const isStatusMatch =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        appointment.status === selectedStatus.value;
      const values = Object.values(appointment).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );

      return isTypeMatch && isStatusMatch && isSearchMatch;
    });

    // Rest of your code...
    setFilteredAppointments(filteredAppointments);
  }, [selectedType, selectedStatus, appointment, searchQuery]);
  const openAdd_Customer_Appointment = () => {
    closeAdd_Customer_Appointment();
    setAdd_Customer_AppointmentModalIsOpen(true);
  };

  const closeAdd_Customer_Appointment = () => {
    setAdd_Customer_AppointmentModalIsOpen(false);
  };
  const openUpdate_Customer_Appointment = () => {
    closeUpdate_Customer_Appointment();
    setUpdate_Customer_AppointmentModalIsOpen(true);
  };

  const closeUpdate_Customer_Appointment = () => {
    setUpdate_Customer_AppointmentModalIsOpen(false);
  };
  const openDelete_Customer_Appointment = (userId) => {
    setSelectedUserId(userId);
    closeDelete_Customer_Appointment();
    setDelete_Customer_AppointmentIsOpen(true);
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const closeDelete_Customer_Appointment = () => {
    setDelete_Customer_AppointmentIsOpen(null);
  };
  const openCust_appo_slot = () => {
    setCust_appo_slotIsOpen(true);
  };
  const closeCust_appo_slot = () => {
    setCust_appo_slotIsOpen(false);
  };

  const openAdd_review = (id) => {
    setId(id);
    closeAdd_review();
    setAdd_reviewModalIsOpen(true);
  };
  const closeAdd_review = () => {
    setAdd_reviewModalIsOpen(false);
  };

  const [modalCancelFormIsOpen, setCancelFormModalIsOpen] = useState("");

  const openCancelForm = (id) => {
    setId(id);
    closeCancelForm();
    setCancelFormModalIsOpen(true);
  };

  const closeCancelForm = () => {
    setCancelFormModalIsOpen(false);
  };

  const formatTimeCreatedAt = (createdAt) => {
    const createdAtTime = new Date(createdAt);
    return createdAtTime.toLocaleTimeString("en-GB", {
      hour: "numeric",
      minute: "numeric",
      // second: "numeric",
      hour12: true,
    });
  };

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const convertTimeTo12HourFormat = (time24hr) => {
    const [start, end] = time24hr.split(" - ");

    const convertTo12HourFormat = (time) => {
      const [hour, minutes] = time.split(":");
      const isPM = parseInt(hour, 10) >= 12;

      const convertedHour = isPM ? parseInt(hour, 10) % 12 : parseInt(hour, 10);
      const period = isPM ? "PM" : "AM";

      return `${convertedHour}:${minutes} ${period}`;
    };

    const convertedStart = convertTo12HourFormat(start);
    const convertedEnd = convertTo12HourFormat(end);

    return `${convertedStart} - ${convertedEnd}`;
  };
  const handleCancelClick = (appointment) => {
    const { id, workshopSlot, bookingSlot } = appointment;
    // if (appointment.status === "Completed") {
    //   return null; // Render nothing if the status is "Completed"
    // }
    // Check if workshopSlot or bookingSlot is defined
    if (
      (workshopSlot && workshopSlot.date) ||
      (bookingSlot && bookingSlot.startDate)
    ) {
      const date = workshopSlot ? workshopSlot.date : bookingSlot.startDate;

      // Calculate the difference in days between the appointment date and the current date
      const differenceInDays = calculateDifferenceInDays(date);

      // Check if the appointment date is more than two days away
      if (differenceInDays > 2) {
        // Display a confirmation popup or perform cancellation logic
        openCancelForm(id);
      } else {
        alert(
          "You cannot cancel the appointment as it is less than two days away."
        );
      }
    } else {
      console.error(
        "Invalid workshopSlot or bookingSlot format for appointment:",
        appointment
      );
      alert("Error: Unable to determine appointment details.");
    }
  };

  return (
    <div>
      <Header></Header>
      {modalAdd_Customer_AppointmentIsOpen && (
        <Add_Customer_Appointment onClose={closeAdd_Customer_Appointment} />
      )}

      {modalDelete_Customer_AppointmentIsOpen && (
        <Delete_Customer_Appointment
          userId={selectedUserId}
          onClose={closeDelete_Customer_Appointment}
        />
      )}
      {modalUpdate_Customer_AppointmentIsOpen && (
        <Update_Customer_Appointment
          onClose={closeUpdate_Customer_Appointment}
        />
      )}
      {modalCust_appo_slotsIsOpen && (
        <Cust_appo_slot onClose={closeCust_appo_slot} />
      )}
      {modalAdd_reviewIsOpen && (
        <Add_review onClose={closeAdd_review} id={id} />
      )}

      {modalCancelFormIsOpen && (
        <CancelForm onClose={closeCancelForm} id={id} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Appointment</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Appointment</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="child col-md-3">
                <input
                  id="myInput"
                  type="text"
                  style={{
                    borderRadius: "5px",
                    height: "38px",
                    padding: "10px",
                    border: "1px solid lightgrey ",
                  }}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  placeholder="Search..."
                />
              </div>
              <div class="col-md-3">
                <Select
                  options={type}
                  value={selectedType}
                  onChange={(selectedOption) => setSelectedType(selectedOption)}
                  placeholder="Select Type"
                />
              </div>
              <div class="col-md-3">
                <Select
                  options={status}
                  value={selectedStatus}
                  onChange={(selectedOption) =>
                    setSelectedStatus(selectedOption)
                  }
                  placeholder="Select Status"
                />
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : appointment.length > 0 ? (
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Title</th>
                      <th scope="col">Type</th>
                      <th scope="col">Slot/Date</th>
                      <th scope="col">Gift</th>
                      <th scope="col">Instructor Details</th>
                      <th scope="col">Payment Amount</th>
                      <th scope="col">Booking Date Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Cancellation Reason</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredAppointments.map((appointment, index) => (
                      <tr key={appointment.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {appointment.type === "Workshop" &&
                          appointment.workshopSlot &&
                          appointment.workshopSlot.workshop
                            ? appointment.workshopSlot.workshop.name ||
                              "No Name"
                            : appointment.type === "Venue" &&
                              appointment.bookingSlot &&
                              appointment.bookingSlot.venue
                            ? appointment.bookingSlot.venue.name || "No Name"
                            : "No Workshop or Venue"}
                        </td>
                        <td>{appointment.type || "No Name"}</td>
                        <td>
                          Date:
                          {appointment.type === "Workshop" ? (
                            <>
                              {formatCreatedAt(
                                appointment.workshopSlot?.date || "--"
                              )}
                            </>
                          ) : (
                            <>
                              {formatCreatedAt(
                                appointment.bookingSlot?.startDate || "--"
                              )}
                            </>
                          )}
                          <br />
                          Slot:{" "}
                          {convertTimeTo12HourFormat(appointment.slot || "--")}
                          <br />
                          <br />
                        </td>
                        <td>
                          {appointment.gift?.name || "-"} <br />
                          {appointment.gift?.mobileNumber || "-"} <br />
                          {appointment.gift?.email || "-"} <br />
                        </td>
                        <td>
                          {appointment.type === "Workshop" &&
                            appointment.workshopSlot &&
                            appointment.workshopSlot.workshop && (
                              <>
                                {appointment.workshopSlot.workshop
                                  .workshopInstructor.name || "No Name"}
                                <br />
                                {appointment.workshopSlot.workshop
                                  .workshopInstructor.mobileNumber || "No Name"}
                                <br />
                                {appointment.workshopSlot.workshop
                                  .workshopInstructor.email || "No Name"}
                                <br />
                              </>
                            )}
                          {appointment.type === "Venue" &&
                            appointment.bookingSlot &&
                            appointment.bookingSlot.venue && (
                              <>
                                {appointment.bookingSlot.venue.venue_owner
                                  .name || "No Name"}
                                <br />
                                {appointment.bookingSlot.venue.venue_owner
                                  .mobileNumber || "No Name"}
                                <br />
                                {appointment.bookingSlot.venue.venue_owner
                                  .email || "No Name"}
                                <br />
                              </>
                            )}
                        </td>
                        <td>{appointment.transaction.amount || "-"}</td>
                        <td>
                          {formatCreatedAt(appointment.created_at)} <br />
                          {formatTimeCreatedAt(appointment.created_at)}
                        </td>
                        <td>{appointment.status || "-"}</td>
                        <td>{appointment.reasonOfCancellation || "-"}</td>
                        {/* <td>
                          {convertTimeTo12HourFormat(appointment.slot) && (
                            <>
                              {appointment.workshopSlot &&
                              appointment.workshopSlot.date ? (
                                appointment.workshopSlot.date <
                                getCurrentDateTime() ? (
                                  <button
                                    id="btnNewUserReview"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#modalAdd"
                                    className="btn-round"
                                    onClick={() =>
                                      openAdd_review(appointment.id)
                                    }
                                  >
                                    <i></i> Review
                                  </button>
                                ) : (
                                  <button
                                    id="btnNewUserCancel"
                                    type="button"
                                    className={`btn-round ${
                                      isCancelEnabled ? "disabled-btn" : ""
                                    }`}
                                    onClick={() =>
                                      handleCancelClick(appointment)
                                    }
                                  >
                                    <i></i> Cancel
                                  </button>
                                )
                              ) : appointment.bookingSlot &&
                                appointment.bookingSlot.startDate ? (
                                appointment.bookingSlot.startDate <
                                getCurrentDateTime() ? (
                                  <button
                                    id="btnNewUserReview"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#modalAdd"
                                    className="btn-round"
                                    onClick={() =>
                                      openAdd_review(appointment.id)
                                    }
                                  >
                                    <i></i> Review
                                  </button>
                                ) : (
                                  <button
                                    id="btnNewUserCancel"
                                    type="button"
                                    className={`btn-round ${
                                      isCancelEnabled ? "disabled-btn" : ""
                                    }`}
                                    onClick={() =>
                                      handleCancelClick(appointment)
                                    }
                                  >
                                    <i></i> Cancel
                                  </button>
                                )
                              ) : null}
                            </>
                          )}
                        </td> */}

                        <td>
                          {convertTimeTo12HourFormat(appointment.slot) && (
                            <>
                              {appointment.status === "Completed" ||
                              appointment.status ===
                                "Cancelled" ? null : appointment.status ===
                                "Reviewing" ? (
                                <button
                                  id="btnNewUserReview"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#modalAdd"
                                  className="btn-round"
                                  onClick={() => openAdd_review(appointment.id)}
                                >
                                  <i></i> Review
                                </button>
                              ) : appointment.status === "Active" ? (
                                <button
                                  id="btnNewUserCancel"
                                  type="button"
                                  className={`btn-round ${
                                    isCancelEnabled ? "disabled-btn" : ""
                                  }`}
                                  onClick={() => handleCancelClick(appointment)}
                                >
                                  <i></i> Cancel
                                </button>
                              ) : appointment.workshopSlot &&
                                appointment.workshopSlot.date ? (
                                appointment.workshopSlot.date <
                                getCurrentDateTime() ? (
                                  <button
                                    id="btnNewUserReview"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#modalAdd"
                                    className="btn-round"
                                    onClick={() =>
                                      openAdd_review(appointment.id)
                                    }
                                  >
                                    <i></i> Review
                                  </button>
                                ) : (
                                  <button
                                    id="btnNewUserCancel"
                                    type="button"
                                    className={`btn-round ${
                                      isCancelEnabled ? "disabled-btn" : ""
                                    }`}
                                    onClick={() =>
                                      handleCancelClick(appointment)
                                    }
                                  >
                                    <i></i> Cancel
                                  </button>
                                )
                              ) : appointment.bookingSlot &&
                                appointment.bookingSlot.startDate ? (
                                appointment.bookingSlot.startDate <
                                getCurrentDateTime() ? (
                                  <button
                                    id="btnNewUserReview"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#modalAdd"
                                    className="btn-round"
                                    onClick={() =>
                                      openAdd_review(appointment.id)
                                    }
                                  >
                                    <i></i> Review
                                  </button>
                                ) : (
                                  <button
                                    id="btnNewUserCancel"
                                    type="button"
                                    className={`btn-round ${
                                      isCancelEnabled ? "disabled-btn" : ""
                                    }`}
                                    onClick={() =>
                                      handleCancelClick(appointment)
                                    }
                                  >
                                    <i></i> Cancel
                                  </button>
                                )
                              ) : null}
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredAppointments.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Customer_Appointment;
