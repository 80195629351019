import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";

import Add_happy from "../../Components/Admin_components/addHappy.js";
import Delete_happy from "../../Components/Admin_components/deleteHappy.js";
import "../../Api/apiUrls";
import { API_URL, getAdminHappy } from "../../Api/apiUrls";



function Happy() {
  const [modalAdd_happyIsOpen, setAdd_happyModalIsOpen] = useState("");
  const [happy, setHappy] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [modalDelete_happyIsOpen, setDelete_happyIsOpen] = useState(false);
  const [selectedHappyId, setSelectedHappyId] = useState(null);
  const openAdd_happy = () => {
    closeAdd_happy();
    setAdd_happyModalIsOpen(true);
  };

  const closeAdd_happy = () => {
    setAdd_happyModalIsOpen(false);
  };
  const openDelete_happy = (UserId) => {
    setSelectedHappyId(UserId);
    closeDelete_happy();
    setDelete_happyIsOpen(true);
  };

  const closeDelete_happy = () => {
    setDelete_happyIsOpen(null);
  };
  useEffect(() => {
    fetch(getAdminHappy)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const happy = data.data.HappyCustomers;
        setHappy(happy);
        console.log(happy);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div>
      <Header></Header>
      {modalAdd_happyIsOpen && <Add_happy onClose={closeAdd_happy} />}
      {modalDelete_happyIsOpen && (
        <Delete_happy UserId={selectedHappyId} onClose={closeDelete_happy} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Happy Customer</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_happy}
                    >
                      <i class="bi bi-person-add"></i> Add Customer
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>

            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : happy.length > 0 ? (
                <table class="table  table-striped ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Image</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {happy.map((happy, index) => (
                      <tr key={happy.id}>
                        <th scope="row">{index + 1}</th>
                        <td>
                          {happy.photo && (
                            <img
                              src={
                                `${API_URL}/${happy.photo}` || "-"
                              }
                              style={{ height: "50px", width: "50px" }}
                            />
                          )}
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            &nbsp;
                            <a onClick={() => openDelete_happy(happy.id)}>
                              <i className="bi bi-trash3"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#595857",
                color: "white",
                padding: "10px",
              }}
            >
              {happy.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Happy;
