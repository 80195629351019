import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_location.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { UpdateAdminCity } from "../../Api/apiUrls";
function Update_city({ cityId, cityName, cityStatus, onClose }) {
  const [modalUpdate_cityIsOpen, setUpdate_cityModalIsOpen] = useState("");
  const [newCityName, setNewCityName] = useState(cityName);
  const [newCityStatus, setNewCityStatus] = useState(cityStatus);
  const [isUpdatingCity, setIsUpdatingCity] = useState(false);
  const [isAddingcity, setIsAddingcity] = useState(false);

  const closeUpdate_city = () => {
    setUpdate_cityModalIsOpen(false);
  };
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  const handleStatusChange = (selectedOption) => {
    setNewCityStatus(selectedOption);
  };

  console.log(cityStatus);
  console.log(cityName);

  const handleUpdate = () => {
    setIsUpdatingCity(true);

    const formData = new FormData();
    formData.append("LocationCity_id", cityId);
    formData.append("name", newCityName);
    formData.append("status", newCityStatus.label);

    fetch(UpdateAdminCity, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingCity(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating city:", data.message);
          setIsUpdatingCity(false);
        }
      })
      .catch((error) => {
        console.error("Error updating city:", error);
        setIsUpdatingCity(false);
      });
  };

  return (
    <div>
      {modalUpdate_cityIsOpen && <update_city onClose={closeUpdate_city} />}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update City</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newCityName}
                    onChange={(e) => setNewCityName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category Status
                  </label>
                  <Select
                    value={newCityStatus}
                    onChange={handleStatusChange}
                    options={Status}
                  ></Select>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isUpdatingCity === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_city;
