import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_venue.css";
import Select from "react-select";
import { Dropdown } from "react-bootstrap";
import "../../Api/apiUrls.js";
import {
  API_URL,
  getBookingSlotById,
  getCategories,
  geLocationCity,
  getLocationArea,
  addFaqPage,
  getFAQByTempId,
  getFAQById,
  updateFaq,
  deleteFAQById,
  addBookingSlot,
  getBookingByTempId,
  updateBookingSlot,
  deleteBookingSlotById,
  addVenues,
} from "../../Api/apiUrls";

function Add_VenueownerVenue({ onClose, dateTime }) {
  const [modalAdd_VenueownerVenueIsOpen, setAdd_VenueownerVenueModalIsOpen] =
    useState("");

  const [isAddingVenueownerVenue, setIsAddingVenueownerVenue] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [showFields2, setShowFields2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalAdd_venueIsOpen, setAdd_venueModalIsOpen] = useState("");
  const [venueName, setVenueName] = useState("");
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [venueShortDescription, setVenueShortDescription] = useState("");
  const [venueDescription, setVenueDescription] = useState("");
  const [venueInclusion, setVenueInclusion] = useState("");
  const [venueCancellation, setVenueCancellation] = useState("");
  const [venueCategory, setVenueCategory] = useState("");
  const [venueKeywords, setVenueKeywords] = useState("");
  const [venueDuration, setVenueDuration] = useState("");
  const [venueCapacity, setVenueCapacity] = useState("");
  const [venuePrice, setVenuePrice] = useState("");
  const [venueArea, setVenueArea] = useState("");
  const [venueCity, setVenueCity] = useState("");
  const [venueAddress1, setVenueAddress1] = useState("");
  const [venueAddress2, setVenueAddress2] = useState("");
  const [venuePostalCode, setVenuePostalCode] = useState("");
  const [venueGoogleCode, setVenueGoogleCode] = useState("");
  const [venueEmail, setVenueEmail] = useState("");
  const [venuePhone, setVenuePhone] = useState("");
  const [venueWebsite, setVenueWebsite] = useState("");
  const [venueStatus, setVenueStatus] = useState("");
  const [isAddingvenue, setIsAddingvenue] = useState(false);

  const [showFields3, setShowFields3] = useState(false);
  const [showbooking, setshowbooking] = useState(false);
  const [categories, setCategories] = useState([]);
  const [city, setCity] = useState([]);
  const [location, setLocation] = useState([]);
  const [bookingNewDate, setBookingNewDate] = useState("");
  const [bookingNewStartTime, setBookingNewStartTime] = useState("");
  const [bookingNewEndTime, setBookingNewEndTime] = useState("");
  const [bookingNewPrice, setBookingNewPrice] = useState("");
  // const [bookingNewCapacity, setBookingNewCapacity] = useState("");
  const [isUpdatingSlot, setIsUpdatingSlot] = useState(false);
  const [isUpdatingFaq, setIsUpdatingFaq] = useState(false);
  // const [eachSlot, setEachSlot] = useState("");
  const [isAddingFaq, setIsAddingFaq] = useState(false);
  const [Question, setQuestion] = useState("");
  const [Answer, setAnswer] = useState("");
  const [newFaqAnswer, setNewFaqAnswer] = useState(Answer);
  const [newFaqQuestion, setNewFaqQuestion] = useState(Question);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isAddingBookingSlot, setIsAddingBookingSlot] = useState(false);
  const [bookingDate, setBookingDate] = useState("");
  const [bookingStartTime, setBookingStartTime] = useState("00:00");
  const [bookingEndTime, setBookingEndTime] = useState("00:00");
  const [bookingPrice, setBookingPrice] = useState("");
  const [bookingCapacity, setBookingCapacity] = useState("");
  const [datetime, setDatetime] = useState("");
  const [id2, setId2] = useState("");
  const [showFields4, setShowFields4] = useState(false);
  const [isAddingworkshop, setIsAddingWorkshop] = useState(false);

  const [eachSlot, setEachSlot] = useState({
    id: "",
    startDate: "",
    startTime: "",
    endTime: "",
    price: "",
    capacity: "",
  });
  const [bookingNewCapacity, setBookingNewCapacity] = useState(
    eachSlot.capacity
  );
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [id, setId] = useState("");
  function getCurrentDateTime() {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");

    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;

    return formattedDateTime;
  }

  const dateTimeString = getCurrentDateTime();
  console.log(dateTimeString);

  const handleAddClick = (event) => {
    event.preventDefault(); // Prevents the default form submission behavior
    setShowFields(true);
  };

  const handleCancelClick = () => {
    setShowFields(false);
  };

  const handleCancelClick4 = () => {
    setShowFields4(false);
  };

  const handleAddClick2 = () => {
    setBookingDate(getTodayDate());
    setBookingStartTime("00:00");
    setBookingEndTime("00:00");
    setBookingPrice("");
    setBookingCapacity("");
    setShowFields2(true);
    fetch(`${getBookingSlotById}${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const bookingEachSlot = data.data.BookingSlot;
        alert("----hey");
        setshowbooking(true);

        console.log("each", bookingEachSlot);
        setEachSlot(bookingEachSlot);
        setBookingNewCapacity(bookingEachSlot.capacity); // Initialize bookingNewCapacity

        // console.log(bookingEachSlot.startDate);
        setBookingNewDate(bookingEachSlot.startDate);
        setBookingNewStartTime(bookingEachSlot.startTime);
        setBookingNewEndTime(bookingEachSlot.endTime);
        setBookingNewPrice(bookingEachSlot.price);
        setBookingNewCapacity(bookingEachSlot.capacity);

        // setEachSlot(bookingEachSlot);

        // setEachSlot(bookingEachSlot);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        // setIsLoading(false);
      });

    setshowbooking(true);
  };

  const handleCancelClick3 = () => {
    setShowFields3(false);
  };

  // const handleCancelClick3 = () => {
  //   setShowFields3(false);
  // };

  const handleCancelClick2 = () => {
    setShowFields2(false);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    // Add your logic for handling the form submission (e.g., sending data to a server)
    // Reset the form state or perform any additional actions if needed
    setShowFields(false);
  };

  const closeAdd_VenueownerVenue = () => {
    setAdd_VenueownerVenueModalIsOpen(false);
  };
  const Category = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  // const city = [
  //   { value: "Yes", label: "Yes" },
  //   { value: "No", label: "No" },
  // ];
  const area = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const homepage = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ];
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  // function reloadPage() {
  //   window.location.reload();
  // }
  const handleStatusChange = (selectedOption) => {
    setVenueStatus(selectedOption.value);
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    const today = getTodayDate();
    setBookingDate(today);
  }, []);

  const formatStartDate = (startDate) => {
    const date = new Date(startDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  // ----------------------------get category--------------------------------
  const [selectedCategories, setSelectedCategories] = useState([]);
  useEffect(() => {
    console.log("------------------------------------------");
    console.log(getCurrentDateTime());
    fetch(`${getCategories}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.Categories)) {
            const workshopCategories = data.data.Categories.filter(
              (category) => category.type === "Venue"
            );
            setCategories(workshopCategories);
          } else {
            console.error(
              "Categories data is not an array:",
              data.data.Categories
            );
          }
        } else {
          console.error("Error fetching category:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching category:", error);
      });
  }, []);

  const categoryOptions = categories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const handleCategoryChange = (selectedOptions) => {
    console.log(selectedOptions);
    setSelectedCategories([selectedOptions]);
    // setaa([selectedOptions.label])

    console.log(selectedOptions);
  };

  const [selectedLocation, setSelectedLocation] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);
  // const [city, setCity] = useState([]); // Add state for city
  const [filteredAreas, setFilteredAreas] = useState([]);

  // Fetch cities and areas
  useEffect(() => {
    fetch(`${geLocationCity}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setCity(data.data.LocationCity); // Set fetched cities
        } else {
          console.error("Error fetching city:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching city:", error);
      });

    fetch(`${getLocationArea}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setLocation(data.data.LocationArea); // Initially set all areas
        } else {
          console.error("Error fetching area:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching area:", error);
      });
  }, []);

  // Filter areas based on selected city
  useEffect(() => {
    if (selectedCity && selectedCity.value) {
      // Check if a city is selected
      console.log(selectedCity);
      const filteredAreasResult = location.filter(
        (location) => location.city.id === selectedCity.value
      );
      setFilteredAreas(filteredAreasResult);
    }
  }, [selectedCity, location]);

  // Map filtered areas to options
  const areaOptions = filteredAreas.map((area) => ({
    value: area.id,
    label: area.name,
  }));

  // Event handler for city selection
  const handleCityChange = (selectedOptions) => {
    setSelectedCity(selectedOptions);
    setSelectedCities([selectedOptions]);
  };

  // Define cityOptions
  const cityOptions = city.map((city) => ({
    value: city.id,
    label: city.name,
  }));

  // Define handleAreaChange function
  const handleAreaChange = (selectedOptions) => {
    setSelectedLocation([selectedOptions]);
  };
  //---------------------------------------- Add FAQ'S-------------------------------------------//
  const [faq, setFaq] = useState(false);
  const handleAddFAQ = async (event) => {
    try {
      event.preventDefault();

      if (!Question) {
        alert("Please fill in all fields");
        return;
      }

      setIsAddingFaq(true);

      const formData = new FormData();
      formData.append("temp_id", dateTime);
      formData.append("type", "Venue");
      formData.append("question", Question);
      formData.append("answer", Answer);

      const addResponse = await fetch(`${addFaqPage}`, {
        method: "POST",
        body: formData,
      });

      if (!addResponse.ok) {
        alert("Failed to add FAQ");
        return;
      }
      const getResponse = await fetch(`${getFAQByTempId}${dateTime}`);

      if (!getResponse.ok) {
        alert("Failed to fetch FAQ");
        return;
      }

      const getData = await getResponse.json();
      const faqData = getData.data.FAQ || [];

      setFaq(faqData);
      setShowFields(false);
      setQuestion("");
      setAnswer("");
    } catch (error) {
      console.error("Error handling FAQ:", error);
    } finally {
      setIsAddingFaq(false);
    }
  };
  // ------------------------------------------get faq by id-----------------------------------------------

  const handleClick3 = (id) => {
    // event.preventDefault();

    fetch(`${getFAQById}${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const bookingFaq = data.data.FAQ;
        console.log("each", bookingFaq);

        setNewFaqAnswer(bookingFaq.answer);
        setNewFaqQuestion(bookingFaq.question);
        setDatetime(bookingFaq.temp_id);
        setId(bookingFaq.id);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });

    setShowFields3(true);
  };
  // --------------------------------------------update faqs-----------------------------------------------------------------------------

  const handleUpdateFaq = async (id) => {
    try {
      setIsUpdatingFaq(true);

      const formData = new FormData();
      formData.append("faq_id", id);
      formData.append("temp_id", datetime);
      formData.append("question", newFaqQuestion);
      formData.append("answer", newFaqAnswer);

      const updateResponse = await fetch(`${updateFaq}`, {
        method: "PUT",
        body: formData,
      });

      if (!updateResponse.ok) {
        alert("Failed to update FAQ");
        return;
      }

      // Trigger get API after update
      const getResponse = await fetch(`${getFAQByTempId}${datetime}`);

      if (!getResponse.ok) {
        alert("Failed to fetch FAQs");
        return;
      }

      const getData = await getResponse.json();
      const updatedFaqData = getData.data.FAQ || [];

      setFaq(updatedFaqData);
      setShowFields3(false);
    } catch (error) {
      console.error("Error updating FAQ:", error);
    } finally {
      setIsUpdatingFaq(false);
    }
  };
  // ----------------------------delete faqs-----------------------------------------------

  const handleDeleteFaq = (id) => {
    fetch(`${deleteFAQById}${id}`, {
      method: "DELETE",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the delete operation was successful
        if (data.success) {
          // Trigger the get API after successful deletion
          return fetch(`${getFAQByTempId}${dateTime}`);
        } else {
          // Handle the error if the delete operation was not successful
          console.error("Error deleting FAQ:", data.message);
        }
      })
      .then((getResponse) => {
        // Check if the get API response is successful
        if (getResponse && getResponse.ok) {
          return getResponse.json();
        } else {
          throw new Error("Failed to fetch FAQs");
        }
      })
      .then((getData) => {
        // Update the table with the fetched data
        const updatedFaqData = getData.data.FAQ || [];
        setFaq(updatedFaqData);
      })
      .catch((error) => {
        console.error("Error handling FAQ:", error);
      });
  };

  // ------------------------------------add venue slot ----------------------------------------------

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(":");
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${minutes}`;
  };
  const [bookingSlot, setBookingSlot] = useState([]);
  const handleAddBookingSlot = async (event) => {
    try {
      event.preventDefault();

      if (!bookingDate) {
        alert("Please fill in all fields");
        return;
      }

      setIsAddingBookingSlot(true);
      // const formattedStartTime = convertTo12HourFormat(bookingStartTime);
      // const formattedEndTime = convertTo12HourFormat(bookingEndTime);

      const formData = new FormData();
      formData.append("startDate", bookingDate);
      formData.append("startTime", bookingStartTime);
      formData.append("endTime", bookingEndTime);
      formData.append("price", bookingPrice);
      formData.append("temp_id", dateTime);
      formData.append("capacity", bookingCapacity);
      formData.append("availableSeats", bookingCapacity);

      const addResponse = await fetch(`${addBookingSlot}`, {
        method: "POST",
        body: formData,
      });

      if (!addResponse.ok) {
        alert("Failed to add Slot");
        return;
      }
      const getResponse = await fetch(`${getBookingByTempId}${dateTime}`);

      if (!getResponse.ok) {
        alert("Failed to fetch slot");
        return;
      }

      const getData = await getResponse.json();
      const bookingSlot = getData.data.BookingSlot || [];

      setBookingSlot(bookingSlot);
      setShowFields2(false);
      setBookingDate("");
      setBookingStartTime("");
      setBookingEndTime("");
      setBookingPrice("");
      setBookingCapacity("");
    } catch (error) {
      console.error("Error handling slot:", error);
    } finally {
      setIsAddingBookingSlot(false);
    }
  };

  //  ------------------------------------get venue slot by id-------------------------------------//

  const handleClick4 = (id2) => {
    fetch(`${getBookingSlotById}${id2}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const bookingEachSlot = data.data.BookingSlot;
        setBookingNewDate(bookingEachSlot.startDate);
        setBookingNewStartTime(bookingEachSlot.startTime);
        setBookingNewEndTime(bookingEachSlot.endTime);
        setBookingNewPrice(bookingEachSlot.price);
        setBookingNewCapacity(bookingEachSlot.capacity);
        setDatetime(bookingEachSlot.temp_id);
        setId2(bookingEachSlot.id);
        console.log(bookingEachSlot.id);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });

    setShowFields4(true);
  };
  // -------------------------update workshop slot ----------------------------------------------------------------

  const handleUpdateVenue = async (id2) => {
    try {
      setIsUpdatingSlot(true);

      const formData = new FormData();
      formData.append(" BookingSlot_id", id2);
      formData.append("temp_id", dateTime);
      formData.append("date", bookingNewDate);
      formData.append("startTime", bookingNewStartTime);
      formData.append("endTime", bookingNewEndTime);
      formData.append("price", bookingNewPrice);
      formData.append("totalSeats", bookingNewCapacity);

      const updateResponse = await fetch(`${updateBookingSlot}`, {
        method: "PUT",
        body: formData,
      });

      if (!updateResponse.ok) {
        alert("Failed to update slot");
        return;
      }

      // Trigger get API after update
      const getResponse = await fetch(`${getBookingByTempId}${dateTime}`);

      if (!getResponse.ok) {
        alert("Failed to fetch slot");
        return;
      }

      const getData = await getResponse.json();
      const bookingSlot = getData.data.BookingSlot || [];

      setBookingSlot(bookingSlot);
      setShowFields4(false);
    } catch (error) {
      console.error("Error handling slot:", error);
    } finally {
      setIsUpdatingSlot(false);
    }
  };

  // ---------------------------delete venue slot ------------------------------------

  const handleDeleteVenue = (id2) => {
    fetch(`${deleteBookingSlotById}${id2}`, {
      method: "DELETE",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the delete operation was successful
        if (data.success) {
          // Trigger the get API after successful deletion
          return fetch(`${getBookingByTempId}${dateTime}`);
        } else {
          // Handle the error if the delete operation was not successful
          alert( data.message);
        }
      })
      .then((getResponse) => {
        // Check if the get API response is successful
        if (getResponse && getResponse.ok) {
          return getResponse.json();
        } else {
          throw new Error("Failed to fetch slot");
        }
      })
      .then((getData) => {
        const bookingSlot = getData.data.BookingSlot || [];
        setBookingSlot(bookingSlot);
      })
      .catch((error) => {
        console.error("Error handling slot:", error);
      });
  };
  // ---------------------add venue -------------------------------------------------------------
  const OwnerId = localStorage.getItem("user_id");
  async function addVenue(
    venueName,
    venueShortDescription,
    venueDescription,
    venueCapacity,
    venueInclusion,
    venueCancellation,
    venueDuration,
    venuePrice,
    venueAddress1,
    venueAddress2,
    venuePostalCode,
    venueGoogleCode,
    venueEmail,
    venuePhone,
    venueStatus,
    selectedLocation,
    selectedCategories,
    selectedCities
  ) {
    try {
      const formData = new FormData();
      formData.append("venue_owner", OwnerId);
      formData.append("temp_id", dateTime);
      formData.append("name", venueName);
      formData.append("short_description", venueShortDescription);
      formData.append("description", venueDescription);
      formData.append("category", selectedCategories[0].value);
      formData.append("capacity", venueCapacity);
      formData.append("duration", venueDuration);
      formData.append("price", venuePrice);
      formData.append("address_line1", venueAddress1);
      formData.append("address_line2", venueAddress2);
      formData.append("inclusion_amenities", venueInclusion);
      formData.append("cancellation_policy", venueCancellation);
      formData.append("city", selectedCities[0].value);
      if (selectedLocation && selectedLocation.length > 0) {
        formData.append("area", selectedLocation[0].value);
      } else {
        console.error("selectedLocation is undefined or empty");
      }
      formData.append("pincode", venuePostalCode);
      formData.append("google_location_link", venueGoogleCode);
      formData.append("email", venueEmail);
      formData.append("mobileNumber", venuePhone);
      formData.append("website", venueWebsite);
      formData.append("status", venueStatus);

      const response = await fetch(`${addVenues}`, {
        method: "POST",
        body: formData,
      });

      console.log(response);
      if (response.ok) {
        return true;
      } else {
        throw new Error("Failed to add venue");
      }
    } catch (error) {
      console.error("Error adding venue:", error);
      return false;
    }
  }
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!isButtonClicked) {
        console.log("🚀 ~ handleBeforeUnload ~ isButtonClicked:", isButtonClicked);
        const confirmationMessage = 'You may lose form data. Are you sure you want to leave?';
        event.preventDefault();
        event.returnValue = confirmationMessage; // Triggers the browser's default confirmation dialog
      }
    };
  
    const handleUnload = () => {
      if (isButtonClicked) {
        console.log("User clicked Yes, form data will be lost.");
        // onClose();
        // reloadPage();
      } else {
        console.log("User clicked No or closed the dialog, staying on the page.");
       
      }
    };
  
    // Add the event listener for page refresh or close
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload); // Capture unload event
  
    // Clean up the event listeners when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [isButtonClicked]);
  const handleClose = () => {
    // Show confirmation dialog
    const confirmClose = window.confirm('Are you sure you want to close? You may lose unsaved changes.');
    if (confirmClose) {
      // User confirmed, set isButtonClicked to true and reload the page
      setIsButtonClicked(true);
      onClose();
      // window.location.reload(); // Reload the page or you can call onClose() to close the modal
    } else {
      // User canceled, do nothing
      console.log("User canceled the close action.");
    }
  };
  const handleAddVenue = async () => {
    setIsButtonClicked(true);
    let missingField = [];

    if (!venueName) missingField.push("venue name");
    if (!venuePrice) missingField.push("venue price");
    if (!venueShortDescription) missingField.push("venue short description");
    if (!venueDescription) missingField.push("venue description");
    if (!selectedCategories || !selectedCategories[0] || !selectedCategories[0].value) missingField.push("venue category");
    if (!selectedCities || !selectedCities[0] || !selectedCities[0].value) missingField.push("venue city");
    if (!selectedLocation || !selectedLocation[0] || !selectedLocation[0].value) missingField.push("venue area");
    if (!venueStatus) missingField.push("venue status");
    if (!venueCapacity) missingField.push("venue seats");
    if (!venueDuration) missingField.push("venue duration");


    if (missingField.length > 0) {
        alert(`${missingField.join(", ")} is required.`);
        return;
    }

    setIsAddingWorkshop(true);

    const result = await addVenue(
      venueName,
      venueShortDescription,
      venueDescription,
      venueCapacity,
      venueInclusion,
      venueCancellation,
      venueDuration,
      venuePrice,
      venueAddress1,
      venueAddress2,
      venuePostalCode,
      venueGoogleCode,
      venueEmail,
      venuePhone,
      venueStatus,
      selectedLocation,
      selectedCategories,
      selectedCities
    );

    setIsAddingWorkshop(false);
    console.log(result);
    if (result) {
      alert("Venue Added Successfully");
      window.location.reload();

      onClose(); // Close the modal
    } else {
      alert("Error Adding Venue");
    }
  };

  return (
    <div>
      {modalAdd_VenueownerVenueIsOpen && (
        <add_VenueownerVenue onClose={closeAdd_VenueownerVenue} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              // overflowY: "scroll",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Venue</b>
              </h5>

              <button
                type="button"
                // onClick={() => {
                //   onClose();
                //   // reloadPage();
                // }}
                onClick={handleClose}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name of Venue:
                    <font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={venueName}
                    onChange={(e) => setVenueName(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Short Description:<font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={venueShortDescription}
                    onChange={(e) => setVenueShortDescription(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description:<font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="description"
                    value={venueDescription}
                    onChange={(e) => setVenueDescription(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Inclusion Ammenities:<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={venueInclusion}
                    onChange={(e) => setVenueInclusion(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Cancellation Policy:<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={venueCancellation}
                    onChange={(e) => setVenueCancellation(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Category :<font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>

                  <Select
                    options={categoryOptions}
                    value={
                      selectedCategories.length > 0
                        ? selectedCategories[0]
                        : null
                    }
                    onChange={handleCategoryChange}
                  ></Select>
                </div>
                {/* <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Keywords:<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={venueKeywords}
                    onChange={(e) => setVenueKeywords(e.target.value)}
                  />
                </div> */}
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Duration in Hrs: <font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    value={venueDuration}
                    onChange={(e) => setVenueDuration(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Capacity: <font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    value={venueCapacity}
                    onChange={(e) => setVenueCapacity(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Price:{" "}
                    <font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    value={venuePrice}
                    onChange={(e) => setVenuePrice(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Address Line1:<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={venueAddress1}
                    onChange={(e) => setVenueAddress1(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Address Line2:<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={venueAddress2}
                    onChange={(e) => setVenueAddress2(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Select City:<font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>
                  <Select
                    options={cityOptions}
                    value={selectedCities.length > 0 ? selectedCities[0] : null}
                    onChange={handleCityChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Select Area :<font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>
                  <Select
                    options={areaOptions}
                    value={
                      selectedLocation.length > 0 ? selectedLocation[0] : null
                    }
                    onChange={handleAreaChange}
                  ></Select>
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Postal Code:<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    value={venuePostalCode}
                    onChange={(e) => setVenuePostalCode(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Google Code:<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={venueGoogleCode}
                    onChange={(e) => setVenueGoogleCode(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Email:<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="email"
                    value={venueEmail}
                    onChange={(e) => setVenueEmail(e.target.value)}
                  />
                  {!venueEmail.includes("@") && (
                    <p style={{ color: "red", fontSize: "12px" }}>
                      Please enter a valid email address.
                    </p>
                  )}
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Phone:<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="tel"
                    maxLength={10}
                    value={venuePhone}
                    onChange={(e) => setVenuePhone(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Website:<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={venueWebsite}
                    onChange={(e) => setVenueWebsite(e.target.value)}
                  />
                </div>
                <div>
                  <div className="form-group">
                    <label className="small mb-1" htmlFor="inputFullName">
                      FAQ's<font color="red"></font>
                    </label>
                    <button
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        borderRadius: "4px",
                        backgroundColor: "#000",
                        color: "#fff",
                      }}
                      onClick={handleAddClick}
                    >
                      + Add
                    </button>
                  </div>
                  {showFields && (
                    <>
                      <div
                        class="col-md-12"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputFullName">
                            Enter Question<font color="red"></font>
                          </label>
                          <input
                            className="form-control"
                            id="inputFullName"
                            type="text"
                            value={Question}
                            onChange={(e) => setQuestion(e.target.value)}
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label className="small mb-1" htmlFor="inputFullName">
                            Enter Answer<font color="red"></font>
                          </label>
                          <input
                            className="form-control"
                            id="inputFullName"
                            type="text"
                            value={Answer}
                            onChange={(e) => setAnswer(e.target.value)}
                          />
                        </div>
                      </div>

                      <div style={{ display: "flex", marginBottom: "20px" }}>
                        <button
                          type="button"
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            backgroundColor: "#000",
                            color: "#fff",
                            border: "none",
                            padding: "5px 20px",
                          }}
                          onClick={handleAddFAQ}
                        >
                          Add
                        </button>
                        <button
                          type="button"
                          onClick={handleCancelClick}
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            borderRadius: "4px",
                            backgroundColor: "lightgrey",
                            color: "#000",
                            border: "none",
                            padding: "5px 20px",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>

                <div className="table-responsive">
                  {Array.isArray(faq) && faq.length > 0 ? (
                    <table
                      className="table table-striped"
                      style={{ padding: "0rem" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">Sr.No</th>
                          <th scope="col">Question</th>
                          <th scope="col">Answer</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {faq.map((faqItem, index) => (
                          <tr key={faqItem.id}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <b>{faqItem.question || "-"}</b>
                            </td>
                            <td>
                              <b>{faqItem.answer || "-"}</b>
                            </td>
                            <td>
                              <div className="social-links mt-2">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick3(faqItem.id)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleDeleteFaq(faqItem.id)}
                                >
                                  <i className="bi bi-trash3"></i>
                                </a>
                                &nbsp;
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h3 style={{ textAlign: "center" }}></h3>
                  )}
                </div>
                
                {showFields3 && (
                  <div>
                    <>
                      <div>
                        <div
                          class="col-md-12"
                          style={{ display: "flex", paddingLeft: "0px" }}
                        >
                          <div
                            class="form-group col-md-6"
                            style={{ paddingLeft: "0px" }}
                          >
                            <label class="small mb-1" for="inputFullName">
                              Question<font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="text"
                              value={newFaqQuestion}
                              onChange={(e) =>
                                setNewFaqQuestion(e.target.value)
                              }
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFullName">
                              Answer <font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="text"
                              // value={eachSlot.startTime}
                              value={newFaqAnswer}
                              onChange={(e) => setNewFaqAnswer(e.target.value)}
                            />
                          </div>
                        </div>

                        <div style={{ display: "flex", marginBottom: "20px" }}>
                          <button
                            type="button"
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "#000",
                              color: "#fff",
                              border: "none",
                              padding: "5px 20px",
                            }}
                            onClick={() =>
                              //handleUpdateFaq(faq.getCurrentDateTime)
                              handleUpdateFaq(id, newFaqAnswer, newFaqQuestion)
                            }
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelClick3}
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "lightgrey",
                              color: "#000",
                              border: "none",
                              padding: "5px 20px",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  </div>
                )}
                {/* <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Booking Slots:<font color="red"></font>
                  </label>
                  <button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                    onClick={handleAddClick2}
                  >
                    + Add
                  </button>
                </div> */}
                {showFields2 && (
                  <>
                    <div
                      class="col-md-12"
                      style={{ display: "flex", paddingLeft: "0px" }}
                    >
                      <div
                        class="form-group col-md-6"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label class="small mb-1" for="inputFullName">
                          Select Date<font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="date"
                          value={bookingDate}
                          min={bookingDate}
                          onChange={(e) => setBookingDate(e.target.value)}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="small mb-1" for="inputFullName">
                          Select Start time <font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="time"
                          value={bookingStartTime}
                          onChange={(e) => setBookingStartTime(e.target.value)}
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-12"
                      style={{ display: "flex", paddingLeft: "0px" }}
                    >
                      <div
                        class="form-group col-md-6"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label class="small mb-1" for="inputFullName">
                          Select End Time<font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="time"
                          value={bookingEndTime}
                          onChange={(e) => setBookingEndTime(e.target.value)}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="small mb-1" for="inputFullName">
                          Enter Price <font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="number"
                          value={bookingPrice}
                          onChange={(e) => setBookingPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="inputFullName">
                        Enter Seating Capacity<font color="red"></font>
                      </label>
                      <input
                        class="form-control"
                        id="inputFullName"
                        type="number"
                        value={bookingCapacity}
                        onChange={(e) => setBookingCapacity(e.target.value)}
                      />
                    </div>
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                      <button
                        type="button"
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "#000",
                          color: "#fff",
                          border: "none",
                          padding: "5px 20px",
                        }}
                        onClick={handleAddBookingSlot}
                      >
                        Add
                      </button>
                      <button
                        type="button"
                        onClick={handleCancelClick2}
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "lightgrey",
                          color: "#000",
                          border: "none",
                          padding: "5px 20px",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
                <div className="table-responsive">
                  {Array.isArray(bookingSlot) && bookingSlot.length > 0 ? (
                    <table
                      className="table table-striped"
                      style={{ padding: "0rem" }}
                    >
                      <thead>
                        <tr>
                          <th scope="col">Sr.No</th>
                          <th scope="col">Start Date</th>
                          <th scope="col">Start Time</th>
                          <th scope="col">End Time</th>
                          <th scope="col">Price</th>
                          <th scope="col">Seating Capacity</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingSlot.map((bookingSlot, index) => (
                          <tr key={bookingSlot.id}>
                            <th scope="row">{index + 1}</th>
                            <td>
                              <b>
                                {formatStartDate(bookingSlot.startDate) ||
                                  "---"}
                              </b>
                            </td>
                            <td>
                              <b>{bookingSlot.startTime}</b>
                              <br />
                            </td>
                            <td>
                              <b>{bookingSlot.endTime}</b>
                              <br />
                            </td>
                            <td>
                              <b>{bookingSlot.price || "---"}</b>
                              <br />
                            </td>
                            <td>
                              <b>{bookingSlot.capacity || "---"}</b>
                              <br />
                            </td>
                            <td>
                              <div className="social-links mt-2">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleClick4(bookingSlot.id)}
                                >
                                  <i className="bi bi-pencil"></i>
                                </a>
                                &nbsp;&nbsp;
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleDeleteVenue(bookingSlot.id)
                                  }
                                >
                                  <i className="bi bi-trash3"></i>
                                </a>
                                &nbsp;
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <h3 style={{ textAlign: "center" }}></h3>
                  )}
                </div>

                {showFields4 && (
                  <div>
                    <>
                      <div>
                        <div
                          class="col-md-12"
                          style={{ display: "flex", paddingLeft: "0px" }}
                        >
                          <div
                            class="form-group col-md-6"
                            style={{ paddingLeft: "0px" }}
                          >
                            <label class="small mb-1" for="inputFullName">
                              Select Date<font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="date"
                              // value={eachSlot.startDate}
                              value={bookingNewDate}
                              onChange={(e) =>
                                setBookingNewDate(e.target.value)
                              }
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFullName">
                              Select Start time <font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="time"
                              // value={eachSlot.startTime}
                              value={bookingNewStartTime}
                              onChange={(e) =>
                                setBookingNewStartTime(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div
                          class="col-md-12"
                          style={{ display: "flex", paddingLeft: "0px" }}
                        >
                          <div
                            class="form-group col-md-6"
                            style={{ paddingLeft: "0px" }}
                          >
                            <label class="small mb-1" for="inputFullName">
                              Select End Time<font color="red"></font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="time"
                              // value={eachSlot.endTime}
                              value={bookingNewEndTime}
                              onChange={(e) =>
                                setBookingNewEndTime(e.target.value)
                              }
                            />
                          </div>
                          <div class="form-group col-md-6">
                            <label class="small mb-1" for="inputFullName">
                              Enter Price <font color="red" style={{ fontSize: "10px" }}>
                      required
                    </font>
                            </label>
                            <input
                              class="form-control"
                              id="inputFullName"
                              type="number"
                              // value={eachSlot.price}
                              value={bookingNewPrice}
                              onChange={(e) =>
                                setBookingNewPrice(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <label class="small mb-1" for="inputFullName">
                            Enter Seating Capacity<font color="red"></font>
                          </label>
                          <input
                            class="form-control"
                            id="inputFullName"
                            type="number"
                            // value={eachSlot.capacity}
                            value={bookingNewCapacity}
                            onChange={(e) =>
                              setBookingNewCapacity(e.target.value)
                            }
                          />
                        </div>
                        <div style={{ display: "flex", marginBottom: "20px" }}>
                          <button
                            type="button"
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "#000",
                              color: "#fff",
                              border: "none",
                              padding: "5px 20px",
                            }}
                            onClick={() =>
                              handleUpdateVenue(
                                id2,
                                bookingNewDate,
                                bookingNewStartTime,
                                bookingNewEndTime,
                                bookingNewPrice,
                                bookingNewCapacity
                              )
                            }
                          >
                            Update
                          </button>
                          <button
                            type="button"
                            onClick={handleCancelClick4}
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              borderRadius: "4px",
                              backgroundColor: "lightgrey",
                              color: "#000",
                              border: "none",
                              padding: "5px 20px",
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </>
                  </div>
                )}
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status:<font color="red" style={{ fontSize: "10px" }}>
                     * required
                    </font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      onChange={handleStatusChange}
                      options={Status}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                // onClick={() => {
                //   onClose();
                //   // reloadPage();
                // }}
                onClick={handleClose}
              >
                Close
              </button>
              {isAddingvenue === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAddVenue}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_VenueownerVenue;
