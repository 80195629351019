import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Update_Customer_Rating from "../../Components/Customer_comp/update_custRating.js";
import Delete_rating from "../../Components/Customer_comp/delete_rating.js";
import "../../Api/apiUrls.js";
import { getCustomerReview } from "../../Api/apiUrls";
function Rating() {
  const [review, setReview] = useState([]);
  const [selectedReviewId, setSelectedReviewId] = useState(null);
  const [selectedTittle, setSelectedTittle] = useState(null);
  const [selectedReview, setSelectedReview] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalDelete_ratingIsOpen, setDelete_ratingIsOpen] = useState("");
  const [
    modalUpdate_Customer_RatingIsOpen,
    setUpdate_Customer_RatingModalIsOpen,
  ] = useState("");

  const renderStars = (review) => {
    const maxReview = 5; // Maximum rating (number of stars)
    const starIcons = [];

    for (let i = 1; i <= maxReview; i++) {
      // Determine whether to render a filled or empty star based on the rating
      const isFilled = i <= review;

      // Add the appropriate star icon (you can customize the star icons as needed)
      starIcons.push(
        <i
          key={i}
          className={`bi bi-star${isFilled ? "-fill" : ""}`}
          style={{ color: isFilled ? "gold" : "gray" }}
        ></i>
      );
    }

    return starIcons;
  };

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const openUpdate_Customer_Rating = (reviewId, title, review, rating) => {
    setSelectedReviewId(reviewId);
    setSelectedTittle(title);
    setSelectedReview(review);
    setSelectedRating(rating);
    closeUpdate_Customer_Rating();
    setUpdate_Customer_RatingModalIsOpen(true);
  };

  const closeUpdate_Customer_Rating = () => {
    setUpdate_Customer_RatingModalIsOpen(false);
  };
  const openDelete_rating = (reviewId) => {
    closeDelete_rating();
    setSelectedReviewId(reviewId);
    setDelete_ratingIsOpen(true);
  };
  const closeDelete_rating = () => {
    setDelete_ratingIsOpen(null);
  };

  const customerId = localStorage.getItem("user_id");

  useEffect(() => {
    fetch(getCustomerReview + customerId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const review = data.data.Review;
        console.log(review);
        setReview(review);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        // console.log("Review value:", Rating);
      });
  }, []);
  const formatDateTime = (dateTimeString) => {
    const optionsDate = { day: "numeric", month: "short", year: "numeric" };
    const optionsTime = {
      hour: "2-digit",
      minute: "2-digit",
    };

    const dateTime = new Date(dateTimeString);
    const formattedDate = dateTime.toLocaleDateString(undefined, optionsDate);
    const formattedTime = dateTime.toLocaleTimeString(undefined, optionsTime);

    return `${formattedDate} ${formattedTime}`;
  };
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const filteredAppointments = review.filter((review) => {
      // Check if any value in the appointment matches the search query
      const values = Object.values(review).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );

      return isSearchMatch;
    });

    setFilteredAppointments(filteredAppointments);
  }, [review, searchQuery]);
  return (
    <div>
      <Header></Header>
      {modalUpdate_Customer_RatingIsOpen && (
        <Update_Customer_Rating onClose={closeUpdate_Customer_Rating} />
      )}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Review Rating</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Review-Rating</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : review.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Title</th>
                      <th scope="col">Review</th>
                      <th scope="col">Rating</th>
                      <th scope="col">Instructor Details</th>
                      <th scope="col">Slot Date/Time</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created On</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredAppointments.map((review, index) => (
                      <tr key={review.id}>
                        <th scope="row">{index + 1}</th>

                        <td>
                          {review.type === "Venue" &&
                          review.venue &&
                          review.venue.name
                            ? review.venue.name
                            : review.type === "Workshop" &&
                              review.workshop &&
                              review.workshop.name
                            ? review.workshop.name
                            : "--"}
                          <br />

                          <br />
                        </td>
                        <td>
                          {review.review || "-"}
                          <br />
                        </td>
                        <td>
                          {renderStars(review.rating)}
                          <br />
                        </td>
                        <td>
                          {review.type === "Workshop" &&
                          review.workshop.workshopInstructor ? (
                            <>
                              {review.workshop.workshopInstructor.name}
                              <br />
                              {review.workshop.workshopInstructor.email} <br />
                              {review.workshop.workshopInstructor.mobileNumber}
                              {/* Add other instructor details as needed */}
                            </>
                          ) : review.type === "Venue" && review.venue ? (
                            <>
                              {review.venue.venue_owner.name}
                              <br />
                              {review.venue.venue_owner.email}
                              <br />
                              {review.venue.venue_owner.mobileNumber}
                              {/* Add other venue details as needed */}
                            </>
                          ) : (
                            "--"
                          )}
                        </td>
                        {/* <td>
                          {formatDateTime(review.appointment?.dateTime)}
                          <br />
                        </td> */}
                        <td>
                          {review.type === "Workshop" &&
                          review.appointment.workshopSlot ? (
                            <>
                              {review.appointment.workshopSlot.date}
                              {review.appointment.workshopSlot.startTime}-
                              {review.appointment.workshopSlot.endTime}
                              <br />
                              {/* Add other instructor details as needed */}
                            </>
                          ) : review.type === "Venue" && review.venue ? (
                            <>
                              {review.appointment?.bookingSlot?.startDate}
                              <br />
                              {review.appointment?.bookingSlot?.startTime}-
                              {review.appointment?.bookingSlot?.endTime}
                            </>
                          ) : (
                            "--"
                          )}
                        </td>

                        <td>
                          {review.type || "-"}
                          <br />
                        </td>
                        <td>
                          {review.status === 1
                            ? "Approved"
                            : review.status === 0
                            ? "Pending"
                            : review.status === -1
                            ? "Rejected"
                            : "No Name"}

                          <br />
                        </td>

                        <td>
                          {/* <b>{review.created_at || "No Name"}</b> */}
                          {formatCreatedAt(review.created_at)}
                          <br />
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdate_Customer_Rating(
                                  review.id,
                                  review.title,
                                  review.review,
                                  review.rating
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openDelete_rating(review.id)}
                            >
                              <i class="bi bi-trash3"></i>
                            </a>
                            &nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
              {modalUpdate_Customer_RatingIsOpen && (
                <Update_Customer_Rating
                  reviewId={selectedReviewId}
                  title={selectedTittle}
                  review={selectedReview}
                  rating={selectedRating}
                  onClose={closeUpdate_Customer_Rating}
                />
              )}
              {modalDelete_ratingIsOpen && (
                <Delete_rating
                  reviewId={selectedReviewId}
                  onClose={closeDelete_rating}
                />
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {review.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Rating;
