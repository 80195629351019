import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_customer from "../../Components/Admin_components/add_customer.js";
import Delete_customer from "../../Components/Admin_components/delete_customer.js";
import "../../Api/apiUrls";
import { getAdminCustomer } from "../../Api/apiUrls";
function Customer() {
  const [modalAdd_customerIsOpen, setAdd_customerModalIsOpen] = useState("");
  const [modalDelete_customerIsOpen, setDelete_customerIsOpen] =
    useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [customer, setCustomer] = useState([]);
  const Status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];

  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredCustomers = customer.filter((cust) => {
    const statusFilter =
      selectedStatus === "All" || cust.status === selectedStatus;
    const searchFilter = Object.values(cust).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return statusFilter && searchFilter;
  });
  const openAdd_customer = () => {
    closeAdd_customer();

    setAdd_customerModalIsOpen(true);
  };

  const closeAdd_customer = () => {
    setAdd_customerModalIsOpen(false);
  };
  const openDelete_customer = (customerId) => {
    setSelectedCustomerId(customerId);
    closeDelete_customer();
    setDelete_customerIsOpen(true);
  };

  const closeDelete_customer = () => {
    setDelete_customerIsOpen(null);
  };
  useEffect(() => {
    fetch(getAdminCustomer)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const customer = data.data.users;
        console.log(customer);
        setCustomer(customer);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("customer value:", customer);
      });
  }, []);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  return (
    <div>
      <Header></Header>
      {modalAdd_customerIsOpen && <Add_customer onClose={closeAdd_customer} />}
      {modalDelete_customerIsOpen && (
        <Delete_customer
          customerId={selectedCustomerId}
          onClose={closeDelete_customer}
        />
      )}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Customer</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Customer</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div className="child ml-2">
                  <Select
                    options={Status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    {/* <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_customer}
                    >
                      <i class="bi bi-person-add"></i> Add Customer
                    </button> */}
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>

            {isLoading === true ? (
              <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                <TailSpin color="black" width={50} />
              </div>
            ) : customer.length > 0 ? (
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Details</th>
                    <th scope="col">Following Artist</th>
                    <th scope="col">Review</th>
                    <th scope="col">Wishlist</th>
                    <th scope="col">Appointment</th>
                    <th scope="col">Location</th>
                    <th scope="col">Saved</th>

                    <th scope="col">Status</th>
                    <th scope="col">Created On</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody id="tableBody">
                  {filteredCustomers.map((customer, index) => (
                    <tr key={customer.id}>
                      <th scope="row">{index + 1}</th>

                      <td>
                        {customer.name || "--"}
                        <br />
                        {customer.mobileNumber || "--"}
                        <br />
                        {customer.email || "--"}
                        <br />
                      </td>

                      <td>
                        {customer.following_count || "--"}
                        <br />
                      </td>

                      <td>
                        {customer.review_count || "--"}
                        <br />
                      </td>
                      <td>
                        {customer.wishlist_count || "--"}
                        <br />
                      </td>
                      <td>
                        {customer.appointment_count || "--"}
                        <br />
                      </td>
                      <td>
                        {customer.city || "--"}
                        <br />
                      </td>
                      <td>
                        {customer.saved_count || "--"}
                        <br />
                      </td>
                      <td>
                        {customer.status || "--"}
                        <br />
                      </td>
                      <td>{formatCreatedAt(customer.created_at)}</td>
                      <td>
                        <div className="social-links mt-2">
                          &nbsp;
                          <a onClick={() => openDelete_customer(customer.id)}>
                            <i class="bi bi-trash3"></i>
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#595857",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredCustomers.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Customer;
