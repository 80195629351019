import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_VenueownerVenue from "../../Components/Venue_owner_comp/add_venue.js";
import Update_VenueownerVenue from "../../Components/Venue_owner_comp/update_venue.js";
import Delete_VenueownerVenue from "../../Components/Venue_owner_comp/delete_venues.js";
import Venueowner_booking_slots from "../../Components/Venue_owner_comp/Venue_booking_slots.js";
import packagePopup from "../../Components/Venue_owner_comp/packagePopup.js";
import PackagePopup from "../../Components/Venue_owner_comp/packagePopup.js";
import VenueTotalAppointment from "../../Components/Admin_components/venueTotalAppointment.js";
import VenueActiveAppointment from "../../Components/Admin_components/venueActiveAppointment.js";
import VenueReview from "../../Components/Admin_components/venueReview.js";
import VenueWishlist from "../../Components/Admin_components/venueWishlist.js";
import "../../Api/apiUrls.js";
import { API_URL, getVenueByVenueOwnerId } from "../../Api/apiUrls";

function VenueownerVenue() {
  const [checked, setChecked] = useState(0);
  const [checked2, setChecked2] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [venueData, setVenueData] = useState([]);
  const [dateTime, setDateTime] = useState("");
  const [id, setId] = useState("");
  const [id2, setId2] = useState("");

  const [venueSelectedName, setSelectedVenueName] = useState(null);
  const [venueSelectedShortDescription, setSelectedVenueShortDescription] =
    useState(null);
  const [venueSelectedDescription, setSelectedVenueDescription] =
    useState(null);
  const [venueSelectedInclusion, setSelectedVenueInclusion] = useState(null);
  const [venueSelectedCancellation, setSelectedVenueCancellation] =
    useState(null);
  const [venueSelectedSeats, setSelectedVenueSeats] = useState(null);
  const [venueSelectedDuration, setSelectedVenueDuration] = useState(null);
  const [venueSelectedPrice, setSelectedVenuePrice] = useState(null);
  const [venueSelectedAddress, setSelectedVenueAddress] = useState(null);
  const [venueSelectedAddress2, setSelectedVenueAddress2] = useState(null);
  const [venueSelectedPostalCode, setSelectedVenuePostalCode] = useState(null);
  const [venueSelectedGoogleCode, setSelectedVenueGoogleCode] = useState(null);
  const [venueSelectedEmail, setSelectedVenueEmail] = useState(null);
  const [venueSelectedPhone, setSelectedVenuePhone] = useState(null);
  const [venueSelectedWebsite, setSelectedVenueWebsite] = useState(null);
  const [venueSelectedCategory, setVenueSelectedCategory] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [venueSelectedCity, setVenueSelectedCity] = useState(null);
  const [venueSelectedLocation, setVenueSelectedLocation] = useState(null);
  const [selectedVenueStatus, setSelectedVenueStatus] = useState(null);
  const [
    modalVenueowner_booking_slotsIsOpen,
    setVenueowner_booking_slotsIsOpen,
  ] = useState("");

  const [
    modalVenueTotalAppointmentIsOpen,
    setVenueTotalAppointmentModalIsOpen,
  ] = useState("");

  const openVenueTotalAppointment = (id) => {
    setId(id);
    setVenueTotalAppointmentModalIsOpen(true);
  };
  const closeVenueTotalAppointment = () => {
    setVenueTotalAppointmentModalIsOpen(false);
  };

  const [
    modalVenueActiveAppointmentIsOpen,
    setVenueActiveAppointmentModalIsOpen,
  ] = useState("");

  const openVenueActiveAppointment = (id) => {
    setId(id);
    setVenueActiveAppointmentModalIsOpen(true);
  };
  const closeVenueActiveAppointment = () => {
    setVenueActiveAppointmentModalIsOpen(false);
  };

  const [modalVenueReviewIsOpen, setVenueReviewModalIsOpen] = useState("");

  const openVenueReview = (id) => {
    setId(id);
    setVenueReviewModalIsOpen(true);
  };
  const closeVenueReview = () => {
    setVenueReviewModalIsOpen(false);
  };

  const [modalVenueWishlistIsOpen, setVenueWishlistModalIsOpen] = useState("");
  const openVenueWishlist = (id) => {
    setId(id);
    setVenueWishlistModalIsOpen(true);
  };
  const closeVenueWishlist = () => {
    setVenueWishlistModalIsOpen(false);
  };

  const [modalPackagePopupIsOpen, setPackagePopupIsOpen] = useState("");
  const [modalAdd_VenueownerVenueIsOpen, setAdd_VenueownerVenueModalIsOpen] =
    useState("");
  const [
    modalUpdate_VenueownerVenueIsOpen,
    setUpdate_VenueownerVenueModalIsOpen,
  ] = useState(false);
  const [modalDelete_VenueownerVenueIsOpen, setDelete_VenueownerVenueIsOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const filter = [
    { value: "All", label: "All" },
    { value: "Featured", label: "Featured" },
    { value: "Sponsored", label: "Sponsored" },
    // { value: "Gift", label: "Gift" },
  ];
  const month = [
    { value: "All", label: "All" },
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  const year = [
    { value: "All", label: "All" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
    { value: "2025", label: "2025" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const date = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");
    const milliseconds = String(now.getMilliseconds()).padStart(3, "0");

    const formattedDateTime = `${year}-${month}-${date} ${hours}:${minutes}:${seconds}.${milliseconds}`;
    return formattedDateTime;
  };
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const [filteredWorkshopData, setFilteredWorkshopData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  // useEffect to filter workshop data when filter options change
  useEffect(() => {
    const filteredData = venueData.filter((venue) => {
      const isFilter =
        !selectedFilter ||
        selectedFilter.value === "All" ||
        venue.filter === selectedFilter.value;
      const isStatus =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        venue.status === selectedStatus.value;
      const isMonth =
        !selectedMonth ||
        selectedMonth.value === "All" ||
        venue.created_at_month === selectedMonth.value;
      const isYear =
        !selectedYear ||
        selectedYear.value === "All" ||
        venue.created_at_year === selectedYear.value;
      const values = Object.values(venue).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );
      return isFilter && isStatus && isMonth && isYear && isSearchMatch;
    });

    setFilteredWorkshopData(filteredData);
  }, [
    selectedFilter,
    selectedStatus,
    selectedMonth,
    selectedYear,
    venueData,
    searchQuery,
  ]);

  const openAdd_VenueownerVenue = () => {
    closeAdd_VenueownerVenue();
    const currentDateTime = getCurrentDateTime();
    setDateTime(currentDateTime);
    setAdd_VenueownerVenueModalIsOpen(true);
  };

  const closeAdd_VenueownerVenue = () => {
    setAdd_VenueownerVenueModalIsOpen(false);
  };
  const openUpdate_VenueownerVenue = (
    venueId,
    venueName,
    venueShort,
    venueDescription,
    venueInclusion,
    venueCancellation,
    venueCategory,
    venueseats,
    venueDuration,
    venuePrice,
    venueAddress,
    venueAddress2,
    venueCity,
    venueLocation,
    venuePostal,
    venueGoogle,
    venueEmail,
    venuePhone,
    venueWebsite,
    venueStatus
  ) => {
    setSelectedUserId(venueId);
    setSelectedVenueName(venueName);
    setSelectedVenueShortDescription(venueShort);
    setSelectedVenueDescription(venueDescription);
    setSelectedVenueInclusion(venueInclusion);
    setSelectedVenueCancellation(venueCancellation);
    setVenueSelectedCategory(venueCategory);
    setSelectedVenueSeats(venueseats);
    setSelectedVenueDuration(venueDuration);
    setSelectedVenuePrice(venuePrice);
    setSelectedVenueAddress(venueAddress);
    setSelectedVenueAddress2(venueAddress2);
    setVenueSelectedCity(venueCity);
    setVenueSelectedLocation(venueLocation);
    setSelectedVenuePostalCode(venuePostal);
    setSelectedVenueGoogleCode(venueGoogle);
    setSelectedVenueEmail(venueEmail);
    setSelectedVenuePhone(venuePhone);
    setSelectedVenueWebsite(venueWebsite);
    setSelectedVenueStatus(venueStatus);
    closeUpdate_VenueownerVenue();
    setUpdate_VenueownerVenueModalIsOpen(true);
  };

  const closeUpdate_VenueownerVenue = () => {
    setUpdate_VenueownerVenueModalIsOpen(false);
  };
  const openDelete_VenueownerVenue = (venueId) => {
    setSelectedUserId(venueId);
    closeDelete_VenueownerVenue();
    setDelete_VenueownerVenueIsOpen(true);
  };

  const closeDelete_VenueownerVenue = () => {
    setDelete_VenueownerVenueIsOpen(null);
  };
  const openVenueowner_booking_slots = (id) => {
    setId(id);
    setVenueowner_booking_slotsIsOpen(true);
  };
  const closeVenueowner_booking_slots = () => {
    setVenueowner_booking_slotsIsOpen(false);
  };

  const openPackagePopup = (id2) => {
    setId2(id2);
    setPackagePopupIsOpen(true);
  };

  const closePackagePopup = () => {
    setPackagePopupIsOpen(false);
  };
  const venueOwnerId = localStorage.getItem("user_id");
  useEffect(() => {
    fetch(`${getVenueByVenueOwnerId}${venueOwnerId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const venue = data.data.Venue;
        setVenueData(venue);
        console.log(venue);
      })

      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div>
      <Header></Header>
      {modalAdd_VenueownerVenueIsOpen && (
        <Add_VenueownerVenue
          onClose={closeAdd_VenueownerVenue}
          dateTime={dateTime}
        />
      )}

      {modalPackagePopupIsOpen && (
        <PackagePopup onClose={closePackagePopup} id2={id2} />
      )}
      {modalUpdate_VenueownerVenueIsOpen && (
        <Update_VenueownerVenue
          venueId={selectedUserId}
          venueName={venueSelectedName}
          venueShort={venueSelectedShortDescription}
          venueDescription={venueSelectedDescription}
          venueInclusion={venueSelectedInclusion}
          venueCancellation={venueSelectedCancellation}
          venueCategory={venueSelectedCategory}
          venueseats={venueSelectedSeats}
          venueDuration={venueSelectedDuration}
          venuePrice={venueSelectedPrice}
          venueAddress={venueSelectedAddress}
          venueAddress2={venueSelectedAddress2}
          venueCity={venueSelectedCity}
          venueLocation={venueSelectedLocation}
          venuePostal={venueSelectedPostalCode}
          venueGoogle={venueSelectedGoogleCode}
          venueEmail={venueSelectedEmail}
          venuePhone={venueSelectedPhone}
          venueWebsite={venueSelectedWebsite}
          venueStatus={selectedVenueStatus}
          onClose={closeUpdate_VenueownerVenue}
        />
      )}
      {modalDelete_VenueownerVenueIsOpen && (
        <Delete_VenueownerVenue
          onClose={closeDelete_VenueownerVenue}
          venueId={selectedUserId}
        />
      )}
      {modalVenueowner_booking_slotsIsOpen && (
        <Venueowner_booking_slots
          onClose={closeVenueowner_booking_slots}
          id={id}
        />
      )}
      {modalVenueTotalAppointmentIsOpen && (
        <VenueTotalAppointment onClose={closeVenueTotalAppointment} id={id} />
      )}

      {modalVenueActiveAppointmentIsOpen && (
        <VenueActiveAppointment onClose={closeVenueActiveAppointment} id={id} />
      )}

      {modalVenueReviewIsOpen && (
        <VenueReview onClose={closeVenueReview} id={id} />
      )}

      {modalVenueWishlistIsOpen && (
        <VenueWishlist onClose={closeVenueWishlist} id={id} />
      )}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Venue</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/">Home</a>
              </li>
              <li class="breadcrumb-item active">Venue</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-12 col-md-12 col-lg-12 row">
                <div class="child col-md-2" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "37px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    placeholder="Search..."
                  />
                </div>
                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={filter}
                    placeholder="Filter By"
                    onChange={(selectedOption) =>
                      setSelectedFilter(selectedOption.value)
                    }
                  />
                </div>
                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={status}
                    placeholder="Select status"
                    onChange={(selectedOption) =>
                      setSelectedStatus(selectedOption.value)
                    }
                  />
                </div>

                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <Select
                    options={month}
                    placeholder="Select Month"
                    onChange={(selectedOption) =>
                      setSelectedMonth(selectedOption.value)
                    }
                  />
                </div>
                <div class="  col-md-2 " style={{ padding: "5px" }}>
                  <Select
                    options={year}
                    placeholder="Select Year"
                    onChange={(selectedOption) =>
                      setSelectedYear(selectedOption.value)
                    }
                  />
                </div>
                <div class=" col-md-2" style={{ padding: "5px" }}>
                  <a>
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_VenueownerVenue}
                    >
                      <i class="bi bi-person-add"></i> Add Venue
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "20px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : venueData.length > 0 ? (
                <table class="table table-striped " style={{ padding: "0rem" }}>
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>

                      {/* <th scope="col">Slots</th> */}
                      <th scope="col">Status</th>
                      <th scope="col">Venue Name</th>
                      {/* <th scope="col">Venue Provider</th> */}
                      {/* <th scope="col">Rating</th>
                      <th scope="col">Reviews</th> */}
                      <th scope="col">Share</th>
                      <th scope="col">Visit</th>
                      <th scope="col">Wishlisted</th>
                      {/* <th scope="col">Total Appointments</th>
                      <th scope="col">Appointment Active</th> */}
                      <th scope="col">Package Type</th>
                      <th scope="col">Status Of Package</th>

                      <th scope="col">Created On</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredWorkshopData.map((venue, index) => (
                      <tr key={venue.id}>
                        <th scope="row">{index + 1}</th>
                        {/* <td>{workshop.id}</td> */}
                        {/* 
                        <td>
                          <div className="social-links mt-2">
                            <a
                              onClick={() =>
                                openVenueowner_booking_slots(venue.id)
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                          </div>
                        </td> */}
                        <td>{venue.status}</td>
                        <td>{venue.name}</td>
                        {/* <td>
                          <b>
                            {venue.venue_owner ? venue.venue_owner.name : "-"}
                          </b>
                          <br />
                          <b>
                            {venue.venue_owner ? venue.venue_owner.email : "-"}
                          </b>
                          <br />
                          <b>
                            {venue.venue_owner
                              ? venue.venue_owner.mobileNumber
                              : "-"}
                          </b>
                        </td> */}
                        {/* <td>{venue.ratings_count}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openVenueReview(venue.id)}
                          >
                            {venue.reviews_count}
                          </a>
                        </td> */}
                        <td>{venue.share_count}</td>
                        <td>{venue.visit}</td>
                        <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openVenueWishlist(venue.id)}
                          >
                            {venue.wishlist_count}
                          </a>
                        </td>
                        {/* <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openVenueTotalAppointment(venue.id)}
                          >
                            {venue.total_appointments}
                          </a>
                        </td> */}
                        {/* <td>
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openVenueActiveAppointment(venue.id)}
                          >
                            {" "}
                            {venue.active_appointments}
                          </a>
                        </td> */}
                        <td>{venue.package ? venue.package.duration : "-"}</td>
                        <td>{venue.package ? venue.package.status : "-"}</td>

                        <td>{formatCreatedAt(venue.created_at)}</td>

                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdate_VenueownerVenue(
                                  venue.id,
                                  venue.name,
                                  venue.short_description,
                                  venue.description,
                                  venue.inclusion_amenities,
                                  venue.cancellation_policy,
                                  {
                                    value: venue.category.id,
                                    label: venue.category.name,
                                  },
                                  venue.duration,
                                  venue.capacity,
                                  venue.price,
                                  venue.address_line1,
                                  venue.address_line2,
                                  {
                                    value: venue.city.id,
                                    label: venue.city.name,
                                  },
                                  {
                                    value: venue.area.id,
                                    label: venue.area.name,
                                  },
                                  venue.pincode,
                                  venue.google_location_link,
                                  venue.email,
                                  venue.mobileNumber,
                                  venue.website,
                                  { value: venue.id, label: venue.status }
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                            &nbsp;&nbsp;
                            {/* <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openDelete_VenueownerVenue(venue.id)
                              }
                            >
                              <i class="bi bi-trash3"></i>
                            </a> */}
                            &nbsp;
                            <a
                              style={{ cursor: "pointer" }}
                              // href={`/venueOwner/packages`}

                              onClick={() => openPackagePopup(venue.id)}
                            >
                              <i class="bi bi-box-arrow-in-up"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredWorkshopData.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default VenueownerVenue;
