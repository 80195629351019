import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { addAdminEmployee } from "../../Api/apiUrls";


function AddEmployee({ onClose }) {
    const [modalAddEmployeeIsOpen, setAddEmployeeModalIsOpen] = useState("");
    const [eventStatus, setEventStatus] = useState("");
    const [isAddingnews, setIsAddingnews] = useState(false);
    const [selectedAccess, setSelectedAccess] = useState([]);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    function reloadPage() {
        window.location.reload();
    }
    const closeAddEmployee = () => {
        setAddEmployeeModalIsOpen(false);
    };
    const status = [
        { value: "Active", label: "Active" },
        { value: "Inactive", label: "Inactive" },
    ];
    const access = [
        { value: "dashboard", label: "Dashboard" },
        { value: "workshop", label: "Workshop" },
        { value: "venue", label: "Venue" },
        { value: "blog", label: "Blog" },
        { value: "pages", label: "Pages" },
        { value: "categories", label: "Categories" },
        { value: "sub_categories", label: "Sub-categories" },
        { value: "instructor", label: "Instructor" },
        { value: "venue_owner", label: "Venue Provider" },
        { value: "customer", label: "Customer" },
        { value: "review", label: "Review" },
        { value: "location", label: "Location" },
        { value: "packageManagement", label: "Package Management" },
        { value: "transaction", label: "Transaction" },
        { value: "appointment", label: "Appointment" },
        { value: "Banner", label: "Banner" },
        { value: "review_and_rating" , label: "Review and Rating" },
        { value: "aboutUs" , label: "About Us" },
        { value: "subscribersList" , label: "Subscribers List" },
        { value: "happyCustomer" , label: "Happy Customer" },
        { value: "Faq" , label: "Faq" },
        { value: "cancellationPolicy" , label: "Cancellation Policy" },
        { value: "privacyPolicy" , label: "Privacy Policy" },
        { value: "terms&conditions" , label: "Terms & Conditions" },
        { value: "weAreHiring", label: "We are Hiring" },
        { value: "questions", label: "Questions" },
        { value: "messages", label: "Messages" },
        { value: "instructorPayOut", label: "Instructor PayOut" },
        { value: "customerPayOut", label: "Customer PayOut" },
        { value: "invoice", label: "Invoice" },
        { value: "events", label: "Events" },
        { value: "employee", label: "Employee" },
    ];
    const handleStatusChange = (selectedOption) => {
        setEventStatus(selectedOption.value);
    };


    async function addEmployee(
        name,
        email,
        password,
        eventStatus,

    ) {
        try {
            const formData = new FormData();
            const accessValues = selectedAccess.map(option => option.value);
            formData.append("name", name);
            formData.append("email", email);
            formData.append("mobileNumber", "8087848067");
            formData.append("password", password);
            formData.append("status", eventStatus);
            formData.append("type_of_user", "Employee");
            formData.append("access", "["+accessValues+"]");

            const response = await fetch(addAdminEmployee, {
                method: "POST",
                body: formData,
            });
            const responseData = await response.json();
            if (response.ok) {
                if (responseData.status === 200) {
                    return true;
                } else {
                    return false;
                }
            } else {
                throw new Error("Failed to add Event");
            }
        } catch (error) {
            console.error("Error adding Event:", error);
            return false;
        }
    }


    const handleAddEmployee = async () => {

        setIsAddingnews(true);

        const result = await addEmployee(
            name,
            email,
            password,
            eventStatus,
        );
        setIsAddingnews(false);
        if (result) {
            alert("Employee Added Successfully");
            window.location.reload();
            onClose();
        } else {
            alert("Error Adding Employee");
        }
    };

    // const handleSubmit = (e) => {
    //     e.preventDefault();
    //     const accessValues = selectedAccess.map(option => option.value);

    //     // Process form data
    //     const formData = {
    //         access: accessValues,
    //     };

    //     console.log('Form Data:', formData);
    //     // You can send formData to your backend here
    // };
    return (
        <div>
            {modalAddEmployeeIsOpen && (
                <AddEmployee onClose={closeAddEmployee} />
            )}

            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Add Employee</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Name<font color="red"></font>
                                    </label>
                                    <input
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}

                                    />
                                </div>


                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Email/Username<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        style={{ height: "100px" }}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}

                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Password<font color="red"></font>
                                    </label>
                                    <textarea
                                        class="form-control"
                                        id="inputFullName"
                                        type="text"
                                        placeholder=""
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}

                                    />
                                </div>
                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="inputAccess">
                                        Access<font color="red"></font>
                                    </label>
                                    <Select
                                        isMulti
                                        id="inputAccess"
                                        options={access}
                                        value={selectedAccess}
                                        onChange={setSelectedAccess}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div class="form-group">
                                    <label class="small mb-1" for="inputFullName">
                                        Status<font color="red" style={{ fontSize: "10px" }}>

                                        </font>
                                    </label>
                                    <div class="child ml-2">
                                        <Select
                                            onChange={handleStatusChange}
                                            options={status}
                                        ></Select>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingnews === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleAddEmployee}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default AddEmployee
