import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_Instructor_Review from "../../Components/instructor_comp/add_review.js";
import Update_Instructor_Review from "../../Components/instructor_comp/update_review.js";
import Delete_Instructor_Review from "../../Components/instructor_comp/delete_review.js";
import Instructor_Review_eye from "../../Components/instructor_comp/eye_review.js";
function Instructor_Review() {
  const [
    modalInstructor_Review_eyeIsOpen,
    setInstructor_Review_eyeModalIsOpen,
  ] = useState("");
  const [
    modalAdd_Instructor_ReviewIsOpen,
    setAdd_Instructor_ReviewModalIsOpen,
  ] = useState("");
  const [
    modalUpdate_Instructor_ReviewIsOpen,
    setUpdate_Instructor_ReviewModalIsOpen,
  ] = useState(false);
  const [
    modalDelete_Instructor_ReviewIsOpen,
    setDelete_Instructor_ReviewIsOpen,
  ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const status = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Approve", label: "Approve" },
    { value: "Reject", label: "Reject" },
  ];
  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const drop = [
    { value: " Website", label: " Website" },
    { value: " Admin", label: " Admin" },
  ];
  const openInstructor_Review_eye = () => {
    closeInstructor_Review_eye();
    setInstructor_Review_eyeModalIsOpen(true);
  };

  const closeInstructor_Review_eye = () => {
    setInstructor_Review_eyeModalIsOpen(false);
  };
  const openAdd_Instructor_Review = () => {
    closeAdd_Instructor_Review();
    setAdd_Instructor_ReviewModalIsOpen(true);
  };

  const closeAdd_Instructor_Review = () => {
    setAdd_Instructor_ReviewModalIsOpen(false);
  };
  const openUpdate_Instructor_Review = () => {
    closeUpdate_Instructor_Review();
    setUpdate_Instructor_ReviewModalIsOpen(true);
  };

  const closeUpdate_Instructor_Review = () => {
    setUpdate_Instructor_ReviewModalIsOpen(false);
  };
  const openDelete_Instructor_Review = (userId) => {
    setSelectedUserId(userId);
    closeDelete_Instructor_Review();
    setDelete_Instructor_ReviewIsOpen(true);
  };

  const closeDelete_Instructor_Review = () => {
    setDelete_Instructor_ReviewIsOpen(null);
  };

  return (
    <div>
      <Header></Header>
      {modalAdd_Instructor_ReviewIsOpen && (
        <Add_Instructor_Review onClose={closeAdd_Instructor_Review} />
      )}
      {modalInstructor_Review_eyeIsOpen && (
        <Instructor_Review_eye onClose={closeInstructor_Review_eye} />
      )}
      {modalUpdate_Instructor_ReviewIsOpen && (
        <Update_Instructor_Review onClose={closeUpdate_Instructor_Review} />
      )}
      {modalDelete_Instructor_ReviewIsOpen && (
        <Delete_Instructor_Review
          userId={selectedUserId}
          onClose={closeDelete_Instructor_Review}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Review</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Review</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                {/* <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div> */}
                <div class="child ml-2">
                  <Select options={status} placeholder="Select Status"></Select>
                </div>
                <div class="child ml-2">
                  <Select options={type} placeholder="Select Type"></Select>
                </div>
              </div>

              {/* <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_review}
                    >
                      <i class="bi bi-person-add"></i> Add Review
                    </button>
                  </a>
                </div>
              </div> */}
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <table class="table table-striped  ">
              <thead>
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Message</th>
                  <th scope="col">User</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>

                  {
                    <td>
                      <div className="social-links mt-2">
                        <a>
                          <i
                            class="bi bi-eye"
                            style={{ cursor: "pointer" }}
                            onClick={openInstructor_Review_eye}
                          ></i>
                        </a>
                        &nbsp;&nbsp;
                      </div>
                    </td>
                  }
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                  <td>@mdo</td>
                  <td>
                    <div className="social-links mt-2">
                      <a>
                        <i
                          class="bi bi-eye"
                          style={{ cursor: "pointer" }}
                          onClick={openInstructor_Review_eye}
                        ></i>
                      </a>
                      &nbsp;&nbsp;
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                  <td>@mdo</td>
                  <td>
                    <div className="social-links mt-2">
                      <a>
                        <i
                          class="bi bi-eye"
                          style={{ cursor: "pointer" }}
                          onClick={openInstructor_Review_eye}
                        ></i>
                      </a>
                      &nbsp;&nbsp;
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Instructor_Review;
