import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";

import { TailSpin } from "react-loader-spinner";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import "../../Api/apiUrls";
import Update_InstructorPayout from "../../Components/Admin_components/update_instructorPayout.js";
import { getAdminInstructorPayout } from "../../Api/apiUrls";
function InstructorPayOut() {
  const [payout, setPayout] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [
    modalUpdate_InstructorPayoutIsOpen,
    setUpdate_InstructorPayoutModalIsOpen,
  ] = useState("");
  const [selectedAccountBalance, setSelectedAccountBalance] = useState(null);
  const [selectedPaymentDone, setSelectedPaymentDone] = useState(null);
  const [selectedPayoutStatus, setInstructorPayoutStatus] = useState(null);
  const [selectedPayoutId, setSelectedPayoutId] = useState(null);
  const openUpdate_instructorPayout = (
    instructorPayoutId,
    accountBalance,
    paymentDone,
    instructorPayoutStatus
  ) => {
    setSelectedPayoutId(instructorPayoutId);
    setSelectedAccountBalance(accountBalance);
    setSelectedPaymentDone(paymentDone);
    setInstructorPayoutStatus(instructorPayoutStatus);

    closeUpdate_instructorPayout();
    setUpdate_InstructorPayoutModalIsOpen(true);
  };

  const closeUpdate_instructorPayout = () => {
    setUpdate_InstructorPayoutModalIsOpen(false);
  };
  useEffect(() => {
    fetch(getAdminInstructorPayout)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const payout = data.data.InstructorOrOwnerPayOut;
        setPayout(payout);
        console.log(payout);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredInstPayout = Array.isArray(payout)
    ? payout.filter((payout) => {
        const values = Object.values(payout).map((value) =>
          typeof value === "string" ? value.toLowerCase() : ""
        );
        const isSearchMatch = values.some((value) =>
          value.includes(searchQuery.toLowerCase())
        );
        return isSearchMatch;
      })
    : [];
  return (
    <div>
      <Header></Header>
      {modalUpdate_InstructorPayoutIsOpen && (
        <Update_InstructorPayout
          onClose={closeUpdate_instructorPayout}
          instructorPayoutId={selectedPayoutId}
          accountBalance={selectedAccountBalance}
          paymentDone={selectedPaymentDone}
          instructorPayoutStatus={selectedPayoutStatus}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Instructor PayOut</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : payout.length > 0 ? (
                <table class="table  table-striped ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Listing type</th>
                      <th scope="col">Listing Name</th>
                      <th scope="col">Listing Id</th>
                      <th scope="col">Instructor Details </th>
                      <th scope="col">Bank details</th>
                      <th scope="col">Appointment Date/Time</th>
                      <th scope="col">Appointment status</th>
                      <th scope="col">Amount Received</th>
                      <th scope="col">Customer Details</th>
                      <th scope="col">Transaction details</th>
                      <th scope="col">Account Balance</th>
                      <th scope="col">Payment Done</th>
                      <th scope="col">Payout Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredInstPayout.map((payout, index) => (
                      <tr key={payout.id}>
                        <th scope="row">{index + 1}</th>

                        <td>
                          <b>{payout.listing_type || "-"}</b>
                        </td>
                        <td>
                          <b>
                            {" "}
                            {payout.venue
                              ? payout.venue.name || "-"
                              : payout.workshop
                              ? payout.workshop.name || "-"
                              : "-"}
                          </b>
                        </td>
                        <td>
                          {" "}
                          <b> {payout.id} </b>
                        </td>
                        <td>
                          <b> {payout.user ? payout.user.name || "-" : "-"}</b>
                          <b>
                            {" "}
                            {payout.user
                              ? payout.user.mobileNumber || "-"
                              : "-"}
                          </b>
                          <b> {payout.user ? payout.user.email || "-" : "-"}</b>
                        </td>
                        <td>
                          {" "}
                          <b>
                            {" "}
                            {payout.user ? payout.user.bank_name || "-" : "-"}
                          </b>
                          <b>
                            {" "}
                            {payout.user
                              ? payout.user.bank_account_number || "-"
                              : "-"}
                          </b>
                          <b>
                            {" "}
                            {payout.user
                              ? payout.user.bank_ifsc_code || "-"
                              : "-"}
                          </b>
                          <b>
                            {" "}
                            {payout.user
                              ? payout.user.bank_account_type || "-"
                              : "-"}
                          </b>
                        </td>
                        <td>
                          {" "}
                          {payout.booking_slot
                            ? `${payout.booking_slot.startDate} ${payout.booking_slot.startTime}`
                            : payout.workshop_slot
                            ? payout.workshop_slot.date +
                              " " +
                              payout.workshop_slot.startTime
                            : "-"}{" "}
                        </td>
                        <td>
                          <b>
                            {" "}
                            {payout.booking_slot
                              ? payout.booking_slot.status || "--"
                              : payout.workshop_slot
                              ? payout.workshop_slot.status || "--"
                              : "No Status"}{" "}
                          </b>
                        </td>
                        <td>
                          {" "}
                          <b>{payout.total_amount_received} </b>
                        </td>
                        <td>
                          {payout.appointment.user?.name}
                          <br />
                          {payout.appointment.user?.email}
                          <br />
                          {payout.appointment.user?.mobileNumber}
                        </td>
                        <td>
                          {" "}
                          <b>OrderId:</b> {payout.transaction.order_id || "-"}
                          <br />
                          <b>PayId:</b> {payout.transaction.payment_id || "-"}
                        </td>
                        <td>{payout.account_balance || "-"}</td>
                        <td>{payout.payment_done || "-"}</td>
                        <td>
                          {" "}
                          <b>{payout.payout_status} </b>
                        </td>
                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                openUpdate_instructorPayout(
                                  payout.id,
                                  payout.account_balance,
                                  payout.payment_done,
                                  {
                                    value: payout.id,
                                    label: payout.payout_status,
                                  }
                                )
                              }
                            >
                              <i class="bi bi-pencil"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#595857",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredInstPayout.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default InstructorPayOut;
