import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_packages.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { updateInstructorPayout } from "../../Api/apiUrls";
function Update_InstructorPayout({
  instructorPayoutId,
  accountBalance,
  paymentDone,
  instructorPayoutStatus,
}) {
  const [
    modalUpdate_InstructorPayoutIsOpen,
    setUpdate_InstructorPayoutModalIsOpen,
  ] = useState("");
  const [newAccountBalance, setNewAccountBalance] = useState(accountBalance);
  const [newPaymentDone, setNewPaymentDone] = useState(paymentDone);
  const [newInstructorPayoutStatus, setNewInstructorPayoutStatus] = useState(
    instructorPayoutStatus
  );
  const [isUpdatingInstructorPayout, setIsUpdatingInstructorPayout] =
    useState(false);

  const status = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Rejected", label: "Rejected" },
  ];
  const closeUpdate_instructorPayout = () => {
    setUpdate_InstructorPayoutModalIsOpen(false);
  };

  const handleStatusChange = (selectedOption) => {
    setNewInstructorPayoutStatus(selectedOption);
  };

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      // onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  // Log author options for debugging

  const handleUpdate = () => {
    setIsUpdatingInstructorPayout(true);
    const formData = new FormData();
    formData.append("InstructorOrOwnerPayOut_id", instructorPayoutId);
    formData.append("account_balance", newAccountBalance);
    formData.append("payment_done", newPaymentDone);
    formData.append("payout_status", newInstructorPayoutStatus.label);

    fetch(`${updateInstructorPayout}`, {
      method: "PUT",

      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated!!");
          setIsUpdatingInstructorPayout(false);
          window.location.reload();
          // onClose();
        } else {
          console.error("Error updating Instructor Payout", data.message);
          setIsUpdatingInstructorPayout(false);
        }
      })
      .catch((error) => {
        console.error("Error updating Instructor Payout", error);
        setIsUpdatingInstructorPayout(false);
      });
  };

  return (
    <div>
      {modalUpdate_InstructorPayoutIsOpen && (
        <Update_InstructorPayout onClose={closeUpdate_instructorPayout} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Instructor Payout</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  // onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Account Balance<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    placeholder=""
                    value={newAccountBalance}
                    onChange={(e) => setNewAccountBalance(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Payment Done<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    placeholder=""
                    value={newPaymentDone}
                    onChange={(e) => setNewPaymentDone(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Payout Status: <font color="red"></font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      placeholder="Status"
                      options={status}
                      value={newInstructorPayoutStatus}
                      onChange={handleStatusChange}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  // onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isUpdatingInstructorPayout === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_InstructorPayout;
