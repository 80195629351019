import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_packages.css";
import Select from "react-select";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls.js";
import {
  API_URL,
  getBookingSlotByVenueId,
  addBookingSlot,
  getBookingSlotById,
  deleteBookingSlotById,
  updateBookingSlot,
} from "../../Api/apiUrls";

function Venueowner_booking_slots({ onClose, id, dateTime }) {
  const [
    modalVenueowner_booking_slotsIsOpen,
    setVenueowner_booking_slotsModalIsOpen,
  ] = useState("");

  const [
    isAddingVenueowner_booking_slots,
    setIsAddingVenueowner_booking_slots,
  ] = useState(false);

  const closeVenueowner_booking_slots = () => {
    setVenueowner_booking_slotsModalIsOpen(false);
  };
  const [showFields2, setShowFields2] = useState(false);
  const [isAddingVenue_booking_slots, setIsAddingVenue_booking_slots] =
    useState(false);
  const [datetime, setDatetime] = useState("");
  const [showFields3, setShowFields3] = useState(false);
  const [showFields4, setShowFields4] = useState(false);
  const [isAddingBookingSlot, setIsAddingBookingSlot] = useState(false);
  const [isUpdatingSlot, setIsUpdatingSlot] = useState(false);
  const [bookingDate, setBookingDate] = useState("");
  const [bookingStartTime, setBookingStartTime] = useState("00:00");
  const [bookingEndTime, setBookingEndTime] = useState("00:00");
  const [bookingPrice, setBookingPrice] = useState("");
  const [bookingCapacity, setBookingCapacity] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [eachSlot, setEachSlot] = useState("");
  const [bookingNewDate, setBookingNewDate] = useState("");
  const [bookingNewStartTime, setBookingNewStartTime] = useState("");
  const [bookingNewEndTime, setBookingNewEndTime] = useState("");
  const [bookingNewPrice, setBookingNewPrice] = useState("");
  const [bookingNewCapacity, setBookingNewCapacity] = useState("");
  const [showFields, setShowFields] = useState(false);
  const [id2, setId2] = useState("");

  const [bookingSlot, setBookingSlot] = useState([]);
  const handleAddClick2 = (event) => {
    event.preventDefault();
    setBookingDate(getTodayDate());
    setBookingStartTime("00:00");
    setBookingEndTime("00:00");
    setBookingPrice("");
    setBookingCapacity("");
    setShowFields2(true);
  };
  const format12HourTime = (time24Hour) => {
    if (time24Hour) {
      const [hours, minutes] = time24Hour.split(":");
      const formattedTime = `${hours % 12 || 12}:${minutes} ${
        hours >= 12 ? "PM" : "AM"
      }`;
      return formattedTime;
    }
    return ""; // Return an empty string or another default value if time24Hour is undefined or null
  };

  const formattedTime = format12HourTime(bookingSlot?.startTime);
  const handleCancelClick2 = () => {
    setShowFields2(false);
  };

  function reloadPage() {
    window.location.reload();
  }
  const [modalDelete_slotIsOpen, setDelete_slotIsOpen] = useState(false);
  const openDelete_slot = () => {
    closeDelete_slot();
    setDelete_slotIsOpen(true);
  };

  const closeDelete_slot = () => {
    setDelete_slotIsOpen(null);
  };
  const handleCancelClick = () => {
    setShowFields(false);
  };

  const formatStartDate = (startDate) => {
    const date = new Date(startDate);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    const today = getTodayDate();
    setBookingDate(today);
  }, []);

  useEffect(() => {
    fetch(`${getBookingSlotByVenueId}${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const bookingSlot = data.data.BookingSlot;
        console.log(bookingSlot);
        setBookingSlot(bookingSlot);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  // ------------------------add workshop slot ------------------------------------------------------------
  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(":");
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${minutes}`;
  };

  // const [bookingSlot, setBookingSlot] = useState([]);
  const handleAddBookingSlot = async (event) => {
    try {
      event.preventDefault();

      if (!bookingDate) {
        alert("Please fill in all fields");
        return;
      }

      setIsAddingBookingSlot(true);
      // const formattedStartTime = convertTo12HourFormat(bookingStartTime);
      // const formattedEndTime = convertTo12HourFormat(bookingEndTime);
      const formData = new FormData();
      formData.append("startDate", bookingDate);
      formData.append("startTime", bookingStartTime);
      formData.append("endTime", bookingStartTime);
      formData.append("price", bookingPrice);
      formData.append("temp_id", dateTime);
      formData.append("capacity", bookingCapacity);
      // formData.append("availableSeats", bookingCapacity);
      formData.append("venue", id);

      const addResponse = await fetch(`${addBookingSlot}`, {
        method: "POST",
        body: formData,
      });

      if (!addResponse.ok) {
        alert("Failed to add Slot");
        return;
      }
      const getResponse = await fetch(`${getBookingSlotByVenueId}${id}`);

      if (!getResponse.ok) {
        alert("Failed to fetch slots");
        return;
      }

      const getData = await getResponse.json();
      const bookingSlot = getData.data.BookingSlot || [];

      setBookingSlot(bookingSlot);
      setShowFields3(false);
      setBookingDate("");
      setBookingStartTime("");
      setBookingEndTime("");
      setBookingPrice("");
      setBookingCapacity("");
    } catch (error) {
      console.error("Error handling FAQ:", error);
    } finally {
      setIsAddingBookingSlot(false);
    }
  };
  // ---------------------------------get workshop slot by id --------------------------------------------------------

  const handleClick4 = (id2) => {
    // event.preventDefault();

    fetch(`${getBookingSlotById}${id2}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const bookingEachSlot = data.data.BookingSlot;
        setBookingNewDate(bookingEachSlot.date);
        setBookingNewStartTime(bookingEachSlot.startTime);
        setBookingNewEndTime(bookingEachSlot.endTime);
        setBookingNewPrice(bookingEachSlot.price);
        setBookingNewCapacity(bookingEachSlot.totalSeats);
        setDatetime(bookingEachSlot.temp_id);
        setId2(bookingEachSlot.id);
        console.log(bookingEachSlot.id);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });

    setShowFields4(true);
  };
  // -------------------------update workshop slot ----------------------------------------------------------------

  const handleUpdateWorkshop = async (id2) => {
    try {
      setIsUpdatingSlot(true);

      const formData = new FormData();
      formData.append("bookingSlot_id", id2);
      formData.append("temp_id", dateTime);
      formData.append("date", bookingNewDate);
      formData.append("startTime", bookingNewStartTime);
      formData.append("endTime", bookingNewEndTime);
      formData.append("price", bookingNewPrice);
      formData.append("capacity", bookingNewCapacity);

      const updateResponse = await fetch(`${updateBookingSlot}`, {
        method: "PUT",
        body: formData,
      });

      if (!updateResponse.ok) {
        alert("Failed to update slot");
        return;
      }

      // Trigger get API after update
      const getResponse = await fetch(`${getBookingSlotByVenueId}${id}`);

      if (!getResponse.ok) {
        alert("Failed to fetch slots");
        return;
      }

      const getData = await getResponse.json();
      const bookingSlot = getData.data.BookingSlot || [];

      setBookingSlot(bookingSlot);
      setShowFields4(false);
    } catch (error) {
      console.error("Error handling slots:", error);
    } finally {
      setIsUpdatingSlot(false);
    }
  };
  // ---------------------------delete workshop slot ------------------------------------

  const handleDeleteWorkshop = (id2) => {
    fetch(`${deleteBookingSlotById}${id2}`, {
      method: "DELETE",
      headers: {
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        // Check if the delete operation was successful
        if (data.success) {
          // Trigger the get API after successful deletion
          return fetch(`${getBookingSlotByVenueId}${id}`);
        } else {
          // Handle the error if the delete operation was not successful
          alert( data.message);
        }
      })
      .then((getResponse) => {
        // Check if the get API response is successful
        if (getResponse && getResponse.ok) {
          return getResponse.json();
        } else {
          throw new Error("Failed to fetch slot");
        }
      })
      .then((getData) => {
        const bookingSlot = getData.data.BookingSlot || [];
        setBookingSlot(bookingSlot);
      })
      .catch((error) => {
        console.error("Error handling slot:", error);
      });
  };
  const handleCancelClick4 = () => {
    setShowFields4(false);
  };

  return (
    <div>
      {modalVenueowner_booking_slotsIsOpen && (
        <Venueowner_booking_slots onClose={closeVenueowner_booking_slots} />
      )}
      {/* {modalDelete_slotIsOpen && <Delete_slot onClose={closeDelete_slot} />} */}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Slot</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="form-group">
              <label class="small mb-1" for="inputFullName">
                Workshop Slots
              </label>
              <button
                style={{
                  marginLeft: "10px",
                  fontSize: "14px",
                  borderRadius: "4px",
                  backgroundColor: "#000",
                  color: "#fff",
                }}
                onClick={handleAddClick2}
              >
                + Add
              </button>
            </div>
            {showFields2 && (
              <>
                <div
                  class="col-md-12"
                  style={{ display: "flex", paddingLeft: "0px" }}
                >
                  <div
                    class="form-group col-md-6"
                    style={{ paddingLeft: "0px" }}
                  >
                    <label class="small mb-1" for="inputFullName">
                      Select Date<font color="red"></font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="date"
                      value={bookingDate}
                      min={bookingDate}
                      onChange={(e) => setBookingDate(e.target.value)}
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputFullName">
                      Select Start time <font color="red"></font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="time"
                      value={bookingStartTime}
                      onChange={(e) => setBookingStartTime(e.target.value)}
                    />
                  </div>
                </div>
                <div
                  class="col-md-12"
                  style={{ display: "flex", paddingLeft: "0px" }}
                >
                  <div
                    class="form-group col-md-6"
                    style={{ paddingLeft: "0px" }}
                  >
                    <label class="small mb-1" for="inputFullName">
                      Select End Time<font color="red"></font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="time"
                      value={bookingEndTime}
                      onChange={(e) => setBookingEndTime(e.target.value)}
                    />
                  </div>
                  <div class="form-group col-md-6">
                    <label class="small mb-1" for="inputFullName">
                      Enter Price <font color="red"></font>
                    </label>
                    <input
                      class="form-control"
                      id="inputFullName"
                      type="number"
                      value={bookingPrice}
                      onChange={(e) => setBookingPrice(e.target.value)}
                    />
                  </div>
                </div>
                <div class="form-group col-md-6">
                  <label class="small mb-1" for="inputFullName">
                    Enter Seating Capacity<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="number"
                    value={bookingCapacity}
                    onChange={(e) => setBookingCapacity(e.target.value)}
                  />
                </div>
                <div style={{ display: "flex", marginBottom: "20px" }}>
                  <button
                    type="button"
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      backgroundColor: "#000",
                      color: "#fff",
                      border: "none",
                      padding: "5px 20px",
                    }}
                    onClick={handleAddBookingSlot}
                  >
                    Add
                  </button>
                  <button
                    type="button"
                    onClick={handleCancelClick2}
                    style={{
                      marginLeft: "10px",
                      fontSize: "14px",
                      borderRadius: "4px",
                      backgroundColor: "lightgrey",
                      color: "#000",
                      border: "none",
                      padding: "5px 20px",
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </>
            )}
            <div className="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : bookingSlot.length > 0 ? (
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">Start Time</th>
                      <th scope="col">End Time</th>
                      <th scope="col">Price</th>
                      <th scope="col">Seating Capacity</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {bookingSlot.map((bookingSlot, index) => (
                      <tr key={bookingSlot.id}>
                        <th scope="row">{index + 1}</th>

                        <td>
                          <b>
                            {formatStartDate(bookingSlot.startDate) || "---"}
                          </b>
                        </td>
                        <td>
                          <b>{format12HourTime(bookingSlot.startTime)}</b>
                          <br />
                        </td>
                        <td>
                          <b>{format12HourTime(bookingSlot.endTime)}</b>
                          <br />
                        </td>
                        <td>
                          <b>{bookingSlot.price || "---"}</b>
                          <br />
                        </td>
                        <td>
                          <b>{bookingSlot.capacity || "---"}</b>
                          <br />
                        </td>

                        <td>
                          <div className="social-links mt-2">
                          {bookingSlot.status !== "Booked" && (
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick4(bookingSlot.id)}
                              >
                                <i className="bi bi-pencil"></i>
                              </a>
                            )}
                            &nbsp;
                            <a
                              onClick={() =>
                                handleDeleteWorkshop(bookingSlot.id)
                              }
                            >
                              <i className="fas fa-trash"></i>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h5 style={{ textAlign: "center" }}> No data available </h5>
              )}
            </div>

            {showFields4 && (
              <div>
                <>
                  <div>
                    <div
                      class="col-md-12"
                      style={{ display: "flex", paddingLeft: "0px" }}
                    >
                      <div
                        class="form-group col-md-6"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label class="small mb-1" for="inputFullName">
                          Select Date<font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="date"
                          // value={eachSlot.startDate}
                          value={bookingNewDate}
                          onChange={(e) => setBookingNewDate(e.target.value)}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="small mb-1" for="inputFullName">
                          Select Start time <font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="time"
                          // value={eachSlot.startTime}
                          value={bookingNewStartTime}
                          onChange={(e) =>
                            setBookingNewStartTime(e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div
                      class="col-md-12"
                      style={{ display: "flex", paddingLeft: "0px" }}
                    >
                      <div
                        class="form-group col-md-6"
                        style={{ paddingLeft: "0px" }}
                      >
                        <label class="small mb-1" for="inputFullName">
                          Select End Time<font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="time"
                          // value={eachSlot.endTime}
                          value={bookingNewEndTime}
                          onChange={(e) => setBookingNewEndTime(e.target.value)}
                        />
                      </div>
                      <div class="form-group col-md-6">
                        <label class="small mb-1" for="inputFullName">
                          Enter Price <font color="red"></font>
                        </label>
                        <input
                          class="form-control"
                          id="inputFullName"
                          type="number"
                          // value={eachSlot.price}
                          value={bookingNewPrice}
                          onChange={(e) => setBookingNewPrice(e.target.value)}
                        />
                      </div>
                    </div>
                    <div class="form-group col-md-6">
                      <label class="small mb-1" for="inputFullName">
                        Enter Seating Capacity<font color="red"></font>
                      </label>
                      <input
                        class="form-control"
                        id="inputFullName"
                        type="number"
                        // value={eachSlot.capacity}
                        value={bookingNewCapacity}
                        onChange={(e) => setBookingNewCapacity(e.target.value)}
                      />
                    </div>
                    <div style={{ display: "flex", marginBottom: "20px" }}>
                      <button
                        type="button"
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "#000",
                          color: "#fff",
                          border: "none",
                          padding: "5px 20px",
                        }}
                        // onClick={handleeUpdate}
                        onClick={() =>
                          handleUpdateWorkshop(
                            id2,
                            bookingNewDate,
                            bookingNewStartTime,
                            bookingNewEndTime,
                            bookingNewPrice,
                            bookingNewCapacity
                          )
                        }
                      >
                        Update
                      </button>
                      <button
                        type="button"
                        onClick={handleCancelClick4}
                        style={{
                          marginLeft: "10px",
                          fontSize: "14px",
                          borderRadius: "4px",
                          backgroundColor: "lightgrey",
                          color: "#000",
                          border: "none",
                          padding: "5px 20px",
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Venueowner_booking_slots;
