import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls.js";
import { getAppointmentByVenueId } from "../../Api/apiUrls";

function VenueTotalAppointment({ onClose, id }) {
  const [
    modalVenueTotalAppointmentIsOpen,
    setVenueTotalAppointmentModalIsOpen,
  ] = useState("");
  const closeVenueTotalAppointment = () => {
    setVenueTotalAppointmentModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const [isLoading, setIsLoading] = useState(true);
  const [appointment, setAppointment] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${getAppointmentByVenueId}${id}`);
        const data = await response.json();
        setAppointment(data.data.Appointments);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id]);
  return (
    <div>
      {modalVenueTotalAppointmentIsOpen && (
        <VenueTotalAppointment onClose={closeVenueTotalAppointment} />
      )}
      <div className="popup-background" onClick={handleClick}>
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Total Appointments</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              {isLoading ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : appointment.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">User</th>
                      <th scope="col">Order Id</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Status</th>
                      <th scope="col">Created On</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "13px" }}>
                    {appointment.map((appointment, index) => (
                      <tr key={appointment.appointments.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{appointment.appointments.user?.name || "---"}</td>
                        <td>
                          {appointment.appointments.transaction?.order_id ||
                            "---"}
                        </td>
                        <td>
                          {appointment.appointments.transaction?.amount ||
                            "---"}
                        </td>
                        <td>{appointment.appointments.status || "---"}</td>
                        <td>
                          {formatCreatedAt(appointment.appointments.created_at)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h5 style={{ textAlign: "center" }}>No data available</h5>
              )}
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default VenueTotalAppointment;
