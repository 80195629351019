import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls.js";
import { getSavedBlogAndNewsByBlogAndNewsId } from "../../Api/apiUrls";

function NewsSave({ onClose, id }) {
  const [modalNewsSaveIsOpen, setNewsSaveModalIsOpen] = useState("");
  const closeNewsSave = () => {
    setNewsSaveModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const [save, setSave] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${getSavedBlogAndNewsByBlogAndNewsId}${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const save = data.data.SavedBlogsAndNews;
        console.log(save);
        setSave(save);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Save value:", save);
      });
  }, []);

  return (
    <div>
      {modalNewsSaveIsOpen && <NewsSave onClose={closeNewsSave} />}

      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Save</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : save.length > 0 ? (
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">User</th>
                      {/* <th scope="col">Comment</th> */}
                      <th scope="col">Saved On</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "13px" }}>
                    {save.map((item, index) => (
                      <tr key={item.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.user.name || "---"}</td>
                        {/* <td>{item.comment || "-----"}</td> */}
                        <td>{formatCreatedAt(item.created_at)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h5 style={{ textAlign: "center" }}> No data available </h5>
              )}
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsSave;
