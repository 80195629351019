import React, { useState, useEffect, useRef } from "react";
import { TailSpin } from "react-loader-spinner";
import useRazorpay from "react-razorpay";
import image from "../../Static/img/ARTIQUITY_LOGO.jpeg";
import "../../Api/apiUrls.js";
import {
  API_URL,
  getPackageForWorkshop,
  razorpayOrderCreate,
  addTransaction,
  addPackageManagement,
} from "../../Api/apiUrls";
import PackageInvoice from "./packageInvoice.js";

function PackagePopup({ onClose, id2 }) {
  console.log(id2);
  const [modalPackagePopupIsOpen, setPackagePopupIsOpen] = useState("");
  const [modalPackageInvoiceIsOpen, setPackageInvoiceIsOpen] = useState("");
  const [packageId, setPackageId] = useState("");
  const [packageName, setPackageName] = useState("");
  const [packageType, setPackageType] = useState("");
  const [workshopId, setWorkshopId] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const closePackagePopup = () => {
    setPackagePopupIsOpen(false);
  };
  const openPackageInvoice = (idpackage , namepackage , idworkshop , typepackage) => {
    setPackageId(idpackage);
    setPackageName(namepackage);
    setWorkshopId(idworkshop);
    setPackageType(typepackage);
    setPackageInvoiceIsOpen(true);
  };

  const closePackageInvoice = () => {
    setPackageInvoiceIsOpen(false);
  };
  const [Razorpay] = useRazorpay();

  function reloadPage() {
    window.location.reload();
  }
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    fetch(`${getPackageForWorkshop}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const packages = data.data.Package.filter(
          (packages) => packages.status === "Active"
        );
        console.log(packages);
        setPackages(packages);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Package value:", packages);
      });
  }, []);
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  const instructorId = localStorage.getItem("user_id");
  // const handlePayment = async (id, name, price, duration) => {
  //   try {
  //     const response = await fetch(`${razorpayOrderCreate}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ amount: price, currency: "INR" }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch");
  //     }

  //     const responseData = await response.json();
  //     console.log(responseData);

  //     if (responseData.data.status === "created") {
  //       let options = {
  //         // key: "rzp_test_ZbzHEjt4c5dilD",
  //         key: "rzp_live_hKgAxRUVv2F589",
  //         amount: responseData.data.amount,
  //         currency: "INR",
  //         name: "Artiquity",
  //         description: "Transaction",
  //         image: image,
  //         order_id: responseData.data.id,
  //         handler: function (response) {
  //           console.log(response.razorpay_payment_id);
  //           console.log(response.razorpay_order_id);
  //           console.log(response.razorpay_signature);
  //           console.log("Payment Successful ..!!");
  //           alert("Congrats !! Payment Successful !!");
  //           var data = {
  //             title: name,
  //             mode: "Online",
  //             user: instructorId,
  //             type: "Workshop",
  //             amount: price,
  //             package: id,
  //             workshop: id2,
  //             transaction_status: "Success",
  //             payment_id: response.razorpay_payment_id,
  //             order_id: response.razorpay_order_id,
  //             signature: response.razorpay_signature,
  //           };

  //           fetch(`${addTransaction}`, {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify(data),
  //           })
  //             .then((response) => {
  //               if (response.ok) {
  //                 return response.json(); // Parse response JSON
  //               } else {
  //                 throw new Error("Failed to submit transaction data");
  //               }
  //             })
  //             .then((responseData) => {
  //               // Check if responseData contains data and Transaction object
  //               if (
  //                 responseData &&
  //                 responseData.data &&
  //                 responseData.data.Transaction
  //               ) {
  //                 alert("Data submitted successfully");

  //                 // Extract transaction ID from responseData
  //                 const transactionId = responseData.data.Transaction.id;

  //                 var packageData = {
  //                   paidAmount: price,
  //                   user: instructorId,
  //                   paymentMode: "Online",
  //                   package: id,
  //                   workshop: id2,
  //                   transaction: transactionId, // Include transaction ID here
  //                 };

  //                 fetch(`${addPackageManagement}`, {
  //                   method: "POST",
  //                   headers: {
  //                     "Content-Type": "application/json",
  //                   },
  //                   body: JSON.stringify(packageData),
  //                 })
  //                   .then((response) => {
  //                     if (response.ok) {
  //                       alert("Data submitted successfully");
  //                       reloadPage();
  //                     } else {
  //                       alert("Failed to submit data");
  //                     }
  //                   })
  //                   .catch((error) => {
  //                     console.error("Error:", error);
  //                     alert("An error occurred while submitting data");
  //                   });
  //               } else {
  //                 alert("Failed to submit data");
  //               }
  //             })
  //             .catch((error) => {
  //               console.error("Error:", error);
  //               alert("An error occurred while submitting data");
  //             });
  //         },
  //         prefill: {
  //           name: "",
  //           email: "",
  //           contact: "",
  //         },
  //         notes: {
  //           address: "",
  //         },
  //         theme: {
  //           color: "#000",
  //         },
  //         config: {
  //           display: {
  //             blocks: {
  //               banks: {
  //                 name: "All payment methods",
  //                 instruments: [
  //                   {
  //                     method: "upi",
  //                   },
  //                   {
  //                     method: "card",
  //                   },
  //                   {
  //                     method: "wallet",
  //                   },
  //                   {
  //                     method: "netbanking",
  //                   },
  //                 ],
  //               },
  //             },
  //             preferences: {
  //               show_default_blocks: true,
  //             },
  //           },
  //         },
  //       };

  //       let rzp1 = new Razorpay(options);
  //       rzp1.on("payment.failed", function (response) {
  //         console.log(response.error.code);
  //         console.log(response.error.description);
  //         console.log(response.error.source);
  //         console.log(response.error.step);
  //         console.log(response.error.reason);
  //         console.log(response.error.metadata.order_id);
  //         console.log(response.error.metadata.payment_id);
  //         alert("Oops ! Payment Failed !");
  //       });
  //       rzp1.open();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     alert("Something Went Wrong..!!");
  //   }
  // };

  return (
    <div>
      {modalPackagePopupIsOpen && <packagePopup onClose={closePackagePopup} />}
      {modalPackageInvoiceIsOpen && (
        <PackageInvoice onClose={closePackageInvoice} idpackage={packageId} namepackage={packageName} idworkshop={workshopId} typepackage={packageType}/>
      )}

      <div className="popup-background" onClick={handleClick}>
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "85%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Packages</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style={{ overflowY: "scroll" }}>
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : (
                <div className="row col-md-12">
                  {packages.map((packages) => (
                    <div key={packages.id} className="col-md-4">
                      <div className="card" style={{ boxShadow: "none" }}>
                        <div className="card-body">
                          <h5 className="card-title">
                            <b>{packages.name}</b>
                          </h5>
                          <p className="card-text">Price: {packages.price}</p>
                          <p className="card-text">
                            Duration: {packages.duration}
                          </p>
                          {/* <p className="card-text">{packages.Details}</p> */}
                          <a
                            href="#"
                            className="btn btn-dark"
                            // onClick={() =>
                            //   handlePayment(
                            //     packages.id,
                            //     packages.name,
                            //     packages.price,
                            //     packages.duration
                            //   )
                            // }
                            onClick={() => openPackageInvoice(packages.id , packages.name,id2 , packages.type)}
                          >
                            Buy
                          </a>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackagePopup;
