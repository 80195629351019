import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_VenueownerReview from "../../Components/Venue_owner_comp/add_review.js";
import Update_VenueownerReview from "../../Components/Venue_owner_comp/update_review.js";
import Delete_VenueownerReview from "../../Components/Venue_owner_comp/delete_review.js";
import Venue_Review_eye from "../../Components/Admin_components/eye_review.js";
function VenueownerReview() {
  const [modalVenue_Review_eyeIsOpen, setVenue_Review_eyeModalIsOpen] =
    useState("");
  const [modalAdd_VenueownerReviewIsOpen, setAdd_VenueownerReviewModalIsOpen] =
    useState("");
  const [
    modalUpdate_VenueownerReviewIsOpen,
    setUpdate_VenueownerReviewModalIsOpen,
  ] = useState(false);
  const [modalDelete_VenueownerReviewIsOpen, setDelete_VenueownerReviewIsOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const status = [
    { value: "All", label: "All" },
    { value: "Pending", label: "Pending" },
    { value: "Approve", label: "Approve" },
    { value: "Reject", label: "Reject" },
  ];
  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const drop = [
    { value: " Website", label: " Website" },
    { value: " Admin", label: " Admin" },
  ];
  const openVenue_Review_eye = () => {
    closeVenue_Review_eye();
    setVenue_Review_eyeModalIsOpen(true);
  };

  const closeVenue_Review_eye = () => {
    setVenue_Review_eyeModalIsOpen(false);
  };
  const openAdd_VenueownerReview = () => {
    closeAdd_VenueownerReview();
    setAdd_VenueownerReviewModalIsOpen(true);
  };

  const closeAdd_VenueownerReview = () => {
    setAdd_VenueownerReviewModalIsOpen(false);
  };
  const openUpdate_VenueownerReview = () => {
    closeUpdate_VenueownerReview();
    setUpdate_VenueownerReviewModalIsOpen(true);
  };

  const closeUpdate_VenueownerReview = () => {
    setUpdate_VenueownerReviewModalIsOpen(false);
  };
  const openDelete_VenueownerReview = (userId) => {
    setSelectedUserId(userId);
    closeDelete_VenueownerReview();
    setDelete_VenueownerReviewIsOpen(true);
  };

  const closeDelete_VenueownerReview = () => {
    setDelete_VenueownerReviewIsOpen(null);
  };

  return (
    <div>
      <Header></Header>
      {modalAdd_VenueownerReviewIsOpen && (
        <Add_VenueownerReview onClose={closeAdd_VenueownerReview} />
      )}
      {/* {modalReview_eyeIsOpen && <Review_eye onClose={closeReview_eye} />} */}
      {modalUpdate_VenueownerReviewIsOpen && (
        <Update_VenueownerReview onClose={closeUpdate_VenueownerReview} />
      )}
      {modalDelete_VenueownerReviewIsOpen && (
        <Delete_VenueownerReview onClose={closeDelete_VenueownerReview} />
      )}
      {modalVenue_Review_eyeIsOpen && (
        <Venue_Review_eye onClose={closeVenue_Review_eye} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Review</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Review</li>
            </ol>
          </nav>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child ml-2">
                  <Select options={status} placeholder="Select Status"></Select>
                </div>
                <div class="child ml-2">
                  <Select options={type} placeholder="Select Type"></Select>
                </div>
              </div>
            </div>
            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <table class="table table-striped  ">
              <thead>
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Message</th>
                  <th scope="col">User</th>
                  <th scope="col">Created At</th>
                  <th scope="col">Status</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>

                  {
                    <td>
                      <div className="social-links mt-2">
                        <a>
                          <i
                            class="bi bi-eye"
                            style={{ cursor: "pointer" }}
                            onClick={openVenue_Review_eye}
                          ></i>
                        </a>
                        &nbsp;&nbsp;
                      </div>
                    </td>
                  }
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                  <td>@mdo</td>
                  <td>
                    <div className="social-links mt-2">
                      <a>
                        <i
                          class="bi bi-eye"
                          style={{ cursor: "pointer" }}
                          onClick={openVenue_Review_eye}
                        ></i>
                      </a>
                      &nbsp;&nbsp;
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                  <td>@mdo</td>
                  <td>
                    <div className="social-links mt-2">
                      <a>
                        <i
                          class="bi bi-eye"
                          style={{ cursor: "pointer" }}
                          onClick={openVenue_Review_eye}
                        ></i>
                      </a>
                      &nbsp;&nbsp;
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default VenueownerReview;
