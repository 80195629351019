import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_Customer_Transaction from "../../Components/Customer_comp/add_transactions.js";
import Delete_Customer_Transaction from "../../Components/Customer_comp/delete_transaction.js";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls.js";
import { getCustomerTransaction } from "../../Api/apiUrls";
function Customer_Transaction() {
  const [
    modalAdd_Customer_TransactionIsOpen,
    setAdd_Customer_TransactionModalIsOpen,
  ] = useState("");
  const [
    modalDelete_Customer_TransactionIsOpen,
    setDelete_Customer_TransactionIsOpen,
  ] = useState(false);

  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const transaction = [
    { value: "All", label: "All" },
    { value: "Success", label: "Success" },
    { value: "Failed", label: "Failed" },
    { value: "On Hold", label: "On Hold" },
  ];

  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    // Filter transactions based on selected filters and search query
    const newFilteredTransactions = transactions.filter((transaction) => {
      // Check if any property of transaction matches the search query
      const searchMatch = Object.values(transaction).some(
        (value) =>
          typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase())
      );

      // Check if transaction matches selected filters
      const typeCondition =
        !selectedType ||
        selectedType.value === "All" ||
        transaction.type === selectedType.value;
      const statusCondition =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        transaction.user.status === selectedStatus.value;
      const transactionCondition =
        !selectedTransaction ||
        selectedTransaction.value === "All" ||
        transaction.transaction_status === selectedTransaction.value;

      // Return true if the transaction matches all conditions
      return (
        typeCondition && statusCondition && transactionCondition && searchMatch
      );
    });

    // Update the state with the filtered transactions
    setFilteredTransactions(newFilteredTransactions);
  }, [
    selectedType,
    selectedStatus,
    selectedTransaction,
    searchQuery,
    transactions,
  ]);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const openAdd_Customer_Transaction = () => {
    closeAdd_Customer_Transaction();
    setAdd_Customer_TransactionModalIsOpen(true);
  };

  const closeAdd_Customer_Transaction = () => {
    setAdd_Customer_TransactionModalIsOpen(false);
  };
  const openDelete_Customer_Transaction = (userId) => {
    setSelectedUserId(userId);
    closeDelete_Customer_Transaction();
    setDelete_Customer_TransactionIsOpen(true);
  };

  const closeDelete_Customer_Transaction = () => {
    setDelete_Customer_TransactionIsOpen(null);
  };
  const convertTimeTo12HourFormat = (time24hr) => {
    const [start, end] = time24hr.split(" - ");

    const convertTo12HourFormat = (time) => {
      const [hour, minutes] = time.split(":");
      const isPM = parseInt(hour, 10) >= 12;

      const convertedHour = isPM ? parseInt(hour, 10) % 12 : parseInt(hour, 10);
      const period = isPM ? "PM" : "AM";

      return `${convertedHour}:${minutes} ${period}`;
    };

    const convertedStart = convertTo12HourFormat(start);
    const convertedEnd = convertTo12HourFormat(end);

    return `${convertedStart} - ${convertedEnd}`;
  };
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const customerId = localStorage.getItem("user_id");
  useEffect(() => {
    fetch(getCustomerTransaction + customerId)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const transactions = data.data.Transaction;
        console.log(transactions);
        setTransactions(transactions);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Transaction value:", transactions);
      });
  }, []);
  return (
    <div>
      <Header></Header>
      {modalAdd_Customer_TransactionIsOpen && (
        <Add_Customer_Transaction onClose={closeAdd_Customer_Transaction} />
      )}
      {modalDelete_Customer_TransactionIsOpen && (
        <Delete_Customer_Transaction
          userId={selectedUserId}
          onClose={closeDelete_Customer_Transaction}
        />
      )}

      {/* {modalAdd_AboutUsIsOpen && <Add_AboutUs onClose={closeAdd_AboutUs} />} */}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Transaction</h1>
        </div>
        <section class="section"></section>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div class="child col-md-3" style={{ padding: "5px" }}>
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "37px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                      width: "100%",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div class=" col-md-3 " style={{ padding: "5px" }}>
                  <Select
                    options={type}
                    placeholder="Select Type"
                    onChange={(selectedOption) =>
                      setSelectedType(selectedOption)
                    }
                  ></Select>
                </div>

                <div class=" col-md-4" style={{ padding: "5px" }}>
                  <Select
                    options={transaction}
                    placeholder="Select Transaction Status"
                    onChange={(selectedOption) =>
                      setSelectedTransaction(selectedOption)
                    }
                  ></Select>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>

            {isLoading === true ? (
              <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                <TailSpin color="black" width={50} />
              </div>
            ) : transactions.length > 0 ? (
              <table class="table table-striped  ">
                <thead>
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Title</th>
                    <th scope="col">Type</th>
                    <th scope="col">Slot</th>
                    <th scope="col">Seats</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Transaction ID</th>
                    <th scope="col">Created On</th>
                    <th scope="col">Payment Mode</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody id="tableBody">
                  {filteredTransactions.map((transactions, index) => (
                    <tr key={transactions.id}>
                      <th scope="row">{index + 1}</th>

                      <td>{transactions.title || "No Name"}</td>

                      <td>{transactions.type || "No Name"}</td>

                      <td>
                        {convertTimeTo12HourFormat(transactions.slot || "-")}
                        <br />
                        {transactions.type === "Venue"
                          ? transactions.bookingSlot?.startDate || "-"
                          : transactions.type === "Workshop"
                          ? transactions.workshopSlot?.date || "-"
                          : "-"}
                      </td>
                      <td>{transactions.seats || "-"}</td>
                      <td>{transactions.amount || "-"}</td>
                      <td>{transactions.payment_id}</td>
                      <td>{formatCreatedAt(transactions.created_at)}</td>
                      <td>{transactions.mode || "-"}</td>
                      <td>{transactions.transaction_status || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredTransactions.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Customer_Transaction;
