import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_appointment from "../../Components/Admin_components/add_appointment.js";
import Delete_appointment from "../../Components/Admin_components/delete_appointment.js";
import Update_appointment from "../../Components/Admin_components/update_appointment.js";
import "../../Api/apiUrls.js";
import { API_URL, getWishlistByUserId, API_URL_NEW } from "../../Api/apiUrls";

function Wishlist() {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [wishlistItemsVenue, setWishlistItemsVenue] = useState([]);
  const customerId = localStorage.getItem("user_id");

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    // Fetch wishlist data from the API
    fetch(`${getWishlistByUserId}${customerId}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // Filter items with type "Workshop"
          const workshopItems = data.data.Wishlist.filter(
            (item) => item.type === "Workshop"
          );
          setWishlistItems(workshopItems);
          console.log(workshopItems);
          const venueItems = data.data.Wishlist.filter(
            (item) => item.type === "venue"
          );
          setWishlistItemsVenue(venueItems);
        } else {
          console.error("Error fetching wishlist:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching wishlist:", error);
      });
  }, []);

  return (
    <div>
      <Header></Header>

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Wishlist</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2"></div>
            <div style={{ padding: "15px" }}>
              <h5>
                <b>Workshops</b>
              </h5>{" "}
              <br />
              <div
                className=" row col-md-12"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {wishlistItems.map((item) => (
                  <div class="card-deck col-md-4">
                    <a
                      href={`${API_URL_NEW}workshop.html?id=${item.workshop.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div class="card" style={{ boxShadow: "none" }}>
                        {item.workshop_images
                          .filter(
                            (workshop_images) => workshop_images.status === 1
                          )
                          .slice(0, 1)
                          .map((workshop_images) => (
                            <img
                              class="card-img-top"
                              src={`${API_URL}/${workshop_images.photo}`}
                              alt="Card image cap"
                              style={{
                                width: "95%",
                                borderRadius: "25px",
                                padding: "10px",
                                aspectRatio: "9/16",
                              }}
                            />
                          ))}
                        <div class="card-body">
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              {" "}
                              {item.workshop.category.name} |{" "}
                              {item.workshop.subcategory.name}
                            </small>
                          </p>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              {" "}
                              {item.workshop.category.name} |{" "}
                              {item.workshop.area.name},{" "}
                              {item.workshop.city.name}
                            </small>
                          </p>
                          <span style={{ color: "#6c757d" }}>
                            {item.workshop?.name}
                          </span>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              {" "}
                              Rs.{item.workshop.price}{" "}
                            </small>
                          </p>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              <i
                                class="bi bi-star-fill"
                                style={{ color: "gold" }}
                              ></i>{" "}
                              {item.workshop.ratings_count}{" "}
                            </small>
                          </p>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              <i class="bi bi-clock"></i>{" "}
                              {item.workshop.duration_in_hour} Hrs{" "}
                            </small>
                          </p>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              <i class="bi bi-people"></i>{" "}
                              {item.workshop.total_seats}{" "}
                            </small>
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>

            <div style={{ padding: "15px" }}>
              <h5>
                <b>Venue</b>
              </h5>{" "}
              <br />
              <div
                className=" row col-md-12"
                style={{ display: "flex", justifyContent: "flex-start" }}
              >
                {wishlistItemsVenue.map((item) => (
                  <div class="card-deck col-md-4">
                    <a
                      href={`${API_URL_NEW}singleVenue.html?id=${item.venue.id}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <div class="card" style={{ boxShadow: "none" }}>
                        {item.venue_images
                          .filter((venue_images) => venue_images.status === 1)
                          .slice(0, 1)
                          .map((venue_images) => (
                            <img
                              class="card-img-top"
                              src={`${API_URL}/${venue_images.photo}`}
                              alt="Card image cap"
                              style={{
                                width: "95%",
                                borderRadius: "25px",
                                padding: "10px",
                                aspectRatio: "16/9",
                              }}
                            />
                          ))}
                        <div class="card-body">
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              {" "}
                              {item.venue.category?.name}{" "}
                            </small>
                          </p>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              {" "}
                              {item.venue.category?.name} |{" "}
                              {item.venue.area?.name}, {item.venue.city?.name}
                            </small>
                          </p>
                          <span style={{ color: "#6c757d" }}>
                            {item.venue?.name}
                          </span>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              {" "}
                              Rs.{item.venue.price}{" "}
                            </small>
                          </p>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              <i
                                class="bi bi-star-fill"
                                style={{ color: "gold" }}
                              ></i>{" "}
                              {item.venue.ratings_count}{" "}
                            </small>
                          </p>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              <i class="bi bi-clock"></i> {item.venue.duration}{" "}
                              Hrs{" "}
                            </small>
                          </p>
                          <p style={{ marginBottom: "0rem" }}>
                            <small class="text-muted">
                              <i class="bi bi-people"></i> {item.venue.capacity}{" "}
                            </small>
                          </p>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Wishlist;
