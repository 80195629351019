import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import VenueOwnerChart from "../../Components/Venue_owner_comp/venueOwnerChart.js";
import "../../Api/apiUrls.js";
import { API_URL, getQuestionByReceiverId } from "../../Api/apiUrls";

function VenueownerQuestion() {
  const [question, setQuestion] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const venueOwnerId = localStorage.getItem("user_id");
  const [questionId, setQuestionId] = useState("");
  const [selectedType, setSelectedType] = useState({ value: "All", label: "All" });

  const [modalVenueOwnerChatIsOpen, setVenueOwnerChatModalIsOpen] =
    useState("");

  const closeVenueOwnerChat = () => {
    setVenueOwnerChatModalIsOpen(false);
  };

  const openVenueOwnerChat = (questionId) => {
    setQuestionId(questionId);
    closeVenueOwnerChat();
    setVenueOwnerChatModalIsOpen(true);
  };

  const type = [
    { value: "All", label: "All" },
    // { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
    { value: "News", label: "News" },
  ];

  useEffect(() => {
    const hasReloaded = localStorage.getItem('hasReloaded');
    if (!hasReloaded) {
      // alert("hiiiiiiiiiii")
      localStorage.setItem('hasReloaded', 'true');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    fetch(`${getQuestionByReceiverId}${venueOwnerId}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const question = data.data.Questions;
        console.log(question);
        setQuestion(question);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Question value:", question);
      });
  }, []);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  const [searchQuery, setSearchQuery] = useState("");
  const filteredQuestions = Array.isArray(question)
  ? question.filter((question) => {
      const values = Object.values(question).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );

      const isTypeMatch = selectedType.value === "All" || question.question_for === selectedType.value;

      return isSearchMatch && isTypeMatch;
    })
  : [];
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTypeChange = (selectedOption) => {
    setSelectedType(selectedOption);
  };

  return (
    <div>
      {modalVenueOwnerChatIsOpen && (
        <VenueOwnerChart
          onClose={closeVenueOwnerChat}
          questionId={questionId}
        />
      )}
      <Header></Header>

      <main id="main" class="main">
        <div class="pagetitle">
          <h1> Question</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Question</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    onChange={handleSearchChange}
                    value={searchQuery}
                    placeholder="Search..."
                  />
                </div>
                <div class="child" style={{ padding: "5px" }}>
                  <Select
                    options={type}
                    placeholder="Select type"
                    value={selectedType}
                    onChange={handleTypeChange}
                  ></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    {/* <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_pages}
                    >
                      <i class="bi bi-person-add"></i> Add Pages
                    </button> */}
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            {/* <table class="table table-striped  ">
              <thead>
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Question</th>
                  <th scope="col">Listing Details</th>
                  <th scope="col">Customer Details</th>
                  <th scope="col">Date</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Mark</td>
                  <td>Otto</td>
                </tr>
              </tbody>
            </table> */}
            <div class="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : question.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Question</th>
                      <th scope="col">Listing Details</th>
                      <th scope="col">Customer Details</th>
                      <th scope="col">Type</th>
                      <th scope="col">Date</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredQuestions.map((question, index) => (
                      <tr key={question.id}>
                        <th scope="row">{index + 1}</th>

                        <td>
                          {question.question || "--"}
                          <br />
                        </td>
                        <td>
                          {question.question_for === "Venue"
                            ? question.venue
                              ? question.venue.name
                              : "--"
                            : question.news
                            ? question.news.title
                            : "--"}
                        </td>
                        <td>
                          {question.sender.name || "--"}
                          <br />
                          {question.sender.mobileNumber || "--"}
                          <br />
                          {question.sender.email || "--"}
                          <br />
                        </td>
                        <td>
                          {question.question_for || "--"}
                          <br />
                        </td>
                        <td>
                          {formatCreatedAt(question.created_at || "--")}

                          <br />
                        </td>

                        <td>
                          <div className="social-links mt-2">
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={() => openVenueOwnerChat(question.id)}
                            >
                              <i class="bi bi-reply"></i>
                            </a>
                            &nbsp;&nbsp;
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {question.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default VenueownerQuestion;
