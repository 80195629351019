import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import { TailSpin } from "react-loader-spinner";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_pages from "../../Components/Admin_components/add_pages.js";
import Update_pages from "../../Components/Admin_components/update_pages.js";
import Delete_pages from "../../Components/Admin_components/delete_pages.js";
import "../../Api/apiUrls";
import { getAdminPages } from "../../Api/apiUrls";
function Pages() {
  const [modalAdd_pagesIsOpen, setAdd_pagesModalIsOpen] = useState(false);
  const [modalUpdate_pagesIsOpen, setUpdate_pagesModalIsOpen] = useState(false);
  const [modalDelete_pagesIsOpen, setDelete_pagesIsOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedPagesTittle, setSelectedPagesTittle] = useState(null);
  const [selectedPagesName, setSelectedPagesName] = useState(null);
  const [selectedPagesDescription, setSelectedPagesDescription] =
    useState(null);
  const [selectedPagesStatus, setSelectedPagesStatus] = useState(null);
  const [pages, setPages] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const Status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredPages = Array.isArray(pages)
    ? pages.filter((page) => {
        const isStatusMatch =
          !selectedStatus ||
          selectedStatus === "All" ||
          page.status === selectedStatus;
        const isSearchMatch =
          page.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          page.description.toLowerCase().includes(searchQuery.toLowerCase());
        return isStatusMatch && isSearchMatch;
      })
    : [];

  const openAdd_pages = () => {
    closeAdd_pages();
    setAdd_pagesModalIsOpen(true);
  };

  const closeAdd_pages = () => {
    setAdd_pagesModalIsOpen(false);
  };

  const openUpdate_pages = (
    UserId,
    PagesTittle,
    PagesDescription,
    PagesName,
    PagesStatus
  ) => {
    setSelectedUserId(UserId);
    setSelectedPagesName(PagesName);
    setSelectedPagesTittle(PagesTittle);
    setSelectedPagesDescription(PagesDescription);
    setSelectedPagesStatus(PagesStatus);
    closeUpdate_pages();
    setUpdate_pagesModalIsOpen(true);
  };

  const closeUpdate_pages = () => {
    setUpdate_pagesModalIsOpen(false);
  };
  const openDelete_pages = (UserId) => {
    setSelectedUserId(UserId);
    closeDelete_pages();
    setDelete_pagesIsOpen(true);
  };

  const closeDelete_pages = () => {
    setDelete_pagesIsOpen(null);
  };

  useEffect(() => {
    fetch(getAdminPages)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const pages = data.data.Pages;
        setPages(pages);
        console.log(pages);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  return (
    <div>
      <Header></Header>
      {modalAdd_pagesIsOpen && <Add_pages onClose={closeAdd_pages} />}
      {modalUpdate_pagesIsOpen && (
        <Update_pages UserId={selectedUserId} onClose={closeUpdate_pages} />
      )}

      <main id="main" class="main">
        <div class="pagetitle">
          <h1> Pages</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Pages</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    value={searchQuery}
                    onChange={handleSearchInputChange}
                    placeholder="Search..."
                  />
                </div>
                <div className="child ml-2">
                  <Select
                    options={Status}
                    placeholder="Select Status"
                    onChange={handleStatusChange}
                  ></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_pages}
                    >
                      <i class="bi bi-person-add"></i> Add Pages
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
            {isLoading === true ? (
              <div
                style={{
                  marginTop: "50px",
                  display: "flex",
                  marginLeft: "50%",
                }}
              >
                <TailSpin color="black" width={50} />
              </div>
            ) : pages.length > 0 ? (
              <table class="table table-striped ">
                <thead>
                  <tr>
                    <th scope="col">Sr.No</th>
                    <th scope="col">Status</th>
                    <th scope="col">Name</th>
                    <th scope="col">Title on Page</th>
                    <th scope="col">Created On</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredPages.map((pages, index) => (
                    <tr key={pages.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{pages.status || "-"}</td>
                      <td>{pages.name || "-"}</td>
                      <td>{pages.title || "-"}</td>
                      <td>{formatCreatedAt(pages.created_at)}</td>
                      <td>
                        <div className="social-links mt-2">
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              openUpdate_pages(
                                pages.id,
                                pages.name,
                                pages.title,
                                pages.description,

                                { value: pages.id, label: pages.status }
                              )
                            }
                          >
                            <i class="bi bi-pencil"></i>
                          </a>
                          &nbsp;&nbsp;
                          <a
                            style={{ cursor: "pointer" }}
                            onClick={() => openDelete_pages(pages.id)}
                          >
                            {" "}
                            <i class="bi bi-trash3"></i>
                          </a>
                          &nbsp;
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <h3 style={{ textAlign: "center" }}> No data available </h3>
            )}
            {modalUpdate_pagesIsOpen && (
              <Update_pages
                UserId={selectedUserId}
                PagesTittle={selectedPagesTittle}
                PagesName={selectedPagesName}
                PagesDescription={selectedPagesDescription}
                PagesStatus={selectedPagesStatus}
                onClose={closeUpdate_pages}
              />
            )}
            {modalDelete_pagesIsOpen && (
              <Delete_pages
                UserId={selectedUserId}
                onClose={closeDelete_pages}
              />
            )}
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredPages.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Pages;
