import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_appointment.css";

function Add_Customer_Appointment({ onClose }) {
  const [
    modalAdd_Customer_AppointmentIsOpen,
    setAdd_Customer_AppointmentModalIsOpen,
  ] = useState("");
  // const [partnerName, setPartnerName] = useState("");
  // const [email, setEmail] = useState("");
  // const [link, setLink] = useState("");
  // const [file, setIconFile] = useState();
  const [isAddingCustomer_Appointment, setIsAddingCustomer_Appointment] =
    useState(false);

  const closeAdd_Customer_Appointment = () => {
    setAdd_Customer_AppointmentModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   setIconFile(file);
  //   console.log(file);

  //   const formData = new FormData();
  //   formData.append("image", file);
  //   const reader = new FileReader();
  //   reader.onload = function (e) {
  //     const imgElement = document.getElementById("selectedImg");
  //     imgElement.src = e.target.result;
  //   };
  //   reader.readAsDataURL(file);
  // };

  // async function add_banners(partnerName, file, email, link) {
  //   try {
  //     const formData = new FormData();
  //     formData.append("name", partnerName);
  //     formData.append("image", file);
  //     formData.append("e_mail", email);
  //     formData.append("website_link", link);

  //     const response = await fetch(
  //       "http://44.207.141.37:7000/partners/add-partners/",
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     );

  //     if (response.ok) {
  //       return true;
  //     } else {
  //       throw new Error("Failed to add Partners");
  //     }
  //   } catch (error) {
  //     console.error("Error adding partner:", error);
  //     return false;
  //   }
  // }

  // const handleAddPartner = async () => {
  //   if (!partnerName) {
  //     alert("Please fill in all fields");
  //     return;
  //   }
  //   setIsAddingPartner(true);
  //   const result = await add_banners(partnerName, file, email, link);
  //   setIsAddingPartner(false);
  //   if (result) {
  //     alert("Partners Added Successfully");
  //     console.log(partnerName);
  //     window.location.reload();
  //     onClose();
  //   } else {
  //     alert("Error Adding Partners");
  //   }
  // };

  return (
    <div>
      {modalAdd_Customer_AppointmentIsOpen && (
        <add_appointment onClose={closeAdd_Customer_Appointment} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "75%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Appointment</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Partner Name<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder="Enter Partner Name"
                  />

                  <div class="valid-feedback">Thank you!</div>
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputDocument">
                    Icon
                  </label>
                  <br />
                  <input
                    type="file"
                    id="selectedImg"
                    name="document"
                    accept="image/*"
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Email<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="email"
                    placeholder="Enter Email"
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Link<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="link"
                    placeholder="Enter Link"
                  />
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingCustomer_Appointment === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#51cbce ", border: "none" }}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_Customer_Appointment;
