import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import Add_appointment from "../../Components/Admin_components/add_appointment.js";
import Delete_appointment from "../../Components/Admin_components/delete_appointment.js";
import Update_appointment from "../../Components/Admin_components/update_appointment.js";
import Report from "../../Components/Admin_components/report.js";
import "../../Api/apiUrls";
import { getAdminAppointment, appointmentExcel } from "../../Api/apiUrls";

function Appointment() {
  const [modalAdd_appointmentIsOpen, setAdd_appointmentModalIsOpen] =
    useState("");
  const [modalDelete_appointmentIsOpen, setDelete_appointmentIsOpen] =
    useState(false);
  const [modalUpdate_appointmentIsOpen, setUpdate_appointmentModalIsOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [appointment, setAppointment] = useState([]);
  const [modalReportIsOpen, setReportModalIsOpen] = useState("");
  const openReport = (userId) => {
    setSelectedUserId(userId);
    closeReport();
    setReportModalIsOpen(true);
  };

  const closeReport = () => {
    setReportModalIsOpen(false);
  };

  const type = [
    { value: "All", label: "All" },
    { value: "Workshop", label: "Workshop" },
    { value: "Venue", label: "Venue" },
  ];
  const status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
    { value: "Cancelled", label: "Cancelled" },
    { value: "Reviewing", label: "Reviewing" },
  ];

  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredAppointments, setFilteredAppointments] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const filteredAppointments = appointment.filter((appointment) => {
      const isTypeMatch =
        !selectedType ||
        selectedType.value === "All" ||
        appointment.type === selectedType.value;

      const isStatusMatch =
        !selectedStatus ||
        selectedStatus.value === "All" ||
        appointment.status === selectedStatus.value;

      // Check if any value in the appointment matches the search query
      const values = Object.values(appointment).map((value) =>
        typeof value === "string" ? value.toLowerCase() : ""
      );
      const isSearchMatch = values.some((value) =>
        value.includes(searchQuery.toLowerCase())
      );

      return isTypeMatch && isStatusMatch && isSearchMatch;
    });

    setFilteredAppointments(filteredAppointments);
  }, [selectedType, selectedStatus, appointment, searchQuery]);
  const convertTimeTo12HourFormat = (time24hr) => {
    // Check if time24hr is null or not in the expected format
    if (!time24hr || typeof time24hr !== 'string' || !time24hr.includes(" - ")) {
      return "--"; // Return a default value if the input is invalid
    }

    const [start, end] = time24hr.split(" - ");

    const convertTo12HourFormat = (time) => {
      const [hour, minutes] = time.split(":");
      const isPM = parseInt(hour, 10) >= 12;

      const convertedHour = isPM ? (parseInt(hour, 10) % 12 || 12) : (parseInt(hour, 10) || 12);
      const period = isPM ? "PM" : "AM";

      return `${convertedHour}:${minutes} ${period}`;
    };

    const convertedStart = convertTo12HourFormat(start);
    const convertedEnd = convertTo12HourFormat(end);

    return `${convertedStart} - ${convertedEnd}`;
  };
  const openAdd_appointment = () => {
    closeAdd_appointment();
    setAdd_appointmentModalIsOpen(true);
  };

  const closeAdd_appointment = () => {
    setAdd_appointmentModalIsOpen(false);
  };
  const openUpdate_appointment = () => {
    closeUpdate_appointment();
    setUpdate_appointmentModalIsOpen(true);
  };

  const closeUpdate_appointment = () => {
    setUpdate_appointmentModalIsOpen(false);
  };
  const openDelete_appointment = (userId) => {
    setSelectedUserId(userId);
    closeDelete_appointment();
    setDelete_appointmentIsOpen(true);
  };

  const closeDelete_appointment = () => {
    setDelete_appointmentIsOpen(null);
  };

  useEffect(() => {
    fetch(getAdminAppointment)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const appointment = data.data.Appointment;
        console.log(appointment);
        setAppointment(appointment);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
        console.log("Appointment value:", Appointment);
      });
  }, []);
  const formatCreatedAt = (createdAt) => {
    const createdAtDate = new Date(createdAt);
    return createdAtDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };
  // const formatTimeCreatedAt = (createdAt) => {
  //   const createdAtTime = new Date(createdAt);
  //   return createdAtTime.toLocaleTimeString("en-GB", {
  //     hour: "numeric",
  //     minute: "numeric",
  //     second: "numeric",
  //   });
  // };
  const formatDateTime = (dateTime) => {
    const formattedDate = dateTime.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });

    const formattedTime = dateTime.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    });

    return `${formattedDate} ${formattedTime}`;
  };
  // const handleDownloadClick = () => {
  //   fetch(appointmentExcel, {
  //     method: "GET", // or 'POST', depending on your API
  //     headers: {
  //       "Content-Type": "application/json",
  //       // Add other headers as needed
  //     },
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.blob(); // Assuming the response is a file
  //     })
  //     .then((blob) => {
  //       // Create a link element
  //       const url = window.URL.createObjectURL(new Blob([blob]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download"); // Set the file name and extension

  //       // Append the link to the body
  //       document.body.appendChild(link);

  //       // Programmatically click the link to trigger the download
  //       link.click();

  //       // Remove the link when done
  //       link.parentNode.removeChild(link);
  //     })
  //     .catch((error) => {
  //       console.error("There was an error with the download:", error);
  //     });
  // };
  function handleDownloadClick() {
    window.open(appointmentExcel, "_blank");
  }

  return (
    <div>
      <Header></Header>
      {modalAdd_appointmentIsOpen && (
        <Add_appointment onClose={closeAdd_appointment} />
      )}

      {modalDelete_appointmentIsOpen && (
        <Delete_appointment
          userId={selectedUserId}
          onClose={closeDelete_appointment}
        />
      )}
      {modalUpdate_appointmentIsOpen && (
        <Update_appointment onClose={closeUpdate_appointment} />
      )}
      {modalReportIsOpen && (
        <Report userId={selectedUserId} onClose={closeReport} />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Appointment</h1>
          {/* <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Appointment</li>
            </ol>
          </nav> */}
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-2" style={{ padding: "5px" }}>
                <input
                  id="myInput"
                  type="text"
                  style={{
                    borderRadius: "5px",
                    height: "37px",
                    padding: "10px",
                    border: "1px solid lightgrey ",
                    width: "100%",
                  }}
                  value={searchQuery}
                  onChange={handleSearchInputChange}
                  placeholder="Search..."
                />
              </div>
              <div className="col-md-2" style={{ padding: "5px" }}>
                <Select
                  options={type}
                  value={selectedType}
                  onChange={(selectedOption) => setSelectedType(selectedOption)}
                  placeholder="Select Type"
                />
              </div>
              <div className="col-md-2" style={{ padding: "5px" }}>
                <Select
                  options={status}
                  value={selectedStatus}
                  onChange={(selectedOption) =>
                    setSelectedStatus(selectedOption)
                  }
                  placeholder="Select Status"
                />
              </div>
              <div className="col-md-2" style={{ padding: "5px" }}>
                <a>
                  <button
                    id="btnNewUser"
                    type="button"
                    data-toggle="modal"
                    data-target="#modalAdd"
                    className="btn-round"
                    onClick={openAdd_appointment}
                  >
                    <i className="bi bi-person-add"></i> Add Appointment
                  </button>
                </a>
              </div>
              <div
                className="col-md-2"
                style={{ cursor: "pointer", padding: '5px' }}
                onClick={handleDownloadClick}
              >
                Download
                <i
                  style={{ fontSize: "20px", marginLeft: "10px" }}
                  class="bi bi-download"
                ></i>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            ></div>
            <div className="table-responsive">
              {isLoading === true ? (
                <div style={{ marginTop: "50px", marginLeft: "50%" }}>
                  <TailSpin color="black" width={50} />
                </div>
              ) : appointment.length > 0 ? (
                <table class="table table-striped  ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Type</th>
                      <th scope="col">Gift</th>
                      <th scope="col">Appointment Date/Slot </th>
                      <th scope="col">Customer Details</th>
                      <th scope="col">Instructor/Venue Owner Details</th>
                      <th scope="col">Payment Amount</th>
                      <th scope="col">Booking Date Time</th>
                      <th scope="col">Status</th>
                      <th scope="col">Cancellation Reason</th>
                      <th scope="col">Report</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody id="tableBody">
                    {filteredAppointments.map((appointment, index) => (
                      <tr key={appointment.id}>
                        <th scope="row">{index + 1}</th>

                        {
                          <td>
                            {/* {appointment.user.name || "--"}
                            <br />
                            {appointment.type || "--"}
                            <br /> */}
                            {appointment.type === "Venue" ? (
                              <>{appointment.bookingSlot?.venue.name || "--"}</>
                            ) : (
                              <>
                                {appointment.workshopSlot?.workshop.name ||
                                  "--"}
                              </>
                            )}
                            <br />
                          </td>
                        }
                        <td>
                          {appointment.type || "--"}
                          <br />
                        </td>

                        <td>
                          {appointment.gift?.name || "--"}
                          <br />
                          {appointment.gift?.mobileNumber || "--"}
                          <br />
                          {appointment.gift?.email || "--"}
                          <br />
                        </td>

                        <td>
                          Date:
                          {appointment.type === "Workshop" ? (
                            <>
                              {formatCreatedAt(
                                appointment.workshopSlot?.date || "--"
                              )}
                            </>
                          ) : (
                            <>
                              {formatCreatedAt(
                                appointment.bookingSlot?.startDate || "--"
                              )}
                            </>
                          )}
                          <br />
                          Slot:{" "}
                          {convertTimeTo12HourFormat(appointment?.slot || "--")}
                          <br />
                          <br />
                        </td>
                        <td>
                          {appointment.user.name || "--"}
                          <br />
                          {appointment.user.email || "--"}
                          <br />
                          {appointment.user.mobileNumber || "--"}
                        </td>

                        <td>
                          {appointment.type === "Workshop" ? (
                            <>
                              {appointment.workshopSlot?.workshop
                                .workshopInstructor.name || "--"}
                              <br />
                              {appointment.workshopSlot?.workshop
                                .workshopInstructor.email || "--"}
                              <br />
                              {appointment.workshopSlot?.workshop
                                .workshopInstructor.mobileNumber || "--"}
                            </>
                          ) : (
                            <>
                              {appointment.bookingSlot?.venue.venue_owner
                                .name || "--"}
                              <br />
                              {appointment.bookingSlot?.venue.venue_owner
                                .email || "--"}
                              <br />
                              {appointment.bookingSlot?.venue.venue_owner
                                .mobileNumber || "--"}
                            </>
                          )}
                        </td>
                        <td>
                          {appointment.transaction?.amount || appointment?.price || "--"}
                        </td>
                        <td>
                          {formatDateTime(new Date(appointment.created_at))}
                        </td>
                        <td>{appointment.status || "-"}</td>
                        <td>{appointment.reasonOfCancellation || "-"}</td>
                        <td> {appointment.report_text || "-"}</td>
                        <td>
                          <button
                            id="btnNewUserCancel"
                            type="button"
                            data-toggle="modal"
                            data-target="#modalAdd"
                            className="btn-round"
                            onClick={() => openReport(appointment.id)}
                          >
                            <i></i> Report
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {filteredAppointments.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Appointment;
