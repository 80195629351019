import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_appointment.css";

function Update_appointment({ onClose }) {
  const [modalUpdate_appointmentIsOpen, setUpdate_appointmentModalIsOpen] =
    useState("");
  // const [partnerName, setPartnerName] = useState("");
  // const [email, setEmail] = useState("");
  // const [link, setLink] = useState("");
  // const [file, setIconFile] = useState();

  const closeUpdate_appointment = () => {
    setUpdate_appointmentModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   setIconFile(file);
  //   console.log(file);

  //   const formData = new FormData();
  //   formData.append("image", file);
  //   const reader = new FileReader();
  //   reader.onload = function (e) {
  //     const imgElement = document.getElementById("selectedImg");
  //     imgElement.src = e.target.result;
  //   };
  //   reader.readAsDataURL(file);
  // };

  // async function add_banners(partnerName, file, email, link) {
  //   try {
  //     const formData = new FormData();
  //     formData.append("name", partnerName);
  //     formData.append("image", file);
  //     formData.append("e_mail", email);
  //     formData.append("website_link", link);

  //     const response = await fetch(
  //       "http://44.207.141.37:7000/partners/add-partners/",
  //       {
  //         method: "POST",
  //         body: formData,
  //       }
  //     );

  //     if (response.ok) {
  //       return true;
  //     } else {
  //       throw new Error("Failed to add Partners");
  //     }
  //   } catch (error) {
  //     console.error("Error adding partner:", error);
  //     return false;
  //   }
  // }

  // const handleAddPartner = async () => {
  //   if (!partnerName) {
  //     alert("Please fill in all fields");
  //     return;
  //   }
  //   setIsAddingPartner(true);
  //   const result = await add_banners(partnerName, file, email, link);
  //   setIsAddingPartner(false);
  //   if (result) {
  //     alert("Partners Added Successfully");
  //     console.log(partnerName);
  //     window.location.reload();
  //     onClose();
  //   } else {
  //     alert("Error Adding Partners");
  //   }
  // };

  return (
    <div>
      {modalUpdate_appointmentIsOpen && (
        <Update_appointment onClose={closeUpdate_appointment} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
              // overflowY: "scroll",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Appointment</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Type<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Gift<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Appointment Date<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Customer Details<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Instructor Details<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Payment Amount<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Payment Mode<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Booking Date Time<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Review/Cancellation Reason<font color="red">*</font>
                  </label>
                  <input class="form-control" id="inputFullName" type="text" />
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              {/* <div>
                <ThreeDots color="#000" height={50} width={50} />
              </div> */}

              <button
                id="btnAdd"
                type="button"
                class="btn btn-primary "
                style={{ background: "#000 ", border: "none" }}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_appointment;
