import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_packages.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { UpdateAdminNews, updateWeAreHiring } from "../../Api/apiUrls";
function Update_Hiring({
  hiringId,
  hiringTittle,
  hiringExperience,
  hiringDescription,
  hiringPosition,
  hiringSkills,
  hiringLocation,
  hiringQualification,
  hiringRolesRes,
  hiringWhyWorkWithUs,
  hiringStatus,
  onClose,
}) {
  const [modalUpdate_HiringIsOpen, setUpdate_HiringModalIsOpen] = useState("");
  const [newHiringTittle, setNewHiringTittle] = useState(hiringTittle);
  const [newHiringDescription, setNewHiringDescription] =
    useState(hiringDescription);
  const [newHiringExperience, setNewHiringExperience] =
    useState(hiringExperience);

  const [newHiringPosition, setNewHiringPosition] = useState(hiringPosition);
  const [newHiringSkills, setNewHHiringSkills] = useState(hiringSkills);
  const [newHiringLocation, setNewHiringLocation] = useState(hiringLocation);
  const [newHiringQualification, setNewHiringQualification] =
    useState(hiringQualification);
  const [newHiringRolesRes, setNewHiringRolesRes] = useState(hiringRolesRes);
  const [newHiringWhyWorkWithUs, setNewHiringWhyWorkWithUs] =
    useState(hiringWhyWorkWithUs);
  useState("");
  const [newHiringStatus, setNewHiringStatus] = useState(hiringStatus);

  const [isUpdatingHiring, setIsUpdatingHiring] = useState(false);

  const status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const closeUpdate_Hiring = () => {
    setUpdate_HiringModalIsOpen(false);
  };

  const handleStatusChange = (selectedOption) => {
    setNewHiringStatus(selectedOption);
  };

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  // Log author options for debugging

  const handleUpdate = () => {
    setIsUpdatingHiring(true);
    const formData = new FormData();
    formData.append("we_are_hiring_id", hiringId);
    formData.append("title", newHiringTittle);
    formData.append("experience", newHiringExperience);
    formData.append("description", newHiringDescription);
    formData.append("open_positions", newHiringPosition);
    formData.append("skills_required", newHiringSkills);
    formData.append("location", newHiringLocation);
    formData.append("qualification", newHiringQualification);
    formData.append("roles_and_responsibilities", newHiringRolesRes);
    formData.append("why_work_with_us", newHiringWhyWorkWithUs);
    formData.append("status", newHiringStatus.label);

    fetch(`${updateWeAreHiring}`, {
      method: "PUT",

      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated!!");
          setIsUpdatingHiring(false);
          window.location.reload();
          onClose();
        } else {
          console.error("Error updating we are hiring:", data.message);
          setIsUpdatingHiring(false);
        }
      })
      .catch((error) => {
        console.error("Error updating we are hiring:", error);
        setIsUpdatingHiring(false);
      });
  };

  return (
    <div>
      {modalUpdate_HiringIsOpen && (
        <Update_Hiring onClose={closeUpdate_Hiring} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update We are Hiring</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Tittle<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newHiringTittle}
                    onChange={(e) => setNewHiringTittle(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Experience<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newHiringExperience}
                    onChange={(e) => setNewHiringExperience(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Description<font color="red"></font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newHiringDescription}
                    onChange={(e) => setNewHiringDescription(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Position<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newHiringPosition}
                    onChange={(e) => setNewHiringPosition(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Skills<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newHiringSkills}
                    onChange={(e) => setNewHHiringSkills(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Location<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newHiringLocation}
                    onChange={(e) => setNewHiringLocation(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Qualification<font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newHiringQualification}
                    onChange={(e) => setNewHiringQualification(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Roles & Responsibilities
                    <font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newHiringRolesRes}
                    onChange={(e) => setNewHiringRolesRes(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Why Work With Us
                    <font color="red"></font>
                  </label>
                  <textarea
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    placeholder=""
                    value={newHiringWhyWorkWithUs}
                    onChange={(e) => setNewHiringWhyWorkWithUs(e.target.value)}
                  />
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Status: <font color="red"></font>
                  </label>
                  <div class="child ml-2">
                    <Select
                      placeholder="Status"
                      options={status}
                      value={newHiringStatus}
                      onChange={handleStatusChange}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isUpdatingHiring === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleUpdate}
                >
                  Update
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_Hiring;
