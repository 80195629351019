import React, { useState, useEffect, useRef } from "react";
import {
    API_URL,
} from "../../Api/apiUrls";

function GenerateInvoice({ slotId, onClose }) {
    console.log(slotId);

    const [modalGenerateInvoiceIsOpen, setGenerateInvoiceIsOpen] = useState("");
    const closeGenerateInvoice = () => {
        setGenerateInvoiceIsOpen(false);
    };

    const [invoiceData, setInvoiceData] = useState(null);
    const userId = localStorage.getItem('user_id');
    useEffect(() => {
        fetch(`${API_URL}/generate_invoice/get_invoice_data/?user_id=${userId}&slot_id=${slotId}`)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    setInvoiceData(data);
                } else {
                    console.error('Failed to fetch invoice data:', data.message);
                }
            })
            .catch(error => console.error('Error fetching invoice data:', error));
    }, []);

    // const downloadInvoice = async () => {
    //     const apiUrl = `${API_URL}/generate_invoice/generate/?user_id=${userId}&slot_id=${slotId}`;

    //     try {
    //         const response = await fetch(apiUrl, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });

    //         if (response.ok) {
    //             const invoiceBlob = await response.blob();
    //             const invoiceUrl = window.URL.createObjectURL(invoiceBlob);

    //             const a = document.createElement('a');
    //             a.href = invoiceUrl;
    //             a.download;
    //             document.body.appendChild(a);
    //             a.click();
    //             a.remove();
    //         } else {
    //             console.error('Failed to generate invoice.');
    //         }
    //     } catch (error) {
    //         console.error('Error generating invoice:', error);
    //     }
    // };

    function downloadInvoice() {
        window.open(`${API_URL}/generate_invoice/generate/?user_id=${userId}&slot_id=${slotId}`, "_blank");
    }

    function numberToWords(num) {
        const a = [
            '', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten',
            'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'
        ];
        const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'];

        if ((num = num.toString()).length > 9) return 'overflow';
        const n = ('000000000' + num).substr(-9).match(/^(\d{2})(\d{3})(\d{3})(\d{3})$/);
        if (!n) return '';
        let str = '';

        str += (n[1] != 0) ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + ' crore ' : '';
        str += (n[2] != 0) ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + ' lakh ' : '';
        str += (n[3] != 0) ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + ' thousand ' : '';
        str += (n[4] != 0) ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + ' rupees ' : '';

        return str.trim();
    }

    function convertToWords(num) {
        // Handle negative numbers
        const isNegative = num < 0;
        const absoluteNum = Math.abs(num);

        // Split the number into rupees and paise
        const [rupees, paise] = absoluteNum.toFixed(2).split('.');

        // Convert the rupees part to words
        let wordString = numberToWords(rupees) + ' rupees';

        // Convert the paise part to words if it's greater than 0
        if (paise && Number(paise) > 0) {
            wordString += ' and ' + numberToWords(Number(paise)) + ' paise';
        }

        // Handle negative case
        return isNegative ? 'minus ' + wordString : wordString;
    }




    return (
        <div>
            {modalGenerateInvoiceIsOpen && (
                <GenerateInvoice onClose={closeGenerateInvoice} />
            )}

            <div className="popup-background" style={{ zIndex: '999' }}>
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "85%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Invoice Summary</b>
                            </h5>

                            <button
                                type="button"
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ overflowY: 'scroll' }}>
                            <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', color: 'black' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <p>Name of Firm/Name of Instructor: {invoiceData?.name}</p>
                                    <p>Address of Firm/ Instructor: {invoiceData?.address}</p>
                                </div>
                                <h4 style={{ textAlign: 'center' }}>BILL OF SUPPLY</h4>
                                <div className="col-md-12 row" style={{ justifyContent: 'space-between', marginTop: '20px' }}>
                                    <div className="col-md-10">
                                        <p style={{ marginBottom: '0px' }}>Name of Buyer: ARTIQUITY EDUCATION LLP</p>
                                        <p style={{ marginBottom: '0px' }}>Address of Buyer: 801 BramhaCorp Vantage Tower,Ram Nagar, Bavdhan-411021,Pune</p>
                                        <p style={{ marginBottom: '0px' }}>Contact: +917066263675</p>
                                        <p style={{ marginBottom: '0px' }}>GSTIN/UIN: 27ACAFA1203F1Z7</p>
                                    </div>
                                    {/* <div className="col-md-5">
                                        <p style={{ marginBottom: '0px' }}>Email: <a href="mailto:cs@artiquity.in">cs@artiquity.in</a></p>
                                        <p style={{ marginBottom: '0px' }}>Contact: +917066263675</p>
                                        <p style={{ marginBottom: '0px' }}>Website: <a href="https://artiquity.in">https://artiquity.in</a></p>
                                    </div> */}
                                </div>
                                <br />
                                <div className="col-md-12 row" style={{ justifyContent: 'space-between', marginTop: '20px' }}>
                                    <div className="col-md-6">
                                        <p style={{ marginBottom: '0px' }}>Workshop Name: {invoiceData?.name_of_workshop} </p>
                                        <p style={{ marginBottom: '0px' }}>Slot: {invoiceData?.slot} </p>
                                    </div>
                                    <div className="col-md-6">
                                        <p style={{ marginBottom: '0px' }}>Invoice No.:{invoiceData?.invoice_number}  </p>
                                        <p style={{ marginBottom: '0px' }}>Date:{invoiceData?.date} </p>
                                        <p style={{ marginBottom: '0px' }}>Due Date: {invoiceData?.due_date}</p>
                                    </div>
                                </div>

                                <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>DESCRIPTION</th>
                                            <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>HSN/SAC</th>
                                            <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>AMOUNT</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoiceData?.data_to_show_on_table?.map((item, index) => (
                                            <tr key={index}>
                                                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>{item.name}</td>
                                                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>9984</td>
                                                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {item.total_price}</td>
                                            </tr>
                                        ))}

                                        <tr>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>IGCST</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>CGST</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}> 9%</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.cgst_amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>SGST</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}> 9%</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.sgst_amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>GST</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}> 18%</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.gst_amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px', fontWeight: 'bold' }}>GRAND TOTAL</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px', fontWeight: 'bold' }}>Rs. {invoiceData?.total_amount} <p><strong>Rs. ({convertToWords(invoiceData?.total_amount)}) only</strong></p></td>
                                        </tr>
                                    </tbody>
                                </table>


                                <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>HSN/SAC</th>
                                            <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Taxable Value</th>
                                            <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }} colspan="2">CGST</th>
                                            <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }} colspan="2">SGST/UTGST</th>
                                            <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Total Tax Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>9984</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.description_amount}</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>9%</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.cgst_amount}</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>9%</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.sgst_amount}</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.gst_amount}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Total</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.description_amount}</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.cgst_amount}</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.sgst_amount}</td>
                                            <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.gst_amount}</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div style={{ marginTop: '20px' }}>
                                    <h6 style={{ fontWeight: 'bold' }}>Terms & Conditions</h6>
                                    <p style={{ marginBottom: '0px' }}>1. This invoice is recognised subject to realization of the payment</p>
                                    <p style={{ marginBottom: '0px' }}>2. Refer to terms & conditions at <a href="http://www.artiquity.in">www.artiquity.in</a></p>
                                    <p style={{ marginBottom: '0px' }}>3. All disputes subject to Pune jurisdiction only</p>
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <p style={{ marginBottom: '0px' }}>GSTIN/UIN: {invoiceData?.gst} </p>
                                    <p style={{ marginBottom: '0px' }}>PAN: {invoiceData?.pan === "null" ? "--" : invoiceData?.pan}</p>

                                    <h6 style={{ fontWeight: 'bold' }}>Bank Details : {invoiceData?.bank_details}</h6>
                                </div>
                            </div>
                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                class="btn btn-primary "
                                style={{ background: "#000 ", border: "none" }}
                                onClick={downloadInvoice}

                            >
                                Download Invoice
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default GenerateInvoice
