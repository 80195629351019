import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./update_location.css";
import Select from "react-select";
import "../../Api/apiUrls";
import { UpdateAdminArea, getAdminLocationCity } from "../../Api/apiUrls";
function Update_location({
  onClose,
  locationId,
  locationName,
  locationStatus,
  selectedCity,
}) {
  const [modalUpdate_locationIsOpen, setUpdate_locationModalIsOpen] =
    useState("");
  const [newLocationName, setNewLocationName] = useState(locationName);
  const [newLocationStatus, setNewLocationStatus] = useState(locationStatus);
  // const [newLocationCity, setNewLocationCity] = useState(selectedCity);
  const [isUpdatingLocation, setIsUpdatingLocation] = useState(false);
  const [newSelectedCity, setNewSelectedCity] = useState(selectedCity);
  const [city, setCity] = useState([]);
  const closeUpdate_location = () => {
    setUpdate_locationModalIsOpen(false);
  };

  useEffect(() => {
    fetch(`${getAdminLocationCity}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (Array.isArray(data.data.LocationCity)) {
            setCity(data.data.LocationCity);
          } else {
            console.error("Area data is not an array:", data.data.LocationCity);
          }
        } else {
          console.error("Error fetching area:", data.message);
        }
      })
      .catch((error) => {
        console.error("Error fetching area:", error);
      });
  }, []);
  const newCityOptions = city.map((city) => ({
    value: city.id,
    label: city.name,
  }));

  const handleCityChange = (newSelectedOptions) => {
    console.log(newSelectedOptions);
    setNewSelectedCity(newSelectedOptions);

    console.log(newSelectedOptions);
  };
  const handleStatusChange = (selectedOption) => {
    setNewLocationStatus(selectedOption);
  };
  const Status = [
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);
  const handleUpdate = () => {
    setIsUpdatingLocation(true);

    const formData = new FormData();
    formData.append("LocationArea_id", locationId);
    formData.append("name", newLocationName);
    formData.append("city", newSelectedCity.value);
    formData.append("status", newLocationStatus.label);

    fetch(UpdateAdminArea, {
      method: "PUT",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Updated");
          setIsUpdatingLocation(false);

          window.location.reload();
          onClose();
        } else {
          console.error("Error updating category:", data.message);
          setIsUpdatingLocation(false);
        }
      })
      .catch((error) => {
        console.error("Error updating category:", error);
        setIsUpdatingLocation(false);
      });
  };

  return (
    <div>
      {modalUpdate_locationIsOpen && (
        <Update_location onClose={closeUpdate_location} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "80",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Update Area</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "scroll", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={newLocationName}
                    onChange={(e) => setNewLocationName(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    City
                  </label>
                  <Select
                    options={newCityOptions}
                    //  value={selectedCategories}
                    value={newSelectedCity}
                    //  value={selectedCategories.length > 0 ? selectedCategories[0] : null}
                    onChange={handleCityChange}
                  ></Select>
                </div>

                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Select Status
                  </label>
                  <div class="child ml-2">
                    <Select
                      value={newLocationStatus}
                      onChange={handleStatusChange}
                      options={Status}
                    ></Select>
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>

              {/* <div>
                <ThreeDots color="#000" height={50} width={50} />
              </div> */}

              <button
                id="btnAdd"
                type="button"
                class="btn btn-primary "
                style={{ background: "#000 ", border: "none" }}
                onClick={handleUpdate}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Update_location;
