import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import { TailSpin } from "react-loader-spinner";
import "../../Api/apiUrls";
import { getAdminSubscribers } from "../../Api/apiUrls";
function Subscriber() {
  const [subscriber, setSubscriber] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch(getAdminSubscribers)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const subscriber = data.data.NewsLetter;
        setSubscriber(subscriber);
        console.log(subscriber);
      })
      .catch((error) => console.error("Error fetching data:", error))
      .finally(() => {
        setIsLoading(false);
      });
  }, []);
  return (
    <div>
      <Header></Header>

      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Subscriber's List</h1>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2"></div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            {/* <table class="table  ">
              <thead class="table-active">
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Name</th>

                  <th scope="col">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                </tr>
              </tbody>
            </table> */}
            <div class="table-responsive">
              {isLoading === true ? (
                <div
                  style={{
                    marginTop: "50px",
                    display: "flex",
                    marginLeft: "50%",
                  }}
                >
                  <TailSpin color="black" width={50} />
                </div>
              ) : subscriber.length > 0 ? (
                <table class="table  table-striped ">
                  <thead>
                    <tr>
                      <th scope="col">Sr.No</th>

                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscriber.map((subscriber, index) => (
                      <tr key={subscriber.id}>
                        <th scope="row">{index + 1}</th>

                        <td>{subscriber.name || "-"}</td>
                        <td>{subscriber.email || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <h3 style={{ textAlign: "center" }}> No data available </h3>
              )}
            </div>
          </div>
          <div>
            {" "}
            <p
              style={{
                backgroundColor: "#000",
                color: "white",
                padding: "10px",
              }}
            >
              {subscriber.length} Total
            </p>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default Subscriber;
