
import React, { useState, useEffect, useRef } from "react";
import "../../Api/apiUrls.js";
import useRazorpay from "react-razorpay";
import image from "../../Static/img/ARTIQUITY_LOGO.jpeg";
import {
  API_URL,
  getPackageForWorkshop,
  razorpayOrderCreate,
  addTransaction,
  addPackageManagement,
} from "../../Api/apiUrls";
function PackageInvoice({ onClose, idpackage, namepackage, idworkshop, typepackage }) {
  console.log(idpackage);
  console.log(namepackage);
  console.log(idworkshop);
  console.log(typepackage);

  const [modalPackageInvoiceIsOpen, setPackageInvoiceIsOpen] = useState("");
  const closePackageInvoice = () => {
    setPackageInvoiceIsOpen(false);
  };

  const [invoiceData, setInvoiceData] = useState(null);
  const userId = localStorage.getItem('user_id');
  useEffect(() => {


    fetch(`${API_URL}/invoice/get-invoice/?user=${userId}&package=${idpackage}`)
      .then(response => response.json())
      .then(data => {
        if (data.success && data.status === 200) {
          setInvoiceData(data.data.invoice_data);
        } else {
          console.error('Failed to fetch invoice data:', data.message);
        }
      })
      .catch(error => console.error('Error fetching invoice data:', error));
  }, []);

  const [Razorpay] = useRazorpay();

  function reloadPage() {
    window.location.reload();
  }

  // const handlePayment = async (totalAmount) => {
  //   try {
  //     const response = await fetch(`${razorpayOrderCreate}`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({ amount: totalAmount * 100, currency: "INR" }),
  //     });

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch");
  //     }

  //     const responseData = await response.json();
  //     console.log(responseData);

  //     if (responseData.data.status === "created") {
  //       let options = {
  //         key: "rzp_live_hKgAxRUVv2F589",
  //         amount: responseData.data.amount,
  //         currency: "INR",
  //         name: "Artiquity",
  //         description: "Transaction",
  //         image: image,
  //         order_id: responseData.data.id,
  //         handler: function (response) {
  //           console.log(response.razorpay_payment_id);
  //           console.log(response.razorpay_order_id);
  //           console.log(response.razorpay_signature);
  //           console.log("Payment Successful ..!!");
  //           alert("Congrats !! Payment Successful !!");

  //           var data = {
  //             title: namepackage,
  //             mode: "Online",
  //             user: userId,
  //             type: typepackage,
  //             amount: totalAmount,
  //             package: idpackage,
  //             transaction_status: "Success",
  //             payment_id: response.razorpay_payment_id,
  //             order_id: response.razorpay_order_id,
  //             signature: response.razorpay_signature,
  //           };

  //           // Conditionally add venue or workshop based on packageType
  //           if (typepackage === "Venue") {
  //             data.venue = idworkshop;
  //           } else if (typepackage === "Workshop") {
  //             data.workshop = idworkshop;
  //           }

  //           fetch(`${addTransaction}`, {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //             body: JSON.stringify(data),
  //           })
  //             .then((response) => {
  //               if (response.ok) {
  //                 return response.json(); // Parse response JSON
  //               } else {
  //                 throw new Error("Failed to submit transaction data");
  //               }
  //             })
  //             .then((responseData) => {
  //               // Check if responseData contains data and Transaction object
  //               if (responseData && responseData.data && responseData.data.Transaction) {
  //                 alert("Data submitted successfully");

  //                 // Extract transaction ID from responseData
  //                 const transactionId = responseData.data.Transaction.id;

  //                 var packageData = {
  //                   paidAmount: totalAmount,
  //                   user: userId,
  //                   paymentMode: "Online",
  //                   package: idpackage,
  //                   transaction: transactionId, // Include transaction ID here
  //                 };

  //                 // Conditionally add venue or workshop based on packageType
  //                 if (typepackage === "Venue") {
  //                   packageData.venue = idworkshop;
  //                 } else if (typepackage === "Workshop") {
  //                   packageData.workshop = idworkshop;
  //                 }

  //                 fetch(`${addPackageManagement}`, {
  //                   method: "POST",
  //                   headers: {
  //                     "Content-Type": "application/json",
  //                   },
  //                   body: JSON.stringify(packageData),
  //                 })
  //                   .then((response) => {
  //                     if (response.ok) {
  //                       alert("Data submitted successfully");
  //                       if (packageData.annual_package) {
  //                         // Store date_of_expiry in localStorage if annual_package is true
  //                         localStorage.setItem("date_of_expiry", packageData.date_of_expiry);
  //                       }
  //                       // reloadPage();
  //                     } else {
  //                       alert("Failed to submit data");
  //                     }
  //                   })
  //                   .catch((error) => {
  //                     console.error("Error:", error);
  //                     alert("An error occurred while submitting data");
  //                   });
  //               } else {
  //                 alert("Failed to submit data");
  //               }
  //             })
  //             .catch((error) => {
  //               console.error("Error:", error);
  //               alert("An error occurred while submitting data");
  //             });
  //         },
  //         prefill: {
  //           name: "",
  //           email: "",
  //           contact: "",
  //         },
  //         notes: {
  //           address: "",
  //         },
  //         theme: {
  //           color: "#000",
  //         },
  //         config: {
  //           display: {
  //             blocks: {
  //               banks: {
  //                 name: "All payment methods",
  //                 instruments: [
  //                   { method: "upi" },
  //                   { method: "card" },
  //                   { method: "wallet" },
  //                   { method: "netbanking" },
  //                 ],
  //               },
  //             },
  //             preferences: {
  //               show_default_blocks: true,
  //             },
  //           },
  //         },
  //       };

  //       let rzp1 = new Razorpay(options);
  //       rzp1.on("payment.failed", function (response) {
  //         console.log(response.error.code);
  //         console.log(response.error.description);
  //         console.log(response.error.source);
  //         console.log(response.error.step);
  //         console.log(response.error.reason);
  //         console.log(response.error.metadata.order_id);
  //         console.log(response.error.metadata.payment_id);
  //         alert("Oops ! Payment Failed !");
  //       });
  //       rzp1.open();
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     alert("Something Went Wrong..!!");
  //   }
  // };
  const handlePayment = async (totalAmount) => {
    try {
      const response = await fetch(`${razorpayOrderCreate}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount: totalAmount * 100, currency: "INR" }),
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch");
      }
  
      const responseData = await response.json();
      console.log(responseData);
  
      if (responseData.data.status === "created") {
        let options = {
          key: "rzp_live_hKgAxRUVv2F589",
          amount: responseData.data.amount,
          currency: "INR",
          name: "Artiquity",
          description: "Transaction",
          image: image,
          order_id: responseData.data.id,
          handler: function (response) {
            console.log(response.razorpay_payment_id);
            console.log(response.razorpay_order_id);
            console.log(response.razorpay_signature);
            console.log("Payment Successful ..!!");
            alert("Congrats !! Payment Successful !!");
  
            var data = {
              title: namepackage,
              mode: "Online",
              user: userId,
              type: typepackage,
              amount: totalAmount,
              package: idpackage,
              transaction_status: "Success",
              payment_id: response.razorpay_payment_id,
              order_id: response.razorpay_order_id,
              signature: response.razorpay_signature,
            };
  
            // Conditionally add venue or workshop based on packageType
            if (typepackage === "Venue") {
              data.venue = idworkshop;
            } else if (typepackage === "Workshop") {
              data.workshop = idworkshop;
            }
  
            fetch(`${addTransaction}`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            })
              .then((response) => {
                if (response.ok) {
                  return response.json(); // Parse response JSON
                } else {
                  throw new Error("Failed to submit transaction data");
                }
              })
              .then((responseData) => {
                // Check if responseData contains data and Transaction object
                if (responseData && responseData.data && responseData.data.Transaction) {
                  alert("Data submitted successfully");
  
                  // Extract transaction ID from responseData
                  const transactionId = responseData.data.Transaction.id;
  
                  var packageData = {
                    paidAmount: totalAmount,
                    user: userId,
                    paymentMode: "Online",
                    package: idpackage,
                    transaction: transactionId, // Include transaction ID here
                  };
  
                  // Conditionally add venue or workshop based on packageType
                  if (typepackage === "Venue") {
                    packageData.venue = idworkshop;
                  } else if (typepackage === "Workshop") {
                    packageData.workshop = idworkshop;
                  }
  
                  fetch(`${addPackageManagement}`, {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(packageData),
                  })
                    .then((response) => response.json())
                    .then((responseData) => {
                      if (responseData.success) {
                        alert("Data submitted successfully");
  
                        // Check if annual_package is true and store date_of_expiry
                        if (responseData.annual_package) {
                          localStorage.setItem("date_of_expiry", responseData.date_of_expiry);
                        }
                        reloadPage();

                      } else {
                        alert("Failed to submit data");
                      }
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                      alert("An error occurred while submitting data");
                    });
                } else {
                  alert("Failed to submit data");
                }
              })
              .catch((error) => {
                console.error("Error:", error);
                alert("An error occurred while submitting data");
              });
          },
          prefill: {
            name: "",
            email: "",
            contact: "",
          },
          notes: {
            address: "",
          },
          theme: {
            color: "#000",
          },
          config: {
            display: {
              blocks: {
                banks: {
                  name: "All payment methods",
                  instruments: [
                    { method: "upi" },
                    { method: "card" },
                    { method: "wallet" },
                    { method: "netbanking" },
                  ],
                },
              },
              preferences: {
                show_default_blocks: true,
              },
            },
          },
        };
  
        let rzp1 = new Razorpay(options);
        rzp1.on("payment.failed", function (response) {
          console.log(response.error.code);
          console.log(response.error.description);
          console.log(response.error.source);
          console.log(response.error.step);
          console.log(response.error.reason);
          console.log(response.error.metadata.order_id);
          console.log(response.error.metadata.payment_id);
          alert("Oops ! Payment Failed !");
        });
        rzp1.open();
      }
    } catch (error) {
      console.error(error);
      alert("Something Went Wrong..!!");
    }
  };
  
  
  return (
    <div>
      {modalPackageInvoiceIsOpen && (
        <PackageInvoice onClose={closePackageInvoice} />
      )}

      <div className="popup-background" style={{ zIndex: '999' }}>
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "85%",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Invoice Summary</b>
              </h5>

              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  onClose();
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: 'scroll' }}>
              <div style={{ maxWidth: '800px', margin: '0 auto', padding: '20px', color: 'black' }}>
                <h4 style={{ textAlign: 'center' }}>ARTIQUITY EDUCATION LLP</h4>
                <div className="col-md-12 row" style={{ justifyContent: 'space-between', marginTop: '20px' }}>
                  <div className="col-md-7">
                    <p style={{ marginBottom: '0px' }}>801 BramhaCorp Vantage Tower,</p>
                    <p style={{ marginBottom: '0px' }}>Ram Nagar, Bavdhan-411021,Pune</p>
                    <p style={{ marginBottom: '0px' }}>State: Maharashtra, State Code-27</p>
                    <p style={{ marginBottom: '0px' }}>PAN: ACAFA1203F</p>
                    <p style={{ marginBottom: '0px' }}>CIN: ABZ-0893</p>
                  </div>
                  <div className="col-md-5">
                    <p style={{ marginBottom: '0px' }}>Email: <a href="mailto:cs@artiquity.in">cs@artiquity.in</a></p>
                    <p style={{ marginBottom: '0px' }}>Contact: +917066263675</p>
                    <p style={{ marginBottom: '0px' }}>Website: <a href="https://artiquity.in">https://artiquity.in</a></p>
                  </div>
                </div>
                <br />
                <h5>BILL TO</h5>
                <div className="col-md-12 row" style={{ justifyContent: 'space-between', marginTop: '20px' }}>
                  <div className="col-md-6">
                    <p style={{ marginBottom: '0px' }}>Name: {invoiceData?.bill_to?.name}</p>
                    <p style={{ marginBottom: '0px' }}>Email: {invoiceData?.bill_to?.email}</p>
                    <p style={{ marginBottom: '0px' }}>GST: {invoiceData?.bill_to?.gst || '-'}</p>
                    <p style={{ marginBottom: '0px' }}>PAN: {invoiceData?.bill_to?.pan || '-'}</p>
                  </div>
                  <div className="col-md-6">
                  <p style={{ marginBottom: '0px' }}>Business Name: {invoiceData?.bill_to?.business_name}</p>
                    <p style={{ marginBottom: '0px' }}>Contact No.: {invoiceData?.bill_to?.contact_no}</p>
                    <p style={{ marginBottom: '0px' }}>Supply Type: B to B</p>
                    <p style={{ marginBottom: '0px' }}>Address: {invoiceData?.bill_to?.address}</p>
                  </div>
                </div>

                <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>DESCRIPTION</th>
                      <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>HSN/SAC</th>
                      <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>AMOUNT</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>{invoiceData?.package_name} (package code {invoiceData?.package_code})</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>{invoiceData?.hsn_code}</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.description_amount}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>IGCST</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>CGST</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}> 9%</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.cgst_amount}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>SGST</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}> 9%</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.sgst_amount}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>GST</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}> 18%</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.gst_amount}</td>
                    </tr>
                    <tr>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px', fontWeight: 'bold' }}>GRAND TOTAL</td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                      <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px', fontWeight: 'bold' }}>Rs. {invoiceData?.total_amount} <p><strong>Rs. {invoiceData?.total_amount_words} only</strong></p></td>
                    </tr>
                  </tbody>
                </table>

                 {/* New table starts here */}
          <table style={{ width: '100%', marginTop: '20px', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>HSN/SAC</th>
                <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Taxable Value</th>
                <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }} colspan="2">CGST</th>
                <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }} colspan="2">SGST/UTGST</th>
                <th style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Total Tax Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>{invoiceData?.hsn_code}</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.description_amount}</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>9%</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.cgst_amount}</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>9%</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.sgst_amount}</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.gst_amount}</td>
              </tr>
              <tr>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Total</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.description_amount}</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.cgst_amount}</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}></td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.sgst_amount}</td>
                <td style={{ border: '1px solid rgb(0 0 0 / 55%)', padding: '10px' }}>Rs. {invoiceData?.gst_amount}</td>
              </tr>
            </tbody>
          </table>
          {/* New table ends here */}

                <div style={{ marginTop: '20px' }}>
                  <h6 style={{ fontWeight: 'bold' }}>Terms & Conditions</h6>
                  <p style={{ marginBottom: '0px' }}>1. This invoice is recognised subject to realization of the payment</p>
                  <p style={{ marginBottom: '0px' }}>2. Refer to terms & conditions at <a href="http://www.artiquity.in">www.artiquity.in</a></p>
                  <p style={{ marginBottom: '0px' }}>3. All disputes subject to Pune jurisdiction only</p>
                </div>

                <div style={{ marginTop: '20px' }}>
                  <h6 style={{ fontWeight: 'bold' }}>Bank Details</h6>
                  <p style={{ marginBottom: '0px' }}>Artiquity education llp</p>
                  <p style={{ marginBottom: '0px' }}>IDFC First Bank, Baner, Pune</p>
                  <p style={{ marginBottom: '0px' }}>A/c no. 10181851381</p>
                  <p style={{ marginBottom: '0px' }}>IFSC code: IDFB0041375</p>
                </div>
              </div>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </button>
              <button
                type="button"
                class="btn btn-primary "
                style={{ background: "#000 ", border: "none" }}
                onClick={() => {
                  const cleanedAmount = String(invoiceData?.total_amount).replace(/\/-$/, '');
                  handlePayment(cleanedAmount);
                }}
              >
                Confirm to Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageInvoice;
