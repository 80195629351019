import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import { TailSpin } from "react-loader-spinner";
import "./Style.css";
import Select2 from 'react-select2-wrapper';
import 'select2/dist/css/select2.css';
import "../../Api/apiUrls";
import { getAdminEvents } from "../../Api/apiUrls";
import $ from 'jquery';
import AddEvents from "../../Components/Admin_components/addEvents.js";
import DeleteEvents from "../../Components/Admin_components/deleteEvents.js";
import UpdateEvents from "../../Components/Admin_components/updateEvents.js";
import EventAddMedia from "../../Components/Admin_components/event_addMedia.js";

function Events() {
    const [modalAddEventIsOpen, setAddEventModalIsOpen] =
        useState("");
    const [modalDeleteEventsIsOpen, setDeleteEventsIsOpen] = useState(false);
    const [modalUpdateEventsIsOpen, setUpdateEventsModalIsOpen] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [selectedeventStatus, setSelectedEventStatus] = useState("");
    const [SelectedeventType, setSelectedEventType] = useState("");
    const [SelectedeventTitle, setSelectedEventTitle] = useState("");
    const [SelectedeventDescription, setSelectedEventDescription] = useState("");
    const [SelectedeventKeywords, setSelectedEventKeywords] = useState("");
    const [SelectedeventSummary, setSelectedEventSummary] = useState("");
    const [SelectedeventOrganizer, setSelectedEventOrganizer] = useState("");
    const [SelectedeventOrganizerBio, setSelectedEventOrganizerBio] = useState("");
    const [SelectedeventOrganizerLogo, setSelectedEventOrganizerLogo] = useState("");
    const [SelectedeventCity, setSelectedEventCity] = useState("");
    const [SelectedeventDate, setSelectedEventDate] = useState("");
    const [SelectedeventInstructor, setSelectedEventInstructor] = useState("");

    const [modalAddEventmediaIsOpen, setAddEventMediaModalIsOpen] = useState("");
    const openAddEventmedia = (eventId) => {
        setSelectedEventId(eventId);
        closeAddEventmedia();
        setAddEventMediaModalIsOpen(true);
    };

    const closeAddEventmedia = () => {
        setAddEventMediaModalIsOpen(false);
    };
    const openUpdateEvents = (eventId, eventTitle, eventDescription, eventKeywords, eventSummary,
        eventOrganizer,
        eventOrganizerBio,
        eventOrganizerLogo,
        eventCity,
        eventDate,
        eventInstructor,
        eventType,
        eventStatus

    ) => {
        setSelectedEventId(eventId);
        setSelectedEventTitle(eventTitle);
        setSelectedEventDescription(eventDescription);
        setSelectedEventKeywords(eventKeywords);
        setSelectedEventSummary(eventSummary);
        setSelectedEventOrganizer(eventOrganizer);
        setSelectedEventOrganizerBio(eventOrganizerBio);
        setSelectedEventOrganizerLogo(eventOrganizerLogo);
        setSelectedEventCity(eventCity);
        setSelectedEventDate(eventDate);
        setSelectedEventInstructor(eventInstructor);
        setSelectedEventType(eventType);
        setSelectedEventStatus(eventStatus);
        closeUpdateEvents();
        setUpdateEventsModalIsOpen(true);
    };
    const closeUpdateEvents = () => {
        setUpdateEventsModalIsOpen(false);
    };

    const openAddEvent = () => {
        closeAddEvent();
        setAddEventModalIsOpen(true);
    };
    const closeAddEvent = () => {
        setAddEventModalIsOpen(false);
    };

    const openDeleteEvents = (eventId) => {
        closeDeleteEevents();
        setSelectedEventId(eventId);
        setDeleteEventsIsOpen(true);
    };

    const closeDeleteEevents = () => {
        setDeleteEventsIsOpen(null);
    };

    const [events, setEvents] = useState(false);

    useEffect(() => {
        fetch(getAdminEvents)
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                const events = data.data.Events;
                setEvents(events);

            })
            .catch((error) => console.error("Error fetching data:", error))
            .finally(() => {
                setIsLoading(false);
            });
    }, []);


    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredEvents = Array.isArray(events)
        ? events.filter((events) => {
            const values = Object.values(events).map((value) =>
                typeof value === "string" ? value.toLowerCase() : ""
            );
            const isSearchMatch = values.some((value) =>
                value.includes(searchQuery.toLowerCase())
            );
            return isSearchMatch;
        })
        : [];

    const formatCreatedAt = (createdAt) => {
        const createdAtDate = new Date(createdAt);

        // Format the date part
        const datePart = createdAtDate.toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
        });

        // Format the time part
        const timePart = createdAtDate.toLocaleTimeString("en-GB", {
            hour: "numeric",
            minute: "numeric",

            hour12: true,
        });

        return `${datePart}`;
    };
    return (
        <div>
            <Header></Header>
            {modalAddEventIsOpen && (
                <AddEvents onClose={closeAddEvent} />
            )}
            {modalDeleteEventsIsOpen && (
                <DeleteEvents
                    eventId={selectedEventId}
                    onClose={closeDeleteEevents}
                />
            )}
            {modalUpdateEventsIsOpen && (
                <UpdateEvents
                    eventId={selectedEventId}
                    eventTitle={SelectedeventTitle}
                    eventDescription={SelectedeventDescription}
                    eventKeywords={SelectedeventKeywords}
                    eventSummary={SelectedeventSummary}
                    eventOrganizer={SelectedeventOrganizer}
                    eventOrganizerBio={SelectedeventOrganizerBio}
                    eventOrganizerLogo={SelectedeventOrganizerLogo}
                    eventCity={SelectedeventCity}
                    eventDate={SelectedeventDate}
                    eventInstructor={SelectedeventInstructor}
                    eventType={SelectedeventType}
                    eventStatus={selectedeventStatus}
                    onClose={closeUpdateEvents} />
            )}

            {modalAddEventmediaIsOpen && (
                <EventAddMedia onClose={closeAddEventmedia} eventId={selectedEventId} />
            )}
            <main id="main" class="main">
                <div class="pagetitle">
                    <h1>Events</h1>
                </div>
                <section class="section">
                    <div class="card p-2">
                        <div class="parent col-md-12 row mt-2">
                            <div class="col-md-6">
                                <div class="child">
                                    <input
                                        onChange={handleSearchChange}
                                        value={searchQuery}
                                        id="myInput"
                                        type="text"
                                        style={{
                                            borderRadius: "5px",
                                            height: "35px",
                                            padding: "10px",
                                            border: "1px solid lightgrey ",
                                        }}
                                        placeholder="Search..."
                                    />
                                </div>
                            </div>

                            <div class="col-md-6" style={{ textAlign: "right" }}>
                                <div class="child">
                                    <a>
                                        {" "}
                                        <button
                                            id="btnNewUser"
                                            type="button"
                                            data-toggle="modal"
                                            data-target="#modalAdd"
                                            class=" btn-round"
                                            onClick={openAddEvent}
                                        >
                                            <i class="bi bi-person-add"></i> Add Event
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}>
                        </div>
                        <div class="table-responsive">
                            {isLoading === true ? (
                                <div
                                    style={{
                                        marginTop: "50px",
                                        display: "flex",
                                        marginLeft: "50%",
                                    }}
                                >
                                    <TailSpin color="black" width={50} />
                                </div>
                            ) : events.length > 0 ? (
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sr.No</th>
                                            <th scope="col">Organizer Logo</th>
                                            <th scope="col">Title</th>
                                            <th scope="col">Organizer</th>
                                            <th scope="col">City</th>
                                            <th scope="col">Instructor Details</th>
                                            <th scope="col">Keywords</th>
                                            <th scope="col">Visit</th>
                                            <th scope="col">Media Count</th>
                                            <th scope="col">Workshop Date</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Created On</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody id="tableBody">
                                        {filteredEvents.map((events, index) => (
                                            <tr key={events.id}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{<img
                                                    src={`https://artiquity.in${events.organizer_image}`}
                                                    style={{ width: "100px", height: "100px" }}
                                                    alt="Cover"
                                                /> || "---"}</td>
                                                <td>{events.title || "---"}</td>
                                                <td>{events.organizer || "---"}</td>
                                                <td>{events.city || "---"}</td>
                                                <td>
                                                    {events.instructor?.name || "---"}
                                                    <br />
                                                    {events.instructor?.mobileNumber || "---"}
                                                    <br />
                                                    {events.instructor?.email || "---"}
                                                    <br />
                                                </td>
                                                <td>{events?.keywords || "-"}</td>
                                                <td>{events.visit || "0"}</td>
                                                <td>{events.media_count || "0"}</td>
                                                <td>{formatCreatedAt(events.event_date || "--")}</td>
                                                <td>{events.status || "--"}</td>
                                                <td>{formatCreatedAt(events.created_at)}</td>
                                                <td>
                                                    <div className="social-links mt-2">
                                                        <a
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() =>
                                                                openUpdateEvents(
                                                                    events.id,
                                                                    events.title,
                                                                    events.description,
                                                                    events.keywords,
                                                                    events.short_summary,
                                                                    events.organizer,
                                                                    events.bio,
                                                                    events.organizer_image,
                                                                    events.city,
                                                                    events.event_date,

                                                                    {
                                                                        value: events.instructor.id,
                                                                        label: events.instructor.name,
                                                                    },

                                                                    { value: events.id, label: events.event_type },

                                                                    { value: events.id, label: events.status }
                                                                )
                                                            }
                                                        >
                                                            <i class="bi bi-pencil"></i>
                                                        </a>
                                                        &nbsp;&nbsp;
                                                        <a
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => openDeleteEvents(events.id)}
                                                        >
                                                            <i class="bi bi-trash3"></i>
                                                        </a>
                                                        <br />
                                                        <a
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => openAddEventmedia(events.id)}
                                                        >
                                                            <i class="bi bi-camera"></i>
                                                        </a>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>

                            ) : (
                                <h3 style={{ textAlign: "center" }}> No data available </h3>
                            )}


                        </div>
                    </div>
                    <div>
                        {" "}
                        <p
                            style={{
                                backgroundColor: "#000",
                                color: "white",
                                padding: "10px",
                            }}
                        >{filteredEvents.length} Total
                        </p>
                    </div>
                </section>
            </main>
            <Footer></Footer>

        </div>
    )
}

export default Events
