import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_appointment.css";
import Select2 from 'react-select2-wrapper';
import 'select2/dist/css/select2.css';
import $ from 'jquery';
import "../../Api/apiUrls";
import { API_URL } from "../../Api/apiUrls";
import Select from "react-select";

function UploadInvoicePopup({ onClose }) {

    const [modalUploadInvoiceIsOpen, setUploadInvoiceModalIsOpen] =
        useState("");
    const [workshops, setWorkshops] = useState([]);
    const [isAddingappointment, setIsAddingappointment] = useState(false);
    const [selectedWorkshop, setSelectedWorkshop] = useState(null);
    const instructorId = localStorage.getItem("user_id");

    const closeUploadInvoice = () => {
        setUploadInvoiceModalIsOpen(false);
    };

    function reloadPage() {
        window.location.reload();
      }

    useEffect(() => {
        fetchWorkshopsByInstructor();
    }, []);

    const fetchWorkshopsByInstructor = () => {
        fetch(`${API_URL}/workshop/get-workshop_for_website_by_workshop_instructor_id/?workshop_instructor_id=${instructorId}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const workshopOptions = data.data.Workshop.map(workshop => ({
                        id: workshop.id,
                        text: workshop.name,
                    }));
                    setWorkshops(workshopOptions);
                } else {
                    setWorkshops([]);
                }
            })
            .catch(error => {
                console.error('Error fetching workshops:', error);
                setWorkshops([]);
            });
    };

    const handleWorkshopChange = (e) => {
        const workshopId = e.target.value;
        setSelectedWorkshop(workshopId);
        fetchAppointmentSlotsByWorkshop(workshopId); 
    };


    const [appointmentSlots, setAppointmentSlots] = useState([]);

    const [selectedAppointmentSlot, setSelectedAppointmentSlot] = useState(null);
    const fetchAppointmentSlotsByWorkshop = (workshopId) => {
        fetch(`${API_URL}/workshop_slot/get-workshop_slot_by_workshop_id/?WorkshopSlot_workshop_id=${workshopId}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    const slotOptions = data.data.WorkshopSlot.map(slot => ({
                        id: slot.id,
                        date: slot.date,         
                        startTime: slot.startTime, 
                        endTime: slot.endTime,  
                    }));
                    setAppointmentSlots(slotOptions); 
                } else {
                    setAppointmentSlots([]); 
                }
            })
            .catch(error => {
                console.error('Error fetching appointment slots:', error);
                setAppointmentSlots([]);
            });
    };

    const [appointments, setAppointments] = useState([]);
    const [file, setIconFile] = useState();

    const handleFileChange = (event) => {
        const file = event.target.files[0];

        setIconFile(file);
        console.log(file);

        const formData = new FormData();
        formData.append("document", file);

        if (file.type === "application/pdf") {
            const reader = new FileReader();
            reader.onload = function (e) {
                console.log("PDF selected: ", e.target.result);
            };
            reader.readAsDataURL(file);
        } else {
            console.log("Please select a valid PDF file.");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Create form data to append values
        const formData = new FormData();
        formData.append('user', instructorId);
        formData.append('workshop_slot', selectedAppointmentSlot);
        formData.append('workshop', selectedWorkshop);
        formData.append('invoice', file);

        // Send POST request to the API
        fetch(`${API_URL}/upload_invoice/add/`, {
            method: 'POST',
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert('Invoice successfully Uploaded!');
                    resetForm();
                    reloadPage();
                } else {
                    alert('Failed to create appointment.');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const resetForm = () => {
        setSelectedWorkshop(null);
        setSelectedAppointmentSlot(null);
    };

    return (
        <div>
            {modalUploadInvoiceIsOpen && (
                <UploadInvoicePopup onClose={closeUploadInvoice} />
            )}
            <div className="popup-background">
                <div className="popup">
                    <div
                        class="modal-content"
                        style={{
                            width: "100%",
                            height: "80%",
                            padding: "2%",
                            backgroundColor: "#fff",
                            borderRadius: "5px",
                        }}
                    >
                        <div
                            class="modal-header"
                            style={{
                                width: "100%",
                                marginBottom: "3%",
                                borderBottom: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <h5
                                class="modal-title"
                                id="modalAddTitle"
                                style={{ marginBottom: "2%" }}
                            >
                                <b>Upload Invoice</b>
                            </h5>

                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                    // reloadPage();
                                }}
                                class="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div
                            class="modal-body"
                            style={{ overflowY: "scroll", padding: "2%" }}
                        >
                            <form>


                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="workshopSelect">
                                        Select Workshop <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                    </label>
                                    <Select2
                                        id="workshopSelect"
                                        data={workshops}
                                        value={selectedWorkshop}
                                        onChange={handleWorkshopChange}
                                        options={{
                                            placeholder: 'Select a workshop',
                                            allowClear: true,
                                        }}
                                        style={{ width: '100%' }}
                                    />
                                </div>

                                <div className="form-group">
                                    <label className="small mb-1" htmlFor="appointmentSelect">
                                        Select Appointment Slot/Date <span style={{ color: 'red', fontSize: '10px' }}>*</span>
                                    </label>
                                    <Select2
                                        id="appointmentSelect"
                                        data={appointmentSlots.map(slot => {
                                            // Format date and time
                                            const formattedDate = new Date(slot.date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            });
                                            const startTime = new Date(`1970-01-01T${slot.startTime}`).toLocaleTimeString('en-US', {
                                                hour: 'numeric',
                                                minute: '2-digit',
                                            });
                                            const endTime = new Date(`1970-01-01T${slot.endTime}`).toLocaleTimeString('en-US', {
                                                hour: 'numeric',
                                                minute: '2-digit',
                                            });

                                            // Return formatted options for Select2
                                            return {
                                                id: slot.id,
                                                text: `${formattedDate} | ${startTime} - ${endTime}`, // Display in the dropdown
                                            };
                                        })}
                                        value={selectedAppointmentSlot}
                                        onChange={(e) => setSelectedAppointmentSlot(e.target.value)}

                                        options={{
                                            placeholder: 'Select an appointment slot',
                                            allowClear: true,
                                        }}
                                        style={{ width: '100%' }} // Full-width dropdown
                                    />
                                </div>

                                <div class="form-group">
                                    <label class="small mb-1" for="inputDocument">Invoice</label>
                                    <br />
                                    <input
                                        type="file"
                                        id="selectedImg"
                                        name="document"
                                        accept=".pdf"
                                        onChange={handleFileChange}
                                    />
                                </div>

                            </form>

                        </div>

                        <div
                            class="modal-footer"
                            style={{
                                padding: "2%",
                                marginTop: "3%",
                                borderTop: "solid 1px #7e7a7a8c",
                            }}
                        >
                            <button
                                type="button"
                                class="btn btn-danger"
                                data-dismiss="modal"
                                onClick={() => {
                                    onClose();
                                    // reloadPage();
                                }}
                            >
                                Close
                            </button>
                            {isAddingappointment === true ? (
                                <div>
                                    <ThreeDots color="#000" height={50} width={50} />
                                </div>
                            ) : (
                                <button
                                    id="btnAdd"
                                    type="button"
                                    class="btn btn-primary "
                                    style={{ background: "#000 ", border: "none" }}
                                    onClick={handleSubmit}
                                >
                                    Add
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default UploadInvoicePopup
