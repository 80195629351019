import React, { useState, useEffect, useRef } from "react";
import { ThreeDots } from "react-loader-spinner";
import "../../Pages/Admin/Style.css";
import "./add_review_rating.css";
import { BsStarFill, BsStar } from "react-icons/bs";
import "../../Api/apiUrls";
import { addAdminReviewRating } from "../../Api/apiUrls";
function Add_review_rating({ onClose }) {
  const [modalAdd_review_ratingIsOpen, setAdd_review_ratingModalIsOpen] =
    useState("");
  const [rating, setRating] = useState("");
  const [review, setReview] = useState("");
  const [name, setName] = useState("");
  const [addedBy, setAddedBy] = useState("");
  const [isAddingreview_rating, setIsAddingreview_rating] = useState(false);
  const handleStarClick = (section, selectedRating) => {
    switch (section) {
      case "rating":
        if (selectedRating === rating) {
          // If the same star is clicked again, reset to half-star (0.5)
          setRating(selectedRating - 0.5);
        } else {
          setRating(selectedRating);
        }
        console.log(selectedRating);
        break;
    }
  };
  const closeAdd_review_rating = () => {
    setAdd_review_ratingModalIsOpen(false);
  };

  function reloadPage() {
    window.location.reload();
  }
  async function add_review_rating(name, review, rating) {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("review", review);
      formData.append("rating", rating);
      formData.append("addedBy", "Admin");

      const response = await fetch(addAdminReviewRating, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        return true; // Category added successfully
      } else {
        throw new Error("Failed to add review rating");
      }
    } catch (error) {
      console.error("Error adding review rating:", error);
      return false; // Category addition failed
    }
  }

  const handleAdd_review_rating = async () => {
    if (!name) {
      alert("Please fill in all fields");
      return;
    }

    setIsAddingreview_rating(true);

    const result = await add_review_rating(name, review, rating);

    setIsAddingreview_rating(false);

    if (result) {
      alert("Review Rating Added Successfully");
      console.log(setRating);
      setRating("");
      window.location.reload();
      // setIconFile(null);
      onClose(); // Close the modal
    } else {
      alert("Error Adding Review Rating");
    }
  };

  function reloadPage() {
    window.location.reload();
  }
  function reloadPage() {
    window.location.reload();
  }

  const handleClick = (e) => {
    if (e.currentTarget === e.target) {
      onClose();
      reloadPage();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [handleClick]);

  return (
    <div>
      {modalAdd_review_ratingIsOpen && (
        <add_review_rating onClose={closeAdd_review_rating} />
      )}
      <div className="popup-background">
        <div className="popup">
          <div
            class="modal-content"
            style={{
              width: "100%",
              height: "auto",
              padding: "2%",
              backgroundColor: "#fff",
              borderRadius: "5px",
            }}
          >
            <div
              class="modal-header"
              style={{
                width: "100%",
                marginBottom: "3%",
                borderBottom: "solid 1px #7e7a7a8c",
              }}
            >
              <h5
                class="modal-title"
                id="modalAddTitle"
                style={{ marginBottom: "2%" }}
              >
                <b>Add Review</b>
              </h5>

              <button
                type="button"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div
              class="modal-body"
              style={{ overflowY: "none", padding: "2%" }}
            >
              <form>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Name<font color="red">*</font>
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div class="form-group">
                  <label class="small mb-1" for="inputFullName">
                    Review
                  </label>
                  <input
                    class="form-control"
                    id="inputFullName"
                    type="text"
                    value={review}
                    onChange={(e) => setReview(e.target.value)}
                  />
                </div>
                <div className="form-group">
                  <label className="small mb-1" htmlFor="inputUsername">
                    Rating
                    <font color="red"></font>
                  </label>
                  <br />
                  <div className="rating-stars">
                    {[1, 2, 3, 4, 5].map((index) => (
                      <span
                        key={index}
                        className="star-icon"
                        onClick={() => handleStarClick("rating", index)}
                      >
                        {rating >= index ? (
                          <BsStarFill
                            style={{
                              fontSize: "14px",
                              color: "gold",
                            }}
                          />
                        ) : (
                          <BsStar
                            style={{
                              fontSize: "14px",
                              color: "gray",
                            }}
                          />
                        )}
                      </span>
                    ))}
                  </div>
                </div>
              </form>
            </div>

            <div
              class="modal-footer"
              style={{
                padding: "2%",
                marginTop: "3%",
                borderTop: "solid 1px #7e7a7a8c",
              }}
            >
              <button
                type="button"
                class="btn btn-danger"
                data-dismiss="modal"
                onClick={() => {
                  onClose();
                  reloadPage();
                }}
              >
                Close
              </button>
              {isAddingreview_rating === true ? (
                <div>
                  <ThreeDots color="#000" height={50} width={50} />
                </div>
              ) : (
                <button
                  id="btnAdd"
                  type="button"
                  class="btn btn-primary "
                  style={{ background: "#000 ", border: "none" }}
                  onClick={handleAdd_review_rating}
                >
                  Add
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Add_review_rating;
