import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
// import { TailSpin } from "react-loader-spinner";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Style.css";
import Add_VenueownerCategories from "../../Components/Venue_owner_comp/add_categories.js";
import Update_VenueownerCategories from "../../Components/Venue_owner_comp/update_categories.js";
import Delete_VenueownerCategories from "../../Components/Venue_owner_comp/delete_categories.js";

function VenueownerCategories() {
  const [
    modalAdd_VenueownerCategoriesIsOpen,
    setAdd_VenueownerCategoriesModalIsOpen,
  ] = useState(false);
  const [
    modalUpdate_VenueownerCategoriesIsOpen,
    setUpdate_VenueownerCategoriesModalIsOpen,
  ] = useState(false);
  const [
    modalDelete_VenueownerCategoriesIsOpen,
    setDelete_VenueownerCategoriesIsOpen,
  ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const Status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const openAdd_VenueownerCategories = () => {
    closeAdd_VenueownerCategories();
    setAdd_VenueownerCategoriesModalIsOpen(true);
  };

  const closeAdd_VenueownerCategories = () => {
    setAdd_VenueownerCategoriesModalIsOpen(false);
  };

  const openUpdate_cVenueownerCategories = () => {
    closeUpdate_VenueownerCategories();
    setUpdate_VenueownerCategoriesModalIsOpen(true);
  };

  const closeUpdate_VenueownerCategories = () => {
    setUpdate_VenueownerCategoriesModalIsOpen(false);
  };
  const openDelete_VenueownerCategories = (userId) => {
    setSelectedUserId(userId);
    closeDelete_VenueownerCategories();
    setDelete_VenueownerCategoriesIsOpen(true);
  };

  const closeDelete_VenueownerCategories = () => {
    setDelete_VenueownerCategoriesIsOpen(null);
  };

  return (
    <div>
      <Header></Header>
      {modalAdd_VenueownerCategoriesIsOpen && (
        <Add_VenueownerCategories onClose={closeAdd_VenueownerCategories} />
      )}
      {modalUpdate_VenueownerCategoriesIsOpen && (
        <Update_VenueownerCategories
          onClose={closeUpdate_VenueownerCategories}
        />
      )}
      {modalDelete_VenueownerCategoriesIsOpen && (
        <Delete_VenueownerCategories
          userId={selectedUserId}
          onClose={closeDelete_VenueownerCategories}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1> Categories</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Categories</li>
            </ol>
          </nav>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div class="child ml-2">
                  <Select options={Status} placeholder="Select Status"></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class="btn-round"
                      onClick={openAdd_VenueownerCategories}
                    >
                      <i class="bi bi-person-add"></i> Add Categories
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <table class="table table-striped  ">
              <thead>
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Category Tag</th>
                  <th scope="col">Status</th>
                  <th scope="col">Show on Home Page</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>
                    <div className="social-links mt-2">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openUpdate_cVenueownerCategories}
                      >
                        {" "}
                        <i class="bi bi-pencil"></i>
                      </a>
                      &nbsp;&nbsp;
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openDelete_VenueownerCategories}
                      >
                        {" "}
                        <i class="bi bi-trash3"></i>
                      </a>
                      &nbsp;
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>
                    <div className="social-links mt-2">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openUpdate_cVenueownerCategories}
                      >
                        {" "}
                        <i class="bi bi-pencil"></i>
                      </a>
                      &nbsp;&nbsp;
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openDelete_VenueownerCategories}
                      >
                        {" "}
                        <i class="bi bi-trash3"></i>
                      </a>
                      &nbsp;
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>
                    <div className="social-links mt-2">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openUpdate_cVenueownerCategories}
                      >
                        {" "}
                        <i class="bi bi-pencil"></i>
                      </a>
                      &nbsp;&nbsp;
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openDelete_VenueownerCategories}
                      >
                        {" "}
                        <i class="bi bi-trash3"></i>
                      </a>
                      &nbsp;
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default VenueownerCategories;
