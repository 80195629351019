import React, { useState, useEffect, useRef } from "react";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Select from "react-select";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../Admin/Style.css";
import Add_InstructorSub_Categories from "../../Components/instructor_comp/add_subcategories.js";
import Update_InstructorSub_Categories from "../../Components/instructor_comp/update_subCategories.js";
import Delete_InstructorSub_Categories from "../../Components/instructor_comp/delete_subCategories.js";
function InstructorSub_Categories() {
  const [
    modalAdd_InstructorSub_CategoriesIsOpen,
    setAdd_InstructorSub_CategoriesModalIsOpen,
  ] = useState("");
  const [
    modalUpdate_InstructorSub_CategoriesIsOpen,
    setUpdate_InstructorSub_CategoriesModalIsOpen,
  ] = useState(false);
  const [
    modalDelete_InstructorSub_CategoriesIsOpen,
    setDelete_InstructorSub_CategoriesIsOpen,
  ] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const Status = [
    { value: "All", label: "All" },
    { value: "Active", label: "Active" },
    { value: "Inactive", label: "Inactive" },
  ];
  const openAdd_InstructorSub_Categories = () => {
    closeAdd_InstructorSub_Categories();
    setAdd_InstructorSub_CategoriesModalIsOpen(true);
  };

  const closeAdd_InstructorSub_Categories = () => {
    setAdd_InstructorSub_CategoriesModalIsOpen(false);
  };
  const openUpdate_InstructorSub_Categories = () => {
    closeUpdate_InstructorSub_Categories();
    setUpdate_InstructorSub_CategoriesModalIsOpen(true);
  };

  const closeUpdate_InstructorSub_Categories = () => {
    setUpdate_InstructorSub_CategoriesModalIsOpen(false);
  };
  const openDelete_InstructorSub_Categories = () => {
    closeDelete_InstructorSub_Categories();
    setDelete_InstructorSub_CategoriesIsOpen(true);
  };

  const closeDelete_InstructorSub_Categories = () => {
    setDelete_InstructorSub_CategoriesIsOpen(null);
  };
  return (
    <div>
      <Header></Header>

      {modalAdd_InstructorSub_CategoriesIsOpen && (
        <Add_InstructorSub_Categories
          onClose={closeAdd_InstructorSub_Categories}
        />
      )}
      {modalUpdate_InstructorSub_CategoriesIsOpen && (
        <Update_InstructorSub_Categories
          onClose={closeUpdate_InstructorSub_Categories}
        />
      )}
      {modalDelete_InstructorSub_CategoriesIsOpen && (
        <Delete_InstructorSub_Categories
          onClose={closeDelete_InstructorSub_Categories}
        />
      )}
      <main id="main" class="main">
        <div class="pagetitle">
          <h1>Sub-Categories</h1>
          <nav>
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/categories">Home</a>
              </li>
              <li class="breadcrumb-item active">Sub-Categories</li>
            </ol>
          </nav>
        </div>
        <section class="section">
          <div class="card p-2">
            <div class="parent col-md-12 row mt-2">
              <div class="col-md-6">
                <div class="child">
                  <input
                    id="myInput"
                    type="text"
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      padding: "10px",
                      border: "1px solid lightgrey ",
                    }}
                    placeholder="Search..."
                  />
                </div>
                <div class="child ml-2">
                  <Select options={Status} placeholder="Select Status"></Select>
                </div>
              </div>
              <div class="col-md-6" style={{ textAlign: "right" }}>
                <div class="child">
                  <a>
                    {" "}
                    <button
                      id="btnNewUser"
                      type="button"
                      data-toggle="modal"
                      data-target="#modalAdd"
                      class=" btn-round"
                      onClick={openAdd_InstructorSub_Categories}
                    >
                      <i class="bi bi-person-add"></i> Add Sub-Categories
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div
              style={{ marginTop: "50px", display: "flex", marginLeft: "50%" }}
            >
              {/* <TailSpin color="black" width={50} /> */}
            </div>
            <table class="table table-striped   ">
              <thead>
                <tr>
                  <th scope="col">Sr.No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Description</th>
                  <th scope="col">Category Tag</th>
                  <th scope="col">Category</th>
                  <th scope="col">Status</th>
                  <th scope="col">Show on Home Page</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>
                    <div className="social-links mt-2">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openUpdate_InstructorSub_Categories}
                      >
                        <i class="bi bi-pencil"></i>
                      </a>
                      &nbsp;&nbsp;
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openDelete_InstructorSub_Categories}
                      >
                        <i class="bi bi-trash3"></i>
                      </a>
                      &nbsp;
                    </div>
                  </td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>@mdo</td>
                  <td>
                    <div className="social-links mt-2">
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openUpdate_InstructorSub_Categories}
                      >
                        <i class="bi bi-pencil"></i>
                      </a>
                      &nbsp;&nbsp;
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={openDelete_InstructorSub_Categories}
                      >
                        <i class="bi bi-trash3"></i>
                      </a>
                      &nbsp;
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <Footer></Footer>
    </div>
  );
}

export default InstructorSub_Categories;
